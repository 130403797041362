import { message, Select, Checkbox, Row, Col } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  ButtonItem,
  InputItem,
  ErrorItem,
  SelectItem,
  InputNumberItem,
} from "../../../../Components/Items"; // Componentes personalizados

const { Option } = Select;

class EditarIncidencia extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
    visible: this.props.visible,
    tiposAcumuladosOptions: null,
    tipoSeleccionado: null,
    derechoSueldoChecked: false,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      this.consultarTiposAcumulados();
      this.setState({ visible: this.props.visible });
      this.cargarDatosIncidencia();
    }
  }

  cargarDatosIncidencia = () => {
    const { incidencia } = this.props;
    if (incidencia && this.formRef.current) {
      this.formRef.current.setFieldsValue({
        codigo: incidencia.codigo,
        nombre: incidencia.nombre,
        tipo: incidencia.tipo,
        valorUnidad: incidencia.valorUnidad,
        derechoSueldo: incidencia.derechoSueldo,
        porcentaje: incidencia.porcentaje,
        disminuyeSeptimo: incidencia.disminuyeSeptimo,
        seConsidera: incidencia.seConsidera,
        tiposAcumulados: incidencia.tiposAcumulados,
      });
      this.setState({
        tipoSeleccionado: incidencia.tipo,
        derechoSueldoChecked: incidencia.derechoSueldo,
      });
    }
  };

  onFinishFailed = (e) => {
    ErrorItem(e);
  };

  consultarTiposAcumulados = () => {
    this.setState({ loading: true });
    axios
      .post(
        "tiposIncidencias/selectDatosParaTipoIncidencia",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          if (res.data.tiposAcumulados && res.data.tiposAcumulados.length > 0) {
            this.setState({
              tiposAcumuladosOptions: res.data.tiposAcumulados.map((tipo) => (
                <Option value={tipo._id} key={tipo._id}>
                  {tipo.nombre}
                </Option>
              )),
            });
            message.success("Tipos de acumulados obtenidos exitosamente");
          } else {
            message.error("No se encontraron tipos de acumulados");
          }
        } else {
          message.error("Error al obtener los tipos de acumulados");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.error("Error al consultar los tipos de acumulados");
      });
  };

  onFinish = (values) => {
    if (values?.codigo && values?.nombre) {
      this.setState({ loading: true });
      const tipoIncidenciaId = this.props.incidencia._id;
      axios
        .post(
          "tiposIncidencias/editarTipoIncidencia",
          {
            ...values,
            empresaId: this.props.empresaId,
            tipoIncidenciaId, // Pasamos el ID de la incidencia a editar
          },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          console.log(values)
          this.setState({ loading: false });
          if (res.data.success === 2) {
            message.success("Tipo de incidencia editado exitosamente");
            this.cerrarModal();
          } else {
            message.error("Error al editar el tipo de incidencia");
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          message.error("Error al comunicarse con el servidor");
        });
    } else {
      message.info("Por favor completa todos los campos requeridos");
    }
  };

  cerrarModal = () => {
    this.setState({ visible: false });
    this.props.cerrarModal();
    if (this.formRef.current) {
      this.formRef.current.resetFields();
    }
  };

  handleTipoChange = (value) => {
    this.setState({ tipoSeleccionado: value });
  };

  handleDerechoSueldoChange = (e) => {
    this.setState({ derechoSueldoChecked: e.target.checked });
  };

  getRules = (field, isDisabled) => {
    return isDisabled ? [] : [{ required: true, message: `Por favor ingresa el ${field}` }];
  };

  render() {
    const { tipoSeleccionado, derechoSueldoChecked } = this.state;

    return (
      <ModalItem
        title="Editar Tipo de Incidencia"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarModal}
        width={1100}
      >
        <Form
          ref={this.formRef}
          name="modalEditarIncidencia"
          layout="vertical"
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
              loading={this.state.loading}
            >
              Guardar Cambios
            </ButtonItem>
          }
        >
          <Row gutter={24}>
            <Col span={12}>
              <FormItem
                name="codigo"
                label="Código de la Incidencia"
                required
                margin
                rules={this.getRules("código de la incidencia", false)}
              >
                <InputItem placeholder="Ingresa el código de la incidencia" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="nombre"
                label="Nombre de la Incidencia"
                required
                margin
                rules={this.getRules("nombre de la incidencia", false)}
              >
                <InputItem placeholder="Ingresa el nombre de la incidencia" />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <FormItem
                name="tipo"
                label="Tipo"
                required
                margin
                rules={this.getRules("tipo de incidencia", false)}
              >
                <SelectItem placeholder="Selecciona el tipo" onChange={this.handleTipoChange}>
                  <Option value={0}>Destajos</Option>
                  <Option value={1}>Días</Option>
                  <Option value={3}>Horas</Option>
                </SelectItem>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="valorUnidad"
                label="Valor por Unidad"
                margin
                rules={this.getRules("valor por unidad", tipoSeleccionado === 1 || tipoSeleccionado === 3)}
              >
                <InputNumberItem
                  placeholder="Ingresa el valor por unidad"
                  disabled={tipoSeleccionado === 1 || tipoSeleccionado === 3}
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <FormItem
                name="derechoSueldo"
                label="Derecho a Sueldo"
                margin
                valuePropName="checked"
              >
                <Checkbox
                  onChange={this.handleDerechoSueldoChange}
                  disabled={tipoSeleccionado === 0}
                >
                  Otorga derecho a sueldo
                </Checkbox>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="porcentaje"
                label="Porcentaje"
                margin
                rules={this.getRules("porcentaje", !derechoSueldoChecked)}
              >
                <InputNumberItem
                  placeholder="Ingresa el porcentaje"
                  disabled={!derechoSueldoChecked}
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <FormItem
                name="disminuyeSeptimo"
                label="Disminuye Séptimo"
                valuePropName="checked"
                margin
              >
                <Checkbox
                  disabled={tipoSeleccionado === 0 || tipoSeleccionado === 3}
                >
                  Disminuye el séptimo día
                </Checkbox>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="seConsidera"
                label="Se Considera"
                margin
                rules={this.getRules("impacto de la incidencia", tipoSeleccionado === 0 || tipoSeleccionado === 3)}
              >
                <SelectItem
                  placeholder="Selecciona el impacto de la incidencia"
                  disabled={tipoSeleccionado === 0 || tipoSeleccionado === 3}
                >
                  <Option value={0}>Ninguno</Option>
                  <Option value={1}>Ausencia</Option>
                  <Option value={2}>Incapacidad</Option>
                  <Option value={3}>Vacaciones</Option>
                </SelectItem>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <FormItem
                name="tiposAcumulados"
                label="Tipos Acumulados"
                margin
              >
                <SelectItem mode="multiple" placeholder="Selecciona los tipos acumulados">
                  {this.state.tiposAcumuladosOptions}
                </SelectItem>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => ({
  headersToken: state.user.headersToken,
  empresaId: state.empresa.id,
});

export default connect(mapStateToProps)(EditarIncidencia);
