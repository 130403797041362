import { Empty, PageHeader, Popconfirm, Space, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem } from "../../../Components/Items";
import moment from "moment";
import "moment/locale/es";
import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import AgregarPeriodo from "./AgregarPeriodos/AgregarPeriodos";
import EditarPeriodo from "./EditarPeriodos/EditarPeriodos";

moment.locale("es");

class Periodos extends Component {
  state = {
    modalAgregar: false,
    modalEditar: false,
    todosPeriodos: [],
    loading: false,
    periodoSeleccionado: null,
  };

  // Abrir modal para agregar
  abrirAgregarPeriodo = () => {
    this.setState({ modalAgregar: true, periodoSeleccionado: null });
  };

  // Cerrar modal de agregar
  cerrarAgregarPeriodo = () => {
    this.setState({ modalAgregar: false });
    this.consultarTodosPeriodos();
  };

  // Abrir modal para editar
  abrirEditarPeriodo = (periodo) => {
    this.setState({ modalEditar: true, periodoSeleccionado: periodo });
  };

  // Cerrar modal de editar
  cerrarEditarPeriodo = () => {
    this.setState({ modalEditar: false });
    this.consultarTodosPeriodos();
  };

  componentDidMount() {
    this.consultarTodosPeriodos();
  }

  // Consultar todos los periodos
  consultarTodosPeriodos() {
    this.setState({ loading: true });
    axios
      .post(
        "periodos/selectTodosPeriodos",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
          message.error("No se encontraron periodos");
        } else {
          this.setState({
            todosPeriodos: res.data.periodos.map((periodo) => ({
              ...periodo,
              key: periodo._id,
              fechaInicio: moment(periodo.fechaInicio).format("DD/MM/YYYY"),
              fechaFin: moment(periodo.fechaFin).format("DD/MM/YYYY"),
            })),
          });
        }
      });
  }

  // Agregar un nuevo periodo
  agregarPeriodo = (datosPeriodo) => {
    axios
      .post(
        "periodos/agregarPeriodo",
        {
          ...datosPeriodo,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === true) {
          message.success("Periodo agregado exitosamente");
          this.consultarTodosPeriodos();
          this.cerrarAgregarPeriodo();
        } else {
          message.error("Error al agregar el periodo");
        }
      });
  };

  // Editar un periodo existente
  editarPeriodo = (datosPeriodo) => {
    axios
      .post(
        "periodos/editarPeriodo",
        {
          ...datosPeriodo,
          empresaId: this.props.empresaId,
          periodoId: this.state.periodoSeleccionado._id,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === true) {
          message.success("Periodo actualizado exitosamente");
          this.consultarTodosPeriodos();
          this.cerrarEditarPeriodo();
        } else {
          message.error("Error al actualizar el periodo");
        }
      });
  };

  // Deshabilitar un periodo
  deshabilitarPeriodo(id) {
    axios
      .post(
        "periodos/deshabilitarPeriodo",
        { periodoId: id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Periodo deshabilitado exitosamente");
          this.consultarTodosPeriodos();
        } else {
          message.error("Error al deshabilitar el periodo");
        }
      });
  }

  // Habilitar un periodo
  habilitarPeriodo(id) {
    axios
      .post(
        "periodos/habilitarPeriodo",
        { periodoId: id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Periodo habilitado exitosamente");
          this.consultarTodosPeriodos();
        } else {
          message.error("Error al habilitar el periodo");
        }
      });
  }

  render() {
    const columns = [
      {
        title: "Número del Periodo",
        dataIndex: "numeroPeriodo",
        key: "numeroPeriodo",
      },
      {
        title: "Fecha Inicio",
        dataIndex: "fechaInicio",
        key: "fechaInicio",
      },
      {
        title: "Fecha Fin",
        dataIndex: "fechaFin",
        key: "fechaFin",
      },
      {
        title: "Días de Pago",
        dataIndex: "diasDePago",
        key: "diasDePago",
      },
      {
        title: "Ejercicio",
        dataIndex: "ejercicio",
        key: "ejercicio",
      },
      {
        title: "Mes",
        dataIndex: "mes",
        key: "mes",
      },
      {
        title: "Acción",
        key: "accion",
        render: (record) => (
          <Space size="middle">
            <EditOutlined
              onClick={() => this.abrirEditarPeriodo(record)}
              style={{ color: "blue" }}
            />
            <>
              {record.estado === 1 ? (
                <Popconfirm
                  title="¿Seguro que quieres deshabilitar este periodo?"
                  onConfirm={() => this.deshabilitarPeriodo(record._id)}
                  okText="Sí"
                  cancelText="No"
                >
                  <CheckCircleOutlined style={{ color: "green" }} />
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="¿Seguro que quieres habilitar este periodo?"
                  onConfirm={() => this.habilitarPeriodo(record._id)}
                  okText="Sí"
                  cancelText="No"
                >
                  <StopOutlined style={{ color: "red" }} />
                </Popconfirm>
              )}
            </>
          </Space>
        ),
      },
    ];

    return (
      <>
        <PageHeader
          title="Periodos"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <ButtonItem
              type="primary"
              key="agregarPeriodo"
              onClick={this.abrirAgregarPeriodo}
            >
              Agregar Periodo
            </ButtonItem>
          }
        />
        <TableItem
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <p>No hay registros de periodos. Aquí puedes gestionar y llevar un control de los periodos.</p>
                  </>
                }
              />
            ),
          }}
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todosPeriodos}
          columns={columns}
          pagination={false}
        />
        <AgregarPeriodo
          visible={this.state.modalAgregar}
          cerrarModal={this.cerrarAgregarPeriodo}
          onGuardar={(datosPeriodo) =>
            this.state.periodoSeleccionado
              ? this.editarPeriodo(datosPeriodo)
              : this.agregarPeriodo(datosPeriodo)
          }
        />
        <EditarPeriodo
          visible={this.state.modalEditar}
          cerrarModal={this.cerrarEditarPeriodo}
          periodo={this.state.periodoSeleccionado}
          onGuardar={(datosPeriodo) =>
            this.editarPeriodo(datosPeriodo)
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(Periodos);
