import {
  Checkbox,
  message,
  Tabs,
  Select,
  PageHeader,
  Col,
  Row,
  Spin,
  Form as FormInitial,
} from "antd";
import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
  Form,
  FormItem,
  InputNumberItem,
  SelectItem,
  cuentaMask,
  HeaderItem,
  TextAreaItem,
  InputItem,
  BoxItem,
  ButtonItem,
  ErrorItem,
} from "../../../../Components/Items";
import AgregarCuentaContable from "../../CuentasContables/AgregarCuentaContable/AgregarCuentaContable";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const { Option } = Select;

class AgregarProveedor extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    todasMonedas: null,
    objetoMonedas: null,
    todasPaises: null,
    objetoPaises: null,
    todasEstados: null,
    objetoEstados: null,
    todasMunicipios: null,
    objetoMunicipios: null,
    todasColonias: null,
    objetoColonias: null,
    todasTipoDeProveedor: null,
    objetoTipoDeProveedor: null,
    todasCuentaContable: null,
    objetoCuentaContable: null,
    todasBancosSat: null,
    objetoBancosSat: null,
    todasTiposDeOperacion: null,
    objetoTiposDeOperacion: null,
    todasTiposDeTerceros: null,
    objetoTiposDeTerceros: null,
    todasRegimenFiscal: null,
    objetoRegimenFiscal: null,
    todasRetenciones: null,
    objetoRetenciones: null,
    modalAgregarCuentaContable: false,
    modalAgregarTipoDeProveedor: false,
  };
  atras() {
    this.props.history.goBack();
  }
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "proveedores/selectDatosParaProveedor",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({
            todasBancosSat: res.data.bancosSat,
            todasTipoDeProveedor: res.data.tipoDeProveedores,
            todasCuentaContable: res.data.cuentasContables,
            todasMonedas: res.data.monedas,
            todasPaises: res.data.paises,
            todasEstados: res.data.estados,
            todasRegimenFiscal: res.data.regimenesFiscales,
            todasRetenciones: res.data.retenciones,
          });
          this.setState({
            objetoMonedas: res.data.monedas.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.abreviacion + " - " + value.nombre}
                </Option>
              );
            }),
            objetoPaises: res.data.paises.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.abreviatura}
                </Option>
              );
            }),
            objetoEstados: res.data.estados.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.abreviatura}
                </Option>
              );
            }),
            objetoCuentaContable: res.data.cuentasContables.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {cuentaMask(
                    value.nivel1 +
                    value.nivel2 +
                    value.nivel3 +
                    value.nivel4 +
                    value.nivel5
                  ) +
                    " - " +
                    value.descripcion}
                </Option>
              );
            }),
            objetoTipoDeProveedor: res.data.tipoDeProveedores.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.descripcion}
                </Option>
              );
            }),
            objetoBancosSat: res.data.bancosSat.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.clave + " - " + value.nombreCorto}
                </Option>
              );
            }),
            objetoRegimenFiscal: res.data.regimenesFiscales.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.c_RegimenFiscal + " - " + value.descripcion}
                </Option>
              );
            }),
            objetoRetenciones: res.data.retenciones.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
          this.formRef.current.setFieldsValue({
            codigo: res.data.sigNumero,
            clave: res.data.sigNumero,
          })
        }
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }

  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "proveedores/agregarProveedor",
        { ...values, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se ha registrado un proveedor");
          this.formRef.current.resetFields();
          this.atras(res.data.codigo);
        } else if (res.data.success === 1) {
          message.error("No se puede registrar el proveedor");
        } else {
          message.error("Hubo un error y el proveedor no se registro");
        }
      });
  }
  abrirAgregarCuentaContable() {
    this.setState({ modalAgregarCuentaContable: true });
  }
  cerrarAgregarCuentaContable(codigo) {
    this.setState({ modalAgregarCuentaContable: false });
    this.consultarTodo().then(() => {
      if (typeof codigo === "string") {
        this.formRef.current.setFieldsValue({ cuentaContableId: codigo });
      }
    });
  }
  onChangeEstado(value, inicial) {
    if (!inicial) {
      this.formRef.current.setFieldsValue({
        municipioId: undefined,
        coloniaId: undefined,
      });
      this.setState({ objetoMunicipios: null, objetoColonias: null });
    }
    axios
      .post(
        "municipios/selectMunicipios",
        { estadoId: value },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasMunicipios: res.data });
          this.setState({
            objetoMunicipios: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
  }
  onChangeMunicipio(value, inicial) {
    if (!inicial) {
      this.formRef.current.setFieldsValue({ coloniaId: undefined });
      this.setState({ objetoColonias: null });
    }
    axios
      .post(
        "colonias/selectColonias",
        { municipioId: value },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasColonias: res.data });
          this.setState({
            objetoColonias: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.cp + " - " + value.nombre}
                </Option>
              );
            }),
          });
        }
      });
  }
  onSearchColonia(value) {
    if (value.toString().length === 5) {
      axios
        .post(
          "colonias/buscarCP",
          { cp: value },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
            message.info("No se encontro el codigo postal");
          } else {
            this.formRef.current.setFieldsValue({
              paisId: res.data.paisId,
              estadoId: res.data.estadoId,
              municipioId: res.data.municipioId,
            });
            this.onChangeEstado(res.data.estadoId, true);
            this.onChangeMunicipio(res.data.municipioId, true);
          }
        });
    }
  }
  onChangeTipoDeProveedor(value) {
    const tipoDeProveedor = this.state.todasTipoDeProveedor.find(
      (valueTodos) => valueTodos._id.toString() === value.toString()
    );
    if (tipoDeProveedor.generarCuentasAutomatico) {
      this.formRef.current.setFieldsValue({
        cuentaProveedorId: "Se generara al guardarlo",
        cuentaAnticipoId: "Se generara al guardarlo",
        generarCuentaAnticipoId: false,
        cuentaFonGarId: "Se generara al guardarlo",
        generarCuentaFonGarId: false,
        cuentaDeudorId: "Se generara al guardarlo",
        generarCuentaDeudorId: false,
      });
    } else {
      this.formRef.current.setFieldsValue({
        cuentaProveedorId: tipoDeProveedor.cuentaProveedorId,
        cuentaAnticipoId: tipoDeProveedor.cuentaAnticipoId,
        generarCuentaAnticipoId: false,
        cuentaFonGarId: tipoDeProveedor.cuentaFonGarId,
        generarCuentaFonGarId: false,
        cuentaDeudorId: tipoDeProveedor.cuentaDeudorId,
        generarCuentaDeudorId: false,
      });
    }
  }
  onSelectColoniaId(coloniaId) {
    const colonia = this.formRef.current.getFieldValue('colonia')
    if (!colonia) {
      const nombreColonia = this.state.todasColonias.find((todasColonia) => todasColonia._id.toString() === coloniaId.toString())?.nombre
      this.formRef.current.setFieldsValue({
        colonia: nombreColonia
      })
    }
  }
  onChangeRegimenFiscal(regimenFiscalId) {
    const retencionAgregar = this.state.todasRetenciones.find(todasRetenciones => todasRetenciones.regimenFiscalId.toString() === regimenFiscalId.toString())
    const retenciones = this.formRef.current.getFieldValue('retenciones')
    if (retencionAgregar) {
      if (retenciones) {
        const findRetencion = retenciones.find(todasRetencionesProv => todasRetencionesProv.retencionId.toString() === retencionAgregar._id.toString())
        if (!findRetencion) {
          this.formRef.current.setFieldsValue({
            retenciones: [...retenciones, { retencionId: retencionAgregar._id }]
          })
        }
      } else {
        this.formRef.current.setFieldsValue({
          retenciones: [{ retencionId: retencionAgregar._id }]
        })
      }
    }
  }
  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Agregar Proveedor"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20, paddingTop: 5 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="agregarProveedor"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Guardar Proveedor
                </ButtonItem>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
              initialValues={{ monedaId: "60c7e4396465ad440c41c15a" }}
            >
              <Tabs defaultActiveKey={1}>
                <TabPane tab="General" tabKey={1} key={1} forceRender>
                  <Row>
                    <Col span={12}>
                      <HeaderItem>DATOS GENERALES</HeaderItem>
                      <BoxItem>
                        <Row>
                          <Col span={12}>
                            <FormItem
                              name="codigo"
                              label="Codigo"
                              margin
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 18 }}
                            >
                              <InputItem disabled placeholder="Codigo" />
                            </FormItem>
                          </Col>
                          <Col span={12}>
                            <FormItem
                              name="clave"
                              label="Clave"
                              margin
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              pattern
                            >
                              <InputItem placeholder="Clave" />
                            </FormItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <FormItem
                              name="persona"
                              label="P. Moral"
                              valuePropName="checked"
                              initialValue={false}
                              margin
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 10 }}
                            >
                              <Checkbox />
                            </FormItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12}>
                            <FormItem
                              label="RFC"
                              name="rfc"
                              required
                              margin
                              rfc
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 18 }}
                            >
                              <InputItem placeholder="RFC" />
                            </FormItem>
                          </Col>
                          <Col span={12}>
                            <FormItem shouldUpdate noStyle>
                              {({ getFieldValue }) => {
                                if (!getFieldValue("persona")) {
                                  return (
                                    <FormItem
                                      label="CURP"
                                      margin
                                      pattern
                                      max={20}
                                      name="curp"
                                      labelCol={{ span: 6 }}
                                      wrapperCol={{ span: 18 }}
                                    >
                                      <InputItem placeholder="Curp" />
                                    </FormItem>
                                  );
                                }
                              }}
                            </FormItem>
                          </Col>
                        </Row>
                        <FormItem shouldUpdate noStyle>
                          {({ getFieldValue }) => {
                            if (getFieldValue("persona")) {
                              return (
                                <FormItem
                                  label="Razon Social"
                                  margin
                                  pattern
                                  required
                                  name="razonSocial"
                                  labelCol={{ span: 3 }}
                                  wrapperCol={{ span: 21 }}
                                >
                                  <InputItem placeholder="Razon Social" />
                                </FormItem>
                              );
                            }
                          }}
                        </FormItem>
                        <Row>
                          <Col span={10}>
                            <FormItem shouldUpdate noStyle>
                              {({ getFieldValue }) => {
                                if (!getFieldValue("persona")) {
                                  return (
                                    <FormItem
                                      label="Nombre"
                                      margin
                                      pattern
                                      required
                                      name="nombre"
                                      labelCol={{ span: 7 }}
                                      wrapperCol={{ span: 17 }}
                                    >
                                      <InputItem
                                        placeholder="Nombre"
                                        width="98%"
                                      />
                                    </FormItem>
                                  );
                                }
                              }}
                            </FormItem>
                          </Col>
                          <Col span={7}>
                            <FormItem shouldUpdate noStyle>
                              {({ getFieldValue }) => {
                                if (!getFieldValue("persona")) {
                                  return (
                                    <FormItem
                                      margin
                                      pattern
                                      required
                                      name="apellidoPaterno"
                                      labelCol={{ span: 0 }}
                                      wrapperCol={{ span: 24 }}
                                    >
                                      <InputItem
                                        placeholder="Appelido Paterno"
                                        width="98%"
                                      />
                                    </FormItem>
                                  );
                                }
                              }}
                            </FormItem>
                          </Col>
                          <Col span={7}>
                            <FormItem shouldUpdate noStyle>
                              {({ getFieldValue }) => {
                                if (!getFieldValue("persona")) {
                                  return (
                                    <FormItem
                                      margin
                                      pattern
                                      required
                                      name="apellidoMaterno"
                                      labelCol={{ span: 0 }}
                                      wrapperCol={{ span: 24 }}
                                    >
                                      <InputItem
                                        placeholder="Appellido Materno"
                                        width="98%"
                                      />
                                    </FormItem>
                                  );
                                }
                              }}
                            </FormItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <FormItem
                              label="Nombre Comercial"
                              margin
                              pattern
                              max={200}
                              name="nombreComercial"
                              labelCol={{ span: 3 }}
                              wrapperCol={{ span: 21 }}
                            >
                              <InputItem placeholder="Nombre Comercial" />
                            </FormItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12}>
                            <FormItem
                              label="Celular"
                              name="celular"
                              number
                              margin
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 14 }}
                            >
                              <InputNumberItem placeholder="Celular" celular />
                            </FormItem>
                            <FormItem
                              label="Telefono"
                              name="telefono"
                              number
                              margin
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 14 }}
                            >
                              <InputNumberItem placeholder="Telefono" celular />
                            </FormItem>
                          </Col>
                          <Col span={12}>
                            <FormItem
                              label="Correo"
                              name="correo"
                              pattern
                              max={200}
                              email
                              margin
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 18 }}
                            >
                              <InputItem placeholder="Correo" />
                            </FormItem>
                            <FormItem
                              label="Pagina Web"
                              name="paginaWeb"
                              pattern
                              max={200}
                              margin
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 18 }}
                            >
                              <InputItem placeholder="Pagina Web" />
                            </FormItem>
                          </Col>
                        </Row>
                        <Col span={24}>
                          <FormItem
                            name="observaciones"
                            label="Observaciones"
                            margin
                            pattern
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                          >
                            <TextAreaItem placeholder="Observaciones" />
                          </FormItem>
                        </Col>
                        <Col span={24}>
                          <HeaderItem>DATOS DEL CONTACTO</HeaderItem>
                          <BoxItem>
                            <Col span={24}>
                              <FormItem
                                label="Nombre
                                  "
                                name="nombreContacto"
                                pattern
                                max={200}
                                margin
                                labelCol={{ span: 2 }}
                                wrapperCol={{ span: 22 }}
                              >
                                <InputItem placeholder="Nombre de Contacto" />
                              </FormItem>
                              <Row>
                                <Col span={12}>
                                  <FormItem
                                    label="Teléfono"
                                    name="telefonoContacto"
                                    number
                                    margin
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 18 }}
                                  >
                                    <InputNumberItem
                                      placeholder="Telefono de Contacto"
                                      celular
                                    />
                                  </FormItem>
                                </Col>
                                <Col span={12}>
                                  <FormItem
                                    label="Correo"
                                    name="correoContacto"
                                    pattern
                                    max={200}
                                    email
                                    margin
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                  >
                                    <InputItem placeholder="Correo de Contacto" />
                                  </FormItem>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <FormItem
                                    label="Personas pedidos"
                                    name="personasPedidos"
                                    pattern
                                    max={200}
                                    margin
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 20 }}
                                  >
                                    <InputItem placeholder="Personas que atienden pedidos" />
                                  </FormItem>
                                </Col>
                              </Row>
                            </Col>
                            <Row>
                              <Col span={24}>
                                <FormItem
                                  label="Jefe de Contacto"
                                  name="jefeContacto"
                                  pattern
                                  max={200}
                                  margin
                                  labelCol={{ span: 4 }}
                                  wrapperCol={{ span: 20 }}
                                >
                                  <InputItem placeholder="Jefe de Contacto" />
                                </FormItem>
                              </Col>
                            </Row>
                            <FormItem
                              label="Pueso Jefe Contacto"
                              name="puestoJefeContacto"
                              pattern
                              max={200}
                              margin
                              labelCol={{ span: 4 }}
                              wrapperCol={{ span: 20 }}
                            >
                              <InputItem placeholder="Pueso del Jefe del Contacto" />
                            </FormItem>
                          </BoxItem>
                        </Col>
                      </BoxItem>
                    </Col>
                    <Col span={12}>
                      <HeaderItem>CREDITO Y BANCOS</HeaderItem>
                      <BoxItem>
                        <Row>
                          <Col span={6}>
                            <FormItem
                              label="Dias crédito"
                              name="diasCredito"
                              numberCero
                              margin
                              labelCol={{ span: 14 }}
                              wrapperCol={{ span: 10 }}
                            >
                              <InputNumberItem placeholder="Dias de Credito" />
                            </FormItem>
                          </Col>
                          <Col span={6}>
                            <FormItem
                              label="Crédito"
                              name="limiteCredito"
                              numberCero
                              margin
                              labelCol={{ span: 14 }}
                              wrapperCol={{ span: 10 }}
                            >
                              <InputNumberItem
                                dinero
                                placeholder="Limite de credito"
                              />
                            </FormItem>
                          </Col>
                          <Col span={12}>
                            <FormItem
                              label="Moneda"
                              name="monedaId"
                              required
                              margin
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                            >
                              <SelectItem placeholder="Tipo de Moneda">
                                {this.state.objetoMonedas}
                              </SelectItem>
                            </FormItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12}>
                            <FormItem
                              label="Banco"
                              name="bancoSatId"
                              margin
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 17 }}
                            >
                              <SelectItem placeholder="Banco">
                                {this.state.objetoBancosSat}
                              </SelectItem>
                            </FormItem>
                            <FormItem
                              label="Cuenta"
                              name="cuentaBanco"
                              number
                              max={20}
                              margin
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 17 }}
                            >
                              <InputItem placeholder="Cuenta de Banco" />
                            </FormItem>
                          </Col>
                          <Col span={12}>
                            <FormItem
                              name="pagoElectronico"
                              label="Pago electrónico"
                              valuePropName="checked"
                              initialValue={true}
                              margin
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 10 }}
                            >
                              <Checkbox />
                            </FormItem>
                            <FormItem
                              label="Cuenta Clabe"
                              name="cuentaClabe"
                              number
                              max={18}
                              min={18}
                              margin
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 18 }}
                            >
                              <InputItem placeholder="Cuenta Clabe" />
                            </FormItem>
                          </Col>
                        </Row>
                      </BoxItem>
                      <HeaderItem>CONTABILIDAD</HeaderItem>
                      <BoxItem>
                        <FormItem
                          name="regimenFiscalId"
                          label="R. Fiscal"
                          margin
                          labelCol={{ span: 4 }}
                          wrapperCol={{ span: 20 }}
                        >
                          <SelectItem onChange={this.onChangeRegimenFiscal.bind(this)} placeholder="Regimen Fiscal">
                            {this.state.objetoRegimenFiscal}
                          </SelectItem>
                        </FormItem>
                        <FormItem
                          label="T. Proveedor"
                          margin
                          name="tipoProveedorId"
                          required
                          labelCol={{ span: 4 }}
                          wrapperCol={{ span: 20 }}
                        >
                          <SelectItem
                            placeholder="Tipo de Proveedor"
                            onChange={this.onChangeTipoDeProveedor.bind(this)}
                          >
                            {this.state.objetoTipoDeProveedor}
                          </SelectItem>
                        </FormItem>
                        <Row>
                          <Col span={12}>
                            <FormItem
                              label="T. Operación DIOT"
                              name="tipoOperacion"
                              required
                              margin
                              initialValue={3}
                              labelCol={{ span: 9 }}
                              wrapperCol={{ span: 15 }}
                            >
                              <SelectItem placeholder="Tipo de Operación DIOT">
                                <Option value={1}>
                                  03 - Prestacion de servicios
                                </Option>
                                <Option value={2}>
                                  06 - Arrendamiento de inmuebles
                                </Option>
                                <Option value={3}>85 - Otros</Option>
                                <Option value={4}>NA - No asignados</Option>
                              </SelectItem>
                            </FormItem>
                          </Col>
                          <Col span={12}>
                            <FormItem
                              label="T. Tercero DIOT"
                              name="tipoTercero"
                              required
                              margin
                              initialValue={1}
                              labelCol={{ span: 9 }}
                              wrapperCol={{ span: 15 }}
                            >
                              <SelectItem placeholder="Tipo de Tercero DIOT">
                                <Option value={1}>
                                  04 - Proveedor Nacional
                                </Option>
                                <Option value={2}>
                                  05 - Proveedor Extranjero
                                </Option>
                                <Option value={3}>15 - Proveedor Global</Option>
                              </SelectItem>
                            </FormItem>
                          </Col>
                        </Row>
                      </BoxItem>
                      <HeaderItem>RETENCIONES</HeaderItem>
                      <BoxItem>
                        <FormInitial.List
                          name="retenciones"
                        >
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ name }, arrayKey) => (
                                <Row>
                                  <Col span={22}>
                                    <FormItem
                                      name={[name, "retencionId"]}
                                      fieldKey={[arrayKey, "retencionId"]}
                                      required
                                      margin
                                      noStyle
                                    >
                                      <SelectItem width='100%' placeholder="Retención">
                                        {this.state.objetoRetenciones}
                                      </SelectItem>
                                    </FormItem>
                                  </Col>
                                  <Col span={2}>
                                    <div style={{ textAlign: 'center' }}>
                                      <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              ))}
                              <div style={{ textAlign: 'center' }}>
                                <ButtonItem
                                  type="primary"
                                  onClick={() => add()}
                                  icon={<PlusOutlined />}
                                >
                                  Agregar
                                </ButtonItem>
                              </div>
                            </>
                          )}
                        </FormInitial.List>
                      </BoxItem>
                      <HeaderItem>CUENTAS CONTABLES</HeaderItem>
                      <BoxItem>
                        <FormItem shouldUpdate noStyle>
                          {({ getFieldValue }) => {
                            const tipoDeProveedorId =
                              getFieldValue("tipoProveedorId");
                            const tipoDeProveedor = tipoDeProveedorId
                              ? this.state.todasTipoDeProveedor.find(
                                (valueTodos) =>
                                  valueTodos._id.toString() ===
                                  tipoDeProveedorId.toString()
                              )
                              : null;
                            const disabled =
                              tipoDeProveedor?.generarCuentasAutomatico
                                ? false
                                : true;
                            return (
                              <>
                                <Row>
                                  <Col span={22}>
                                    <FormItem
                                      label="Proveedor"
                                      name="cuentaProveedorId"
                                      margin
                                      labelCol={{ span: 5 }}
                                      wrapperCol={{ span: 19 }}
                                      tooltip="Selecciona primero un tipo de proveedor"
                                    >
                                      <SelectItem
                                        disabled
                                        placeholder="Cuenta Proveedor"
                                        width="100%"
                                      >
                                        {this.state.objetoCuentaContable}
                                      </SelectItem>
                                    </FormItem>
                                    <Col span={2}></Col>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={22}>
                                    <FormItem
                                      name="cuentaAnticipoId"
                                      label="Anticipo"
                                      margin
                                      labelCol={{ span: 5 }}
                                      wrapperCol={{ span: 19 }}
                                      tooltip="Selecciona primero un tipo de proveedor"
                                    >
                                      <SelectItem
                                        disabled
                                        placeholder="Cuenta Anticipo"
                                        width="100%"
                                      >
                                        {this.state.objetoCuentaContable}
                                      </SelectItem>
                                    </FormItem>
                                  </Col>
                                  <Col span={2}>
                                    <FormItem
                                      noStyle
                                      name="generarCuentaAnticipoId"
                                      valuePropName="checked"
                                      initialValue={true}
                                    >
                                      <Checkbox disabled={disabled}></Checkbox>
                                    </FormItem>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={22}>
                                    <FormItem
                                      label="Fondo Garantía."
                                      tooltip="Selecciona primero un tipo de proveedor"
                                      margin
                                      labelCol={{ span: 5 }}
                                      wrapperCol={{ span: 19 }}
                                      name="cuentaFonGarId"
                                    >
                                      <SelectItem
                                        disabled
                                        placeholder="Cuenta Fondo de Gararantia"
                                        width="100%"
                                      >
                                        {this.state.objetoCuentaContable}
                                      </SelectItem>
                                    </FormItem>
                                  </Col>
                                  <Col span={2}>
                                    <FormItem
                                      noStyle
                                      name="generarCuentaFonGarId"
                                      valuePropName="checked"
                                      initialValue={true}
                                    >
                                      <Checkbox disabled={disabled}></Checkbox>
                                    </FormItem>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={22}>
                                    <FormItem
                                      label="Acreedor"
                                      name="cuentaDeudorId"
                                      margin
                                      labelCol={{ span: 5 }}
                                      wrapperCol={{ span: 19 }}
                                      tooltip="Selecciona primero un tipo de proveedor"
                                    >
                                      <SelectItem
                                        disabled
                                        placeholder="Cuenta Deudor"
                                        width="100%"
                                      >
                                        {this.state.objetoCuentaContable}
                                      </SelectItem>
                                    </FormItem>
                                  </Col>
                                  <Col span={2}>
                                    <FormItem
                                      noStyle
                                      name="generarCuentaDeudorId"
                                      valuePropName="checked"
                                      initialValue={true}
                                    >
                                      <Checkbox disabled={disabled}></Checkbox>
                                    </FormItem>
                                  </Col>
                                </Row>
                              </>
                            );
                          }}
                        </FormItem>
                      </BoxItem>
                    </Col>

                    <Col span={12}>
                      {/* <FormItem
                        label="Registro Patronal"
                        name="registroPatronal"
                        pattern
                        max={200}
                        margin
                      >
                        <InputItem placeholder="Registro Patronal" />
                      </FormItem>
                      <FormItem
                        label="Registro Camara"
                        name="registroCamara"
                        pattern
                        max={200}
                        margin
                      >
                        <InputItem placeholder="Registro Camara" />
                      </FormItem>
                      <FormItem
                        label="Infonavit"
                        name="infonavit"
                        pattern
                        max={200}
                        margin
                      >
                        <InputItem placeholder="Infonavit" />
                      </FormItem> */}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Dirección" tabKey={2} key={2} forceRender>
                  <Row>
                    <Col span={12}>
                      <FormItem
                        label="Código Postal"
                        name="coloniaId"
                        margin
                      >
                        <SelectItem
                          placeholder="Colonia"
                          onSearch={this.onSearchColonia.bind(this)}
                          onSelect={this.onSelectColoniaId.bind(this)}
                        >
                          {this.state.objetoColonias === null ? (
                            <Option disabled>
                              Eliga primero un municipio
                            </Option>
                          ) : (
                            this.state.objetoColonias
                          )}
                          <Option disabled>Busque por codigo postal</Option>
                        </SelectItem>
                      </FormItem>
                      <FormItem
                        label="Municipio"
                        name="municipioId"
                        margin
                      >
                        <SelectItem
                          placeholder="Municipio"
                          onChange={this.onChangeMunicipio.bind(this)}
                        >
                          {this.state.objetoMunicipios === null ? (
                            <Option disabled>Eliga primero un estado</Option>
                          ) : (
                            this.state.objetoMunicipios
                          )}
                        </SelectItem>
                      </FormItem>
                      <FormItem label="Estado" name="estadoId" margin>
                        <SelectItem
                          placeholder="Estado"
                          onChange={this.onChangeEstado.bind(this)}
                        >
                          {this.state.objetoEstados}
                        </SelectItem>
                      </FormItem>
                      <FormItem label="Pais" name="paisId" margin>
                        <SelectItem placeholder="Pais">
                          {this.state.objetoPaises}
                        </SelectItem>
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem name="colonia" label="Colonia" margin pattern>
                        <InputItem placeholder="Colonia" />
                      </FormItem>
                      <FormItem
                        label="Calle"
                        name="calle"
                        max={200}
                        margin
                        pattern
                      >
                        <InputItem placeholder="Calle" />
                      </FormItem>
                      <FormItem label="Numero" name="numeroCalle" margin number>
                        <InputNumberItem placeholder="Numero" numeroCalle />
                      </FormItem>
                      <FormItem
                        label="Numero Interior"
                        name="numeroInterior"
                        margin
                      >
                        <InputNumberItem
                          placeholder="Numero Interior"
                          numeroCalle
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </Form>
          </Spin>
        </div>
        <AgregarCuentaContable
          visible={this.state.modalAgregarCuentaContable}
          cerrarAgregarCuentaContable={this.cerrarAgregarCuentaContable.bind(
            this
          )}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    complementoDetallista: state.empresa.complementoDetallista,
  };
};

export default connect(mapStateToProps)(AgregarProveedor);
