const calcularSimilitud = (string1, string2) => {
    // Función para calcular la distancia de Levenshtein
    const distanciaLevenshtein = (a, b) => {
        const matriz = Array(a.length + 1)
            .fill(null)
            .map(() => Array(b.length + 1).fill(null));
  
        for (let i = 0; i <= a.length; i++) {
            matriz[i][0] = i;
        }
        for (let j = 0; j <= b.length; j++) {
            matriz[0][j] = j;
        }
  
        for (let i = 1; i <= a.length; i++) {
            for (let j = 1; j <= b.length; j++) {
                const costo = a[i - 1] === b[j - 1] ? 0 : 1;
                matriz[i][j] = Math.min(
                    matriz[i - 1][j] + 1, // Eliminación
                    matriz[i][j - 1] + 1, // Inserción
                    matriz[i - 1][j - 1] + costo // Sustitución
                );
            }
        }
  
        return matriz[a.length][b.length];
    }
  
    const distancia = distanciaLevenshtein(string1, string2);
    const longitudMaxima = Math.max(string1.length, string2.length);
  
    // Similitud en porcentaje
    const similitud = ((longitudMaxima - distancia) / longitudMaxima) * 100;
    return similitud.toFixed(2); // Redondear a 2 decimales
  }


  export default calcularSimilitud