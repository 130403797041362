import { Empty, PageHeader, Popconfirm, Space, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem } from "../../../Components/Items";
import moment from "moment";
import "moment/locale/es";
import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import AgregarDepartamento from "./AgregarDepartamento/AgregarDepartamento";
import EditarDepartamentos from "./EditarDepartamentos/EditarDepartamentos";

moment.locale("es");

class Departamentos extends Component {
  state = {
    modalAgregar: false,
    modalEditar: false,
    todosDepartamentos: [],
    loading: false,
    departamentoSeleccionado: null,
  };

  // Abrir modal para agregar
  abrirAgregarDepartamento = () => {
    this.setState({ modalAgregar: true, departamentoSeleccionado: null });
  };

  // Cerrar modal de agregar
  cerrarAgregarDepartamento = () => {
    this.setState({ modalAgregar: false });
    this.consultarTodosDepartamentos();
  };

  // Abrir modal para editar
  abrirEditarDepartamento = (departamento) => {
    this.setState({ modalEditar: true, departamentoSeleccionado: departamento });
  };

  // Cerrar modal de editar
  cerrarEditarDepartamento = () => {
    this.setState({ modalEditar: false });
    this.consultarTodosDepartamentos();
  };

  componentDidMount() {
    this.consultarTodosDepartamentos();
  }

  consultarTodosDepartamentos() {
    this.setState({ loading: true });
    axios
      .post(
        "departamentos/selectTodosDepartamentos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
          message.error("No se encontraron departamentos");
        } else {
          this.setState({
            todosDepartamentos: res.data.departamentos.map((value) => {
              return { ...value, proyectoAsociado:value?.proyecto?.nombre , accion: { _id: value._id, estado: value.estado } };
            }),
          });
        }
      });
  }

  // Agrega un nuevo departamento
  agregarDepartamento = (datosDepartamento) => {
    axios
      .post(
        "departamentos/agregarDepartamento",
        {
          ...datosDepartamento,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === true) {
          message.success("Departamento agregado exitosamente");
          this.consultarTodosDepartamentos();
          this.cerrarAgregarDepartamento();
        } else {
          message.error("Error al agregar el departamento");
        }
      });
  };

  // Deshabilita un departamento 
  deshabilitarDepartamento(id) {
    axios
      .post(
        "departamentos/deshabilitarDepartamento",
        { departamentoId: id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Departamento deshabilitado exitosamente");
          this.consultarTodosDepartamentos();
        } else if (res.data.success === 1){
          message.error("Error al deshabilitar el departamento");
        }else{
          message.error("Error");
        }
      });
  }

  // Habilita un departamento
  habilitarDepartamento(id) {
    axios
      .post(
        "departamentos/habilitarDepartamento",
        { departamentoId: id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Departamento habilitado exitosamente");
          this.consultarTodosDepartamentos();
        } else if (res.data.success === 1){
          message.error("Error al habilitar el departamento");
        }else{
          message.error("Error");
        }
      });
  }

  render() {
    const columns = [
      {
        title: "Código",
        dataIndex: "codigo",
        key: "codigo",
        sorter: (a, b) => a.codigo.localeCompare(b.codigo),
      },
      {
        title: "Nombre del Departamento",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      },
      {
        title: "Proyecto Asociado",
        dataIndex: "proyectoAsociado",
        key: "proyectoAsociado",
        sorter: (a, b) => a.proyectoAsociado.localeCompare(b.proyectoAsociado),
      },
      {
        title: "Acción",
        key: "accion",
        render: (record) => (
          <Space size="middle">
            <EditOutlined
              onClick={() => this.abrirEditarDepartamento(record)}
              style={{ color: "blue" }}
            />
           {
              // Eliminar la verificación de permiso por ahora
              <>
                {record.estado === 1 ? (
                  <Popconfirm
                    title="¿Seguro que quieres deshabilitar este departamento?"
                    onConfirm={() => this.deshabilitarDepartamento(record._id)}
                    okText="Sí"
                    cancelText="No"
                  >
                    <CheckCircleOutlined style={{ color: "green" }} />
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    title="¿Seguro que quieres habilitar este departamento?"
                    onConfirm={() => this.habilitarDepartamento(record._id)}
                    okText="Sí"
                    cancelText="No"
                  >
                    <StopOutlined style={{ color: "red" }} />
                  </Popconfirm>
                )}
              </>
            }

          </Space>
        ),
      },
    ];

    return (
      <>
        <PageHeader
          title="Departamentos"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <ButtonItem
              type="primary"
              key="agregarDepartamento"
              onClick={this.abrirAgregarDepartamento}
            >
              Agregar Departamento
            </ButtonItem>
          }
        />
        <TableItem
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <p>
                      No hay registros de departamentos. Aquí puedes gestionar y
                      llevar un control de los departamentos.
                    </p>
                  </>
                }
              />
            ),
          }}
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todosDepartamentos}
          columns={columns}
          pagination={false}
        />
        <AgregarDepartamento
          visible={this.state.modalAgregar}
          cerrarModal={this.cerrarAgregarDepartamento}
        />
        <EditarDepartamentos
          visible={this.state.modalEditar}
          cerrarModal={this.cerrarEditarDepartamento}
          departamento={this.state.departamentoSeleccionado}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoDeshabilitarDepartamento: state.user.permisoDeshabilitarDepartamento, // Adaptación para el permiso
  };
};

export default connect(mapStateToProps)(Departamentos);
