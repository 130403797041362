import * as pdfjsLib from 'pdfjs-dist';

pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js';


const identificarPersonaPorRFC = (rfc) => {
    const regexPersonaFisica = /^[A-Z&]{4}\d{6}[A-Z0-9]{3}$/;
    const regexPersonaMoral = /^[A-Z&]{3}\d{6}[A-Z0-9]{3}$/;

    if (regexPersonaFisica.test(rfc)) {
        return 1;
    } else if (regexPersonaMoral.test(rfc)) {
        return 2;
    } else {
        return undefined;
    }
}


const extraerDatosConstanciaFiscal = async (pdfFile) => {
    const pdfData = await pdfFile.arrayBuffer(); // Convierte el archivo a un ArrayBuffer
    const pdfDocument = await pdfjsLib.getDocument(pdfData).promise;

    let textoCompleto = '';

    // Extrae todo el texto del PDF
    for (let i = 1; i <= pdfDocument.numPages; i++) {
        const page = await pdfDocument.getPage(i);
        const textContent = await page.getTextContent();

        // Concatenar el texto de cada página
        textoCompleto += textContent.items.map(item => item.str).join(' ') + ' ';
    }

    // Función de limpieza para eliminar los campos no deseados
    function limpiarCampo(campo) {
        if (campo) {
            // Expresiones regulares para eliminar campos específicos con soporte para acentos y caracteres especiales
            return campo
                .replace(/RFC.*/i, '') // Elimina RFC y cualquier texto posterior
                .replace(/CURP.*/i, '') // Elimina CURP y cualquier texto posterior
                .replace(/Nombre\s?\(s\).*/i, '') // Elimina Nombre(s) y cualquier texto posterior
                .replace(/Primer\s+Apellido.*/i, '') // Elimina Primer apellido
                .replace(/Segundo\s+Apellido.*/i, '') // Elimina Segundo apellido
                .replace(/Fecha\s+inicio\s+de\s+operaciones.*/i, '') // Elimina Fecha inicio de operaciones
                .replace(/Estatus\s+en\s+el\s+padrón.*/i, '') // Elimina Estatus en el padrón
                .replace(/Fecha\s+de\s+último\s+cambio\s+de\s+estado.*/i, '') // Elimina Fecha de último cambio de estado
                .replace(/Nombre\s+Comercial.*/i, '') // Elimina Nombre Comercial
                .replace(/Código\s+Postal.*/i, '') // Elimina Código Postal
                .replace(/Tipo\s+de\s+Vialidad.*/i, '') // Elimina Tipo de Vialidad
                .replace(/Nombre\s+de\s+Vialidad.*/i, '') // Elimina Nombre de Vialidad
                .replace(/Número\s+Exterior.*/i, '') // Elimina Número Exterior
                .replace(/Número\s+Interior.*/i, '') // Elimina Número Interior
                .replace(/Nombre\s+de\s+la\s+Colonia.*/i, '') // Elimina Nombre de la Colonia
                .replace(/Nombre\s+de\s+la\s+Localidad.*/i, '') // Elimina Nombre de la Localidad
                .replace(/Datos\s+del\s+domicilio\s+registrado\s+Código\s+Postal.*/i, '') // Elimina Nombre del Municipio o Demarcación Territorial
                .replace(/Datos\s+del\s+domicilio\s+registrado.*/i, '') // Elimina Nombre del Municipio o Demarcación Territorial
                .replace(/Nombre\s+de\s+la\s+Entidad\s+Federativa.*/i, '') // Elimina Nombre de la Entidad Federativa
                .replace(/Entre\s+Calle\s+Y\s+Calle.*/i, '') // Elimina Entre Calle Y Calle
                .replace(/Denominación\/Razón\s+Social.*/i, '') // Elimina Denominación/Razón Social
                .replace(/Régimen\s+Capital.*/i, '') // Elimina Régimen Capital
                .trim(); // Limpia los espacios al inicio y final
        }
        return null;
    }

    // Busca y extrae datos clave
    const rfcMatch = textoCompleto.match(/\b[A-ZÑ&]{3,4}\d{6}[A-Z0-9]{3}\b/);  // RFC
    const razonSocialMatch = textoCompleto.match(/Denominación\/Razón\s+Social:\s+([\w\sÑñáéíóúÁÉÍÓÚ&.]+)/);  // Razón Social
    const curpMatch = textoCompleto.match(/\b[A-Z]{4}\d{6}[HM][A-Z]{5}[A-Z0-9]{2}\b/i); // CURP
    const nombreMatch = textoCompleto.match(/Nombre\s+\(s\):\s+([\w\sÑñáéíóúÁÉÍÓÚ&]+)/);  // Nombre
    const nombreComercialMatch = textoCompleto.match(/Nombre\s+Comercial:\s+([\w\sÑñáéíóúÁÉÍÓÚ&]+)/);  // Nombre
    const apellidoPaternoMatch = textoCompleto.match(/Primer\s+Apellido:\s+([\w\sÑñáéíóúÁÉÍÓÚ]+)/); // Apellido Paterno
    const apellidoMaternoMatch = textoCompleto.match(/Segundo\s+Apellido:\s+([\w\sÑñáéíóúÁÉÍÓÚ]+)/); // Apellido Materno
    const vialidadMatch = textoCompleto.match(/Nombre\s+de\s+Vialidad:\s+([\w\s.,#ÑñáéíóúÁÉÍÓÚ\d]+)/);  // Vialidad
    const numeroExteriorMatch = textoCompleto.match(/Número\s+Exterior:\s+([\w\s#ÑñáéíóúÁÉÍÓÚ\d]+)/); // Número Exterior
    const numeroInteriorMatch = textoCompleto.match(/Número\s+Interior:\s+([\w\s#ÑñáéíóúÁÉÍÓÚ\d]+)/);  // Número Interior
    const coloniaMatch = textoCompleto.match(/Nombre\s+de\s+la\s+Colonia:\s+([\w\s.,#ÑñáéíóúÁÉÍÓÚ\d]+)/); // Colonia
    const cpMatch = textoCompleto.match(/Código\s+Postal:\s+(\d{5})/);  // Código Postal
    const regimenFiscalMatch = textoCompleto.match(/Regímenes:\s+Régimen Fecha Inicio Fecha Fin\s+([^\d]+)/)
    // const regimenFiscalMatch = textoCompleto.match(/Regímenes:\s+Régimen Fecha Inicio Fecha Fin Régimen\s+(.*?)\s+\d{2}\/\d{2}\/\d{4}/)

    return {
        razonSocial: razonSocialMatch ? limpiarCampo(razonSocialMatch[1].trim()) : null,
        rfc: rfcMatch ? rfcMatch[0] : null,
        curp: curpMatch ? curpMatch[0] : null,
        regimenFiscal: regimenFiscalMatch ? regimenFiscalMatch[1].trim() : null,
        nombreComercial: nombreComercialMatch ? limpiarCampo(nombreComercialMatch[1]) : null,
        nombre: nombreMatch ? limpiarCampo(nombreMatch[1]) : null,
        apellidoPaterno: apellidoPaternoMatch ? limpiarCampo(apellidoPaternoMatch[1]) : null,
        apellidoMaterno: apellidoMaternoMatch ? limpiarCampo(apellidoMaternoMatch[1]) : null,
        calle: vialidadMatch ? limpiarCampo(vialidadMatch[1]) : null,
        numeroExterior: numeroExteriorMatch ? limpiarCampo(numeroExteriorMatch[1]) : null,
        numeroInterior: numeroInteriorMatch ? limpiarCampo(numeroInteriorMatch[1]) : null,
        colonia: coloniaMatch ? limpiarCampo(coloniaMatch[1]) : null, // Limpieza aplicada
        codigoPostal: cpMatch ? cpMatch[1] : null,
        persona: identificarPersonaPorRFC(rfcMatch ? rfcMatch[0] : null),
    };
}



export default extraerDatosConstanciaFiscal