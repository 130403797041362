import { message, Select } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  ButtonItem,
  InputItem,
  ErrorItem,
  SelectItem,
} from "../../../../Components/Items";

const { Option } = Select;

class AgregarTipoAcumulado extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
    visible: this.props.visible,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      this.setState({ visible: this.props.visible });
    }
  }

  onFinishFailed(e) {
    ErrorItem(e);
  }

  onFinish(values) {
    console.log(values);
  
    if (values?.nombre && values?.codigo && (values?.tipoAcumulado === 0 || values?.tipoAcumulado === 1 || values?.tipoAcumulado === 2)) {
      this.setState({ loading: true });
      axios
        .post(
          "tiposAcumulados/agregarTipoAcumulado",
          {
            ...values,
            empresaId: this.props.empresaId,
          },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          this.setState({ loading: false });
  
          if (res.data.success === 2) {
            message.success("Tipo de acumulado agregado exitosamente");
            this.cerrarModal();
          } else if (res.data.success === 1) {
            message.error("Error al agregar el tipo de acumulado");
          } else {
            message.error("Error desconocido en la solicitud");
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          message.error("Error al comunicarse con el servidor");
        });
    } else {
      message.info("Por favor completa todos los campos requeridos y asegúrate que 'Tipo de Acumulado' sea 0, 1 o 2");
    }
  }
  

  cerrarModal = () => {
    this.setState({ visible: false });
    this.props.cerrarModal();
    if (this.formRef.current) {
      this.formRef.current.resetFields();
    }
  };

  render() {
    return (
      <ModalItem
        title="Agregar Tipo de Acumulado"
        visible={this.state.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarModal}
        width={800}
      >
        <Form
          ref={this.formRef}
          name="modalAgregarTipoAcumulado"
          layout
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
              loading={this.state.loading}
            >
              Guardar
            </ButtonItem>
          }
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          <FormItem
            name="codigo"
            label="Código"
            required
            margin
            rules={[{ required: true, message: "Por favor ingresa el código del tipo de acumulado" }]}
          >
            <InputItem placeholder="Ingresa el código" />
          </FormItem>

          <FormItem
            name="nombre"
            label="Nombre"
            required
            margin
            rules={[{ required: true, message: "Por favor ingresa el nombre del tipo de acumulado" }]}
          >
            <InputItem placeholder="Ingresa el nombre" />
          </FormItem>

          {/* Campo para el tipo de acumulado */}
          <FormItem
            name="tipoAcumulado"
            label="Tipo de Acumulado"
            required
            margin
            rules={[{ required: true, message: "Por favor selecciona el tipo de acumulado" }]}
          >
            <SelectItem placeholder="Selecciona el tipo de acumulado">
              <Option value={0}>Días y horas</Option>
              <Option value={1}>Percepción, deducción y obligación</Option>
            </SelectItem>
          </FormItem>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => ({
  headersToken: state.user.headersToken,
  empresaId: state.empresa.id,
});

export default connect(mapStateToProps)(AgregarTipoAcumulado);
