import { Empty, PageHeader, Popconfirm, Space, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem } from "../../../Components/Items";
import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import AgregarRegistroPatronal from "./AgregarRegistroPatronal/AgregarRegistroPatronal";
import EditarRegistroPatronal from "./EditarRegistroPatronal/EditarRegistroPatronal";

class RegistroPatronal extends Component {
  state = {
    modalAgregar: false,
    modalEditar: false,
    todasRegistroPatronal: [],
    loading: false,
    registroPatronalSeleccionado: null,
    fraccionesRiesgo: [],
    ObjetoFraccionesRiesgo: [],
    entidadesFederativas: [],
    objetoEntidadesFederativas: [],
    clasesRiesgos: [],
    objetoClasesRiesgo: [],
  };

  // Abrir modal para agregar
  abrirAgregarRegistroPatronal = () => {
    this.setState({ modalAgregar: true, registroPatronalSeleccionado: null });
  };

  // Cerrar modal de agregar
  cerrarAgregarRegistroPatronal = () => {
    this.setState({ modalAgregar: false });
    this.consultarRegistroPatronal();
  };

  // Abrir modal para editar
  abrirEditarRegistroPatronal = (registro) => {
    this.setState({ modalEditar: true, registroPatronalSeleccionado: registro });
  };

  // Cerrar modal de editar
  cerrarEditarRegistroPatronal = () => {
    this.setState({ modalEditar: false });
    this.consultarRegistroPatronal();
  };

  componentDidMount() {
    this.consultarRegistroPatronal();
  }

  // Consultar datos de riesgo


  // Consultar todos los registros patronales
  consultarRegistroPatronal() {
    this.setState({ loading: true });
    axios
      .post(
        "registrosPatronales/selectTodosRegistros",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data && res.data.registros) {
          this.setState({
            todasRegistroPatronal: res.data.registros.map((registro) => ({
              ...registro,
              key: registro._id,
              claseRiesgoDescripcion: registro.claseRiesgo?.Descripcion || "N/A",
              fraccionRiesgoActividad: registro.fraccionRiesgo?.actividad || "N/A",
            })),
          });
        } else {
          message.error("No se encontraron registros patronales.");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.error("Error al consultar los registros patronales.");
        console.error(error);
      });
  }


  deshabilitarRegistroPatronal(id) {
    axios
      .post(
        "registrosPatronales/deshabilitarRegistro",
        { registroId: id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success) {
          message.success("Registro patronal deshabilitado exitosamente");
          this.consultarRegistroPatronal();
        } else {
          message.error("Error al deshabilitar el registro patronal");
        }
      })
      .catch((error) => {
        message.error("Error al deshabilitar el registro patronal.");
        console.error(error);
      });
  }

  habilitarRegistroPatronal(id) {
    axios
      .post(
        "registrosPatronales/habilitarRegistro",
        { registroId: id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success) {
          message.success("Registro patronal habilitado exitosamente");
          this.consultarRegistroPatronal();
        } else {
          message.error("Error al habilitar el registro patronal");
        }
      })
      .catch((error) => {
        message.error("Error al habilitar el registro patronal.");
        console.error(error);
      });
  }

  render() {
    const columns = [
      {
        title: "Registro Patronal",
        dataIndex: "registroPatronalIMSS",
        key: "registroPatronalIMSS",
      },
      {
        title: "Clase de Riesgo",
        dataIndex: "claseRiesgoDescripcion",
        key: "claseRiesgoDescripcion",
      },
      {
        title: "Fracción de Riesgo",
        dataIndex: "fraccionRiesgoActividad",
        key: "fraccionRiesgoActividad",
      },
      {
        title: "Acción",
        key: "accion",
        render: (record) => (
          <Space size="middle">
            <EditOutlined
              onClick={() => this.abrirEditarRegistroPatronal(record)}
              style={{ color: "blue" }}
            />
            {record.estado === 1 ? (
              <Popconfirm
                title="¿Seguro que quieres deshabilitar este registro?"
                onConfirm={() => this.deshabilitarRegistroPatronal(record._id)}
                okText="Sí"
                cancelText="No"
              >
                <CheckCircleOutlined style={{ color: "green" }} />
              </Popconfirm>
            ) : (
              <Popconfirm
                title="¿Seguro que quieres habilitar este registro?"
                onConfirm={() => this.habilitarRegistroPatronal(record._id)}
                okText="Sí"
                cancelText="No"
              >
                <StopOutlined style={{ color: "red" }} />
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ];
    

    return (
      <>
        <PageHeader
          title="Registro Patronal"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <ButtonItem
              type="primary"
              key="agregarRegistroPatronal"
              onClick={this.abrirAgregarRegistroPatronal}
            >
              Agregar Registro Patronal
            </ButtonItem>
          }
        />
        <TableItem
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No hay registros de registros patronales"
              />
            ),
          }}
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todasRegistroPatronal}
          columns={columns}
          pagination={false}
        />
        <AgregarRegistroPatronal
          visible={this.state.modalAgregar}
          cerrarModal={this.cerrarAgregarRegistroPatronal}
          onGuardar={(datosRegistro) => this.agregarRegistroPatronal(datosRegistro)}
        />
        <EditarRegistroPatronal
          visible={this.state.modalEditar}
          cerrarModal={this.cerrarEditarRegistroPatronal}
          registroPatronal={this.state.registroPatronalSeleccionado}
          onGuardar={(datosRegistro) => this.editarRegistroPatronal(datosRegistro)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(RegistroPatronal);
