import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Col,
  PageHeader,
  Row,
  Spin,
  Form as FormInitial,
  message,
  Select,
} from "antd";
import {
  BoxItem,
  ButtonItem,
  Form,
  HeaderItem,
  InputNumberItem,
  SelectItem,
  TextAreaItem,
  FormItem,
  ErrorItem
} from "../../../../Components/Items";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";

const { Option } = Select;

class AgregarRequisicion extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    todasProveedores: null,
    objetoProveedoresPInsumos: [],
    objetoProyectos: null,
    todasProyectos: null,
    objetoInsumos: null,
    todasInsumos: null,
    impuestos: [],
    modalElegirTipoDeProveedor: false,
    modalElegirTipoDeProveedorId: null,
    modalElegirTipoDeProveedorNombre: null,
  };
  componentDidMount() {
    this.consultarTodo();
  }
  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  async consultarTodo() {
    this.setState({ loading: true });
    const responseProveedores = axios.post(
      "proveedores/selectProveedoresConTipoDeProveedor",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    );

    const responseProyectos = axios.post(
      "proyectos/selectProyectos",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    );

    const responseInsumos = axios.post(
      "insumos/selectInsumosCompra",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    );

    const responses = await Promise.all([
      responseProveedores,
      responseProyectos,
      responseInsumos,
    ]);

    let newState = { loading: false };

    if (responses[0].data.status === 404) {
    } else {
      newState.todasProveedores = responses[0].data;
    }

    if (responses[1].data.status === 404) {
    } else {
      newState.todasProyectos = responses[1].data;
      newState.objetoProyectos = responses[1].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.nombre}
          </Option>
        );
      });
    }

    if (responses[2].data.status === 404) {
    } else {
      newState.todasInsumos = responses[2].data;
      newState.objetoInsumos = responses[2].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.stringBuscar}
          </Option>
        );
      });
    }

    await this.promisedSetState(newState);
  }
  atras() {
    this.props.history.goBack();
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }
  async onFinish(values) {
    if (!values.insumos || values.insumos?.length === 0)
      return message.error("Agregue insumos para agregar la requsicion");
    this.setState({ loading: true });
    axios
      .post(
        "requisiciones/agregarRequisicion",
        {
          ...values,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success(
            "Se ha registrado una requisicion: " + res.data.codigo
          );
          this.formRef.current.resetFields();
          this.atras(res.data.codigo);
        } else if (res.data.success === 1) {
          message.error("No se puede registrar la requisicion");
        } else {
          message.error("Hubo un error y la requisicion no se registro");
        }
      });
  }

  async onChangeInsumo(value, key) {
    let arrayValue = await this.formRef.current.getFieldValue("insumos");
    const insumo = this.state.todasInsumos.find(
      (valueInsumos) => valueInsumos._id === value
    );
    if (!insumo) return;

    let objetoProveedores = []

    if (insumo.relaciones && insumo.relaciones?.length > 0) {

      let keysProveedores = []

      for (const relacion of insumo.relaciones) {
        if (relacion.estado !== 0) {

          const indexProv = keysProveedores.findIndex(valueKey => valueKey.toString() === relacion.proveedorId.toString())

          if (indexProv === -1) {
            keysProveedores.push(relacion.proveedorId.toString())
          }

        }
      }

      for (const proveedorId of keysProveedores) {

        const proveedor = this.state.todasProveedores.find(
          (valueProveedores) =>
            valueProveedores._id.toString() === proveedorId.toString()
        );

        objetoProveedores.push(
          <Option value={proveedor._id} key={proveedor._id}>
            {proveedor.stringBuscar}
          </Option>
        )

      }
    }


    let objetoProveedoresPInsumos = [...this.state.objetoProveedoresPInsumos]

    objetoProveedoresPInsumos[key] = objetoProveedores


    this.setState({ objetoProveedoresPInsumos })

    if (!insumo?.proveedorDefaultId) return;


    const proveedor = this.state.todasProveedores.find(
      (valueProveedores) =>
        valueProveedores._id.toString() === insumo.proveedorDefaultId.toString()
    );



    arrayValue[key].proveedorId = proveedor ? proveedor._id : undefined;

    this.formRef.current.setFieldsValue({
      insumos: arrayValue,
    });
  }


  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Agregar Requisicion"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="agregarRequisicion"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Agregar
                </ButtonItem>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
              <Row>
                <Col span={18}>
                  <HeaderItem>DATOS GENERALES</HeaderItem>
                  <BoxItem>
                    <Row>
                      <Col span={12}>
                        <FormItem
                          label="Proyecto"
                          name="proyectoId"
                          required
                          margin
                        >
                          <SelectItem placeholder="Proyecto">
                            {this.state.objetoProyectos}
                          </SelectItem>
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem shouldUpdate noStyle>
                          {({ getFieldValue }) => {
                            let proyectoId = getFieldValue("proyectoId");
                            if (!proyectoId)
                              return <p>Seleccione un proyecto primero</p>;
                            let proyecto = this.state.todasProyectos.find(
                              (value) => value._id === proyectoId
                            );
                            return (
                              <>
                                <Row>
                                  <Col span={12}>
                                    <h1 style={{ margin: 0 }}>
                                      Nombre: {proyecto.nombre}
                                    </h1>
                                    <p>Descripcion: {proyecto.descripcion}</p>
                                  </Col>
                                  <Col span={12}>
                                    <h1 style={{ margin: 0 }}>
                                      Dirección de envio
                                    </h1>
                                    {proyecto.calle ? (
                                      <>
                                        <p style={{ margin: 0 }}>
                                          Calle: {proyecto.calle}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                          Numero: {proyecto.numeroCalle}
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        <p style={{ margin: 0 }}>
                                          Marca: {proyecto.marca}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                          Modelo: {proyecto.modelo}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                          Placas: {proyecto.placas}
                                        </p>
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              </>
                            );
                          }}
                        </FormItem>
                      </Col>
                    </Row>
                    <Row></Row>
                  </BoxItem>
                </Col>
                <Col span={6}>
                  <HeaderItem>EXTRAS</HeaderItem>
                  <BoxItem>
                    <FormItem
                      name="observaciones"
                      margin
                      labelCol={{ span: 3 }}
                      wrapperCol={{ span: 21 }}
                      pattern
                    >
                      <TextAreaItem placeholder="Observaciones" />
                    </FormItem>
                  </BoxItem>
                </Col>
              </Row>
              <HeaderItem>INSUMOS</HeaderItem>
              <BoxItem>
                <FormInitial.List name="insumos">
                  {(fields, { add, remove }) => (
                    <>
                      <table className="table">
                        {fields.length > 0 ? (
                          <thead>
                            <tr>
                              <th
                                className="th-border"
                                style={{ width: "40%" }}
                              >
                                Insumo
                              </th>
                              <th
                                className="th-border"
                                style={{ width: "30%" }}
                              >
                                Cantidad
                              </th>
                              <th
                                className="th-border"
                                style={{ width: "30%" }}
                              >
                                Proveedor
                              </th>
                              <th
                                className="th-border-sin-right"
                              ></th>
                            </tr>
                          </thead>
                        ) : null}
                        {fields.map(
                          ({ key, name, fieldKey, ...restField }, arrayKey) => (
                            <tbody key={arrayKey}>
                              <tr>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "insumoId"]}
                                    required
                                    noStyle
                                  >
                                    <SelectItem
                                      sinBorde
                                      autoFocus
                                      style={{ width: "100%" }}
                                      placeholder="Insumo"
                                      onChange={(value) =>
                                        this.onChangeInsumo(value, arrayKey)
                                      }
                                    >
                                      {this.state.objetoInsumos}
                                    </SelectItem>
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "cantidad"]}
                                    required
                                    number
                                    noStyle
                                  >
                                    <InputNumberItem
                                      sinBorde
                                      placeholder="Cantidad"
                                    />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "proveedorId"]}
                                    noStyle
                                  >
                                    <SelectItem
                                      sinBorde
                                      style={{ width: "100%" }}
                                      placeholder="Proveedor"
                                    >
                                      {this.state?.objetoProveedoresPInsumos[arrayKey] ? this.state.objetoProveedoresPInsumos[arrayKey] : null}
                                    </SelectItem>
                                  </FormItem>
                                </td>
                                <td className="td-border-sin-right">
                                  <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                    style={{
                                      alignSelf: "center",
                                      justifySelf: "center",
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          )
                        )}
                      </table>
                      <FormItem noStyle>
                        <ButtonItem
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                            justifySelf: fields.length > 0 ? "left" : null,
                            width: fields.length > 0 ? "auto" : null,
                          }}
                          type="primary"
                          onClick={() => {
                            const insumos = this.formRef.current.getFieldValue('insumos')
                            add()
                            if (this.state.objetoProveedoresPInsumos?.length <= insumos?.length || !insumos) {
                              this.setState({
                                objetoProveedoresPInsumos: [...this.state.objetoProveedoresPInsumos, (
                                  <Option value={0} key={0} disabled>
                                    Seleccione un insumo para ver sus proveedores
                                  </Option>
                                )]
                              })
                            }else{
                              let objetoProveedoresPInsumos = [...this.state.objetoProveedoresPInsumos]

                              objetoProveedoresPInsumos[insumos.length] = (
                                <Option value={0} key={0} disabled>
                                  Seleccione un insumo para ver sus proveedores
                                </Option>
                              )

                              this.setState({objetoProveedoresPInsumos})
                            }
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Agregar Insumo
                        </ButtonItem>
                      </FormItem>
                    </>
                  )}
                </FormInitial.List>
              </BoxItem>
            </Form>
          </Spin>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(AgregarRequisicion);
