import { message, Select } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Form,
    ModalItem,
    FormItem,
    SelectItem,
    InputItem,
    ButtonItem,
} from "../../../../Components/Items";

const { Option } = Select;

class EditarRemolque extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        todasSubtiposRemolques: null,
        objetoSubtiposRemolques: null,
    };
    componentDidUpdate(prevProps) {
        if (this.props.visible !== prevProps.visible && this.props.visible)
            this.consultarTodo();
    }
    async consultarTodo() {
        this.setState({ loading: true });
        await axios
            .post("remolques/selectDatosParaRemolque", null, {
                headers: this.props.headersToken,
            })
            .then((res) => {
                if (res.data.success === 2) {
                    this.setState({
                        todasSubtiposRemolques: res.data.subtiposRemolques,
                        objetoSubtiposRemolques: res.data.subtiposRemolques.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.Clave + ' - ' + value.Descripcion}
                                </Option>
                            );
                        }),
                    });
                }
            });
        await axios
            .post(
                "remolques/datosRemolque",
                { empresaId: this.props.empresaId, remolqueId: this.props.id },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    this.formRef.current.setFieldsValue({
                        ...res.data.remolque,
                    })
                } else {
                    message.error("Error no se encontro informacion del remolque");
                }
            });
        this.setState({ loading: false });
    }
    cerrarEditarRemolque(codigo) {
        this.props.cerrarEditarRemolque(codigo);
    }
    onFinishFailed(e) {
        message.error("Porfavor ingrese bien los datos");
    }
    onFinish(values) {
        this.setState({ loading: true });
        axios
            .post(
                "remolques/editarRemolque",
                { ...values, empresaId: this.props.empresaId, remolqueId: this.props.id },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                this.setState({ loading: false });
                if (res.data.success === 2) {
                    message.success("Se edito un remolque");
                    this.formRef.current.resetFields();
                    this.cerrarEditarRemolque(res.data.codigo);
                } else {
                    message.error("Sucedio un error y no se guardo");
                }
            });
    }
    render() {
        return (
            <ModalItem
                title="Editar Remolque"
                visible={this.props.visible}
                spinning={this.state.loading}
                onCancel={this.cerrarEditarRemolque.bind(this)}
                paddingTop
            >
                <Form
                    ref={this.formRef}
                    name="editarRemoqlue"
                    layout
                    bottom={
                        <ButtonItem
                            type="primary"
                            htmlType="submit"
                            style={{ display: "block", margin: "0 0 0 auto" }}
                        >
                            Guardar
                        </ButtonItem>
                    }
                    onFinish={this.onFinish.bind(this)}
                    onFinishFailed={this.onFinishFailed}
                >
                    <FormItem
                        label='Descripcion'
                        name='descripcion'
                        pattern
                        margin
                        required
                    >
                        <InputItem placeholder='Descripcion' />
                    </FormItem>
                    <FormItem required label="Subtipo R." name="subtipoRemolqueSatId" margin>
                        <SelectItem placeholder="Subtipo R.">
                            {this.state.objetoSubtiposRemolques}
                        </SelectItem>
                    </FormItem>
                    <FormItem
                        label='Placa'
                        name='placa'
                        pattern
                        margin
                        required
                    >
                        <InputItem placeholder='Placa' />
                    </FormItem>
                </Form>
            </ModalItem>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
    };
};

export default connect(mapStateToProps)(EditarRemolque);
