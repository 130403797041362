import { message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  InputNumberItem,
  InputItem,
  ButtonItem,
  ErrorItem
} from "../../../../Components/Items";

class AgregarUnidadDeNegocio extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    validateStatus: "success",
    errorMsg: "Comieza por el primer nivel para agregar mas (100-999)",
  };
  cerrarAgregarUnidadDeNegocio(codigo) {
    this.props.cerrarAgregarUnidadDeNegocio(codigo);
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }
  onFinish(values) {
    if (this.state.validateStatus !== "success")
      return message.error("Porfavor ingrese bien los datos");
    this.setState({ loading: true });
    axios
      .post(
        "unidadesdenegocio/agregarUnidadDeNegocio",
        { ...values, empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se agrego una unidad de negocio");
          this.formRef.current.resetFields();
          this.cerrarAgregarUnidadDeNegocio(res.data.codigo);
        } else {
          message.error("Sucedio un error y no se guardo");
        }
      });
  }
  onChangeCodigo(value) {
    if (value === null) return;
    if (value.toString().length < 3) {
      this.setState({
        errorMsg: "Tiene que ser un conjunto de 3 numeros",
        validateStatus: "error",
      });
    } else if (value.toString().length === 3) {
      axios
        .post(
          "unidadesdenegocio/checarNivel1",
          { codigo: value, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.existeNivel1 === 1) {
            this.setState({
              errorMsg: "Esta cuenta ya existe",
              validateStatus: "error",
            });
          } else if (res.data.existeNivel1 === 0) {
            this.setState({
              errorMsg: "",
              validateStatus: "success",
            });
          }
        });
    } else if (value.toString().length < 5) {
      this.setState({
        errorMsg: "Tiene que ser un conjunto de 5 numeros",
        validateStatus: "error",
      });
    } else if (value.toString().length === 5) {
      axios
        .post(
          "unidadesdenegocio/checarNivel2",
          { codigo: value, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.existeNivel1 === 1) {
            if (res.data.existeNivel2 === 1) {
              this.setState({
                errorMsg: "Esta cuenta ya existe",
                validateStatus: "error",
              });
            } else if (res.data.existeNivel2 === 0) {
              if (res.data.existeProyecto === 1) {
                this.setState({
                  errorMsg: "La cuenta mayor ya tiene un proyecto registrado",
                  validateStatus: "error",
                });
              } else {
                this.setState({
                  errorMsg: "",
                  validateStatus: "success",
                });
              }
            }
          } else if (res.data.existeNivel1 === 0) {
            this.setState({
              errorMsg: "El nivel 1 no existe, primero registre el nivel 1.",
              validateStatus: "error",
            });
          }
        });
    } else if (value.toString().length < 8) {
      this.setState({
        errorMsg: "Tiene que ser un conjunto de 8 numeros",
        validateStatus: "error",
      });
    } else if (value.toString().length === 8) {
      axios
        .post(
          "unidadesdenegocio/checarNivel3",
          { codigo: value, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.existeNivel12 === 1) {
            if (res.data.existeNivel3 === 1) {
              this.setState({
                errorMsg: "Esta cuenta ya existe",
                validateStatus: "error",
              });
            } else if (res.data.existeNivel3 === 0) {
              if (res.data.existeProyecto === 1) {
                this.setState({
                  errorMsg: "La cuenta mayor ya tiene un proyecto registrado",
                  validateStatus: "error",
                });
              } else {
                this.setState({
                  errorMsg: "",
                  validateStatus: "success",
                });
              }
            }
          } else if (res.data.existeNivel12 === 0) {
            this.setState({
              errorMsg:
                "Los primeros niveles no existen, registre primero los niveles.",
              validateStatus: "error",
            });
          }
        });
    } else if (value.toString().length < 11) {
      this.setState({
        errorMsg: "Tiene que ser un conjunto de 11 numeros",
        validateStatus: "error",
      });
    } else if (value.toString().length === 11) {
      axios
        .post(
          "unidadesdenegocio/checarNivel4",
          { codigo: value, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.existeNivel123 === 1) {
            if (res.data.existeNivel4 === 1) {
              this.setState({
                errorMsg: "Esta cuenta ya existe",
                validateStatus: "error",
              });
            } else if (res.data.existeNivel4 === 0) {
              if (res.data.existeProyecto === 1) {
                this.setState({
                  errorMsg: "La cuenta mayor ya tiene un proyecto registrado",
                  validateStatus: "error",
                });
              } else {
                this.setState({
                  errorMsg: "",
                  validateStatus: "success",
                });
              }
            }
          } else if (res.data.existeNivel123 === 0) {
            this.setState({
              errorMsg:
                "Los primeros niveles no existen, registre primero los niveles.",
              validateStatus: "error",
            });
          }
        });
    } else if (value.toString().length < 14) {
      this.setState({
        errorMsg: "Tiene que ser un conjunto de 14 numeros",
        validateStatus: "error",
      });
    } else if (value.toString().length === 14) {
      axios
        .post(
          "unidadesdenegocio/checarNivel5",
          { codigo: value, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.existeNivel1234 === 1) {
            if (res.data.existeNivel5 === 1) {
              this.setState({
                errorMsg: "Esta cuenta ya existe",
                validateStatus: "error",
              });
            } else if (res.data.existeNivel5 === 0) {
              if (res.data.existeProyecto === 1) {
                this.setState({
                  errorMsg: "La cuenta mayor ya tiene un proyecto registrado",
                  validateStatus: "error",
                });
              } else {
                this.setState({
                  errorMsg: "",
                  validateStatus: "success",
                });
              }
            }
          } else if (res.data.existeNivel1234 === 0) {
            this.setState({
              errorMsg:
                "Los primeros niveles no existen, registre primero los niveles.",
              validateStatus: "error",
            });
          }
        });
    }
  }
  render() {
    return (
      <ModalItem
        title="Agregar Unidad de Negocio"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarAgregarUnidadDeNegocio.bind(this)}
        paddingTop
      >
        <Form
          ref={this.formRef}
          name="agregarUnidadDeNegocio"
          layout
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
            >
              Agregar
            </ButtonItem>
          }
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          <FormItem
            name="cuenta"
            label="Cuenta"
            required
            margin
            number
            validateStatus={this.state.validateStatus}
            help={this.state.errorMsg}
            tooltip={{ title: "Niveles de 3 digitos entre 100-999" }}
          >
            <InputNumberItem
              placeholder="Cuenta"
              cuentaContable
              onChange={this.onChangeCodigo.bind(this)}
            />
          </FormItem>
          <FormItem
            name="nombre"
            label="Nombre"
            required
            max={100}
            margin
            pattern
          >
            <InputItem placeholder="Nombre" />
          </FormItem>
          <FormItem
            name="descripcion"
            label="Descripción"
            margin
            pattern
            required
          >
            <InputItem placeholder="Descripción" />
          </FormItem>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(AgregarUnidadDeNegocio);
