import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { message, Select, Form as FormInitial } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  InputNumberItem,
  SelectItem,
  NotificacionPolizas,
  ButtonItem,
  ErrorItem
} from "../../../../../Components/Items";

const { Option } = Select;

class CostearInsumo extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    objetoInsumos: null,
    todasInsumos: null,
    objetoProyectos: null,
    todasProyectos: null,
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
    .post(
      "servicios/selectInsumoEEquivalencias",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      if (res.data.status === 404) {
      } else {
        this.setState({ todasInsumos: res.data.insumos });
        this.setState({
          objetoInsumos: res.data.insumos.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.stringBuscar}
              </Option>
            );
          }),
        });
      }
    });
    await axios
    .post(
      "proyectos/selectProyectos",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      if (res.data.status === 404) {
      } else {
        this.setState({ todasProyectos: res.data });
        this.setState({
          objetoProyectos: res.data.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.nombre}
              </Option>
            );
          }),
        });
      }
    });
    await axios
    .post(
      "insumos/datosInsumo",
      { empresaId: this.props.empresaId, insumoId: this.props.insumoId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      if (res.data.status === 404) {
      } else {
        this.formRef.current.setFieldsValue({insumos: res.data.insumosUtilizar})
        let arrayValue = this.formRef.current.getFieldValue("insumos");
        if(!arrayValue) return
          arrayValue.map((value, key) => {
              this.onChangeInsumoProyecto('', key)
              return null
          })
      }
    });
    this.setState({ loading: false });
  }
  cerrarCostearInsumo(codigo) {
    this.props.cerrarCostearInsumo(codigo);
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "ventas/costearInsumoVenta",
        { ...values, proyectoId: this.props.proyectoId, empresaId: this.props.empresaId, ventaId: this.props.ventaId, insumoVentaId: this.props.insumoVentaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se costeo un unsumo");
          NotificacionPolizas(res.data.polizasId)
          this.formRef.current.resetFields();
          this.cerrarCostearInsumo();
        } else {
          message.error("Sucedio un error y no se costeo");
        }
      });
  }
  async onChangeInsumoProyecto(value, key) {
    let arrayValue = await this.formRef.current.getFieldValue("insumos");
    let proyectoId = arrayValue[key].proyectoId;
    const insumoId = arrayValue[key].insumoId;
    const insumo = this.state.todasInsumos.find(
      (valueInsumos) => valueInsumos._id === insumoId
    );
   if(!insumo) return
    if (insumo.inventariable || insumo.insumoCompuesto) {
      await axios
        .post(
          "inventarios/inventarioInsumoProyecto",
          {
            proyectoId: proyectoId,
            insumoId: insumoId,
            noPrincipal: insumo.noPrincipal,
            insumoCompuesto: insumo.insumoCompuesto,
            empresaId: this.props.empresaId,
          },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          this.setState({ loading: false });
          arrayValue[key].cantidadProyecto = res.data.cantidad;
        });
    } else {
      arrayValue[key].cantidadProyecto = "No inventariable";
    }
    this.formRef.current.setFieldsValue({
      insumos: arrayValue,
    });
  }
  render() {
    return (
      <>
      <ModalItem
        title="Costear Insumo"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarCostearInsumo.bind(this)}
        paddingTop
        width='70%'
      >
        <Form
          ref={this.formRef}
          name="costearInsumo"
          layout
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
            >
              Costear
            </ButtonItem>
          }
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
                          <FormInitial.List name="insumos">
                {(fields, {add, remove}) => (
                  <>
                    <table className="table">
                      {fields.length > 0 ? (
                        <thead>
                          <tr>
                            <th className="th-border" style={{ width: "40%" }}>
                              Insumo
                            </th>
                            <th className="th-border" style={{ width: "20%" }}>
                              Proyecto
                            </th>
                            <th className="th-border" style={{ width: "20%" }}>
                              Cantidad Proyecto
                            </th>
                            <th className="th-border" style={{ width: "20%" }}>
                              Cantidad a Utilizar
                            </th>
                          </tr>
                        </thead>
                      ) : null}
                      {fields.map(({ key, name, fieldKey, ...restField }, arrayKey) => (
                        <tbody key={fieldKey}>
                          <tr>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "insumoId"]}
                                fieldKey={[fieldKey, "insumoId"]}
                                required
                                noStyle
                              >
                                <SelectItem
                                  sinBorde
                                  style={{ width: "100%" }}
                                  placeholder="Insumo"
                                  onChange={(value) =>
                                    this.onChangeInsumoProyecto(
                                      value,
                                      arrayKey
                                    )
                                  }
                                >
                                  {this.state.objetoInsumos}
                                </SelectItem>
                              </FormItem>
                            </td>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "proyectoId"]}
                                fieldKey={[fieldKey, "proyectoId"]}
                                required
                                noStyle
                                initialValue={this.props.proyectoId}
                              >
                                <SelectItem
                                  sinBorde
                                  style={{ width: "100%" }}
                                  placeholder="Proyecto"
                                  onChange={(value) =>
                                    this.onChangeInsumoProyecto(
                                      value,
                                      arrayKey
                                    )
                                  }
                                >
                                  {this.state.objetoProyectos}
                                </SelectItem>
                              </FormItem>
                            </td>
                            <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "cantidadProyecto"]}
                                  required
                                  noStyle
                                >
                                  <InputNumberItem
                                    sinBorde
                                    disabled
                                    placeholder="Cantidad en Proyecto"
                                  />
                                </FormItem>
                              </td>
                            <td className="td-border">
                              <FormItem shouldUpdate noStyle>
                                {({getFieldValue}) => {
                                  const insumos = getFieldValue("insumos");
                                  const cantidadProyecto = insumos[arrayKey]?.cantidadProyecto ?
                                    insumos[arrayKey].cantidadProyecto : 0;
                                  return (
                                    <FormItem
                                      {...restField}
                                      name={[name, "cantidad"]}
                                      fieldKey={[fieldKey, "cantidad"]}
                                      number
                                      noStyle
                                      max={cantidadProyecto}
                                      type="number"
                                    >
                                      <InputNumberItem
                                        sinBorde
                                        placeholder={"Max: " + cantidadProyecto}
                                      />
                                    </FormItem>
                                  );
                                }}
                              </FormItem>
                            </td>
                            <td>
                                    <MinusCircleOutlined
                                      onClick={() => remove(name)}
                                      style={{
                                        alignSelf: "center",
                                        justifySelf: "center",
                                      }}
                                    />
                                  </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                    <FormItem noStyle>
                          <ButtonItem
                            style={{ marginTop: 10, marginBottom: 10 }}
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Agregar Campo
                          </ButtonItem>
                        </FormItem>
                  </>
                )}
              </FormInitial.List>
        </Form>
      </ModalItem>
        </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(CostearInsumo);
