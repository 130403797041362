import {
    message,
    Tabs,
    PageHeader,
    Form as FormInitial,
    Spin,
  } from "antd";
  import React, { Component } from "react";
  import axios from "axios";
  import { connect } from "react-redux";
  import {
    Form,
    FormItem,
    HeaderItem,
    BoxItem,
    ButtonItem,
    InputItem,
    InputNumberItem,
    DatePickerItem,
    ErrorItem,
  } from "../../../../Components/Items";
  
  import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
  
  const { TabPane } = Tabs;
  
  class AgregarTipoPrestacion extends Component {
    formRef = React.createRef();
    state = {
      loading: false,
    };


    onFinishFailed (e) {
        ErrorItem(e)
      };
      
  
    onFinish = (values) => {
      this.setState({ loading: true });
      const datos = {
        ...values,
        empresaId: this.props.empresaId,
      };
  
      axios
        .post(
          'tiposPrestaciones/agregarTipoPrestacion',
          datos,
          { headers: this.props.headersToken }
        )
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.success === 2) {
            message.success('Tipo de prestación agregado exitosamente');
            this.props.history.goBack();
          } else {
            message.error('Error al agregar el tipo de prestación');
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          message.error('Error en la solicitud: ' + err.message);
        });
    };
  
    calculateFactor = (name) => {
      const fieldsValue = this.formRef.current.getFieldValue(["prestaciones", name]);
      const { diasVacaciones, primaVacacional, diasAguinaldo } = fieldsValue;
      if (diasVacaciones !== undefined && primaVacacional !== undefined && diasAguinaldo !== undefined) {
        const factorIntegracion =
          1 +
          diasAguinaldo / 365 +
          (diasVacaciones * (primaVacacional / 100)) / 365;
        this.formRef.current.setFieldsValue({
          prestaciones: {
            [name]: { factorIntegracion: factorIntegracion.toFixed(8) }
          }
        });
      }
    };
  
    render() {
      return (
        <>
          <PageHeader
            onBack={() => this.props.history.goBack()}
            title="Agregar Tipo de Prestación"
            style={{
              border: "1px solid rgb(235, 237, 240)",
              backgroundColor: "white",
              marginBottom: 10,
            }}
          />
          <div style={{ backgroundColor: "white", padding: 20, paddingTop: 5 }}>
            <Spin spinning={this.state.loading}>
              <Form
                ref={this.formRef}
                name="agregarTipoPrestacion"
                layout="vertical"
                onFinish={this.onFinish}
                bottom={
                  <ButtonItem
                    type="primary"
                    htmlType="submit"
                    style={{ display: "block", margin: "0 0 0 auto" }}
                  >
                    Agregar Tipo de Prestación
                  </ButtonItem>
                }
              >
                <Tabs defaultActiveKey={1}>
                  <TabPane tab="Datos del Tipo de Prestación" key={1}>
                    <HeaderItem>DATOS GENERALES</HeaderItem>
                    <BoxItem>
                      <FormItem
                        label="Nombre"
                        name="nombre"
                        required
                        margin
                        rules={[{ required: true, message: "Ingrese el nombre del tipo de prestación" }]}
                      >
                        <InputItem placeholder="Nombre del tipo de prestación" />
                      </FormItem>
  
                      <FormItem
                        label="Fecha de entrada en vigor"
                        name="fechaInicio"
                        required
                        margin
                        rules={[{ required: true, message: "Ingrese la fecha de inicio" }]}
                      >
                        <DatePickerItem style={{ width: '100%' }} />
                      </FormItem>
  
                      <FormInitial.List name="prestaciones">
                        {(fields, { add, remove }) => (
                          <>
                            <table className="table">
                              {fields.length > 0 ? (
                                <thead>
                                  <tr>
                                    <th className="th-border" style={{width: '17%'}}>Antiguedad</th>
                                    <th className="th-border" style={{width: '17%'}}>Dias Vacaciones</th>
                                    <th className="th-border" style={{width: '17%'}}>Prima Vacacional (%)</th>
                                    <th className="th-border" style={{width: '17%'}}>Dias Aguinaldo</th>
                                    <th className="th-border" style={{width: '16%'}}>Factor Integración</th>
                                    <th className="th-border" style={{width: '16%'}}>Dias Antiguedad</th>
                                  </tr>
                                </thead>
                              ) : null}
                              {fields.map(({ key, name, fieldKey, ...restField }, arrayKey) => (
                                <tbody key={key}>
                                  <tr>
                                    <td className="td-border">
                                      <FormItem
                                        {...restField}
                                        name={[name, "antiguedad"]}
                                        fieldKey={[arrayKey, "antiguedad"]}
                                        number
                                        required
                                        noStyle
                                      >
                                        <InputNumberItem placeholder="Antiguedad" style={{ width: '100%' }} />
                                      </FormItem>
                                    </td>
                                    <td className="td-border">
                                      <FormItem
                                        {...restField}
                                        name={[name, "diasVacaciones"]}
                                        fieldKey={[arrayKey, "diasVacaciones"]}
                                        number
                                        noStyle
                                        required
                                      >
                                        <InputNumberItem
                                          placeholder="Dias de Vacaciones"
                                          style={{ width: '100%' }}
                                          onChange={() => this.calculateFactor(name)}
                                        />
                                      </FormItem>
                                    </td>
                                    <td className="td-border">
                                      <FormItem
                                        {...restField}
                                        name={[name, "primaVacacional"]}
                                        fieldKey={[arrayKey, "primaVacacional"]}
                                        number
                                        noStyle
                                        required
                                      >
                                        <InputNumberItem
                                          placeholder="Prima Vacacional"
                                          style={{ width: '100%' }}
                                          onChange={() => this.calculateFactor(name)}
                                        />
                                      </FormItem>
                                    </td>
                                    <td className="td-border">
                                      <FormItem
                                        {...restField}
                                        name={[name, "diasAguinaldo"]}
                                        fieldKey={[arrayKey, "diasAguinaldo"]}
                                        number
                                        noStyle
                                        required
                                      >
                                        <InputNumberItem
                                          placeholder="Dias Aguinaldo"
                                          style={{ width: '100%' }}
                                          onChange={() => this.calculateFactor(name)}
                                        />
                                      </FormItem>
                                    </td>
                                    <td className="td-border">
                                      <FormItem
                                        {...restField}
                                        name={[name, "factorIntegracion"]}
                                        fieldKey={[arrayKey, "factorIntegracion"]}
                                        number
                                        noStyle
                                        required
                                      >
                                        <InputNumberItem
                                          placeholder="Factor de Integracion"
                                          disabled
                                          style={{ width: '100%' }}
                                        />
                                      </FormItem>
                                    </td>
                                    <td className="td-border">
                                      <FormItem
                                        {...restField}
                                        name={[name, "diasAntiguedad"]}
                                        fieldKey={[arrayKey, "diasAntiguedad"]}
                                        number
                                        noStyle
                                        required
                                      >
                                        <InputNumberItem placeholder="Dias de Antiguedad" style={{ width: '100%' }} />
                                      </FormItem>
                                    </td>
                                    <td>
                                      <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                        style={{ alignSelf: "center", justifySelf: "center" }}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                            </table>
                            <ButtonItem
                              style={{ marginTop: 10, marginBottom: 10 }}
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Agregar Campo
                            </ButtonItem>
                          </>
                        )}
                      </FormInitial.List>
                    </BoxItem>
                  </TabPane>
                </Tabs>
              </Form>
            </Spin>
          </div>
        </>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      headersToken: state.user.headersToken,
      empresaId: state.empresa.id,
    };
  };
  
  export default connect(mapStateToProps)(AgregarTipoPrestacion);
  