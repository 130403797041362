import React, { Component } from "react";
import {
  Input,
  message,
  Select,
  Row,
  Col,
  Spin,
  Divider
} from "antd";
import { Form, FormItem, InputItem, InputNumberItem, SelectItem, cuentaMask,ButtonItem, ErrorItem } from "../../../../Components/Items";
import axios from "axios";
import { connect } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import AgregarCuentaContable from "../../CuentasContables/AgregarCuentaContable/AgregarCuentaContable";
import AgregarTipoDePoliza from "../../TiposDePolizas/AgregarTipoDePoliza/AgregarTipoDePoliza";

const { Option } = Select

class ContabilidadConfiguracion extends Component {
  formRef = React.createRef();
  state = {
    modalAgregarCuentaContable: false,
    objetoCuentaContable: null,
    todasCuentaContable: null,
    objetoTiposDePolizasDiario: null,
    todasTiposDePolizas: null,
    objetoRegimenFiscal: null,
    todasRegimenFiscal: null,
    cuentaAgregando: null,
    modalAgregarTipoDePoliza: false,
    loading: false
  }
  componentDidMount() {
    this.consultarConfiguracion()
  }
  onFinish(values) {
    axios
      .post(
        "empresas/editarConfiguracionContabilidad",
        { ...values, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Se ha modificado la configuracion de la empresa");
          this.consultarConfiguracion();
        } else if (res.data.success === 1) {
          message.error("No se pudo modificar la configuracion");
        } else {
          message.error("Hubo un error y no se modifico la configuracion");
        }
      });
  }
  async consultarConfiguracion() {
    this.setState({ loading: true })
    await axios
      .post(
        "empresas/selectConfiguracionContabilidad",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro configuracion de esta empresa");
        } else {
          this.formRef.current.setFieldsValue({
            cuentaIntercentroId:
              res.data.cuentaIntercentroId,
            cuentaUtilidadCambiariaId:
              res.data.cuentaUtilidadCambiariaId,
            cuentaDescuentoComprasId: res.data.cuentaDescuentoComprasId,
            cuentaPerdidaCambiariaId:
              res.data.cuentaPerdidaCambiariaId,
            regimenFiscalId:
              res.data.regimenFiscalId,
            tipoDePolizaDiarioCCId:
              res.data.tipoDePolizaDiarioCCId,
            tipoDePolizaDiarioTECId:
              res.data.tipoDePolizaDiarioTECId,
            tipoDePolizaDiarioIId:
              res.data.tipoDePolizaDiarioIId,
            cuentaAjusteFacturasId:
              res.data.cuentaAjusteFacturasId,
            ajusteMax:
              res.data.ajusteMax,
            serieFacturas: res.data.serieFacturas,
            folioFacturas: res.data.folioFacturas,
            serieRemisiones: res.data.serieRemisiones,
            folioRemisiones: res.data.folioRemisiones,
          });
        }
      });
    await axios
      .post(
        "cuentascontables/selectCuentasContablesFinales",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasCuentaContable: res.data });
          this.setState({
            objetoCuentaContable: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {cuentaMask(
                    value.nivel1 +
                    value.nivel2 +
                    value.nivel3 +
                    value.nivel4 +
                    value.nivel5
                  ) +
                    " - " +
                    value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "regimenfiscal/selectRegimenesFiscales",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasRegimenFiscal: res.data });
          this.setState({
            objetoRegimenFiscal: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.c_RegimenFiscal + " - " + value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "tiposdepolizas/selectTiposDePolizas",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasTiposDePolizas: res.data });
          this.setState({
            objetoTiposDePolizasDiario: res.data.map((value) => {
              if (value.tipoDePolizaId === 1 || value.tipoDePolizaId === 2) {
                return null;
              }
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
    this.setState({ loading: false })
  }
  abrirAgregarCuentaContable(cuentaAgregando) {
    this.setState({ modalAgregarCuentaContable: true, cuentaAgregando });
  }
  cerrarAgregarCuentaContable(codigo) {
    this.setState({ modalAgregarCuentaContable: false });
    this.consultarConfiguracion().then(() => {
      if (typeof codigo === "string") {
        this.formRef.current.setFieldsValue({ [this.state.cuentaAgregando]: codigo });
        this.setState({ cuentaAgregando: null });
      }
    });
  }
  abrirAgregarTipoDePoliza() {
    this.setState({ modalAgregarTipoDePoliza: true });
  }
  cerrarAgregarTipoDePoliza(codigo) {
    this.setState({ modalAgregarTipoDePoliza: false });
    this.consultarConfiguracion();
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }
  render() {
    return (
      <>
        <Spin spinning={this.state.loading}>
          <Form
            name="contabilidadConfiguracion"
            layout
            onFinish={this.onFinish.bind(this)}
            onFinishFailed={this.onFinishFailed}
            ref={this.formRef}
          >
            <Row>
              <Col span={12}>
                <FormItem
                  label="Regimen Fiscal"
                  name="regimenFiscalId"
                  margin
                  required
                >
                  <SelectItem placeholder="Regimen Fiscal">
                    {this.state.objetoRegimenFiscal}
                  </SelectItem>
                </FormItem>
                <FormItem margin label='Cuenta Intercentro'>
                  <Input.Group compact>
                    <FormItem
                      name="cuentaIntercentroId"
                      noStyle
                      required
                    >
                      <SelectItem
                        placeholder="Cuenta Intercentro"
                        width="80%"
                      >
                        {this.state.objetoCuentaContable}
                      </SelectItem>
                    </FormItem>
                    <ButtonItem 
                      blanco
                      icon={<PlusOutlined />}
                      style={{ width: "20%" }}
                      onClick={() => this.abrirAgregarCuentaContable('cuentaIntercentroId')}
                    />
                  </Input.Group>
                </FormItem>
                <FormItem margin label='Cuenta Utilidad Cambiaria'>
                  <Input.Group compact>
                    <FormItem
                      name="cuentaUtilidadCambiariaId"
                      noStyle
                      required
                    >
                      <SelectItem
                        placeholder="Cuenta Utilidad Cambiaria"
                        width="80%"
                      >
                        {this.state.objetoCuentaContable}
                      </SelectItem>
                    </FormItem>
                    <ButtonItem
                      blanco
                      icon={<PlusOutlined />}
                      style={{ width: "20%" }}
                      onClick={() => this.abrirAgregarCuentaContable('cuentaUtilidadCambiariaId')}
                    />
                  </Input.Group>
                </FormItem>
                <FormItem margin label='Cuenta Perdida Cambiaria'>
                  <Input.Group compact>
                    <FormItem
                      name="cuentaPerdidaCambiariaId"
                      noStyle
                      required
                    >
                      <SelectItem
                        placeholder="Cuenta Perdida Cambiaria"
                        width="80%"
                      >
                        {this.state.objetoCuentaContable}
                      </SelectItem>
                    </FormItem>
                    <ButtonItem
                      blanco
                      icon={<PlusOutlined />}
                      style={{ width: "20%" }}
                      onClick={() => this.abrirAgregarCuentaContable('cuentaPerdidaCambiariaId')}
                    />
                  </Input.Group>
                </FormItem>
                <FormItem margin label='Cuenta Ajuste Facturas'>
                  <Input.Group compact>
                    <FormItem
                      name="cuentaAjusteFacturasId"
                      noStyle
                      required
                    >
                      <SelectItem
                        placeholder="Cuenta Ajuste Facturas"
                        width="80%"
                      >
                        {this.state.objetoCuentaContable}
                      </SelectItem>
                    </FormItem>
                    <ButtonItem
                      blanco
                      icon={<PlusOutlined />}
                      style={{ width: "20%" }}
                      onClick={() => this.abrirAgregarCuentaContable('cuentaAjusteFacturasId')}
                    />
                  </Input.Group>
                </FormItem>
                <FormItem margin label='Cuenta Descuento Compras'>
                  <Input.Group compact>
                    <FormItem
                      name="cuentaDescuentoComprasId"
                      noStyle
                      required
                    >
                      <SelectItem
                        placeholder="Cuenta Descuento Compras"
                        width="80%"
                      >
                        {this.state.objetoCuentaContable}
                      </SelectItem>
                    </FormItem>
                    <ButtonItem
                      blanco
                      icon={<PlusOutlined />}
                      style={{ width: "20%" }}
                      onClick={() => this.abrirAgregarCuentaContable('cuentaDescuentoComprasId')}
                    />
                  </Input.Group>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Ajuste Max."
                  name="ajusteMax"
                  margin
                  number
                  required
                >
                  <InputNumberItem placeholder="Ajuste Max." masMenos />
                </FormItem>
                <FormItem
                  label="Poliza Diario Caja Chica"
                  margin
                  tooltip="Elegir polizas de diario solamente"
                >
                  <Input.Group compact>
                    <FormItem name="tipoDePolizaDiarioCCId" required noStyle>
                      <SelectItem
                        placeholder="Tipo de Poliza Diario Caja Chica"
                        width="80%"
                      >
                        {this.state.objetoTiposDePolizasDiario}
                      </SelectItem>
                    </FormItem>
                    <ButtonItem
                      blanco
                      icon={<PlusOutlined />}
                      style={{ width: "20%" }}
                      onClick={this.abrirAgregarTipoDePoliza.bind(this)}
                    />
                  </Input.Group>
                </FormItem>
                <FormItem
                  label="Poliza Diario Traspasos"
                  margin
                  tooltip="Elegir polizas de diario solamente"
                >
                  <Input.Group compact>
                    <FormItem name="tipoDePolizaDiarioTECId" required noStyle>
                      <SelectItem
                        placeholder="Tipo de Poliza Diario Traspasos"
                        width="80%"
                      >
                        {this.state.objetoTiposDePolizasDiario}
                      </SelectItem>
                    </FormItem>
                    <ButtonItem
                      blanco
                      icon={<PlusOutlined />}
                      style={{ width: "20%" }}
                      onClick={this.abrirAgregarTipoDePoliza.bind(this)}
                    />
                  </Input.Group>
                </FormItem>
                <FormItem
                  label="Poliza Diario Inventario"
                  margin
                  tooltip="Elegir polizas de diario solamente"
                >
                  <Input.Group compact>
                    <FormItem name="tipoDePolizaDiarioIId" required noStyle>
                      <SelectItem
                        placeholder="Tipo de Poliza Diario Inventario"
                        width="80%"
                      >
                        {this.state.objetoTiposDePolizasDiario}
                      </SelectItem>
                    </FormItem>
                    <ButtonItem
                      blanco
                      icon={<PlusOutlined />}
                      style={{ width: "20%" }}
                      onClick={this.abrirAgregarTipoDePoliza.bind(this)}
                    />
                  </Input.Group>
                </FormItem>
              </Col>
            </Row>
            <Divider style={{ margin: 0 }}>Numeración</Divider>
            <Row>
              <Col span={12}>
                <FormItem
                  name="serieFacturas"
                  label="Serie Facturas"
                  tooltip={{ title: "Serie que se mostrara en facturas." }}
                  required
                  margin
                  pattern
                >
                  <InputItem placeholder="Serie que se mostrara en facturas" />
                </FormItem>
                <FormItem
                  name="folioFacturas"
                  label="Folio Facturas"
                  tooltip={{ title: "Folio de la siguiente factura." }}
                  type='number'
                  min={1}
                  required
                  number
                  margin
                >
                  <InputNumberItem placeholder="Folio de la siguiente factura" />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  name="serieRemisiones"
                  label="Serie Remisiones"
                  tooltip={{ title: "Serie que se mostrara en remisiones." }}
                  required
                  margin
                  pattern
                >
                  <InputItem placeholder="Serie que se mostrara en remisiones" />
                </FormItem>
                <FormItem
                  name="folioRemisiones"
                  label="Folio Remisiones"
                  type='number'
                  min={1}
                  tooltip={{ title: "Folio de la siguiente remision." }}
                  required
                  number
                  margin
                >
                  <InputNumberItem placeholder="Folio de la siguiente remision" />
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <FormItem
                  name="serieCartaPorte"
                  label="Serie Carta Porte"
                  tooltip={{ title: "Serie que se mostrara en carta porte." }}
                  required
                  margin
                  pattern
                >
                  <InputItem placeholder="Serie que se mostrara en carta porte" />
                </FormItem>
                <FormItem
                  name="folioCartaPorte"
                  label="Folio Carta Porte"
                  tooltip={{ title: "Folio de la siguiente carta porte." }}
                  type='number'
                  min={1}
                  required
                  number
                  margin
                >
                  <InputNumberItem placeholder="Folio de la siguiente carta porte" />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  name="serieFacturaGlobal"
                  label="Serie Factura Global"
                  tooltip={{ title: "Serie que se mostrara en factura global." }}
                  required
                  margin
                  pattern
                >
                  <InputItem placeholder="Serie que se mostrara en factura global" />
                </FormItem>
                <FormItem
                  name="folioFacturaGlobal"
                  label="Folio Factura Global"
                  type='number'
                  min={1}
                  tooltip={{ title: "Folio de la siguiente factura global." }}
                  required
                  number
                  margin
                >
                  <InputNumberItem placeholder="Folio de la siguiente factura global" />
                </FormItem>
              </Col>
            </Row>
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
            >
              Guardar Configuracion
            </ButtonItem>
          </Form>
          <AgregarTipoDePoliza
            visible={this.state.modalAgregarTipoDePoliza}
            cerrarAgregarTipoDePoliza={this.cerrarAgregarTipoDePoliza.bind(this)}
          />
          <AgregarCuentaContable
            visible={this.state.modalAgregarCuentaContable}
            cerrarAgregarCuentaContable={this.cerrarAgregarCuentaContable.bind(
              this
            )}
          />
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(ContabilidadConfiguracion);
