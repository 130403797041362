import { Empty, PageHeader, Popconfirm, Space, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem } from "../../../Components/Items";
import moment from "moment";
import "moment/locale/es";
import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import AgregarPuesto from "./AgregarPuesto/AgregarPuesto";
import EditarPuesto from "./EditarPuesto/EditarPuesto";

moment.locale("es");

class Puestos extends Component {
  state = {
    modalAgregar: false,
    modalEditar: false,
    todosPuestos: [],
    loading: false,
    puestoSeleccionado: null,
  };

  // Abrir modal para agregar
  abrirAgregarPuesto = () => {
    this.setState({ modalAgregar: true, puestoSeleccionado: null });
  };

  // Cerrar modal de agregar
  cerrarAgregarPuesto = () => {
    this.setState({ modalAgregar: false });
    this.consultarTodosPuestos();
  };

  // Abrir modal para editar
  abrirEditarPuesto = (puesto) => {
    this.setState({ modalEditar: true, puestoSeleccionado: puesto });
  };

  // Cerrar modal de editar
  cerrarEditarPuesto = () => {
    this.setState({ modalEditar: false });
    this.consultarTodosPuestos();
  };

  componentDidMount() {
    this.consultarTodosPuestos();
  }

  consultarTodosPuestos() {
    this.setState({ loading: true });
    axios
      .post(
        "puestos/selectTodosPuestos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
          message.error("No se encontraron puestos");
        } else {
          this.setState({
            todosPuestos: res.data.puestos.map((value) => {
              return { ...value, accion: { _id: value._id, estado: value.estado } };
            }),
          });
        }
      });
  }

  // Agrega un nuevo puesto
  agregarPuesto = (datosPuesto) => {
    axios
      .post(
        "puestos/agregarPuesto",
        {
          ...datosPuesto,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === true) {
          message.success("Puesto agregado exitosamente");
          this.consultarTodosPuestos();
          this.cerrarAgregarPuesto();
        } else {
          message.error("Error al agregar el puesto");
        }
      });
  };

  // Edita un puesto existente
  editarPuesto = (datosPuesto) => {
    axios
      .post(
        "puestos/editarPuesto",
        {
          ...datosPuesto,
          empresaId: this.props.empresaId,
          puestoId: this.state.puestoSeleccionado._id,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === true) {
          message.success("Puesto actualizado exitosamente");
          this.consultarTodosPuestos();
          this.cerrarEditarPuesto();
        } else {
          message.error("Error al actualizar el puesto");
        }
      });
  };

  // Deshabilita un puesto
  deshabilitarPuesto(id) {
    axios
      .post(
        "puestos/deshabilitarPuestos",
        { puestoId: id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Puesto deshabilitado exitosamente");
          this.consultarTodosPuestos();
        } else if (res.data.success === 1){
          message.error("Error al deshabilitar el puesto");
        } else {
          message.error("Error");
        }
      });
  }

  // Habilita un puesto
  habilitarPuesto(id) {
    axios
      .post(
        "puestos/habilitarPuesto",
        { puestoId: id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Puesto habilitado exitosamente");
          this.consultarTodosPuestos();
        } else if (res.data.success === 1){
          message.error("Error al habilitar el puesto");
        } else {
          message.error("Error");
        }
      });
  }

  render() {
    const columns = [
      {
        title: "Número",
        dataIndex: "codigo",
        key: "codigo",
        sorter: (a, b) => a.codigo.localeCompare(b.codigo),
      },
      {
        title: "Nombre del Puesto",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      },
      {
        title: "Descripción",
        dataIndex: "descripcion",
        key: "descripcion",
        sorter: (a, b) => a.descripcion.localeCompare(b.descripcion),
      },
      {
        title: "Acción",
        key: "accion",
        render: (record) => (
          <Space size="middle">
            <EditOutlined
              onClick={() => this.abrirEditarPuesto(record)}
              style={{ color: "blue" }}
            />
            <>
              {record.estado === 1 ? (
                <Popconfirm
                  title="¿Seguro que quieres deshabilitar este puesto?"
                  onConfirm={() => this.deshabilitarPuesto(record._id)}
                  okText="Sí"
                  cancelText="No"
                >
                  <CheckCircleOutlined style={{ color: "green" }} />
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="¿Seguro que quieres habilitar este puesto?"
                  onConfirm={() => this.habilitarPuesto(record._id)}
                  okText="Sí"
                  cancelText="No"
                >
                  <StopOutlined style={{ color: "red" }} />
                </Popconfirm>
              )}
            </>
          </Space>
        ),
      },
    ];

    return (
      <>
        <PageHeader
          title="Puestos"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <ButtonItem
              type="primary"
              key="agregarPuesto"
              onClick={this.abrirAgregarPuesto}
            >
              Agregar Puesto
            </ButtonItem>
          }
        />
        <TableItem
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <p>No hay registros de puestos. Aquí puedes gestionar y llevar un control de los puestos.</p>
                  </>
                }
              />
            ),
          }}
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todosPuestos}
          columns={columns}
          pagination={false}
        />
        <AgregarPuesto
          visible={this.state.modalAgregar}
          cerrarModal={this.cerrarAgregarPuesto}
          puesto={this.state.puestoSeleccionado}
          onGuardar={(datosPuesto) =>
            this.state.puestoSeleccionado
              ? this.editarPuesto(datosPuesto)
              : this.agregarPuesto(datosPuesto)
          }
        />
        <EditarPuesto
          visible={this.state.modalEditar}
          cerrarModal={this.cerrarEditarPuesto}
          puesto={this.state.puestoSeleccionado}
          onGuardar={(datosPuesto) =>
            this.editarPuesto(datosPuesto)
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(Puestos);
