import React, { Component } from "react";
import {
  Space,
  Form as FormInicial,
  message,
  Spin,
  Row,
  Col,
} from "antd";
import { /*MinusCircleOutlined,*/ PlusOutlined } from "@ant-design/icons";
import { Form, FormItem, InputItem, InputNumberItem, ButtonItem, SwitchItem, ErrorItem } from "../../../../Components/Items";
import axios from "axios";
import { connect } from "react-redux";

class InsumosConfiguracion extends Component {
  formRef = React.createRef();

  state = {
    loading: false
  }

  componentDidMount() {
    this.consultarConfiguracion()
  }
  onFinish(values) {
    if (values.precios?.length === 0 || !values.precios)
      return message.warning("Es necesario por lo menos un precio");
    axios
      .post(
        "empresas/editarConfiguracionInsumos",
        { ...values, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Se ha modificado la configuracion de la empresa");
          this.consultarConfiguracion();
        } else if (res.data.success === 1) {
          message.error("No se pudo modificar la configuracion");
        } else {
          message.error("Hubo un error y no se modifico la configuracion");
        }
      });
  }
  async consultarConfiguracion() {
    this.setState({ loading: true })
    await axios
      .post(
        "empresas/selectConfiguracionInsumos",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro configuracion de esta empresa");
        } else {
          this.formRef.current.setFieldsValue({
            calcularPreciosAutomaticamente:
              res.data.calcularPreciosAutomaticamente,
            calcularPreciosSobre:
              res.data.calcularPreciosSobre,
            precios: res.data.precios,
            venderSinInventario: res.data.venderSinInventario,
            marbetes: res.data.marbetes,
          });
        }
      });
    this.setState({ loading: false })
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }
  render() {
    return (
      <Spin spinning={this.state.loading}>
        <Form
          name="insumosConfiguracion"
          layout
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
          ref={this.formRef}
        >
          <Row>
            <Col span={12}>
              <FormItem
                label="Vender sin inventario"
                name="venderSinInventario"
                margin
                initialValue={false}
                labelCol={{ span: 14 }}
                wrapperCol={{ span: 10 }}
                valuePropName="checked"
                tooltip='Se podra vender con inventario mas sin embargo no se podran entregar insumos hasta no tener el inventario'
              >
                <SwitchItem />
              </FormItem>
              <FormItem
                label="Calcular Precios Automaticamente"
                name="calcularPreciosAutomaticamente"
                margin
                initialValue={false}
                labelCol={{ span: 14 }}
                wrapperCol={{ span: 10 }}
                valuePropName="checked"
                tooltip='Se calcularan por porcentaje del insumo cuando el costo cambie'
              >
                <SwitchItem />
              </FormItem>
              <FormItem
                label="Control Marbetes"
                name="marbetes"
                tooltip='Con esta opción se podra llevar un control de marbetes y numeraciones de los mismos'
                margin
                initialValue={false}
                labelCol={{ span: 14 }}
                wrapperCol={{ span: 10 }}
                valuePropName="checked"
              >
                <SwitchItem disabled />
              </FormItem>
              <FormItem
                label="Calcular Precios Sobre"
                name="calcularPreciosSobre"
                margin
                initialValue={false}
                labelCol={{ span: 14 }}
                wrapperCol={{ span: 10 }}
                valuePropName="checked"
              >
                <SwitchItem unCheckedChildren="Venta" checkedChildren="Costo" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormInicial.List name="precios">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <FormItem
                          {...restField}
                          label="Nombre"
                          name={[name, "nombre"]}
                          fieldKey={[fieldKey, "nombre"]}
                          required
                          margin
                        >
                          <InputItem placeholder="Ej. Precio Medio" />
                        </FormItem>
                        <FormItem
                          {...restField}
                          label="Porcentaje"
                          name={[name, "porcentaje"]}
                          fieldKey={[fieldKey, "porcentaje"]}
                          required
                          margin
                        >
                          <InputNumberItem placeholder="Ej. 20%" porcentaje />
                        </FormItem>
                        {/* {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(name)}
                        />
                      ) : null} */}
                      </Space>
                    ))}
                    <FormItem>
                      <ButtonItem
                        type="dashed"
                        onClick={() => {
                          if (fields.length < 10) {
                            add()
                          } else {
                            message.warning('No se pueden agregar mas de 10 precios')
                          }
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Agregar Precio
                      </ButtonItem>
                    </FormItem>
                  </>
                )}
              </FormInicial.List>
            </Col>
          </Row>
          <ButtonItem
            type="primary"
            htmlType="submit"
            style={{ display: "block", margin: "0 0 0 auto" }}
          >
            Guardar Configuracion
          </ButtonItem>
        </Form>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(InsumosConfiguracion);
