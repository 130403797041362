import {
    Col,
    message,
    PageHeader,
    Row,
    Spin,
    Select,
    Checkbox,
    Space,
    Tabs,
  } from "antd";
  import axios from "axios";
  import React, { Component } from "react";
  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  import { connect } from "react-redux";
  // import balanceGeneralPdf from "../../../../Pdf/balanceGeneralPdf";
  import {
    SelectItem,
    Form,
    FormItem,
    cuentaMask,
    RangePickerItem,
    TableItem,
    dineroDisabledMask,
    ButtonItem,
    ErrorItem
  } from "../../../../Components/Items";
  import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
  import * as XLSX from "xlsx";
  import { ResponsiveLine } from "@nivo/line";
  import moment from "moment";
  import "moment/locale/es";
    import reporteDeComprasPdf from "../../../../Pdf/reporteDeComprasPdf";
  moment.locale("es");
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
  const { Option } = Select;
  const { TabPane } = Tabs;
  
  class ReporteCompras extends Component {
    state = {
      loading: false,
      pdf: null,
      objetoProveedores: null,
      todasProveedores: null,
      objetoProyectos: null,
      todasProyectos: null,
      objetoUniadesDeNegocio: null,
      todasUniadesDeNegocio: null,
      dataReporte: null,
      todasCompras: [],
      dataGrafico: [],
      subtotal: 0,
      totalImpuestos: 0,
      total: 0,
      totalRetenciones: 0,
      totalAPagar: 0,
      desglosarProductos: false
    };
  
    atras() {
        this.props.history.goBack();
    }
  
    async componentDidMount() {
      this.setState({ loading: true });
      await axios
        .post(
          "proveedores/selectProveedores",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasProveedores: res.data });
            this.setState({
              objetoProveedores: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.stringBuscar}
                  </Option>
                );
              }),
            });
          }
        });
      await axios
        .post(
          "usuarios/selectUsuariosEmpresa",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasUsuarios: res.data });
            this.setState({
              objetoUsuarios: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre +
                      " " +
                      value.apellidoPaterno +
                      " " +
                      value.apellidoMaterno}
                  </Option>
                );
              }),
            });
          }
        });
      await axios
        .post(
          "proyectos/selectProyectos",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasProyectos: res.data });
            this.setState({
              objetoProyectos: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre}
                  </Option>
                );
              }),
            });
          }
        });
      await axios
        .post(
          "unidadesdenegocio/selectUnidadesDeNegocio",
          { empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasUniadesDeNegocio: res.data });
            this.setState({
              objetoUniadesDeNegocio: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {cuentaMask(
                      value.nivel1 +
                        value.nivel2 +
                        value.nivel3 +
                        value.nivel4 +
                        value.nivel5
                    ) +
                      " - " +
                      value.nombre}
                  </Option>
                );
              }),
            });
          }
        });
      this.consultarReporteCompras({
        periodo: [moment().subtract(1, "month"), moment()],
        proveedores: 0,
        filtrarProyecto: 0,
        estadoFacturas: 5,
      });
    }
  
    async crearGrafico(valueCompras, fechaInicial, fechaFinal) {
      let grafico = [];
      let graficoSumatoria = [];
      let sumatoria = 0;
  
      let fechaCambiante = moment(fechaInicial).locale("es");
      const fechaFinalMoment = moment(fechaFinal).locale("es");
  
      if (fechaFinalMoment.diff(fechaCambiante, "days") <= 31) {
        while (fechaFinalMoment.diff(fechaCambiante, "days") !== -1) {
          // eslint-disable-next-line no-loop-func
          const compras = valueCompras.filter((todasCompras) => {
            if (isNaN(todasCompras.fechaFactura)) {
              return moment(fechaCambiante).isSame(
                moment(todasCompras.fechaFactura, "DD/MM/YYYY"),
                "date"
              );
            } else {
              return false;
            }
          });
  
          let totalPagar = 0;
  
          compras.map((valueCompraDia) => {
            totalPagar += valueCompraDia.totalPagar;
            return null;
          });
  
          grafico.push({
            x: moment(fechaCambiante).locale("es").format("DD/MM"),
            y: totalPagar,
          });
  
          graficoSumatoria.push({
            x: moment(fechaCambiante).locale("es").format("DD/MM"),
            y: totalPagar + sumatoria,
          });
  
          sumatoria += totalPagar;
          fechaCambiante = moment(fechaCambiante).add(1, "day").locale("es");
        }
      } else if (fechaFinalMoment.diff(fechaCambiante, "days") < 180) {
        while (fechaFinalMoment.diff(fechaCambiante, "weeks") !== -1) {
          // eslint-disable-next-line no-loop-func
          const compras = valueCompras.filter((todasCompras) => {
            if (isNaN(todasCompras.fechaFactura)) {
              return moment(fechaCambiante).isSame(
                moment(todasCompras.fechaFactura, "DD/MM/YYYY"),
                "week"
              );
            } else {
              return false;
            }
          });
          let totalPagar = 0;
  
          compras.map((valueCompraDia) => {
            totalPagar += valueCompraDia.totalPagar;
            return null;
          });
  
          grafico.push({
            x: moment(fechaCambiante).locale("es").format("[SEM] WW"),
            y: totalPagar,
          });
  
          graficoSumatoria.push({
            x: moment(fechaCambiante).locale("es").format("[SEM] WW"),
            y: totalPagar + sumatoria,
          });
  
          sumatoria += totalPagar;
          fechaCambiante = moment(fechaCambiante).add(1, "week").locale("es");
        }
      } else if (fechaFinalMoment.diff(fechaCambiante, "months") < 36) {
        while (fechaFinalMoment.diff(fechaCambiante, "months") !== -1) {
          // eslint-disable-next-line no-loop-func
          const compras = valueCompras.filter((todasCompras) => {
            if (isNaN(todasCompras.fechaFactura)) {
              return moment(fechaCambiante).isSame(
                moment(todasCompras.fechaFactura, "DD/MM/YYYY"),
                "month"
              );
            } else {
              return false;
            }
          });
          let totalPagar = 0;
  
          compras.map((valueCompraDia) => {
            totalPagar += valueCompraDia.totalPagar;
            return null;
          });
  
          grafico.push({
            x: moment(fechaCambiante).locale("es").format("MM-YY"),
            y: totalPagar,
          });
  
          graficoSumatoria.push({
            x: moment(fechaCambiante).locale("es").format("MM-YY"),
            y: totalPagar + sumatoria,
          });
  
          sumatoria += totalPagar;
          fechaCambiante = moment(fechaCambiante).add(1, "month").locale("es");
        }
      } else {
        while (fechaFinalMoment.diff(fechaCambiante, "years") !== -1) {
          // eslint-disable-next-line no-loop-func
          const compras = valueCompras.filter((todasCompras) => {
            if (isNaN(todasCompras.fechaFactura)) {
              return moment(fechaCambiante).isSame(
                moment(todasCompras.fechaFactura, "DD/MM/YYYY"),
                "year"
              );
            } else {
              return false;
            }
          });
          let totalPagar = 0;
  
          compras.map((valueCompraDia) => {
            totalPagar += valueCompraDia.totalPagar;
            return null;
          });
  
          grafico.push({
            x: moment(fechaCambiante).locale("es").format("YYYY"),
            y: totalPagar,
          });
  
          graficoSumatoria.push({
            x: moment(fechaCambiante).locale("es").format("YYYY"),
            y: totalPagar + sumatoria,
          });
  
          sumatoria += totalPagar;
          fechaCambiante = moment(fechaCambiante).add(1, "year").locale("es");
        }
      }
  
      await this.setState({
        dataGrafico: [
          {
            id: "Compras",
            color: "hsl(15, 70%, 50%)",
            data: grafico,
          },
          {
            id: "Compras Acumuladas",
            color: "hsl(15, 70%, 50%)",
            data: graficoSumatoria,
          },
        ],
      });
    }
  
    consultarReporteCompras(values) {
      this.setState({ loading: true });
      axios
        .post(
          "reportesDeCompras/reporteCompras",
          { ...values, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.success === 2) {
            this.crearGrafico(
              res.data.compras,
              res.data.fechaInicial,
              res.data.fechaFinal
            );
            this.setState({
              loading: false,
              dataReporte: res.data,
              todasCompras: res.data.compras,
              subtotal: res.data.subtotal,
              totalImpuestos: res.data.totalImpuestos,
              total: res.data.total,
              totalRetenciones: res.data.totalRetenciones,
              totalAPagar: res.data.totalAPagar,
              desglosarProductos: res.data.desglosarProductos,
            });
          } else if (res.data.success === 1) {
            message.error("Ha sucedido un error y no se cargo bien el reporte");
          } else {
            message.error("Ha sucedido un error y no se cargo bien el reporte");
          }
          this.setState({ loading: false });
        });
    }
  
    descargarExcel() {
      let datosDescargar = []
      if(this.state.desglosarProductos){
        for (const valueCompra of this.state.todasCompras) {
          if(valueCompra.folio === ''){
            const compra = this.state.todasCompras.find(todasCompras => todasCompras.folio !== '' && todasCompras._id.toString() === valueCompra._id.toString())
            datosDescargar.push({
              Folio: compra.folio,
              "# Factura": compra.facturaNumero,
              "# Remision": compra.remisionNumero,
              "Codigo Proveedor": compra.codigoProveedor,
              "Clave Proveedor": compra.claveProveedor,
              Fecha: compra.fechaFactura,
              Proveedor: compra.proveedor,
              "Codigo Insumo": valueCompra.codigoProveedor,
              "Clave Insumo": valueCompra.claveProveedor,
              "Cantidad": valueCompra.fechaFactura,
              "Descripcion": valueCompra.proveedor,
              "Familia": valueCompra.familia,
              "Precio Unitario": valueCompra.total,
              "Subtotal Insumo": valueCompra.totalPagado,
              "Precio Con Impuestos": valueCompra.saldo,
              "Total Insumo": valueCompra.estado,
              "Fecha probable de pago": compra.fechaPago,
              "Total A Cobrar": compra.totalPagar,
              "Fecha Vencimiento": compra.fechaVencimiento,
              Total: compra.total,
              Abonos: compra.totalPagado,
              Saldo: compra.saldo,
              Estado: compra.estado,
            })
          }
        }
      }else{
        datosDescargar = this.state.todasCompras.map((valueCompra) => {
          return {
            Folio: valueCompra.folio,
            "# Factura": valueCompra.facturaNumero,
            "# Remision": valueCompra.remisionNumero,
            "Codigo Proveedor": valueCompra.codigoProveedor,
            "Clave Proveedor": valueCompra.claveProveedor,
            Fecha: valueCompra.fechaFactura,
            Proveedor: valueCompra.proveedor,
            "Fecha probable de pago": valueCompra.fechaPago,
            "Total A Cobrar": valueCompra.totalPagar,
            "Fecha Vencimiento": valueCompra.fechaVencimiento,
            Total: valueCompra.total,
            Abonos: valueCompra.totalPagado,
            Saldo: valueCompra.saldo,
            Estado: valueCompra.estado,
          };
        });
      }
      const worksheet = XLSX.utils.json_to_sheet(datosDescargar);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte Compras");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "Reporte_Compras.xlsx");
    }
  
    descargarPdf() {
      pdfMake
        .createPdf(reporteDeComprasPdf(this.state.dataReporte))
        .download("Reporte Compras");
    }
  
    onFinishFailed(e) {
      ErrorItem(e);
    }
    render() {
      const columns = [
        {
          title: "Folio",
          dataIndex: "folio",
          key: "folio",
          sorter: (a, b) => {
            if (a.folio < b.folio) {
              return -1;
            }
            if (a.folio > b.folio) {
              return 1;
            }
            return 0;
          },
          buscar: "folio",
          ellipsis: true,
        },
        {
          title: "Fecha",
          dataIndex: "fechaFactura",
          key: "fechaFactura",
          sorter: (a, b) => {
            if (moment(a.fechaFactura, "DD/MM/YYYY") < moment(b.fechaFactura, "DD/MM/YYYY")) {
              return -1;
            }
            if (moment(a.fechaFactura, "DD/MM/YYYY") > moment(b.fechaFactura, "DD/MM/YYYY")) {
              return 1;
            }
            return 0;
          },
          buscar: "fechaFactura",
          ellipsis: true,
        },
        {
          title: "Proveedor",
          dataIndex: "proveedor",
          key: "proveedor",
          sorter: (a, b) => {
            if (a.proveedor < b.proveedor) {
              return -1;
            }
            if (a.proveedor > b.proveedor) {
              return 1;
            }
            return 0;
          },
          buscar: "proveedor",
          ellipsis: true,
        },
        {
          title: "Vencimiento",
          dataIndex: "fechaVencimiento",
          key: "fechaVencimiento",
          sorter: (a, b) => {
            if (moment(a.fechaVencimiento, "DD/MM/YYYY") < moment(b.fechaVencimiento, "DD/MM/YYYY")) {
              return -1;
            }
            if (moment(a.fechaVencimiento, "DD/MM/YYYY") > moment(b.fechaVencimiento, "DD/MM/YYYY")) {
              return 1;
            }
            return 0;
          },
          buscar: "fechaVencimiento",
          ellipsis: true,
        },
        {
          title: "Total",
          dataIndex: "total",
          key: "total",
          sorter: (a, b) => {
            if (a.total < b.total) {
              return -1;
            }
            if (a.total > b.total) {
              return 1;
            }
            return 0;
          },
          buscar: "total",
          render: (value) => dineroDisabledMask(value),
          align: "right",
          ellipsis: true,
        },
        {
          title: "Abonos",
          dataIndex: "totalPagado",
          key: "totalPagado",
          sorter: (a, b) => {
            if (a.totalPagado < b.totalPagado) {
              return -1;
            }
            if (a.totalPagado > b.totalPagado) {
              return 1;
            }
            return 0;
          },
          buscar: "totalPagado",
          ellipsis: true,
          render: (value) => dineroDisabledMask(value),
          align: "right",
        },
        {
          title: "Saldo",
          dataIndex: "saldo",
          key: "saldo",
          sorter: (a, b) => {
            if (a.saldo < b.saldo) {
              return -1;
            }
            if (a.saldo > b.saldo) {
              return 1;
            }
            return 0;
          },
          buscar: "saldo",
          ellipsis: true,
          render: (value) => dineroDisabledMask(value),
          align: "right",
        },
        {
          title: "Estado",
          dataIndex: "estado",
          key: "estado",
          sorter: (a, b) => {
            if (a.estado < b.estado) {
              return -1;
            }
            if (a.estado > b.estado) {
              return 1;
            }
            return 0;
          },
          buscar: "estado",
          ellipsis: true,
        },
      ];
      return (
        <>
          <Form
            ref={this.formRef}
            name="rerpoteCompras"
            onFinish={this.consultarReporteCompras.bind(this)}
            onFinishFailed={this.onFinishFailed}
            initialValues={{
              periodo: [moment().subtract(1, "month"), moment()],
            }}
          >
            <PageHeader
              onBack={this.atras.bind(this)}
              title="Reporte Compras"
              style={{
                border: "1px solid rgb(235, 237, 240)",
                backgroundColor: "white",
                marginBottom: 10,
              }}
              extra={
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <Space>
                    {this.state.dataReporte && (
                      <ButtonItem
                        blanco
                        onClick={() => this.descargarExcel()}
                        icon={<FileExcelOutlined />}
                        style={{ display: "block", margin: "0 0 0 auto" }}
                      >
                        Descargar Excel
                      </ButtonItem>
                    )}
                    {this.state.dataReporte && (
                      <ButtonItem
                        blanco
                        onClick={() => this.descargarPdf()}
                        icon={<FilePdfOutlined />}
                        style={{ display: "block", margin: "0 0 0 auto" }}
                      >
                        Descargar PDF
                      </ButtonItem>
                    )}
                    <ButtonItem
                      type="primary"
                      htmlType="submit"
                      style={{ display: "block", margin: "0 0 0 auto" }}
                    >
                      Consultar
                    </ButtonItem>
                  </Space>
                </div>
              }
            />
            <Spin spinning={this.state.loading}>
              <div
                style={{ backgroundColor: "white", padding: 20, paddingTop: 5 }}
              >
                <Tabs
                  style={{ margin: 0 }}
                  tabBarExtraContent={{
                    left: (
                      <h1 style={{ padding: 0, margin: "0 10px 0 0" }}>
                        Filtros:
                      </h1>
                    ),
                  }}
                >
                  <TabPane tabKey="1" key="1" forceRender tab="Periodo">
                    <FormItem name="periodo" required noStyle>
                      <RangePickerItem />
                    </FormItem>
                  </TabPane>
                  <TabPane tabKey="2" key="2" forceRender tab="Proveedores">
                    <FormItem name="proveedores" noStyle margin>
                      <SelectItem
                        width="90%"
                        mode="multiple"
                        placeholder="Proveedores"
                      >
                        {this.state.objetoProveedores}
                      </SelectItem>
                    </FormItem>
                  </TabPane>
                  <TabPane tabKey="4" key="4" forceRender tab="Proyecto">
                    <Row>
                      <Col span={8}>
                        <FormItem
                          name="filtrarProyecto"
                          required
                          noStyle
                          initialValue={0}
                        >
                          <SelectItem width="90%" placeholder="Filtrar Proyecto">
                            <Option default value={0}>
                              Niguno
                            </Option>
                            <Option value={1}>Proyectos</Option>
                            <Option value={2}>Unidades de negocio</Option>
                          </SelectItem>
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem shouldUpdate noStyle>
                          {({ getFieldValue }) => {
                            const filtrarProyecto =
                              getFieldValue("filtrarProyecto");
                            if (filtrarProyecto === 1) {
                              return (
                                <FormItem required name="proyectos" margin noStyle>
                                  <SelectItem
                                    width="90%"
                                    placeholder="Proyectos"
                                    mode="multiple"
                                  >
                                    {this.state.objetoProyectos}
                                  </SelectItem>
                                </FormItem>
                              );
                            }
                            if (filtrarProyecto === 2) {
                              return (
                                <FormItem required margin name="unidadDeNegocioId" noStyle>
                                  <SelectItem
                                    width="90%"
                                    placeholder="Unidades de Negocio"
                                  >
                                    {this.state.objetoUniadesDeNegocio}
                                  </SelectItem>
                                </FormItem>
                              );
                            }
                          }}
                        </FormItem>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabKey="5" key="5" forceRender tab="Detalles">
                    <Row style={{ padding: 0, margin: 0 }} gutter={[16, 0]}>
                      <Col span={8}>
                        <FormItem
                          label="Estado"
                          name="estadoFacturas"
                          required
                          initialValue={5}
                          style={{ marginBottom: 0 }}
                        >
                          <SelectItem placeholder="Filtrar Por">
                            <Option default value={5}>
                              Todos
                            </Option>
                            <Option value={4}>Por Pagar</Option>
                            <Option value={3}>Pagada</Option>
                            <Option value={2}>Vencida</Option>
                            <Option value={1}>Canceladas</Option>
                          </SelectItem>
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem
                          label="Desglosar Productos"
                          name="desglosarProductos"
                          valuePropName="checked"
                          margin
                        >
                          <Checkbox />
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        {/* <FormItem
                          label="Detalle"
                          name="detalle"
                          valuePropName="checked"
                          margin
                        >
                          <Checkbox />
                        </FormItem> */}
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  padding: 15,
                  marginTop: 10,
                  marginBottom: 10,
                  height: "400px",
                }}
              >
                <h1 style={{ fontSize: "20px" }}>Grafico</h1>
                <ResponsiveLine
                  data={this.state.dataGrafico}
                  margin={{ top: 20, right: 80, bottom: 100, left: 80 }}
                  yFormat={(e) => dineroDisabledMask(Math.abs(e))}
                  xScale={{ type: "point" }}
                  yScale={{
                    type: "linear",
                    min: 0,
                    max: "auto",
                    stacked: false,
                    reverse: false,
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 90,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                  }}
                  colors={['#ff6633', '#FF926E', '#339999', '#66cccc']}
                  colorBy="index"
                  pointSize={10}
                  pointColor={{ theme: "background" }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: "serieColor" }}
                  pointLabelYOffset={-12}
                  useMesh={true}
                />
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  padding: 15,
                  marginBottom: 10,
                  height: "75px",
                }}
              >
                <Row>
                  <Col span={4} style={{ textAlign: "center" }}>
                    <p style={{ margin: 0 }}>Subtotal</p>
                    <h1 style={{ fontSize: 15 }}>
                      {dineroDisabledMask(this.state.subtotal)}
                    </h1>
                  </Col>
                  <Col span={1} style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: 20 }}>+</h1>
                  </Col>
                  <Col span={4} style={{ textAlign: "center" }}>
                    <p style={{ margin: 0 }}>Impuestos</p>
                    <h1 style={{ fontSize: 15 }}>
                      {dineroDisabledMask(this.state.totalImpuestos)}
                    </h1>
                  </Col>
                  <Col span={1} style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: 20 }}>=</h1>
                  </Col>
                  <Col span={4} style={{ textAlign: "center" }}>
                    <p style={{ margin: 0 }}>Total</p>
                    <h1 style={{ fontSize: 15 }}>
                      {dineroDisabledMask(this.state.total)}
                    </h1>
                  </Col>
                  <Col span={1} style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: 20 }}>-</h1>
                  </Col>
                  <Col span={4} style={{ textAlign: "center" }}>
                    <p style={{ margin: 0 }}>Retenciones</p>
                    <h1 style={{ fontSize: 15 }}>
                      {dineroDisabledMask(this.state.totalRetenciones)}
                    </h1>
                  </Col>
                  <Col span={1} style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: 20 }}>=</h1>
                  </Col>
                  <Col span={4} style={{ textAlign: "center" }}>
                    <p style={{ margin: 0 }}>Total A Pagar</p>
                    <h1 style={{ fontSize: 15 }}>
                      {dineroDisabledMask(this.state.totalAPagar)}
                    </h1>
                  </Col>
                </Row>
              </div>
              <TableItem
                // onRow={(record, rowIndex) => {
                //   return {
                //     onDoubleClick: (event) => {
                //       this.props.history.push("/panel/verVenta", {
                //         id: record._id,
                //       });
                //     },
                //   };
                // }}
                rowKey={(value) => value._id.toString() + Math.random()}
                columns={columns}
                dataSource={this.state.todasCompras}
                size="small"
                pagination={false}
              />
              {/* <div style={{ backgroundColor: "white", padding: 20, paddingTop: 5 }}>
            {this.state.pdf && (
              <iframe
                title={"documentos"}
                style={{ width: "100%", height: "100vh" }}
                id="printPdf"
                name="printPdf"
              />
            )}
          </div> */}
            </Spin>
          </Form>
        </>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      headersToken: state.user.headersToken,
      empresaId: state.empresa.id,
    };
  };
  
  export default connect(mapStateToProps)(ReporteCompras);
  