import { message, Select } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  ButtonItem,
  InputItem,
  ErrorItem,
  SelectItem,
} from "../../../../Components/Items";

const { Option } = Select;

class EditarTipoAcumulado extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
    tipoAcumulado: this.props.tipoAcumulado || {}, // Cargar datos iniciales
  };

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      this.formRef.current.resetFields(); // Resetear campos antes de llenar el formulario
      this.consultarDatosTipoAcumulado(this.props.tipoAcumulado._id);
    }
  }

  consultarDatosTipoAcumulado(id) {
    this.setState({ loading: true });
    axios
      .post(
        "tiposAcumulados/datosTipoAcumulado",
        { tipoAcumuladoId: id },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
          message.error("No se encontraron los datos del tipo de acumulado");
        } else {
          const { codigo, nombre, tipoAcumulado } = res.data.tipoAcumulado;
  
          // Llenar los campos del formulario después de cargar los datos
          if (this.formRef.current) {
            this.formRef.current.setFieldsValue({
              codigo,
              nombre,
              tipoAcumulado: tipoAcumulado.toString(), // Convertir a string para que el Select lo reconozca correctamente
            });
          }
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Error al comunicarse con el servidor");
      });
  }

  onFinishFailed(e) {
    ErrorItem(e);
  }

  onFinish(values) {
    this.setState({ loading: true });
  
    axios
      .post(
        "tiposAcumulados/editarTipoAcumulado",
        {
          ...values,
          tipoAcumuladoId: this.props.tipoAcumulado._id, // Pasar el ID del tipo de acumulado
        },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
  
        if (res.data.success === 2) {
          message.success("Tipo de acumulado actualizado exitosamente");
          this.cerrarModal();
        } else if (res.data.success === 1) {
          message.error("Error al actualizar el tipo de acumulado");
        } else {
          message.error("Error desconocido en la solicitud");
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Error al comunicarse con el servidor");
      });
  }

  cerrarModal = () => {
    this.setState({ visible: false });
    this.props.cerrarModal();
    if (this.formRef.current) {
      this.formRef.current.resetFields();
    }
  };

  render() {
    return (
      <ModalItem
        title="Editar Tipo de Acumulado"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarModal}
        width={800}
      >
        <Form
          ref={this.formRef}
          name="modalEditarTipoAcumulado"
          layout
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
              loading={this.state.loading}
            >
              Guardar
            </ButtonItem>
          }
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          {/* Campo para el código del tipo de acumulado */}
          <FormItem
            name="codigo"
            label="Código"
            required
            margin
            rules={[{ required: true, message: "Por favor ingresa el código del tipo de acumulado" }]}
          >
            <InputItem placeholder="Ingresa el código" />
          </FormItem>

          {/* Campo para el nombre del tipo de acumulado */}
          <FormItem
            name="nombre"
            label="Nombre"
            required
            margin
            rules={[{ required: true, message: "Por favor ingresa el nombre del tipo de acumulado" }]}
          >
            <InputItem placeholder="Ingresa el nombre" />
          </FormItem>

          {/* Campo para el tipo de acumulado */}
          <FormItem
            name="tipoAcumulado"
            label="Tipo de Acumulado"
            required
            margin
            rules={[{ required: true, message: "Por favor selecciona el tipo de acumulado" }]}
          >
            <SelectItem placeholder="Selecciona el tipo de acumulado">
              <Option value="0">Días y horas</Option>
              <Option value="1">Percepción, deducción y obligación</Option>
            </SelectItem>
          </FormItem>

        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => ({
  headersToken: state.user.headersToken,
  empresaId: state.empresa.id,
});

export default connect(mapStateToProps)(EditarTipoAcumulado);
