import { message, Select } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  ButtonItem,
  InputItem,
  ErrorItem,
  SelectItem,
} from "../../../../Components/Items";

const { Option } = Select 
class AgregarDepartamento extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
    visible: this.props.visible,
    todasProyectos: null,
    objetoProyectos: null,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
        this.consultarProyecto();
        this.setState({ visible: this.props.visible });
    }
  }

  onFinishFailed(e) {
    ErrorItem(e);
  };

  consultarProyecto() {
    this.setState({ loading: true });
    axios
      .post(
        "departamentos/selectDatosParaDepartamento",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
          message.error("No se encontraron departamentos");
        } else {
          this.setState({
            todosDepartamentos: res.data.proyectos,
            objetoProyectos: res.data.proyectos.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre}
                  </Option>
                );
              }),
          })
          ;
        }
      });
  }

  onFinish(values) {
    if (values?.nombre) {
      this.setState({ loading: true });
      axios
        .post(
          "departamentos/agregarDepartamento",
          {
            ...values,
            empresaId: this.props.empresaId,
          },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.success === 2) {
            message.success("Departamento agregado exitosamente");
            this.cerrarModal();
          } else if (res.data.success === 1) {
            message.error("No se pudo agregar el departamento");
          } else {
            message.error("Sucedió un error y no se pudo agregar el departamento");
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          message.error("Error al comunicarse con el servidor");
        });
    } else {
      message.info("Proporcione al menos el nombre del departamento");
    }
  }
  

  cerrarModal = () => {
    this.setState({ visible: false });
    this.props.cerrarModal();
    if (this.formRef.current) {
      this.formRef.current.resetFields();
    }
  };

  render() {
    return (
      <ModalItem
        title="Agregar Departamento"
        visible={this.state.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarModal}
        width={800} 
      >
        <Form
          ref={this.formRef}
          name="modalAgregarDepartamento"
          layout
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
              loading={this.state.loading}
            >
              Guardar
            </ButtonItem>
          }
          
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          {/* Campo para el código del departamento */}
          <FormItem
            name="codigo"
            label="Número de Departamento"
            required
            margin
            rules={[{ required: true, message: "Por favor ingresa el número del departamento" }]}
          >
            <InputItem placeholder="Ingresa el número de departamento" />
          </FormItem>

          {/* Campo para el nombre del departamento */}
          <FormItem
            name="nombre"
            label="Nombre del Departamento"
            required
            margin
            rules={[{ required: true, message: "Por favor ingresa el nombre del departamento" }]}
          >
            <InputItem placeholder="Ingresa el nombre del departamento" />
          </FormItem>

          {/* Campo opcional para el proyecto asociado */}
          <FormItem
            name="proyectoId"
            label="Nombre del Proyecto (Opcional)"
            margin
          >
            <SelectItem placeholder="Ingresa el nombre del proyecto (opcional)" >
                {this.state.objetoProyectos}
                </SelectItem>
          </FormItem>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => ({
  headersToken: state.user.headersToken,
  empresaId: state.empresa.id,
});

export default connect(mapStateToProps)(AgregarDepartamento);
