import React, { Component } from "react";
import { Form } from "antd";

class FormItem extends Component {
  render() {
    let rules = [];
    if (this.props.pattern) {
      rules.push({
        pattern: /^[A-Za-z0-9\n. ,"'´`#°ºªáÁéÉíÍóÓúÚüÜñÑ@_/*+%&()!-–:;]*$/,
        message: "No se admiten caracteres especiales",
      });
    } else if (this.props.rfc) {
      rules.push({
        pattern:
          /^(([ÑA-Z|ña-z|&]{3}|[A-Z|a-z]{4})\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)(\w{2})([A|a|0-9]{1}))$|^(([ÑA-Z|ña-z|&]{3}|[A-Z|a-z]{4})([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9]{1})$/,
        message: "No es un rfc valido",
      });
    } else if (this.props.number) {
      rules.push({
        pattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
        message: "Solo se admiten numeros positivos",
      });
    } else if (this.props.numberNegativo) {
      rules.push({
        pattern: /^[0-9.-]*$/,
        message: "Solo se admiten numeros",
      });
    } else if (this.props.numberCero) {
      rules.push({
        pattern: /^[0-9.]*$/,
        message: "No se admiten numeros negativos.",
      });
    } else if (this.props.curp) {
      rules.push({
        pattern:
          /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        message: "No es un curp valido",
      });
    } else if (this.props.registroPatronal) {
      rules.push({
        pattern:
          /^[A-Z]{3}-\d{6}-\d{2}-\d{1}$/,
        message: "No es un Registro Patronal valido",
      });
    } 
    if (this.props.required) {
      let fieldName = '';
    
      // Función para capitalizar la primera letra de la cadena
      const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };
    
      // Función para limpiar nombres dinámicos, eliminar puntos y capitalizar la primera letra
      const cleanFieldName = (field) => {
        // Eliminar los índices y los puntos iniciales, luego capitalizar
        const cleanedField = field.replace(/\.\d+\./g, ' ').replace(/\d+/g, '').trim();
        return cleanedField.startsWith('.') ? cleanedField.slice(1) : cleanedField;
      };
    
      // Verificar si label o name existe
      if (this.props.label) {
        fieldName = this.props.label;
      } else if (this.props.name) {
        // Si el name es un array (caso dinámico), unirlo en un solo string
        if (Array.isArray(this.props.name)) {
          fieldName = this.props.name.join('.');
        } else {
          fieldName = this.props.name;
        }
    
        // Limpiar los índices y eliminar los puntos iniciales
        fieldName = cleanFieldName(fieldName);
    
        // Verificar si el name contiene "id" y eliminar la última aparición
        if (fieldName.toLowerCase().endsWith('id')) {
          fieldName = fieldName.replace(/id$/i, '').trim(); // Eliminar solo la última "id"
        } else {
          // Verificar si el name tiene una letra mayúscula en el medio
          fieldName = fieldName.replace(/([a-z])([A-Z])/g, '$1 $2'); // Agregar espacio antes de la mayúscula
        }
      } else {
        // Valor por defecto si no hay ni name ni label
        fieldName = 'Campo';
      }
    
      // Capitalizar la primera letra del nombre del campo
      fieldName = capitalizeFirstLetter(fieldName);
    
      rules.push({
        required: true,
        message: `Se requiere este campo: ${fieldName}`,
      });
    }
    
    
    
    if (this.props.max || this.props.max === 0) {
      rules.push({
        max: this.props.max,
        type: this.props.type,
        message: this.props.type === 'number' ? `El maximo es ${this.props.max}`: `Solo admite hasta ${this.props.max} caracteres`,
      });
    }
    if(this.props.gt || this.props.gt === 0){
      const gt = this.props.gt
      rules.push({
          validator(_, value) {
            if ( value > gt ) {
              return Promise.resolve();
            }else{
              return Promise.reject(new Error(`Tiene que ser mayor a ${gt}`));
            }
          },
          message: `Tiene que ser mayor a ${gt}`
      })
    }
    if (this.props.min || this.props.min === 0) {
      rules.push({
        min: this.props.min,
        type: this.props.type,
        message: this.props.type === 'number' ? `El minimo es ${this.props.min}`: `No se admite menos de ${this.props.min} caracteres`,
      });
    }
    if (this.props.email) {
      rules.push({
        type: "email",
        message: "No es un correo valido",
      });
    }
    let layout
    if(this.props.specialLayout){
      layout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 },
      };
    }
    if(this.props.specialLayoutSwitch){
      layout = {
        labelCol: { span: 16 },
        wrapperCol: { span: 8 },
      };
    }
    if(this.props.specialTableLayout){
      layout = {
        labelCol: { span: 0 },
        wrapperCol: { span: 22 },
      };
    }
    let transformedProperties = {};
    for (var key in this.props) {
      if (
        !(
          key.toString() === "children" ||
          key.toString() === "pattern" ||
          key.toString() === "required" ||
          key.toString() === "max" ||
          key.toString() === "min" ||
          key.toString() === "email" ||
          key.toString() === "margin" ||
          key.toString() === "rfc" ||
          key.toString() === "curp" ||
          key.toString() === "specialLayout" ||
          key.toString() === "type" ||
          key.toString() === "numberCero" ||
          key.toString() === "numberNegativo" ||
          key.toString() === "number" ||
          key.toString() === "registroPatronal"
        )
      ) {
        transformedProperties = {
          ...transformedProperties,
          [key]: this.props[key],
        };
      }
    }
    const style = {
      borderWidth: 0,
      border: 0,
      marginBottom: this.props.margin ? "3px" : this.props.marginBottom,
      ...this.props.style,
    };
    return (
      <Form.Item
        {...transformedProperties}
        rules={rules}
        style={style}
        {...layout}
      >
        {this.props.children}
      </Form.Item>
    );
  }
}

export default FormItem;
