import { Empty, PageHeader, Popconfirm, Space, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem } from "../../../Components/Items";
import moment from "moment";
import "moment/locale/es";
import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import AgregarTipoPeriodo from "./AgregarTipoPeriodo/AgregarTipoPeriodo";
import EditarTipoPeriodo from "./EditarTipoPeriodo/EditarTipoPeriodo";

moment.locale("es");

class TiposPeriodo extends Component {
  state = {
    modalAgregar: false,
    modalEditar: false,
    todosTiposPeriodos: [],
    loading: false,
    tipoPeriodoSeleccionado: null,
    periocidadesSat: [],
  };

  // Abrir modal para agregar
  abrirAgregarTipoPeriodo = () => {
    this.setState({ modalAgregar: true, tipoPeriodoSeleccionado: null });
  };

  // Cerrar modal de agregar
  cerrarAgregarTipoPeriodo = () => {
    this.setState({ modalAgregar: false });
    this.consultarTodosTiposPeriodos();
  };

  // Abrir modal para editar
  abrirEditarTipoPeriodo = (tipoPeriodo) => {
    this.setState({ modalEditar: true, tipoPeriodoSeleccionado: tipoPeriodo });
  };

  // Cerrar modal de editar
  cerrarEditarTipoPeriodo = () => {
    this.setState({ modalEditar: false });
    this.consultarTodosTiposPeriodos();
  };

  componentDidMount() {
    this.consultarTodosTiposPeriodos();
  }

  // Consultar todos los tipos de periodos
  consultarTodosTiposPeriodos() {
    this.setState({ loading: true });
    axios
      .post(
        "tiposPeriodos/selectTodosTiposPeriodos",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
          message.error("No se encontraron tipos de periodos");
        } else {
          this.setState({
            todosTiposPeriodos: res.data.tiposPeriodos.map((periodo) => ({
              ...periodo,
              key: periodo._id,  
            })),
          });
        }
      });
  }

  // Agregar un nuevo tipo de periodo
  agregarTipoPeriodo = (datosTipoPeriodo) => {
    axios
      .post(
        "tiposPeriodos/agregarTipoPeriodo",
        {
          ...datosTipoPeriodo,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === true) {
          message.success("Tipo de periodo agregado exitosamente");
          this.consultarTodosTiposPeriodos();
          this.cerrarAgregarTipoPeriodo();
        } else {
          message.error("Error al agregar el tipo de periodo");
        }
      });
  };

  // Editar un tipo de periodo existente
  editarTipoPeriodo = (datosTipoPeriodo) => {
    axios
      .post(
        "tiposPeriodos/editarTipoPeriodo",
        {
          ...datosTipoPeriodo,
          empresaId: this.props.empresaId,
          tipoPeriodoId: this.state.tipoPeriodoSeleccionado._id,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === true) {
          message.success("Tipo de periodo actualizado exitosamente");
          this.consultarTodosTiposPeriodos();
          this.cerrarEditarTipoPeriodo();
        } else {
          message.error("Error al actualizar el tipo de periodo");
        }
      });
  };

  // Deshabilitar un tipo de periodo
  deshabilitarTipoPeriodo(id) {
    axios
      .post(
        "tiposPeriodos/deshabilitarTipoPeriodo",
        { tipoPeriodoId: id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Tipo de periodo deshabilitado exitosamente");
          this.consultarTodosTiposPeriodos();
        } else {
          message.error("Error al deshabilitar el tipo de periodo");
        }
      });
  }

  // Habilitar un tipo de periodo
  habilitarTipoPeriodo(id) {
    axios
      .post(
        "tiposPeriodos/habilitarTipoPeriodo",
        { tipoPeriodoId: id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Tipo de periodo habilitado exitosamente");
          this.consultarTodosTiposPeriodos();
        } else {
          message.error("Error al habilitar el tipo de periodo");
        }
      });
  }

  render() {
    const columns = [
      {
        title: "Nombre del Periodo",
        dataIndex: "nombre",
        key: "nombre",
      },
      {
        title: "Días del Periodo",
        dataIndex: "diasPeriodo",
        key: "diasPeriodo",
      },
      {
        title: "Días de Pago",
        dataIndex: "diasDePago",
        key: "diasDePago",
      },
      {
        title: "Ajustar Mes",
        dataIndex: "ajustarMes",
        key: "ajustarMes",
        render: (ajustarMes) => (ajustarMes === 0 ? "Días laborados" : "Días no laborados"),
      },
      {
        title: "Día de Pago",
        dataIndex: "diaDePago",
        key: "diaDePago",
      },
      {
        title: "Acción",
        key: "accion",
        render: (record) => (
          <Space size="middle">
            <EditOutlined
              onClick={() => this.abrirEditarTipoPeriodo(record)}
              style={{ color: "blue" }}
            />
            <>
              {record.estado === 1 ? (
                <Popconfirm
                  title="¿Seguro que quieres deshabilitar este tipo de periodo?"
                  onConfirm={() => this.deshabilitarTipoPeriodo(record._id)}
                  okText="Sí"
                  cancelText="No"
                >
                  <CheckCircleOutlined style={{ color: "green" }} />
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="¿Seguro que quieres habilitar este tipo de periodo?"
                  onConfirm={() => this.habilitarTipoPeriodo(record._id)}
                  okText="Sí"
                  cancelText="No"
                >
                  <StopOutlined style={{ color: "red" }} />
                </Popconfirm>
              )}
            </>
          </Space>
        ),
      },
    ];

    return (
      <>
        <PageHeader
          title="Tipos de Periodo"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <ButtonItem
              type="primary"
              key="agregarTipoPeriodo"
              onClick={this.abrirAgregarTipoPeriodo}
            >
              Agregar Tipo de Periodo
            </ButtonItem>
          }
        />
        <TableItem
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <p>No hay registros de tipos de periodo. Aquí puedes gestionar y llevar un control de los periodos de pago.</p>
                  </>
                }
              />
            ),
          }}
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todosTiposPeriodos}
          columns={columns}
          pagination={false}
        />
        <AgregarTipoPeriodo
          visible={this.state.modalAgregar}
          cerrarModal={this.cerrarAgregarTipoPeriodo}
          periocidadesSat={this.state.periocidadesSat}
          onGuardar={(datosTipoPeriodo) =>
            this.state.tipoPeriodoSeleccionado
              ? this.editarTipoPeriodo(datosTipoPeriodo)
              : this.agregarTipoPeriodo(datosTipoPeriodo)
          }
        />
        <EditarTipoPeriodo
          visible={this.state.modalEditar}
          cerrarModal={this.cerrarEditarTipoPeriodo}
          tipoPeriodo={this.state.tipoPeriodoSeleccionado}
          periocidadesSat={this.state.periocidadesSat}
          onGuardar={(datosTipoPeriodo) =>
            this.editarTipoPeriodo(datosTipoPeriodo)
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(TiposPeriodo);
