import { message, PageHeader, Spin, Upload, Row, Col } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from 'axios'
import {
    Form,
    FormItem,
    TableItem,
    ButtonItem,
    ErrorItem
} from "../../../../Components/Items";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from 'xlsx'
import plantilla from "../../../../assets/plantillaClientes.xlsx";

class ImportarClientes extends Component {
    formRef = React.createRef();
  state = {
    loading: false,
    archivosExcel: [],
    data: [],
    archivoError: null,
  }
  atras() {
    this.props.history.goBack();
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }

  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "clientes/importarClientes",
        { ...values, empresaId: this.props.empresaId, data: this.state.data },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se importaron "+res.data.numero+" clientes");
          if(res.data.repetidos){
              message.warning("No se importaron "+res.data.repetidos+" clientes ya que estaban repetidos");
          }
          this.atras();
        } else if (res.data.success === 1) {
          message.error("No se pueden importar los clientes");
        } else {
          message.error("Hubo un error y los clientes no se importaron");
        }
      });
  }
  async onChangeArchivo(e) {
      this.setState({loading: true})
    if(e.file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
        this.setState({ archivosExcel: [{...e.file, name: e.file.name, status: 'uploading', response: 'Cargando',}], arhcivo: e.file });
        this.cargarArchivoExcel(e.file)
    }else{
        message.error('El archivo debe ser un archivo de excel')
        this.setState({loading: false})
    }
}

descargarArchivoErrores(){
    let wbout = XLSX.write(this.state.archivoError, {bookType:'xlsx',  type: 'binary'});
    const s2ab = (s) => { 
        let buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        let view = new Uint8Array(buf);  //create uint8array as viewer
        for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;    
    }
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    const blob = new Blob([s2ab(wbout)],{type:"application/octet-stream"})
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'errores.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
}
    cargarArchivoExcel(file) {
            let hojas = [];
            let reader = new FileReader()
            reader.readAsArrayBuffer(file)
            reader.onloadend = (e) => {
                let data = new Uint8Array(e.target.result)
                let workbook = XLSX.read(data, { type: 'array' })

                workbook.SheetNames.forEach((sheetName) => {
                    let Xl_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName])
                    hojas.push({
                        data: Xl_row_object,
                        sheetName
                    })
                })
                let hoja1 = hojas[0].data
                this.archivoVerificar(hoja1).then((dataReturn) => {
                    if (dataReturn.errores === 0) {
                        this.setState({ archivosExcel: [{...file, name: file.name, status: 'success'}], data: dataReturn.objeto });
                    } else {
                        this.setState({ archivosExcel: [{...file, name: file.name, status: 'success', response: 'Con errores',}], archivoError: dataReturn.archivoErrores, data: dataReturn.objeto });
                        message.error('Se han detectado '+dataReturn.errores+' errores')
                        message.info('Se se importan solo se importaran los campos correctos: '+Number(hoja1.length-dataReturn.filaErrores))
                    }
                    this.setState({loading: false})
                })
            }
    }

    async archivoVerificar(data) {
        let errores = 0
        let filaErrores = 0
        let mensaje = []
        const archivoEditar = XLSX.utils.book_new()
        const arrayErrores = []
        let objeto = []
        await data.map((data, index) => {
            let mensajeErrores = null
            let erroresFila = 0
            let fila = data['__rowNum__'] + 1
            let codigo = data['Código'] ? `${data['Código']}`.trim() : undefined
            let rfc = data['RFC'] ? `${data['RFC']}`.replace('-', '').toUpperCase() : undefined
            let persona = rfc.length === 12 && rfc !== 'XAXX010101000'
            let razonSocial = persona ? data['Razón Social'] || data['Nombre Persona física'] ? data['Razón Social'] || data['Nombre Persona física'] : undefined : undefined
            let nombre = !persona ? data['Razón Social'] || data['Nombre Persona física'] ? data['Nombre Persona física'] || data['Razón Social'] : undefined : undefined
            let apellidoPaterno = data['Apellido Paterno'] ? data['Apellido Paterno'] : undefined
            let apellidoMaterno = data['Apellido Materno'] ? data['Apellido Materno'] : undefined
            let curp = data['CURP'] ? `${data['CURP']}`.toUpperCase() : undefined
            let telefono = data['Teléfono Empresa'] ? Number(data['Teléfono Empresa'].toString().replace(/\$\s?|(,*)/g, '')): undefined
            let correo = data['Correo Empresa'] ? `${data['Correo Empresa']}`.trim() : undefined
            let usoDeCFDI = data['Uso de CFDI'] ? `${data['Uso de CFDI']}`.trim() : undefined
            let regimenFiscal = data['Regimen Fiscal'] ? `${data['Regimen Fiscal']}`.trim() : undefined
            let diasCredito = data['Dias de Crédito'] ? Number(data['Dias de Crédito'].toString().replace(/\$\s?|(,*)/g, '')): undefined
            let limiteCredito = data['Limite de Crédito'] ? Number(data['Limite de Crédito'].toString().replace(/\$\s?|(,*)/g, '')): undefined
            let limiteDescuento = data['Descuento Max'] ? Number(data['Descuento Max'].toString().replace(/\$\s?|(,*)/g, '')): undefined
            let nombreContacto = data['Nombre contacto'] ? data['Nombre contacto'] : undefined
            let telefonoContacto = data['Teléfono Contacto'] ? Number(data['Teléfono Contacto'].toString().replace(/\$\s?|(,*)/g, '')): undefined
            let correoContacto = data['Correo Contacto'] ? `${data['Correo Contacto']}`.trim() : undefined
            let observaciones = data['Observaciones'] ? data['Observaciones'] : undefined
            let paginaWeb = data['Página web'] ? `${data['Página web']}`.trim() : undefined
            let cp = data['Código Postal'] ? data['Código Postal'] : undefined
            let colonia = data['Colonia'] ? data['Colonia'] : undefined
            let calle = data['Calle'] ? data['Calle'] : undefined
            let numeroCalle = data['Número'] ? Number(data['Número'].toString().replace(/\$\s?|(,*)/g, '')): undefined
            let numeroInterior = data['Número Int'] ? data['Número Int'].toString().replace(/\$\s?|(,*)/g, ''): undefined
            const checarQueSeaNumero = (valor, nombreCampo) => {
                if(valor !== '' && valor !== undefined){
                    if (isNaN(valor) === true) {
                        errores += 1
                        erroresFila += 1
                        mensaje.push( `${nombreCampo} fila: ${fila} no es un numero`)
                        mensajeErrores = mensajeErrores ? mensajeErrores+`, ${nombreCampo} no es un numero` : `${nombreCampo} no es un numero`
                    }
                }
            }
            const queEste = (valor, nombreCampo) => {
                if (valor === '' || valor === undefined) {
                    errores += 1
                    erroresFila += 1
                    mensaje.push( `${nombreCampo} fila: ${fila} esta vacio`)
                    mensajeErrores = mensajeErrores ? mensajeErrores+`, ${nombreCampo} esta vacio` : `${nombreCampo} esta vacio`
                }
            }
            const queSeaStringValido = (valor, nombreCampo) => {
                const patron = /^[A-Za-z0-9\n. ,"'´`#°ºªáÁéÉíÍóÓúÚüÜñÑ@_/*+%&()!-]*$/
                if(valor !== '' && valor !== undefined){
                    if (!patron.test(valor)) {
                        errores += 1
                        erroresFila += 1
                        mensaje.push( `${nombreCampo} fila: ${fila} no es un valor acceptado`)
                        mensajeErrores = mensajeErrores ? mensajeErrores+`, ${nombreCampo} no es un valor acceptado` : `${nombreCampo} no es un valor acceptado`
                    }
                }
            }
            const queSeaRFC = (valor, nombreCampo) => {
                const patron = /^(([ÑA-Z|ña-z|&]{3}|[A-Z|a-z]{4})\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)(\w{2})([A|a|0-9]{1}))$|^(([ÑA-Z|ña-z|&]{3}|[A-Z|a-z]{4})([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9]{1})$/
                if(valor !== '' && valor !== undefined){
                    if (!patron.test(valor)) {
                        errores += 1
                        erroresFila += 1
                        mensaje.push( `${nombreCampo} fila: ${fila} no es un rfc`)
                        mensajeErrores = mensajeErrores ? mensajeErrores+`, ${nombreCampo} no es un rfc` : `${nombreCampo} no es un rfc`
                    }
                }
            }
            if(razonSocial){
                queEste(razonSocial, 'Razón Social')
                queSeaStringValido(razonSocial, 'Razón Social')
            }else{
                queEste(nombre, 'Nombre Persona física')
                queSeaStringValido(nombre, 'Nombre Persona física')
                queSeaStringValido(apellidoPaterno, 'Apellido Paterno')
                queSeaStringValido(apellidoMaterno, 'Apellido Materno')
            }
            queEste(codigo, 'Código', 'A')
            queSeaStringValido(codigo, 'Código', 'A')
            queSeaStringValido(curp, 'CURP')
            queEste(rfc, 'RFC')
            queSeaRFC(rfc, 'RFC')
            checarQueSeaNumero(telefono, 'Teléfono Empresa')
            queSeaStringValido(correo, 'Correo Empresa')
            queSeaStringValido(usoDeCFDI, 'Uso de CFDI')
            queSeaStringValido(regimenFiscal, 'Regimen Fiscal')
            checarQueSeaNumero(diasCredito, 'Dias de Crédito')
            checarQueSeaNumero(limiteCredito, 'Limite de Crédito')
            checarQueSeaNumero(limiteDescuento, 'Descuento Max')
            queSeaStringValido(nombreContacto, 'Nombre Contacto')
            checarQueSeaNumero(telefonoContacto, 'Teléfono Contacto')
            queSeaStringValido(correoContacto, 'Correo Contacto')
            queSeaStringValido(observaciones, 'Observaciones')
            queSeaStringValido(paginaWeb, 'Página web')
            checarQueSeaNumero(cp ? Number(cp) : undefined, 'Código Postal')
            queSeaStringValido(colonia, 'Colonia')
            queSeaStringValido(calle, 'Calle')
            checarQueSeaNumero(numeroCalle, 'Número')
            queSeaStringValido(numeroInterior, 'Número Interior')
            if(index < 10){
                console.log(persona,  rfc,rfc !== 'XAXX010101000')
                console.log(rfc.length, persona)
            }
            if(erroresFila === 0){
                objeto.push({
                    codigo,
                    razonSocial,
                    persona,
                    nombre,
                    apellidoPaterno,
                    apellidoMaterno,
                    curp,
                    rfc,
                    telefono,
                    correo,
                    usoDeCFDI,
                    regimenFiscal,
                    diasCredito,
                    limiteCredito,
                    limiteDescuento,
                    nombreContacto,
                    telefonoContacto,
                    correoContacto,
                    observaciones,
                    paginaWeb,
                    cp,
                    colonia,
                    calle,
                    numeroCalle,
                    numeroInterior,
                })
            }else{
                arrayErrores.push({
                    'Errores': mensajeErrores,
                    'Fila archivo inicial': fila,
                    ...data,
                })
                filaErrores += 1
            }
            return null
        })
        const hoja = XLSX.utils.json_to_sheet(arrayErrores)
        XLSX.utils.book_append_sheet(archivoEditar, hoja, 'Hoja Errores')
        console.log(archivoEditar.Sheets['Hoja Errores'])
        return { objeto: objeto, errores: errores, filaErrores, mensaje: mensaje, archivoErrores: archivoEditar }
    }
    render() {
        const columns = [
        {
          title: "Codigo",
          dataIndex: "codigo",
          key: "codigo",
          sorter: (a, b) => {
            if (a.codigo < b.codigo) {
              return -1;
            }
            if (a.codigo > b.codigo) {
              return 1;
            }
            return 0;
          },
          buscar: "codigo",
        },
        {
          title: "Nombre",
          dataIndex: "nombre",
          key: "nombre",
          sorter: (a, b) => {
            if (a.nombre < b.nombre) {
              return -1;
            }
            if (a.nombre > b.nombre) {
              return 1;
            }
            return 0;
          },
          buscar: "nombre",
        },
        {
            title: "Razon Social",
            dataIndex: "razonSocial",
            key: "razonSocial",
            sorter: (a, b) => {
              if (a.razonSocial < b.razonSocial) {
                return -1;
              }
              if (a.razonSocial > b.razonSocial) {
                return 1;
              }
              return 0;
            },
            buscar: "razonSocial",
          },
        {
          title: "RFC",
          dataIndex: "rfc",
          key: "rfc",
          sorter: (a, b) => {
            if (a.rfc < b.rfc) {
              return -1;
            }
            if (a.rfc > b.rfc) {
              return 1;
            }
            return 0;
          },
          buscar: "rfc",
        },
      ];
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Importar Clientes"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={this.state.archivoError ? <ButtonItem onClick={this.descargarArchivoErrores.bind(this)}>Descargar Errores</ButtonItem> : null}
        />
                <div style={{ backgroundColor: "white", padding: 20, paddingTop: 5 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="importarClientes"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Importar
                </ButtonItem>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
                <Row style={{marginTop: 20}}>
                    <Col span={12} style={{display: 'flex', justifyContent: 'center'}}>
                        <ButtonItem  href={plantilla} type="primary">Descargar Plantilla</ButtonItem>
                    </Col>
                    <Col span={12}>
                        <FormItem name="archivo" label="Archivo">
                            <Upload
                            showUploadList={{
                                showRemoveIcon: false,
                                showPreviewIcon: false,
                            }}
                            fileList={this.state.archivosExcel}
                            customRequest={this.onChangeArchivo.bind(this)}
                            accept='.xlsx'
                            >
                            <ButtonItem icon={<UploadOutlined />}>Subir</ButtonItem>
                            </Upload>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <TableItem size='small' dataSource={this.state.data} columns={columns} />
            </Spin>
            </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(ImportarClientes);
