import moment from "moment";
import "moment/locale/es";
import { dineroDisabledMask } from "../Components/Items";
import numeroALetra from "../Components/numeroALetra";

moment.locale("es");

const cobroPdf = (values) => {
  const empresa = values.respuestaComplemento.empresa;
  const dataSat = values.respuestaComplemento.dataSat;
  let dd
  const pagos = dataSat.Complemento.Any[0]["Pago20:Pagos"].Pago[0].DoctoRelacionado.map((valuePago) => {

    let folio = ''

    if(valuePago.folioVenta){
      folio = valuePago.folioVenta
    }

    if(moment(dataSat.Fecha) > moment('2024-12-04T10:09:20')){
      folio = valuePago.Serie + ' - ' + valuePago.Folio
    }

        return [
          { text: folio, style: "textoTabla", alignment: "center" },
          { text: valuePago.MonedaDR, style: "textoTabla", alignment: "center" },
          { text: valuePago.NumParcialidad, style: "textoTabla", alignment: "center" },
          { text: dineroDisabledMask(valuePago.ImpSaldoAnt), style: "textoTabla", alignment: "right" },
          { text: dineroDisabledMask(valuePago.ImpPagado), style: "textoTabla", alignment: "right" },
          { text: dineroDisabledMask(valuePago.ImpSaldoInsoluto), style: "textoTabla", alignment: "right" },
        ]
    })
    let observaciones = {}
    if(values.imprimirObservaciones && values.observaciones){
      observaciones = {
        table: {
          body: [
            [
              {
                border: [true, true, true, false],
                text: 'Observaciones:',
                margin: [0,0,0,5],
                style: 'header',
              },
            ],
            [
              {
                border: [true, false, true, true],
                text: values.observaciones,
                margin: [0,0,0,5],
                style: 'textoChico'
              }
            ]
  
          ],
          layout: {
            defaultBorder: false,
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 1;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 1;
            },
            hLineColor: function (i, node) {
              return "#adaaaa";
            },
            vLineColor: function (i, node) {
              return "#adaaaa";
            },
            hLineStyle: function (i, node) {
              if (i === 0 || i === node.table.body.length) {
                return null;
              }
              return { dash: { length: 2, space: 1 } };
            },
            vLineStyle: function (i, node) {
              if (i === 0 || i === node.table.widths.length) {
                return null;
              }
              return { dash: { length: 2 } };
            },
          },
        }
      }
    }
    dd = {
      content: [
        {
          style: "tableExample",
          table: {
            widths: ["18%", "52%", "30%"],
            layout: { defaultBorder: false },
            body: [
              [
                {
                  border: [false, false, false, false],
                  image: "logo",
                  fit: [80, 80] 
                },
                {
                  border: [false, false, false, false],
                  table: {
                    headerRows: 1,
                    widths: ["100%"],
                    body: [
                      [{ text: empresa.persona
                          ? empresa.nombre
                          : empresa.nombre +
                            " " +
                            empresa.apellidoPaterno +
                            " " +
                            empresa.apellidoMaterno, style: "tableHeader" }],
                      [
                        {
                          text: "R.F.C: "+ empresa.rfc,
                          style: "textoChico",
                          alignment: "center",
                        },
                      ],
                      [
                        {
                          text: empresa.calle +
                          " " +
                          empresa.numeroCalle +
                          " " +
                          (empresa.numeroInterior
                            ? empresa.numeroInterior
                            : ""),
                          style: "textoChico",
                          alignment: "center",
                        },
                      ],
                      [
                        {
                          text: empresa.colonia.tipoAsentamiento +
                          " " +
                          empresa.colonia.nombre +
                          " C.P. " +
                          empresa.colonia.cp +
                          " " +
                          empresa.municipio.nombre +
                          " " +
                          empresa.estado.abreviatura +
                          " " +
                          empresa.pais.abreviatura +
                          " ",
                          style: "textoChico",
                          alignment: "center",
                        },
                      ],
                      [
                        {
                          text: "Regimen Fiscal: (" +
                          empresa.regimenFiscal.c_RegimenFiscal +
                          ") " +
                          empresa.regimenFiscal.descripcion,
                          style: "textoChico",
                          alignment: "center",
                        },
                      ],
                      [
                        {
                          text: "Tipo De Comprobante: (P) Pago",
                          style: "textoChico",
                          alignment: "center",
                        },
                      ],
                    ],
                  },
                  layout: "noBorders",
                },
                {
                  border: [false, false, false, false],
                  table: {
                    headerRows: 1,
                    widths: ["100%"],
                    body: [
                      [
                        {
                          text: "N° de Pago:",
                          style: "subheader",
                          alignment: "center",
                        },
                      ],
                      [{ text: dataSat.Serie + ' - ' + dataSat.Folio, style: "textoChico", alignment: "center" }],
                    ],
                  },
                  layout: "noBorders",
                },
              ],
            ],
          },
        },
        {
          style: "tableExample",
          table: {
            widths: ["60%", "40%"],
            layout: { defaultBorder: false },
            body: [
              [
                {
                  table: {
                    widths: ["30%", "70%"],
                    layout: { defaultBorder: false },
                    body: [
                      [
                          { text: "Nombre del cliente :", style: "subheader" },
                          { text: dataSat.Receptor.Nombre, style: "textoChico" },
                        ],
                        [
                          { text: "R.F.C :", style: "subheader" },
                          { text: dataSat.Receptor.Rfc, style: "textoChico" },
                        ],
                        // [
                        //   { text: "Direccion :", style: "subheader" },
                        //   { text: "Variable", style: "textoChico" },
                        // ],
                        
                    ],
                  },
                  layout: "noBorders",
                },
                {
                  table: {
                    widths: ["40%", "60%"],
                    layout: { defaultBorder: false },
                    body: [
                      [
                        { text: "Fecha:", style: "subheader" },
                        { text: moment(dataSat.Fecha).locale("es").format("LL"), style: "textoChico" },
                      ],
                      [
                        { text: "Lugar de expedicion:", style: "subheader" },
                        { text: dataSat.LugarExpedicion, style: "textoChico" },
                      ],
                    ],
                  },
                  layout: "noBorders",
                },
              ],
            ],
          },
          layout: {
              fillColor: (rowIndex, node, columnIndex) => {
                return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
              },
              hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 1 : 1;
              },
              vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 1 : 1;
              },
              hLineColor: function (i, node) {
                return "#adaaaa";
              },
              vLineColor: function (i, node) {
                return "#adaaaa";
              },
              hLineStyle: function (i, node) {
                if (i === 0 || i === node.table.body.length) {
                  return null;
                }
                return { dash: { length: 2, space: 1 } };
              },
              vLineStyle: function (i, node) {
                if (i === 0 || i === node.table.widths.length) {
                  return null;
                }
                return { dash: { length: 2 } };
              },
            },
        },
        
        {
          text: "Pagos",
          style: "tableHeader",
        },
        {
          style: "tableExample",
          table: {
            widths: [ "12%", "14%", "14%", "20%", "20%", "20%"],
            layout: { defaultBorder: true },
            body: [
              [
                { text: "Folio", style: "tituloTabla" },
                { text: "Moneda DR", style: "tituloTabla" },
                { text: "Parcialidad", style: "tituloTabla" },
                { text: "Importe saldo anterior", style: "tituloTabla" },
                { text: "Importe pagado", style: "tituloTabla" },
                { text: "Importe saldo insoluto", style: "tituloTabla" },
              ],
              ...pagos
            ],
          },
          layout: {
              fillColor: (rowIndex, node, columnIndex) => {
                return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
              },
              hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 1 : 1;
              },
              vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 1 : 1;
              },
              hLineColor: function (i, node) {
                return "#adaaaa";
              },
              vLineColor: function (i, node) {
                return "#adaaaa";
              },
              hLineStyle: function (i, node) {
                if (i === 0 || i === node.table.body.length) {
                  return null;
                }
                return { dash: { length: 2, space: 1 } };
              },
              vLineStyle: function (i, node) {
                if (i === 0 || i === node.table.widths.length) {
                  return null;
                }
                return { dash: { length: 2 } };
              },
            },
        },
        {
          text: "Forma De Pago",
          style: "tableHeader",
        },
        {
          style: "tableExample",
          table: {
            widths: ["20%", "18%", "14%", "18%", "30%"],
            layout: { defaultBorder: true },
            body: [
              [
                { text: "Fecha de Pago", style: "tituloTabla" },
                { text: "Forma de pago", style: "tituloTabla" },
                { text: "Moneda", style: "tituloTabla" },
                { text: "Num. Operacion", style: "tituloTabla" },
                { text: "Total", style: "tituloTabla" },
              ],
              [
                { text: dataSat.Complemento.Any[0]["Pago20:Pagos"].Pago[0].FechaPago, style: "textoTabla", alignment: "center" },
                { text: dataSat.Complemento.Any[0]["Pago20:Pagos"].Pago[0].FormaDePagoP, style: "textoTabla", alignment: "center" },
                { text: dataSat.Complemento.Any[0]["Pago20:Pagos"].Pago[0].MonedaP, style: "textoTabla", alignment: "center" },
                { text: dataSat.Complemento.Any[0]["Pago20:Pagos"].Pago[0].NumOperacion, style: "textoTabla", alignment: "center" },
                { text: dineroDisabledMask(dataSat.Complemento.Any[0]["Pago20:Pagos"].Totales.MontoTotalPagos), style: "textoTabla", alignment: "right" },
              ],
            ],
          },
          layout: {
              fillColor: (rowIndex, node, columnIndex) => {
                return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
              },
              hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 1 : 1;
              },
              vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 1 : 1;
              },
              hLineColor: function (i, node) {
                return "#adaaaa";
              },
              vLineColor: function (i, node) {
                return "#adaaaa";
              },
              hLineStyle: function (i, node) {
                if (i === 0 || i === node.table.body.length) {
                  return null;
                }
                return { dash: { length: 2, space: 1 } };
              },
              vLineStyle: function (i, node) {
                if (i === 0 || i === node.table.widths.length) {
                  return null;
                }
                return { dash: { length: 2 } };
              },
            },
        },
        {
          style: "tablaSinMargen",
          table: {
            widths: ["30%", "70%"],
            layout: { defaultBorder: true },
            body: [
              [
                { text: '' },
                {
                  table: {
                    widths: ["25%", "25%", "25%", "25%"],
                    layout: { defaultBorder: false },
                    body: [
                      [
                          {
                            border: [true, true, false, false],
                            text: "Cuenta Ordenante:",
                            style: "subheader",
                          },
                          {
                            border: [false, true, false, false],
                            text: dataSat.Complemento.Any[0]["Pago20:Pagos"].Pago[0].CtaOrdenante || "",
                            style: "textoChico",
                            fontSize: 7,
                          },
                          {
                              border: [false, true, false, false],
                              text: "Rfc Ordenante:",
                              style: "subheader",
                            },
                            {
                              border: [false, true, true, false],
                              text: dataSat.Complemento.Any[0]["Pago20:Pagos"].Pago[0].RfcEmisorCtaOrd || "",
                              style: "textoChico",
                              fontSize: 7,
                            },
                        ],
                        [
                            {
                              border: [true, false, false, false],
                              text: "Banco Ordenante:",
                              style: "subheader",
                            },
                            {
                              border: [false, false, true, false],
                              text: dataSat.Complemento.Any[0]["Pago20:Pagos"].Pago[0].NomBancoOrdExt || "",
                              style: "textoChico",
                              colSpan: 3
                            },
                            {},
                            {}
                          ],
                        [
                          {
                            border: [true, false, false, true],
                            text: "Cuenta Beneficiario:",
                            style: "subheader",
                          },
                          {
                            border: [false, false, false, true],
                            text: dataSat.Complemento.Any[0]["Pago20:Pagos"].Pago[0].CtaBeneficiario || "",
                            style: "textoChico",
                          },
                          {
                              border: [false, false, false, true],
                              text: "Rfc Beneficiario:",
                              style: "subheader",
                            },
                            {
                              border: [false, false, true, true],
                              text: dataSat.Complemento.Any[0]["Pago20:Pagos"].Pago[0].RfcEmisorCtaBen || "",
                              style: "textoChico",
                            },
                        ],
                      [
                        {
                          border: [true, true, false, false],
                          text: "Cantidad con letra:",
                          style: "subheader",
                          colSpan: 2
                        },
                        {},
                        {
                          border: [false, true, true, false],
                          text: "( " + numeroALetra(dataSat.Complemento.Any[0]["Pago20:Pagos"].Totales.MontoTotalPagos, dataSat.Complemento.Any[0]["Pago20:Pagos"].Pago[0].MonedaP) + " )",
                          style: "textoChico",
                          fontSize: 7,
                          colSpan: 2
                        },
                        {}
                      ],
                      [
                          {
                            border: [true, false, false, false],
                            text: "Moneda:",
                            style: "subheader",
                            colSpan: 2
                          },
                          {},
                          {
                            border: [false, false, true, false],
                            text: dataSat.Moneda,
                            style: "textoChico",
                            colSpan: 2
                          },
                          {}
                        ],
                      [
                        {
                          border: [true, false, false, false],
                          text: "Uso de CFDI:",
                          style: "subheader",
                          colSpan: 2
                        },
                        {},
                        {
                          border: [false, false, true, false],
                          text: "(" +
                          dataSat.usoDeCFDI.c_UsoCFDI +
                          ") " +
                          dataSat.usoDeCFDI.descripcion,
                          style: "textoChico",
                          colSpan: 2
                        },
                        {}
                      ],
                      [
                          {
                            border: [true, true, false, false],
                            text: "Banco:",
                            style: "subheader",
                            colSpan: 2
                          },
                          {},
                          {
                            border: [false, true, true, false],
                            text: empresa.banco ? empresa.banco : "",
                            style: "textoChico",
                            colSpan: 2
                          },
                          {}
                        ],
                        [
                          {
                            border: [true, false, false, false],
                            text: "Cuenta:",
                            style: "subheader",
                            colSpan: 2
                          },
                          {},
                          {
                            border: [false, false, true, false],
                            text: empresa.cuentaBanco ? empresa.cuentaBanco : "",
                            style: "textoChico",
                            colSpan: 2
                          },
                          {}
                        ],
                        [
                          {
                            border: [true, false, false, true],
                            text: "Cuenta CLABE:",
                            style: "subheader",
                            colSpan: 2
                          },
                          {},
                          {
                            border: [false, false, true, true],
                            text: empresa.cuentaClabe ? empresa.cuentaClabe : "",
                            style: "textoChico",
                            colSpan: 2
                          },
                          {}
                        ],
                    ],
                  },
                  layout: {},
                },
              ],
            ],
          },
          layout: "noBorders",
        },
        observaciones,
      ],
      styles: {
        header: { fontSize: 9, bold: true },
        subheader: { fontSize: 8, bold: true },
        tableExample: { margin: [0, 5, 0, 5] },
        tablaSinMargen: { margin: [0, 0, 0, 0] },
        tableHeader: {
          bold: true,
          fontSize: 12,
          color: "black",
          alignment: "center",
        },
        textoChico: { fontSize: 8 },
        textoChicoData: { fontSize: 6 },
        tituloTabla: {
          fontSize: 8,
          bold: true,
          fillColor: "#cccccc",
          style: "subheader",
          alignment: "center",
          border: true,
        },
        textoTabla: { fontSize: 8, italics: false },
        textoTablaChico: {
          fontSize: 6,
          italics: false,
        },
      },
      images: {
          logo: empresa.logo.base64Completa,
      },
      pageSize: "LETTER",
      defaultStyle: {
        columnGap: 20,
      },
    };

  return dd;
};

export default cobroPdf;