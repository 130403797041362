import {
    message,
    PageHeader,
    Spin,
    Select,
    Form as FormInitial,
    Divider,
    Row,
    Col,
  } from "antd";
  import axios from "axios";
  import React, { Component } from "react";
  import { connect } from "react-redux";
  import {
    Form,
    FormItem,
    SelectItem,
    InputNumberItem,
    cuentaMask,
    DatePickerItem,
    InputItem,
    ButtonItem,
    SwitchItem,
    ErrorItem,
  } from "../../../../Components/Items";
  import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
  import moment from "moment";
  
  const { Option } = Select;
  
  class EditarPresupuesto extends Component {
    formRef = React.createRef();
    state = {
      loading: false,
      objetoProyectos: null,
      todasProyectos: null,
      todasCuentaContable: null,
      objetoCuentaContable: null,
      id: this.props.location.state.id,
    };
    componentDidMount() {
      this.consultarTodo();
    }
    async consultarTodo() {
      this.setState({ loading: true });
      await axios
        .post(
          "proyectos/selectProyectos",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasProyectos: res.data });
            this.setState({
              objetoProyectos: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre}
                  </Option>
                );
              }),
            });
          }
        });
      await axios
        .post(
          "cuentascontables/selectCuentasContablesFinalesIE",
          { empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasCuentaContable: res.data });
            this.setState({
              objetoCuentaContable: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {cuentaMask(
                      value.nivel1 +
                        value.nivel2 +
                        value.nivel3 +
                        value.nivel4 +
                        value.nivel5
                    ) +
                      " - " +
                      value.descripcion}
                  </Option>
                );
              }),
            });
          }
        });
        await axios
      .post(
        "presupuestos/datosPresupuesto",
        { empresaId: this.props.empresaId, presupuestoId: this.state.id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro informacion del presupuesto");
        } else {
          this.formRef.current.setFieldsValue({
            ...res.data,
            proyectoId: res.data.proyectoId ? res.data.proyectoId : 0,
            fechaInicio: moment(res.data.fechaInicio),
            fechaFinal: res.data.fechaFinal ? moment(res.data.fechaFinal) : undefined,
            cuentasAfectadas: res.data.cuentasAfectadas.map(cuentaAfectada => {
                return {
                    ...cuentaAfectada,
                    fechaInicio: moment(cuentaAfectada.fechaInicio),
                    fechaFinal: cuentaAfectada.fechaFinal ? moment(cuentaAfectada.fechaFinal) : undefined,
                }
            })
          });
        }
      });
      this.setState({ loading: false });
    }
    atras() {
      this.props.history.goBack();
    }
    onFinishFailed(e) {
      ErrorItem(e);
    }
    onFinish(values) {
      this.setState({ loading: true });
      axios
        .post(
          "presupuestos/editarPresupuesto",
          {
            ...values,
            presupuestoId: this.state.id,
            empresaId: this.props.empresaId,
          },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.success === 2) {
            message.success("Se ha editado el presupuesto");
            this.formRef.current.resetFields();
            this.atras();
          } else if (res.data.success === 1) {
            message.error("No se puede editar el presupuesto");
          } else {
            message.error("Hubo un error y el presupuesto no se edito");
          }
        });
    }
    render() {
      return (
        <>
          <PageHeader
            onBack={this.atras.bind(this)}
            title="Editar Presupuesto"
            style={{
              border: "1px solid rgb(235, 237, 240)",
              backgroundColor: "white",
              marginBottom: 10,
            }}
          />
          <div style={{ backgroundColor: "white", padding: 20 }}>
            <Spin spinning={this.state.loading}>
              <Form
                ref={this.formRef}
                name="editarPresupuesto"
                layout
                bottom={
                  <ButtonItem
                    type="primary"
                    htmlType="submit"
                    style={{ display: "block", margin: "0 0 0 auto" }}
                  >
                    Editar
                  </ButtonItem>
                }
                onFinish={this.onFinish.bind(this)}
                onFinishFailed={this.onFinishFailed}
              >
                <Row>
                  <Col span={12}>
                    <FormItem
                      label="Nombre"
                      name="nombre"
                      required
                      margin
                      pattern
                    >
                      <InputItem placeholder="Nombre" />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label="Descripción"
                      name="descripcion"
                      margin
                      pattern
                    >
                      <InputItem placeholder="Descripción" />
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <FormItem
                      label="Proyecto"
                      name="proyectoId"
                      initialValue={0}
                      margin
                      required
                    >
                      <SelectItem placeholder="Proyecto">
                        <Option value={0}>Todos</Option>
                        {this.state.objetoProyectos}
                      </SelectItem>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <FormItem
                      label="Fecha de Inicio"
                      name="fechaInicio"
                      required
                      margin
                      initialValue={moment()}
                    >
                      <DatePickerItem allowClear={false} placeholder="Fecha de Inicio" />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const fechaInicio = getFieldValue("fechaInicio");
                        return (
                          <FormItem
                            label="Fecha de Final"
                            name="fechaFinal"
                            margin
                          >
                            <DatePickerItem
                              disabledDate={(current) => {
                                // Can not select days before today and today
                                return current < moment(fechaInicio);
                              }}
                              placeholder="Fecha de Final"
                              allowClear={true}
                            />
                          </FormItem>
                        );
                      }}
                    </FormItem>
                  </Col>
                </Row>
                <Divider
                  style={{ marginBottom: 10, marginTop: 5 }}
                  children="Cuentas Presupuesto"
                />
                <FormInitial.List name="cuentasAfectadas">
                  {(fields, { add, remove }) => (
                    <>
                      <table className="table">
                        {fields.length > 0 ? (
                          <thead>
                            <tr>
                              <th className="th-border" style={{ width: "15%" }}>
                                Cuenta Contable
                              </th>
                              <th className="th-border" style={{ width: "15%" }}>
                                Fecha Inicio
                              </th>
                              <th className="th-border" style={{ width: "15%" }}>
                                Fecha Final
                              </th>
                              <th className="th-border" style={{ width: "15%" }}>
                                Concurrencia
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Mostar En Flujo
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Ingreso
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Monto
                              </th>
                              <th className="th-border" style={{ width: "15%" }}>
                                Concepto
                              </th>
                            </tr>
                          </thead>
                        ) : null}
                        {fields.map(
                          ({ key, name, fieldKey, ...restField }, arrayKey) => (
                            <tbody>
                              <tr>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "cuentaContableId"]}
                                    fieldKey={[arrayKey, "cuentaContableId"]}
                                    required
                                    noStyle
                                  >
                                    <SelectItem
                                      sinBorde
                                      autoFocus
                                      width="100%"
                                      placeholder="Cuenta"
                                    >
                                      {this.state.objetoCuentaContable}
                                    </SelectItem>
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem shouldUpdate noStyle>
                                    {({ getFieldValue }) => {
                                      const fechaInicio =
                                        getFieldValue("fechaInicio");
                                      const fechaFinal =
                                        getFieldValue("fechaFinal");
  
                                      return (
                                        <FormItem
                                          {...restField}
                                          name={[name, "fechaInicio"]}
                                          fieldKey={[arrayKey, "fechaInicio"]}
                                          required
                                          noStyle
                                          initialValue={moment()}
                                        >
                                          <DatePickerItem
                                            placeholder="Fecha de Inicio"
                                            disabledDate={(current) => {

                                              // Can not select days before today and today
                                              if (fechaFinal) {
                                                return (
                                                  current < moment(fechaInicio).startOf('date') ||
                                                  current > moment(fechaFinal).endOf('date')
                                                );
                                              } else {
                                                return (
                                                  current < moment(fechaInicio).startOf('date')
                                                );
                                              }
                                            }}
                                            allowClear={false}
                                          />
                                        </FormItem>
                                      );
                                    }}
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem shouldUpdate noStyle>
                                    {({ getFieldValue }) => {
                                      const cuentasAfectadas =
                                        getFieldValue("cuentasAfectadas");
                                      const fechaInicio =
                                        cuentasAfectadas[arrayKey].fechaInicio;
                                      const fechaFinal =
                                        getFieldValue("fechaFinal");
                                      return (
                                        <FormItem
                                          {...restField}
                                          name={[name, "fechaFinal"]}
                                          fieldKey={[arrayKey, "fechaFinal"]}
                                          noStyle
                                        >
                                          <DatePickerItem
                                            disabledDate={(current) => {
                                              // Can not select days before today and today
                                              if (fechaFinal) {
                                                return (
                                                  current < moment(fechaInicio).startOf('date') ||
                                                  current > moment(fechaFinal).endOf('date')
                                                );
                                              } else {
                                                return (
                                                  current < moment(fechaInicio).startOf('date')
                                                );
                                              }
                                            }}
                                            placeholder="Fecha Final"
                                            allowClear={true}
                                          />
                                        </FormItem>
                                      );
                                    }}
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "concurrencia"]}
                                    fieldKey={[arrayKey, "concurrencia"]}
                                    margin
                                    required
                                    noStyle
                                  >
                                    <SelectItem
                                      sinBorde
                                      placeholder="Concurrencia"
                                    >
                                      <Option value={1}>Diario</Option>
                                      <Option value={2}>2 Dias</Option>
                                      <Option value={3}>5 Dias</Option>
                                      <Option value={4}>Semanal</Option>
                                      <Option value={5}>Quincenal</Option>
                                      <Option value={6}>Mensual</Option>
                                      <Option value={7}>Bimestral</Option>
                                      <Option value={8}>Trimestral</Option>
                                      <Option value={9}>Semestral</Option>
                                      <Option value={10}>Anual</Option>
                                    </SelectItem>
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "mostrarEnFlujo"]}
                                    fieldKey={[arrayKey, "mostrarEnFlujo"]}
                                    noStyle
                                    initialValue={true}
                                    valuePropName="checked"
                                  >
                                    <SwitchItem />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "ingreso"]}
                                    fieldKey={[arrayKey, "ingreso"]}
                                    noStyle
                                    valuePropName="checked"
                                  >
                                    <SwitchItem />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "monto"]}
                                    fieldKey={[arrayKey, "monto"]}
                                    number
                                    noStyle
                                  >
                                    <InputNumberItem sinBorde dinero />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "concepto"]}
                                    fieldKey={[arrayKey, "concepto"]}
                                    required
                                    noStyle
                                  >
                                    <InputItem sinBorde />
                                  </FormItem>
                                </td>
                                <td>
                                  <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                    style={{
                                      alignSelf: "center",
                                      justifySelf: "center",
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          )
                        )}
                      </table>
                      <FormItem noStyle>
                        <ButtonItem
                          blanco
                          style={{ marginTop: 10, marginBottom: 10 }}
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Agregar Campo
                        </ButtonItem>
                      </FormItem>
                    </>
                  )}
                </FormInitial.List>
              </Form>
            </Spin>
          </div>
        </>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      headersToken: state.user.headersToken,
      empresaId: state.empresa.id,
    };
  };
  
  export default connect(mapStateToProps)(EditarPresupuesto);
  