import React, { Component } from "react";
import {
  Layout,
  Menu,
  Spin,
  Card,
  Input,
  Button,
  message,
} from "antd";
import { NavLink } from "react-router-dom";
import { LockOutlined } from "@ant-design/icons";
import logoHorizontal from "../../assets/Biwo_Horizontal.png";
import logoGrande from "../../assets/Biwo logo grande.png";
import { FormItem, Form, ErrorItem } from "../../Components/Items";
import axios from "axios";

const { Header, Content, Footer } = Layout;

class CambiarContrasena extends Component {
  state = {
    loading: false,
  };

  onFinish(values) {
    this.setState({ loading: true });
    if (values.password === values.confirmarcontraseña) {
        const url = new URL(window.location.href)
        const token = url.searchParams.get('token')
        axios
          .post(
            "/cambiarContrasena",
            {...values, token},
            { headers: this.props.headersToken }
          )
          .then((res) => {
            this.setState({ loading: false });
            if (res.data.success === 2) {
              message.success("Se ha cambiado la contraseña");
              this.props.history.push("/login");
            } else if(res.data.success === 1){
                message.warning(res.data.message)
            } else{
              message.error("Hubo un error y no pudimos cambiar la contraseña, porfavor comuniquese con nosotros");
            }
          });
    } else {
      this.setState({ loading: false });
      message.error("Las contraseñas no coinciden");
    }
  }

  onFinishFailed(e) {
    ErrorItem(e);
  }

  render() {
    const center = {
      style: {
        margin: "auto",
        display: "block",
        alignSelf: "center",
      },
    };

    const current = this.props.history.location.pathname;
    return (
      <Layout>
        <Header id="header-fixed">
          <Menu
            mode="horizontal"
            selectedKeys={current}
            style={{ height: "100%" }}
          >
            <Menu.Item key={"/login"} id={window.innerWidth > 750 ? 'menuitem-izquierda' : 'menuitem-center'}>
              <NavLink to={"/login"}>
                <img src={logoHorizontal} alt='Logo' id="img-logo" />
              </NavLink>
            </Menu.Item>
          </Menu>
        </Header>
        <Content style={{ marginTop: 64 }}>
          <Spin spinning={this.state.loading} size="large">
            <Card id="login-card" bodyStyle={{ paddingTop: 0 }}>
              <img src={logoGrande} id="img-center" alt="Biwo" />

              <Form
              defaultBehavior
                name="cambiarContrasena"
                onFinish={this.onFinish.bind(this)}
                onFinishFailed={this.onFinishFailed}
              >
                <FormItem name="password" pattern required max={200} min={5}>
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Contraseña"
                  />
                </FormItem>
                <FormItem
                  name="confirmarcontraseña"
                  pattern
                  required
                  max={200}
                  min={5}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Confirmar Contraseña"
                  />
                </FormItem>
                <FormItem>
                  <Button type="primary" htmlType="submit" {...center}>
                    Cambiar Contraseña
                  </Button>
                </FormItem>
              </Form>
            </Card>
          </Spin>
        </Content>
        <Footer id="content-background" style={{ textAlign: "center" }}>
          Biwo ©2020
        </Footer>
      </Layout>
    );
  }
}

export default CambiarContrasena;
