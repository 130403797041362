import {
    message,
    PageHeader,
    Spin,
    Space,
    Tabs,
    Row,
    Col,
    Select
  } from "antd";
  import axios from "axios";
  import React, { Component } from "react";
  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  import { connect } from "react-redux";
  import {
    Form,
    FormItem,
    round2Decimals,
    DatePickerItem,
    dineroDisabledMask,
    TableItem,
    SelectItem,
    cuentaMask,
    ButtonItem,
    ErrorItem
  } from "../../../../Components/Items";
  import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
  import * as XLSX from "xlsx";
  import moment from "moment";
  import "moment/locale/es";
import { ResponsiveBar } from "@nivo/bar";
import reporteDeFlujoDeEfectivoPresupuestadoPdf from "../../../../Pdf/reporteDeFlujoDeEfectivoPresupuestadoPdf";
  
  moment.locale("es");
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
  const { TabPane } = Tabs;
  const { Option } = Select;
  
  class ReporteFlujoDeEfectivoPresupuestado extends Component {
    state = {
      loading: false,
      dataReporte: null,
      todasDiasFlujoEfectivo: [],
      dataGrafico: [],
      objetoProyectos: null,
      todasProyectos: null,
      objetoUniadesDeNegocio: null,
      todasUniadesDeNegocio: null,
    };
  
    atras() {
      this.props.history.goBack();
    }
  
    async componentDidMount() {
      this.setState({ loading: true });
      await axios
      .post(
        "proyectos/selectProyectos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProyectos: res.data });
          this.setState({
            objetoProyectos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "unidadesdenegocio/selectUnidadesDeNegocio",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasUniadesDeNegocio: res.data });
          this.setState({
            objetoUniadesDeNegocio: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {cuentaMask(
                    value.nivel1 +
                      value.nivel2 +
                      value.nivel3 +
                      value.nivel4 +
                      value.nivel5
                  ) +
                    " - " +
                    value.nombre}
                </Option>
              );
            }),
          });
        }
      });
      this.consultarReporteFlujoDeEfectivoPresupuestado({
        fechaFinal: moment().add(15, 'days'),
      });
    }
  
    consultarReporteFlujoDeEfectivoPresupuestado(values) {
      this.setState({ loading: true });
      axios
        .post(
          "reportesFinancieros/reporteFlujoDeEfectivoPresupuestado",
          { ...values, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.success === 2) {
            this.crearGrafico(
                res.data.diasFlujoDeEfectivo,
                res.data.fechaFinal
              );
            this.setState({
              loading: false,
              dataReporte: res.data,
              todasDiasFlujoEfectivo: res.data.diasFlujoDeEfectivo
            });
          } else if (res.data.success === 1) {
            message.error("Ha sucedido un error y no se cargo bien el reporte");
          } else {
            message.error("Ha sucedido un error y no se cargo bien el reporte");
          }
          this.setState({ loading: false });
        });
    }
  
    descargarExcel() {
      let datosDescargar = [];
  
      for (const valueDFE of this.state.todasDiasFlujoEfectivo) {
        datosDescargar.push({
            'Fecha': valueDFE.dia,
            'Saldo Inicial': round2Decimals(valueDFE.saldoInicial),
            'Movimientos del dia': round2Decimals(valueDFE.movimientosDia),
            'Ingresos': round2Decimals(valueDFE.ingresos),
            'Egresos': round2Decimals(valueDFE.egresos),
            'Cuentas por Pagar': round2Decimals(valueDFE.cuentasPorPagar),
            'Cuentas por Cobrar': round2Decimals(valueDFE.cuentasPorCobrar),
            'Saldo Final': round2Decimals(valueDFE.saldoFinal),
        });
      }
  
      const worksheet = XLSX.utils.json_to_sheet(datosDescargar);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        "Flujo de Efectivo Presupuestado"
      );
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(
        workbook,
        `Flujo_De_Efectivo_Presupuestado_${moment().locale("es").format("L")}.xlsx`
      );
    }
  
    descargarPdf() {
      pdfMake
        .createPdf(reporteDeFlujoDeEfectivoPresupuestadoPdf(this.state.dataReporte))
        .download(
          `Flujo_De_Efectivo_Presupuestado_${moment().locale("es").format("L")}`
        );
    }

    async crearGrafico(valueDFE, fechaFinal) {
        let grafico = [];
    
        let fechaCambiante = moment().locale("es");
        const fechaFinalMoment = moment(fechaFinal).locale("es");
    
        if (fechaFinalMoment.diff(fechaCambiante, "days") <= 31) {
          while (fechaFinalMoment.diff(fechaCambiante, "days") !== -1) {
            // eslint-disable-next-line no-loop-func
            const dia = valueDFE.filter((todasDias) => {
                if (isNaN(todasDias.dia)) {
                  return moment(fechaCambiante).isSame(
                    moment(todasDias.dia, "DD/MM/YYYY"),
                    "date"
                  );
                } else {
                  return false;
                }
              });

              let saldoInicial = 0
              let movimientosDia = 0
              let ingresos = 0
              let egresos = 0
              let cuentasPorPagar = 0
              let cuentasPorCobrar = 0
              let saldoFinal = 0
  
              dia.map((valueVentaDia) => {
                  saldoInicial += valueVentaDia.saldoInicial;
                  movimientosDia += valueVentaDia.movimientosDia;
                  ingresos += valueVentaDia.ingresos;
                  egresos -= valueVentaDia.egresos;
                  cuentasPorPagar -= valueVentaDia.cuentasPorPagar;
                  cuentasPorCobrar += valueVentaDia.cuentasPorCobrar;
                  saldoFinal += valueVentaDia.saldoFinal;
                  return null;
                });

                grafico.push({
                    'Fecha': moment(fechaCambiante).locale("es").format("DD-MM"),
                    'Saldo Inicial': round2Decimals(saldoInicial),
                    'Movimientos del dia': round2Decimals(movimientosDia),
                    'Ingresos': round2Decimals(ingresos),
                    'Egresos': round2Decimals(egresos),
                    'Cuentas por Pagar': round2Decimals(cuentasPorPagar),
                    'Cuentas por Cobrar': round2Decimals(cuentasPorCobrar),
                    'Saldo Final': round2Decimals(saldoFinal),
                })

            fechaCambiante = moment(fechaCambiante).add(1, "day").locale("es");
          }
        } else if (fechaFinalMoment.diff(fechaCambiante, "days") < 180) {
          while (fechaFinalMoment.diff(fechaCambiante, "weeks") !== -1) {
            // eslint-disable-next-line no-loop-func
            const diasSemana = valueDFE.filter((todasDFE) => {
              if (isNaN(todasDFE.dia)) {
                return moment(fechaCambiante).isSame(
                    moment(todasDFE.dia, "DD/MM/YYYY"),
                  "week"
                );
              } else {
                return false;
              }
            });

            let saldoInicial = 0
            let movimientosDia = 0
            let ingresos = 0
            let egresos = 0
            let cuentasPorPagar = 0
            let cuentasPorCobrar = 0
            let saldoFinal = 0

            diasSemana.map((valueVentaDia, indexDia) => {
                if(indexDia === 0){
                    saldoInicial = valueVentaDia.saldoInicial;
                }
                if(indexDia === (diasSemana.length - 1)){
                    saldoFinal = valueVentaDia.saldoFinal;
                }
                movimientosDia += valueVentaDia.movimientosDia;
                cuentasPorPagar -= valueVentaDia.cuentasPorPagar;
                ingresos += valueVentaDia.ingresos;
                cuentasPorCobrar += valueVentaDia.cuentasPorCobrar;
                egresos -= valueVentaDia.egresos;
                return null;
              });

            grafico.push({
                'Fecha': moment(fechaCambiante).locale("es").format("[SEM] WW"),
                'Saldo Inicial': round2Decimals(saldoInicial),
                'Movimientos del dia': round2Decimals(movimientosDia),
                'Ingresos': round2Decimals(ingresos),
                'Egresos': round2Decimals(egresos),
                'Cuentas por Pagar': round2Decimals(cuentasPorPagar),
                'Cuentas por Cobrar': round2Decimals(cuentasPorCobrar),
                'Saldo Final': round2Decimals(saldoFinal),
            })
    
            fechaCambiante = moment(fechaCambiante).add(1, "week").locale("es");
          }
        } else if (fechaFinalMoment.diff(fechaCambiante, "months") < 36) {
          while (fechaFinalMoment.diff(fechaCambiante, "months") !== -1) {
            // eslint-disable-next-line no-loop-func
            const diasMes = valueDFE.filter((todasDFE) => {
                if (isNaN(todasDFE.dia)) {
                  return moment(fechaCambiante).isSame(
                    moment(todasDFE.dia, "DD/MM/YYYY"),
                    "month"
                  );
                } else {
                  return false;
                }
              });
  
              let saldoInicial = 0
              let movimientosDia = 0
              let cuentasPorPagar = 0
              let cuentasPorCobrar = 0
              let saldoFinal = 0
              let ingresos = 0
              let egresos = 0
  
              diasMes.map((valueVentaDia, indexDia) => {
                if(indexDia === 0){
                    saldoInicial = valueVentaDia.saldoInicial;
                }
                if(indexDia === (diasMes.length - 1)){
                    saldoFinal = valueVentaDia.saldoFinal;
                }
                ingresos += valueVentaDia.ingresos;
                egresos -= valueVentaDia.egresos;
                movimientosDia += valueVentaDia.movimientosDia;
                cuentasPorPagar -= valueVentaDia.cuentasPorPagar;
                cuentasPorCobrar += valueVentaDia.cuentasPorCobrar;
                return null;
              });
  
              grafico.push({
                  'Fecha': moment(fechaCambiante).locale("es").format("MM-YY"),
                  'Saldo Inicial': round2Decimals(saldoInicial),
                  'Movimientos del dia': round2Decimals(movimientosDia),
                  'Ingresos': round2Decimals(ingresos),
                  'Egresos': round2Decimals(egresos),
                  'Cuentas por Pagar': round2Decimals(cuentasPorPagar),
                  'Cuentas por Cobrar': round2Decimals(cuentasPorCobrar),
                  'Saldo Final': round2Decimals(saldoFinal),
              })
            fechaCambiante = moment(fechaCambiante).add(1, "month").locale("es");
          }
        } else {
          while (fechaFinalMoment.diff(fechaCambiante, "years") !== -1) {
            // eslint-disable-next-line no-loop-func
            const diasAno = valueDFE.filter((todasDFE) => {
                if (isNaN(todasDFE.dia)) {
                  return moment(fechaCambiante).isSame(
                    moment(todasDFE.dia, "DD/MM/YYYY"),
                    "year"
                  );
                } else {
                  return false;
                }
              });
  
              let saldoInicial = 0
              let movimientosDia = 0
              let cuentasPorPagar = 0
              let cuentasPorCobrar = 0
              let saldoFinal = 0
              let ingresos = 0
              let egresos = 0
  
              diasAno.map((valueVentaDia, indexDia) => {
                if(indexDia === 0){
                    saldoInicial = valueVentaDia.saldoInicial;
                }
                if(indexDia === (diasAno.length - 1)){
                    saldoFinal = valueVentaDia.saldoFinal;
                }
                movimientosDia += valueVentaDia.movimientosDia;
                cuentasPorPagar -= valueVentaDia.cuentasPorPagar;
                cuentasPorCobrar += valueVentaDia.cuentasPorCobrar;
                ingresos += valueVentaDia.ingresos;
                egresos -= valueVentaDia.egresos;
                return null;
              });
  
              grafico.push({
                  'Fecha': moment(fechaCambiante).locale("es").format("YYYY"),
                  'Saldo Inicial': round2Decimals(saldoInicial),
                  'Movimientos del dia': round2Decimals(movimientosDia),
                  'Ingresos': round2Decimals(ingresos),
                  'Egresos': round2Decimals(egresos),
                  'Cuentas por Pagar': round2Decimals(cuentasPorPagar),
                  'Cuentas por Cobrar': round2Decimals(cuentasPorCobrar),
                  'Saldo Final': round2Decimals(saldoFinal),
              })
            fechaCambiante = moment(fechaCambiante).add(1, "year").locale("es");
          }
        }
    
        await this.setState({
          dataGrafico: grafico
        });
      }
  
    onFinishFailed(e) {
      ErrorItem(e);
    }
  
    render() {
        const columns = [
            {
              title: "Dia",
              dataIndex: "dia",
              key: "dia",
              sorter: (a, b) => {
                if (a.dia < b.dia) {
                  return -1;
                }
                if (a.dia > b.dia) {
                  return 1;
                }
                return 0;
              },
              buscar: "dia",
              ellipsis: true,
            },
            {
              title: "Saldo Inicial",
              dataIndex: "saldoInicial",
              key: "saldoInicial",
              sorter: (a, b) => {
                if (a.saldoInicial < b.saldoInicial) {
                  return -1;
                }
                if (a.saldoInicial > b.saldoInicial) {
                  return 1;
                }
                return 0;
              },
              buscar: "saldoInicial",
              render: (value) => dineroDisabledMask(value),
              ellipsis: true,
              align: "right",
            },
            {
                title: "Movimientos del dia",
                dataIndex: "movimientosDia",
                key: "movimientosDia",
                sorter: (a, b) => {
                  if (a.movimientosDia < b.movimientosDia) {
                    return -1;
                  }
                  if (a.movimientosDia > b.movimientosDia) {
                    return 1;
                  }
                  return 0;
                },
                buscar: "movimientosDia",
                render: (value) => dineroDisabledMask(value),
                ellipsis: true,
                align: "right",
              },
              {
                title: "Ingresos",
                dataIndex: "ingresos",
                key: "ingresos",
                sorter: (a, b) => {
                  if (a.ingresos < b.ingresos) {
                    return -1;
                  }
                  if (a.ingresos > b.ingresos) {
                    return 1;
                  }
                  return 0;
                },
                buscar: "ingresos",
                render: (value) => dineroDisabledMask(value),
                ellipsis: true,
                align: "right",
              },
              {
                title: "Egresos",
                dataIndex: "egresos",
                key: "egresos",
                sorter: (a, b) => {
                  if (a.egresos < b.egresos) {
                    return -1;
                  }
                  if (a.egresos > b.egresos) {
                    return 1;
                  }
                  return 0;
                },
                buscar: "egresos",
                render: (value) => dineroDisabledMask(value),
                ellipsis: true,
                align: "right",
              },
              {
                title: "Cuentas Por Pagar",
                dataIndex: "cuentasPorPagar",
                key: "cuentasPorPagar",
                sorter: (a, b) => {
                  if (a.cuentasPorPagar < b.cuentasPorPagar) {
                    return -1;
                  }
                  if (a.cuentasPorPagar > b.cuentasPorPagar) {
                    return 1;
                  }
                  return 0;
                },
                buscar: "cuentasPorPagar",
                render: (value) => dineroDisabledMask(value),
                ellipsis: true,
                align: "right",
              },
              {
                title: "Cuentas Por Cobrar",
                dataIndex: "cuentasPorCobrar",
                key: "cuentasPorCobrar",
                sorter: (a, b) => {
                  if (a.cuentasPorCobrar < b.cuentasPorCobrar) {
                    return -1;
                  }
                  if (a.cuentasPorCobrar > b.cuentasPorCobrar) {
                    return 1;
                  }
                  return 0;
                },
                buscar: "cuentasPorCobrar",
                render: (value) => dineroDisabledMask(value),
                ellipsis: true,
                align: "right",
              },
              {
                title: "Saldo Final",
                dataIndex: "saldoFinal",
                key: "saldoFinal",
                sorter: (a, b) => {
                  if (a.saldoFinal < b.saldoFinal) {
                    return -1;
                  }
                  if (a.saldoFinal > b.saldoFinal) {
                    return 1;
                  }
                  return 0;
                },
                buscar: "saldoFinal",
                render: (value) => dineroDisabledMask(value),
                ellipsis: true,
                align: "right",
              },
          ];
      return (
        <>
          <Form
            ref={this.formRef}
            name="rerpoteFlujoDeEfectivo"
            onFinish={this.consultarReporteFlujoDeEfectivoPresupuestado.bind(this)}
            onFinishFailed={this.onFinishFailed}
            inita
          >
            <PageHeader
              onBack={this.atras.bind(this)}
              title="Reporte Flujo de Efectivo Presupuestado"
              style={{
                border: "1px solid rgb(235, 237, 240)",
                backgroundColor: "white",
                marginBottom: 10,
              }}
              extra={
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <Space>
                    {this.state.dataReporte && (
                      <ButtonItem
                        blanco
                        onClick={() => this.descargarExcel()}
                        icon={<FileExcelOutlined />}
                        style={{ display: "block", margin: "0 0 0 auto" }}
                      >
                        Descargar Excel
                      </ButtonItem>
                    )}
                    {this.state.dataReporte && (
                      <ButtonItem
                        blanco
                        onClick={() => this.descargarPdf()}
                        icon={<FilePdfOutlined />}
                        style={{ display: "block", margin: "0 0 0 auto" }}
                      >
                        Descargar PDF
                      </ButtonItem>
                    )}
                    <ButtonItem
                      type="primary"
                      htmlType="submit"
                      style={{ display: "block", margin: "0 0 0 auto" }}
                    >
                      Consultar
                    </ButtonItem>
                  </Space>
                </div>
              }
            />
            <Spin spinning={this.state.loading}>
              <div
                style={{
                  backgroundColor: "white",
                  padding: 20,
                  paddingTop: 5,
                  marginBottom: 20,
                }}
              >
                <Tabs
                  style={{ margin: 0 }}
                  tabBarExtraContent={{
                    left: (
                      <h1 style={{ padding: 0, margin: "0 10px 0 0" }}>
                        Filtros:
                      </h1>
                    ),
                  }}
                >
                <TabPane tabKey="3" key="3" forceRender tab="Fecha Final">
                  <FormItem marginBottom='0px' initialValue={moment().add(15, 'days')} label="Fecha Final" name="fechaFinal" required>
                    <DatePickerItem           disabledDate={(current) => {
            // Can not select days before today and today
            return current && (current < moment() || current > moment().add(24, 'months').add(1, 'day'));
          }} />
                  </FormItem>
                </TabPane>
                <TabPane tabKey="4" key="4" forceRender tab="Proyecto">
                    <Row>
                      <Col span={8}>
                        <FormItem
                          name="filtrarProyecto"
                          required
                          noStyle
                          initialValue={0}
                        >
                          <SelectItem width="90%" placeholder="Filtrar Proyecto">
                            <Option default value={0}>
                              Niguno
                            </Option>
                            <Option value={1}>Proyectos</Option>
                            <Option value={2}>Unidades de negocio</Option>
                          </SelectItem>
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem shouldUpdate noStyle>
                          {({ getFieldValue }) => {
                            const filtrarProyecto =
                              getFieldValue("filtrarProyecto");
                            if (filtrarProyecto === 1) {
                              return (
                                <FormItem
                                  required
                                  name="proyectos"
                                  noStyle
                                >
                                  <SelectItem
                                    width="90%"
                                    placeholder="Proyectos"
                                    mode="multiple"
                                  >
                                    {this.state.objetoProyectos}
                                  </SelectItem>
                                </FormItem>
                              );
                            }
                            if (filtrarProyecto === 2) {
                              return (
                                <FormItem
                                  required
                                  name="unidadDeNegocioId"
                                  noStyle
                                >
                                  <SelectItem
                                    width="90%"
                                    placeholder="Unidades de Negocio"
                                  >
                                    {this.state.objetoUniadesDeNegocio}
                                  </SelectItem>
                                </FormItem>
                              );
                            }
                          }}
                        </FormItem>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </div>
              <div
              style={{
                backgroundColor: "white",
                padding: 15,
                marginTop: 10,
                marginBottom: 10,
                height: "400px",
              }}
            >
              <h1 style={{ fontSize: "20px" }}>Grafico</h1>
              <ResponsiveBar
                  data={this.state.dataGrafico}
                  keys={[
                    "Saldo Inicial",
                    "Movimientos del dia",
                    "Ingresos",
                    "Egresos",
                    "Cuentas por Pagar",
                    "Cuentas por Cobrar",
                    "Saldo Final",
                  ]}
                  indexBy="Fecha"
                  valueFormat={(e) => dineroDisabledMask(Math.abs(e))}
                  margin={{ top: 20, right: 80, bottom: 100, left: 80 }}
                  padding={0.3}
                  groupMode="grouped"
                  valueScale={{ type: "linear" }}
                  indexScale={{ type: "band", round: true }}
                  colors={['#cccc33', '#33cccc', '#66cccc', '#ff6633', '#ff6633', '#66cccc', '#33cccc']}
                  colorBy="id"
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                  }}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 90,
                }}
                  axisTop={null}
                  axisRight={null}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                  }}
                />
            </div>
            <h1 style={{ textAlign: "center" }}>Cuentas</h1>
            <TableItem
              columns={columns}
              dataSource={this.state.todasDiasFlujoEfectivo}
              size="small"
              pagination={false}
            />
            </Spin>
          </Form>
        </>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      headersToken: state.user.headersToken,
      empresaId: state.empresa.id,
    };
  };
  
  export default connect(mapStateToProps)(ReporteFlujoDeEfectivoPresupuestado);
  