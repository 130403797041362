import {
    Col,
    message,
    PageHeader,
    Row,
    Spin,
    Select,
    Space,
    Tabs,
  } from "antd";
  import axios from "axios";
  import React, { Component } from "react";
  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  import { connect } from "react-redux";
  // import balanceGeneralPdf from "../../../../Pdf/balanceGeneralPdf";
  import {
    SelectItem,
    Form,
    FormItem,
    cuentaMask,
    TableItem,
    ButtonItem,
    ErrorItem
  } from "../../../../Components/Items";
  import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
  import * as XLSX from "xlsx";
  import moment from "moment";
  import "moment/locale/es";
import { ResponsiveSwarmPlot } from "@nivo/swarmplot";
import reporteDeInventarioPdf from "../../../../Pdf/reporteDeInventarioPdf";



  moment.locale("es");
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
  const { Option } = Select;
  const { TabPane } = Tabs;
  
  class ReporteInventario extends Component {
    state = {
      loading: false,
      pdf: null,
      objetoProyectos: null,
      todasProyectos: null,
      objetoUniadesDeNegocio: null,
      todasUniadesDeNegocio: null,
      dataReporte: null,
      todasInventario: [],
      dataGrafico: [],
      valorMaximoCirculo: 0
    };
  
    atras() {
        this.props.history.goBack();
    }
  
    async componentDidMount() {
      this.setState({ loading: true });
      await axios
        .post(
          "proyectos/selectProyectos",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasProyectos: res.data });
            this.setState({
              objetoProyectos: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre}
                  </Option>
                );
              }),
            });
          }
        });
      await axios
        .post(
          "unidadesdenegocio/selectUnidadesDeNegocio",
          { empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasUniadesDeNegocio: res.data });
            this.setState({
              objetoUniadesDeNegocio: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {cuentaMask(
                      value.nivel1 +
                        value.nivel2 +
                        value.nivel3 +
                        value.nivel4 +
                        value.nivel5
                    ) +
                      " - " +
                      value.nombre}
                  </Option>
                );
              }),
            });
          }
        });
      this.consultarReporteInventario();
    }
  
    async crearGrafico(valueInventarios) {

      let valorMaximoCirculo = 0

      let grafico = valueInventarios.map(valueInventario => {

        valorMaximoCirculo = Math.max(valueInventario.cantidad, valorMaximoCirculo)

        return {
            "id": valueInventario.descripcionInsumo+' - cantidad : '+valueInventario.cantidad,
            "group": "Cantidad",
            "cantidad": 0,
            "volume": valueInventario.cantidad
        }
      });

      let grafico2 = valueInventarios.map(valueInventario => {

        valorMaximoCirculo = Math.max(valueInventario.cantidadPorComprarMin, valorMaximoCirculo)

        return {
            "id": valueInventario.descripcionInsumo+' - compra min. : '+valueInventario.cantidadPorComprarMin,
            "group": "Compra Min",
            "cantidad": 0,
            "volume": valueInventario.cantidadPorComprarMin
        }
      });


      let grafico3 = valueInventarios.map(valueInventario => {

        valorMaximoCirculo = Math.max(valueInventario.cantidadPorComprarMax, valorMaximoCirculo)

        return {
            "id": valueInventario.descripcionInsumo+' - compra max. : '+valueInventario.cantidadPorComprarMax,
            "group": "Compra Max",
            "cantidad": 0,
            "volume": valueInventario.cantidadPorComprarMax
        }
      });

  
      await this.setState({
        valorMaximoCirculo,
        dataGrafico: [
            ...grafico,
            ...grafico2,
            ...grafico3
        ],
      });
    }
  
    consultarReporteInventario(values) {
      this.setState({ loading: true });
      axios
        .post(
          "reportesDeInventario/reporteInventario",
          { ...values, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.success === 2) {
            this.crearGrafico(
              res.data.inventario,
            );
            this.setState({
              loading: false,
              dataReporte: res.data,
              todasInventario: res.data.inventario,
            });
          } else if (res.data.success === 1) {
            message.error("Ha sucedido un error y no se cargo bien el reporte");
          } else {
            message.error("Ha sucedido un error y no se cargo bien el reporte");
          }
          this.setState({ loading: false });
        });
    }
  
    descargarExcel() {
      let datosDescargar = this.state.todasInventario.map(valueInventario => {
        return {
          'Codigo': valueInventario.codigoInsumo,
          'Clave': valueInventario.claveInsumo,
          'Descripcion': valueInventario.descripcionInsumo,
          'Familia': valueInventario.nombreFamilia,
          'Cantidad': valueInventario.cantidad,
          'Min.': valueInventario.cantidadMin,
          'Max.': valueInventario.cantidadMax,
          'Compra Min.': valueInventario.cantidadPorComprarMin,
          'Compra Max.': valueInventario.cantidadPorComprarMax,
        }
      })
      const worksheet = XLSX.utils.json_to_sheet(datosDescargar);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte Inventario");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, `Reporte_Inventario_${moment().locale("es").format("L")}.xlsx`);
    }
  
    descargarPdf() {
      pdfMake
        .createPdf(reporteDeInventarioPdf(this.state.dataReporte))
        .download(`Reporte_Inventario_${moment().locale("es").format("L")}`);
    }
  
    onFinishFailed(e) {
      console.log(e);
      ErrorItem(e);
    }
    render() {
      const columns = [
        {
          title: "Clave",
          dataIndex: "claveInsumo",
          key: "claveInsumo",
          sorter: (a, b) => {
            if (a.claveInsumo < b.claveInsumo) {
              return -1;
            }
            if (a.claveInsumo > b.claveInsumo) {
              return 1;
            }
            return 0;
          },
          buscar: "claveInsumo",
          ellipsis: true,
        },
        {
          title: "Descripcion",
          dataIndex: "descripcionInsumo",
          key: "descripcionInsumo",
          sorter: (a, b) => {
            if (a.descripcionInsumo < b.descripcionInsumo) {
              return -1;
            }
            if (a.descripcionInsumo > b.descripcionInsumo) {
              return 1;
            }
            return 0;
          },
          buscar: "descripcionInsumo",
          ellipsis: true,
        },
        {
          title: "Familia",
          dataIndex: "nombreFamilia",
          key: "nombreFamilia",
          sorter: (a, b) => {
            if (a.nombreFamilia < b.nombreFamilia) {
              return -1;
            }
            if (a.nombreFamilia > b.nombreFamilia) {
              return 1;
            }
            return 0;
          },
          buscar: "nombreFamilia",
          ellipsis: true,
        },
        {
          title: "Cantidad",
          dataIndex: "cantidad",
          key: "cantidad",
          sorter: (a, b) => {
            if (a.cantidad < b.cantidad) {
              return -1;
            }
            if (a.cantidad > b.cantidad) {
              return 1;
            }
            return 0;
          },
          buscar: "cantidad",
          ellipsis: true,
        },
        {
          title: "Cantidad Min",
          dataIndex: "cantidadMin",
          key: "cantidadMin",
          sorter: (a, b) => {
            if (a.cantidadMin < b.cantidadMin) {
              return -1;
            }
            if (a.cantidadMin > b.cantidadMin) {
              return 1;
            }
            return 0;
          },
          buscar: "cantidadMin",
          ellipsis: true,
        },
        {
            title: "Cantidad Max",
            dataIndex: "cantidadMax",
            key: "cantidadMax",
            sorter: (a, b) => {
              if (a.cantidadMax < b.cantidadMax) {
                return -1;
              }
              if (a.cantidadMax > b.cantidadMax) {
                return 1;
              }
              return 0;
            },
            buscar: "cantidadMax",
            ellipsis: true,
          },
        {
          title: "Compra Minima",
          dataIndex: "cantidadPorComprarMin",
          key: "cantidadPorComprarMin",
          sorter: (a, b) => {
            if (a.cantidadPorComprarMin < b.cantidadPorComprarMin) {
              return -1;
            }
            if (a.cantidadPorComprarMin > b.cantidadPorComprarMin) {
              return 1;
            }
            return 0;
          },
          buscar: "cantidadPorComprarMin",
          ellipsis: true,
        },
        {
            title: "Compra Maxima",
            dataIndex: "cantidadPorComprarMax",
            key: "cantidadPorComprarMax",
            sorter: (a, b) => {
              if (a.cantidadPorComprarMax < b.cantidadPorComprarMax) {
                return -1;
              }
              if (a.cantidadPorComprarMax > b.cantidadPorComprarMax) {
                return 1;
              }
              return 0;
            },
            buscar: "cantidadPorComprarMax",
            ellipsis: true,
          },
      ];
      return (
        <>
          <Form
            ref={this.formRef}
            name="rerpoteInventario"
            onFinish={this.consultarReporteInventario.bind(this)}
            onFinishFailed={this.onFinishFailed}
            initialValues={{
              periodo: [moment().subtract(1, "month"), moment()],
            }}
          >
            <PageHeader
              onBack={this.atras.bind(this)}
              title="Reporte Inventario"
              style={{
                border: "1px solid rgb(235, 237, 240)",
                backgroundColor: "white",
                marginBottom: 10,
              }}
              extra={
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <Space>
                    {this.state.dataReporte && (
                      <ButtonItem
                        blanco
                        onClick={() => this.descargarExcel()}
                        icon={<FileExcelOutlined />}
                        style={{ display: "block", margin: "0 0 0 auto" }}
                      >
                        Descargar Excel
                      </ButtonItem>
                    )}
                    {this.state.dataReporte && (
                      <ButtonItem
                        blanco
                        onClick={() => this.descargarPdf()}
                        icon={<FilePdfOutlined />}
                        style={{ display: "block", margin: "0 0 0 auto" }}
                      >
                        Descargar PDF
                      </ButtonItem>
                    )}
                    <ButtonItem
                      type="primary"
                      htmlType="submit"
                      style={{ display: "block", margin: "0 0 0 auto" }}
                    >
                      Consultar
                    </ButtonItem>
                  </Space>
                </div>
              }
            />
            <Spin spinning={this.state.loading}>
              <div
                style={{ backgroundColor: "white", padding: 20, paddingTop: 5 }}
              >
                <Tabs
                  style={{ margin: 0 }}
                  tabBarExtraContent={{
                    left: (
                      <h1 style={{ padding: 0, margin: "0 10px 0 0" }}>
                        Filtros:
                      </h1>
                    ),
                  }}
                >
                  <TabPane tabKey="4" key="4" forceRender tab="Proyecto">
                    <Row>
                      <Col span={8}>
                        <FormItem
                          name="filtrarProyecto"
                          required
                          noStyle
                          initialValue={0}
                        >
                          <SelectItem width="90%" placeholder="Filtrar Proyecto">
                            <Option default value={0}>
                              Niguno
                            </Option>
                            <Option value={1}>Proyectos</Option>
                            <Option value={2}>Unidades de negocio</Option>
                          </SelectItem>
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem shouldUpdate noStyle>
                          {({ getFieldValue }) => {
                            const filtrarProyecto =
                              getFieldValue("filtrarProyecto");
                            if (filtrarProyecto === 1) {
                              return (
                                <FormItem required name="proyectos" margin noStyle>
                                  <SelectItem
                                    width="90%"
                                    placeholder="Proyectos"
                                    mode="multiple"
                                  >
                                    {this.state.objetoProyectos}
                                  </SelectItem>
                                </FormItem>
                              );
                            }
                            if (filtrarProyecto === 2) {
                              return (
                                <FormItem required margin name="unidadDeNegocioId" noStyle>
                                  <SelectItem
                                    width="90%"
                                    placeholder="Unidades de Negocio"
                                  >
                                    {this.state.objetoUniadesDeNegocio}
                                  </SelectItem>
                                </FormItem>
                              );
                            }
                          }}
                        </FormItem>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  padding: 15,
                  marginTop: 10,
                  marginBottom: 10,
                  height: "400px",
                }}
              >
                <h1 style={{ fontSize: "20px" }}>Grafico</h1>
                <ResponsiveSwarmPlot
                    data={this.state.dataGrafico}
                    groups={[ 'Cantidad', 'Compra Min', 'Compra Max' ]}
                    identity="id"
                    value="cantidad"
                    valueFormat={() => ''}
                    spacing={4}
                    gap={30}
                    valueScale={{ type: 'linear', min: 0, max: 'auto', reverse: false }}
                    size={{
                        key: 'volume',
                        values: [
                            0,
                            this.state.valorMaximoCirculo
                        ],
                        sizes: [
                            6,
                            30
                        ]
                    }}
                    forceStrength={0.2}
                    simulationIterations={100}
                    enableGridY={false}
                    margin={{ top: 80, right: 100, bottom: 80, left: 100 }}
                    axisTop={{
                        orient: 'top',
                        tickSize: 10,
                        tickPadding: 5,
                        tickRotation: 0,
                    }}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 10,
                        tickPadding: 5,
                        tickRotation: 0,
                    }}
                    axisLeft={null}
                    colors={['#ff6633', '#33cccc', '#cccc33']}
                    colorBy="index"
                />
              </div>
              <TableItem
                rowKey={(value) => value.insumoId.toString() + Math.random()}
                columns={columns}
                dataSource={this.state.todasInventario}
                size="small"
                pagination={false}
              />
              {/* <div style={{ backgroundColor: "white", padding: 20, paddingTop: 5 }}>
            {this.state.pdf && (
              <iframe
                title={"documentos"}
                style={{ width: "100%", height: "100vh" }}
                id="printPdf"
                name="printPdf"
              />
            )}
          </div> */}
            </Spin>
          </Form>
        </>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      headersToken: state.user.headersToken,
      empresaId: state.empresa.id,
    };
  };
  
  export default connect(mapStateToProps)(ReporteInventario);
  