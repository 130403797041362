import {
    message,
    Tabs,
    Select,
    PageHeader,
    Spin,
    Row,
    Col,
  } from "antd";
  import React, { Component } from "react";
  import axios from "axios";
  import { connect } from "react-redux";
  import {
    Form,
    FormItem,
    SelectItem,
    HeaderItem,
    BoxItem,
    ButtonItem,
    TextAreaItem,
    SwitchItem,
    InputItem,
  } from "../../../../Components/Items";
  
  const { TabPane } = Tabs;
  const { Option } = Select;
  
  class AgregarConcepto extends Component {
    formRef = React.createRef();
    state = {
      loading: false,
      tiposPercepcionesSat: null,
      objetotiposPercepcionesSat: null,
    };
  
    componentDidMount() {
      this.consultarTiposPercepcion();
    }
  
    consultarTiposPercepcion() {
      axios
        .post(
          "conceptosNomina/selectDatosParaConcepto",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
            console.log(res)
          if (res.data.status === 404) {
            message.error("No se encontraron tipos de percepción.");
          } else {
            this.setState({ 
                tiposPercepcionesSat: res.data.tiposPercepcionesSat,
                objetotiposPercepcionesSat: res.data.tiposPercepcionesSat.map((value) => (
                <Option value={value._id} key={value._id}>
                    {value.Descripcion}
                  </Option>))
             });
          }
        });
    }
  
    onFinish(values) {
      this.setState({ loading: true });
      axios
        .post("conceptosNomina/agregarConcepto", {
          ...values,
          empresaId: this.props.empresaId,
        }, {
          headers: this.props.headersToken,
        })
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.success === 2) {
            message.success("Concepto agregado correctamente");
            this.formRef.current.resetFields();
            this.props.history.goBack();
          } else {
            message.error("Error al agregar el concepto");
          }
        });
    }
  
    render() {
      return (
        <>
          <PageHeader
            onBack={() => this.props.history.goBack()}
            title="Agregar Concepto"
            style={{
              border: "1px solid rgb(235, 237, 240)",
              backgroundColor: "white",
              marginBottom: 10,
            }}
          />
          <div style={{ backgroundColor: "white", padding: 20, paddingTop: 5 }}>
            <Spin spinning={this.state.loading}>
              <Form
                ref={this.formRef}
                name="agregarConcepto"
                layout="vertical"
                onFinish={this.onFinish.bind(this)}
                bottom={
                  <ButtonItem
                    type="primary"
                    htmlType="submit"
                    style={{ display: "block", margin: "0 0 0 auto" }}
                  >
                    Agregar Concepto
                  </ButtonItem>
                }
              >
  
                <Tabs defaultActiveKey={1}>
                  <TabPane tab="Datos del Concepto" key={1}>
                    <Row gutter={16}>
                      <Col span={12}>
                        <HeaderItem>DATOS GENERALES</HeaderItem>
                        <BoxItem>
  
                          {/* Campo para Número de Concepto */}
                          <FormItem
                            label="Número de Concepto"
                            name="numeroConcepto"
                            required
                            margin
                            rules={[{ required: true, message: "Ingrese el número de concepto" }]}
                          >
                            <InputItem placeholder="Número de Concepto" />
                          </FormItem>
  
                          <FormItem
                            label="Tipo de Concepto"
                            name="tipo"
                            required
                            margin
                            rules={[{ required: true, message: "Seleccione el tipo" }]}
                          >
                            <SelectItem placeholder="Seleccione el tipo">
                              <Option value={0}>Percepción</Option>
                              <Option value={1}>Deducción</Option>
                              <Option value={2}>Obligación</Option>
                            </SelectItem>
                          </FormItem>
  
                          <FormItem
                            label="Descripción"
                            name="descripcion"
                            required
                            margin
                            rules={[{ required: true, message: "Ingrese la descripción" }]}
                          >
                            <InputItem placeholder="Descripción" />
                          </FormItem>
  
                          <FormItem
                            label="Tipo de Percepción SAT"
                            name="tipoPercepcionSatId"
                            required
                            margin
                            rules={[{ required: true, message: "Seleccione el tipo de percepción SAT" }]}
                          >
                            <SelectItem placeholder="Seleccione el tipo SAT">
                              {this.state.objetotiposPercepcionesSat}
                            </SelectItem>
                          </FormItem>
  
                          <FormItem
                            label="Leyenda"
                            name="leyenda"
                            margin
                          >
                            <TextAreaItem placeholder="Leyenda" />
                          </FormItem>
                        </BoxItem>
                      </Col>
  
                      <Col span={12}>
                        <HeaderItem>CONFIGURACIONES</HeaderItem>
                        <BoxItem>
                          <FormItem
                            label="Automático Global"
                            name="automaticoGlobal"
                            valuePropName="checked"
                            margin
                          >
                            <SwitchItem />
                          </FormItem>
  
                          <FormItem
                            label="Automático Liquidación"
                            name="automaticoLiquidacion"
                            valuePropName="checked"
                            margin
                          >
                            <SwitchItem />
                          </FormItem>
  
                          <FormItem
                            label="Especie"
                            name="especie"
                            valuePropName="checked"
                            margin
                          >
                            <SwitchItem />
                          </FormItem>
  
                          <FormItem
                            label="Imprimir en Recibo"
                            name="imprimir"
                            valuePropName="checked"
                            margin
                          >
                            <SwitchItem />
                          </FormItem>
                        </BoxItem>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </Form>
            </Spin>
          </div>
        </>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      headersToken: state.user.headersToken,
      empresaId: state.empresa.id,
    };
  };
  
  export default connect(mapStateToProps)(AgregarConcepto);
  