import { message, Select, TimePicker } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment"
import {
  Form,
  ModalItem,
  FormItem,
  ButtonItem,
  InputItem,
  ErrorItem,
  SelectItem,
} from "../../../../Components/Items"; // Componentes personalizados

const { Option } = Select;

class EditarTurno extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
    visible: this.props.visible,
    todasJornadas: null,
    objetoTiposJornada: null,
    horas: null,
    minutosEntrada: null,
    minutosSalida: null,
    turno: null, // Estado para almacenar el turno actual
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      this.consultarTiposJornada();
      this.formRef.current.resetFields();
      this.consultarDatosTurno(this.props.turno._id);
    }
  }

  consultarDatosTurno = (id) => {
    axios
      .post(
        "turnos/datosTurno",
        { turnoId: id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          const turno = res.data.turno;
          const minutosEntrada = moment().startOf('day').add(turno.minutosEntrada, 'minutes');
          const minutosSalida = moment().startOf('day').add(turno.minutosSalida, 'minutes');
          const horasCalculadas = this.calcularHoras(minutosEntrada, minutosSalida);

          this.setState({
            turno,
            minutosEntrada,
            minutosSalida,
            horas: horasCalculadas,
          });

          this.formRef.current.setFieldsValue({
            ...turno,
            minutosSalida,
            minutosEntrada
          });

          message.success("Datos del turno obtenidos exitosamente");
        } else {
          message.error("Error al obtener los datos del turno");
        }
      })
      .catch((error) => {
        message.error("Error al consultar los datos del turno");
      });
  };

  consultarTiposJornada = () => {
    this.setState({ loading: true });
    axios
      .post(
        "turnos/selectDatosParaTurno",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        
        if (res.data.success === 2) {
          if (res.data.tiposJornadas && res.data.tiposJornadas.length > 0) {
            this.setState({
              todasJornadas: res.data.tiposJornadas,
              objetoTiposJornada: res.data.tiposJornadas.map((value) => (
                <Option value={value._id} key={value._id}>
                  {value.c_TipoJornada + " - " + value.Descripcion}
                </Option>
              )),
            });
          } else {
            message.error("No se encontraron tipos de jornada");
          }
        } else if (res.data.success === 1) {
          message.error("Error al obtener los tipos de jornada");
        } else {
          message.error("Error desconocido en la solicitud");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.error("Error al consultar los tipos de jornada");
      });
  };

  calcularHoras = (minutosEntrada, minutosSalida) => {
    const entradaEnMinutos = minutosEntrada.hours() * 60 + minutosEntrada.minutes();
    let salidaEnMinutos = minutosSalida.hours() * 60 + minutosSalida.minutes();

    if (salidaEnMinutos < entradaEnMinutos) {
      salidaEnMinutos += 1440; // Sumar 24 horas
    }

    const diferenciaEnMinutos = salidaEnMinutos - entradaEnMinutos;
    const horas = Math.floor(diferenciaEnMinutos / 60);
    const minutos = diferenciaEnMinutos % 60;

    return `${horas} horas ${minutos} minutos`;
  };

  onFinishFailed = (e) => {
    ErrorItem(e);
  };

  onTimeChange = (time, field) => {
    if (field === "minutosEntrada") {
      this.setState({ minutosEntrada: time }, this.actualizarHoras);
    } else {
      this.setState({ minutosSalida: time }, this.actualizarHoras);
    }
  };

  actualizarHoras = () => {
    const { minutosEntrada, minutosSalida } = this.state;
    if (minutosEntrada && minutosSalida) {
      const horasCalculadas = this.calcularHoras(minutosEntrada, minutosSalida);
      this.setState({ horas: horasCalculadas });
    }
  };

  onFinish = (values) => {
    const { minutosEntrada, minutosSalida, horas } = this.state;

    if (!minutosEntrada || !minutosSalida) {
      message.error("Por favor selecciona la hora de entrada y salida");
      return;
    }

    const minutosEntradaMinutos = minutosEntrada.hours() * 60 + minutosEntrada.minutes();
    const minutosSalidaMinutos = minutosSalida.hours() * 60 + minutosSalida.minutes();

    axios
      .post(
        "turnos/editarTurno",
        {
          ...values,
          turnoId: this.state.turno._id,
          minutosEntrada: minutosEntradaMinutos,
          minutosSalida: minutosSalidaMinutos,
          horas,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Turno actualizado exitosamente");
          this.props.cerrarModal();
        } else {
          message.error("Error al actualizar el turno");
        }
      })
      .catch((error) => {
        message.error("Error al actualizar el turno");
      });
  };

  render() {
    return (
      <ModalItem
        title="Editar Turno"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.props.cerrarModal}
        width={800}
      >
        <Form
          ref={this.formRef}
          name="modalEditarTurno"
          layout="vertical"
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
              loading={this.state.loading}
            >
              Guardar
            </ButtonItem>
          }
        >
          <FormItem
            name="codigo"
            label="Código del Turno"
            required
            margin
            rules={[{ required: true, message: "Por favor ingresa el código del turno" }]}
          >
            <InputItem placeholder="Ingresa el código del turno" />
          </FormItem>

          <FormItem
            name="nombre"
            label="Nombre del Turno"
            required
            margin
            rules={[{ required: true, message: "Por favor ingresa el nombre del turno" }]}
          >
            <InputItem placeholder="Ingresa el nombre del turno" />
          </FormItem>

          <FormItem
            label="Hora de Entrada"
            required
            margin
          >
            <TimePicker
              value={this.state.minutosEntrada}
              format="HH:mm"
              onChange={(time) => this.onTimeChange(time, "minutosEntrada")}
            />
          </FormItem>

          <FormItem
            label="Hora de Salida"
            required
            margin
          >
              <TimePicker
                value={this.state.minutosSalida}
                format="HH:mm"
                onChange={(time) => this.onTimeChange(time, "minutosSalida")}
              />
          </FormItem>

          <FormItem label="Horas calculadas" margin>
            
            <InputItem value={this.state.horas} disabled placeholder="Horas calculadas automáticamente" />
          </FormItem>

          <FormItem
            name="tipoJornadaSatId"
            label="Tipo de Jornada"
            required
            margin
            rules={[{ required: true, message: "Por favor selecciona el tipo de jornada" }]}
          >
            <SelectItem placeholder="Selecciona el tipo de jornada">
              {this.state.objetoTiposJornada}
            </SelectItem>
          </FormItem>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => ({
  headersToken: state.user.headersToken,
  empresaId: state.empresa.id,
});

export default connect(mapStateToProps)(EditarTurno);
