import { message, Select, Row, Form as FormInitial, Col } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  ButtonItem,
  InputItem,
  InputNumberItem,
  SelectItem,
  DatePickerItem,
  ErrorItem,
} from "../../../../Components/Items"; // Componentes personalizados

const { Option } = Select;

class AgregarRegistroPatronal extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
    visible: this.props.visible,
    clasesRiesgo: null,
    objetoClasesRiesgo: [],
    fraccionesRiesgo:null,
    ObjetoFraccionesRiesgo: [],
    localidades: [],
    entidadesFederativas: null,
    objetoEntidadesFederativas: [],
    fraccionRiesgoEnabled: false, // Nuevo estado para habilitar el campo de fracción de riesgo
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      this.consultarDatosRiesgo();
      this.consultarLocalidades();
      this.setState({ visible: this.props.visible });
    }
  }

  onFinishFailed = (e) => {
    ErrorItem(e);
  };

  // Consultar las clases y fracciones de riesgo
  consultarDatosRiesgo = (value) => {
    this.setState({ loading: true });
    axios
      .post(
        "registrosPatronales/selectFraccionesClase",
        { empresaId: this.props.empresaId, claseRiesgoId:value },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success) {
          this.setState({
            fraccionesRiesgo: res.data.fraccionesRiesgo,
            ObjetoFraccionesRiesgo: res.data.fraccionesRiesgo.map((actividad)=> (
              <Option value={actividad._id} key={actividad._id}>
                  {actividad.actividad}
                </Option>
            ))
          });
          message.success("Datos de riesgo obtenidos exitosamente");
        } else {
          message.error("No se encontraron datos de riesgo");
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Error al consultar los datos de riesgo");
      });
  };

  // Consultar localidades y entidades federativas
  consultarLocalidades = () => {
    this.setState({ loading: true });
    axios
      .post(
        "registrosPatronales/selectDatosParaRegistro",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          if (res.data.entidadesFederativas && res.data.entidadesFederativas.length > 0) {
            this.setState({
              entidadesFederativas: res.data.entidadesFederativas,
              objetoEntidadesFederativas: res.data.entidadesFederativas.map((entidad) => (
                <Option value={entidad._id} key={entidad._id}>
                  {entidad.nombre}
                </Option>
              )),
              clasesRiesgos: res.data.clasesRiesgos,
              objetoClasesRiesgo: res.data.clasesRiesgos.map((clases) => (
                <Option value={clases._id} key={clases._id}>
                  {clases.Descripcion}
                </Option>
              )),
            });
            message.success("Entidades obtenidas exitosamente");
          } else {
            message.error("No se encontraron Entidades");
          }
        } else {
          message.error("Error al obtener las Entidades");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.error("Error al consultar las Entidades");
      });
  };

  handleClaseRiesgoChange = (value) => {
    this.setState({ fraccionRiesgoEnabled: !!value }, () => {
      if (value) {
        this.consultarDatosRiesgo(value); // Consulta las fracciones de riesgo cuando se selecciona una clase
      }
    });
  };

  onFinish = (values) => {
    this.setState({ loading: true });
    axios
      .post(
        "registrosPatronales/agregarRegistro",
        {
          ...values,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Registro patronal agregado exitosamente");
          this.cerrarModal();
        } else {
          message.error("Error al agregar el registro patronal");
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Error al comunicarse con el servidor");
      });
  };

  cerrarModal = () => {
    this.setState({ visible: false });
    this.props.cerrarModal();
    if (this.formRef.current) {
      this.formRef.current.resetFields();
    }
  };

  render() {
    return (
      <ModalItem
        title="Agregar Registro Patronal"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarModal}
        width={1000}
      >
        <Form
          ref={this.formRef}
          name="modalAgregarRegistroPatronal"
          layout="vertical"
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
              loading={this.state.loading}
            >
              Guardar
            </ButtonItem>
          }
        >
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                name="registroPatronalIMSS"
                label="Registro Patronal IMSS"
                required
                margin
                rules={[{ required: true, message: "Por favor ingresa el registro patronal IMSS" }]}
              >
                <InputItem placeholder="Ingresa el registro patronal IMSS" />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <FormItem name="localidad" label="Localidad" margin>
                <InputItem placeholder="Selecciona la localidad" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="entidadFederativaId"
                label="Entidad Federativa"
                required
                margin
                rules={[{ required: true, message: "Por favor selecciona la entidad federativa" }]}
              >
                <SelectItem placeholder="Selecciona la entidad federativa">
                  {this.state.objetoEntidadesFederativas}
                </SelectItem>
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                name="codigoPostal"
                label="Código Postal"
                margin
                rules={[{ required: true, message: "Por favor ingresa el código postal" }]}
              >
                <InputItem placeholder="Ingresa el código postal" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="claseRiesgoId"
                label="Clase de Riesgo"
                required
                margin
                rules={[{ required: true, message: "Por favor selecciona la clase de riesgo" }]}
              >
                <SelectItem
                  placeholder="Selecciona la clase de riesgo"
                  onChange={this.handleClaseRiesgoChange}
                >
                  {this.state.objetoClasesRiesgo}
                </SelectItem>
              </FormItem>
            </Col>
          </Row>

          <FormInitial.List name="factores">
            {(fields, { add, remove }) => (
              <>
                <table className="table">
                  {fields.length > 0 ? (
                    <thead>
                      <tr>
                        <th className="th-border" style={{ width: "50%" }}>
                          Factor de Riesgo
                        </th>
                        <th className="th-border" style={{ width: "50%" }}>
                          Fecha de inicio
                        </th>
                      </tr>
                    </thead>
                  ) : null}
                  {fields.map(({ key, name, fieldKey, ...restField }, arrayKey) => (
                    <tbody key={key}>
                      <tr>
                        <td className="td-border">
                          <FormItem
                            {...restField}
                            name={[name, "factorRiesgo"]}
                            fieldKey={[arrayKey, "factorRiesgo"]}
                            required
                            noStyle
                          >
                            <InputNumberItem placeholder="Factor de riesgo" style={{ width: "100%" }} />
                          </FormItem>
                        </td>
                        <td className="td-border">
                          <FormItem
                            {...restField}
                            name={[name, "fechaInicio"]}
                            fieldKey={[arrayKey, "fechaInicio"]}
                            noStyle
                            required
                          >
                            <DatePickerItem placeholder="Fecha de inicio" style={{ width: "100%" }} />
                          </FormItem>
                        </td>
                        <td>
                          <MinusCircleOutlined
                            onClick={() => remove(name)}
                            style={{ alignSelf: "center", justifySelf: "center" }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
                <ButtonItem
                  style={{ marginTop: 10, marginBottom: 10 }}
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Agregar Campo
                </ButtonItem>
              </>
            )}
          </FormInitial.List>

          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                name="fraccionRiesgoId"
                label="Fracción de Riesgo"
                required
                margin
                rules={[{ required: true, message: "Por favor selecciona la fracción de riesgo" }]}
              >
                <SelectItem
                  placeholder="Selecciona la fracción de riesgo"
                  disabled={!this.state.fraccionRiesgoEnabled}
                >
                  {this.state.ObjetoFraccionesRiesgo}
                </SelectItem>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => ({
  headersToken: state.user.headersToken,
  empresaId: state.empresa.id,
});

export default connect(mapStateToProps)(AgregarRegistroPatronal);
