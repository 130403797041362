import { message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  ButtonItem,
  InputItem,
  ErrorItem,
} from "../../../../Components/Items";

class EditarPuesto extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
    visible: this.props.visible,
    puesto: this.props.puesto || {},  // Cargar datos iniciales del puesto
  };

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      this.formRef.current.resetFields();
      this.consultarDatosPuesto(this.props.puesto._id);
    }
  }

  onFinishFailed(e) {
    ErrorItem(e);
  }

  consultarDatosPuesto(id) {
    this.setState({ loading: true });
    axios
      .post(
        "puestos/datosPuesto",
        { puestoId: id },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
          message.error("No se encontraron los datos del puesto");
        } else {
          const { codigo, nombre, descripcion } = res.data.puesto;
          this.formRef.current.setFieldsValue({
            codigo,
            nombre,
            descripcion,
          });
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Error al comunicarse con el servidor");
      });
  }

  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "puestos/editarPuesto",
        {
          ...values,
          puestoId: this.props.puesto._id,
        },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Puesto actualizado exitosamente");
          this.cerrarModal();
        } else {
          message.error("No se pudo actualizar el puesto");
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Error al comunicarse con el servidor");
      });
  }

  cerrarModal = () => {
    this.props.cerrarModal();
    if (this.formRef.current) {
      this.formRef.current.resetFields();
    }
  };

  render() {
    return (
      <ModalItem
        title="Editar Puesto"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarModal}
        width={800}
      >
        <Form
          ref={this.formRef}
          name="modalEditarPuesto"
          layout
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
              loading={this.state.loading}
            >
              Guardar
            </ButtonItem>
          }
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          {/* Campo para el código del puesto */}
          <FormItem
            name="codigo"
            label="Número de Puesto"
            required
            margin
            rules={[{ required: true, message: "Por favor ingresa el número del puesto" }]}
          >
            <InputItem placeholder="Ingresa el número del puesto" />
          </FormItem>

          {/* Campo para el nombre del puesto */}
          <FormItem
            name="nombre"
            label="Nombre del Puesto"
            required
            margin
            rules={[{ required: true, message: "Por favor ingresa el nombre del puesto" }]}
          >
            <InputItem placeholder="Ingresa el nombre del puesto" />
          </FormItem>

          {/* Campo para la descripción del puesto */}
          <FormItem
            name="descripcion"
            label="Descripción del Puesto"
            margin
          >
            <InputItem placeholder="Ingresa una breve descripción del puesto" />
          </FormItem>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => ({
  headersToken: state.user.headersToken,
  empresaId: state.empresa.id,
});

export default connect(mapStateToProps)(EditarPuesto);
