import { message, Select, Row, Form as FormInitial, Col } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";
import {
  Form,
  ModalItem,
  FormItem,
  ButtonItem,
  InputItem,
  InputNumberItem,
  SelectItem,
  DatePickerItem,
  ErrorItem,
} from "../../../../Components/Items"; // Componentes personalizados

const { Option } = Select;

class EditarRegistroPatronal extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
    visible: false,
    clasesRiesgo: null,
    objetoClasesRiesgo: [],
    fraccionesRiesgo: null,
    ObjetoFraccionesRiesgo: [],
    localidades: [],
    entidadesFederativas: null,
    objetoEntidadesFederativas: [],
    fraccionRiesgoEnabled: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.visible && this.props.visible !== prevProps.visible) {
      this.setState({ visible: this.props.visible });
      if (this.props.registroPatronal && this.props.registroPatronal._id) {
        console.log("a")
        this.cargarDatosRegistro(this.props.registroPatronal._id);
      }
    }
  }
  
  

  cargarDatosRegistro = (id) => {
    this.setState({ loading: true });
    console.log(id);
    axios
      .post(
        "registrosPatronales/datosRegistro",
        { registroId: id }, // Confirmar que envías correctamente el id
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        console.log(res);
        if (res.data.success) {
          const datos = res.data.registro;
  
          // Configuración de los datos en el formulario, incluyendo la conversión de fechas para Form.List
          this.formRef.current.setFieldsValue({
            ...datos,
            factores: datos.factores.map((factor) => ({
              factorRiesgo: factor.factorRiesgo,
              fechaInicio: moment(factor.fechaInicio), // Asegúrate de que fechaInicio sea un momento válido
            })),
          });
  
          this.consultarLocalidades();
  
          if (datos.claseRiesgoId) {
            this.consultarDatosRiesgo(datos.claseRiesgoId);
          }
        } else {
          message.error("No se encontraron datos para el registro");
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Error al cargar los datos del registro");
      });
  };
  

  consultarDatosRiesgo = (value) => {
    this.setState({ loading: true });
    axios
      .post(
        "registrosPatronales/selectFraccionesClase",
        { empresaId: this.props.empresaId, claseRiesgoId: value },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success) {
          this.setState({
            fraccionesRiesgo: res.data.fraccionesRiesgo,
            ObjetoFraccionesRiesgo: res.data.fraccionesRiesgo.map((actividad) => (
              <Option value={actividad._id} key={actividad._id}>
                {actividad.actividad}
              </Option>
            )),
            fraccionRiesgoEnabled: true,
          });
          message.success("Datos de riesgo obtenidos exitosamente");
        } else {
          message.error("No se encontraron datos de riesgo");
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Error al consultar los datos de riesgo");
      });
  };

  consultarLocalidades = () => {
    this.setState({ loading: true });
    axios
      .post(
        "registrosPatronales/selectDatosParaRegistro",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          this.setState({
            entidadesFederativas: res.data.entidadesFederativas,
            objetoEntidadesFederativas: res.data.entidadesFederativas.map((entidad) => (
              <Option value={entidad._id} key={entidad._id}>
                {entidad.nombre}
              </Option>
            )),
            clasesRiesgos: res.data.clasesRiesgos,
            objetoClasesRiesgo: res.data.clasesRiesgos.map((clase) => (
              <Option value={clase._id} key={clase._id}>
                {clase.Descripcion}
              </Option>
            )),
          });
          message.success("Entidades obtenidas exitosamente");
        } else {
          message.error("No se encontraron Entidades");
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Error al consultar las Entidades");
      });
  };

  handleClaseRiesgoChange = (value) => {
    this.setState({ fraccionRiesgoEnabled: !!value }, () => {
      if (value) {
        this.consultarDatosRiesgo(value);
      }
    });
  };

  onFinishFailed = (e) => {
    ErrorItem(e);
  };

  onFinish = (values) => {
    this.setState({ loading: true });
    axios
      .post(
        "registrosPatronales/editarRegistro",
        {
          ...values,
          registroId: this.props.registroPatronal._id,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success) {
          message.success("Registro patronal editado exitosamente");
          this.cerrarModal();
        } else {
          message.error("Error al editar el registro patronal");
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Error al comunicarse con el servidor");
      });
  };

  cerrarModal = () => {
    this.setState({ visible: false });
    this.props.cerrarModal();
    if (this.formRef.current) {
      this.formRef.current.resetFields();
    }
  };

  render() {
    return (
      <ModalItem
        title="Editar Registro Patronal"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarModal}
        width={1000}
      >
        <Form
          ref={this.formRef}
          name="modalEditarRegistroPatronal"
          layout="vertical"
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
              loading={this.state.loading}
            >
              Guardar Cambios
            </ButtonItem>
          }
        >
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                name="registroPatronalIMSS"
                label="Registro Patronal IMSS"
                required
                rules={[{ required: true, message: "Por favor ingresa el registro patronal IMSS" }]}
              >
                <InputItem placeholder="Ingresa el registro patronal IMSS" />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <FormItem name="localidad" label="Localidad">
                <InputItem placeholder="Selecciona la localidad" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="entidadFederativaId"
                label="Entidad Federativa"
                required
                rules={[{ required: true, message: "Por favor selecciona la entidad federativa" }]}
              >
                <SelectItem placeholder="Selecciona la entidad federativa">
                  {this.state.objetoEntidadesFederativas}
                </SelectItem>
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                name="codigoPostal"
                label="Código Postal"
                rules={[{ required: true, message: "Por favor ingresa el código postal" }]}
              >
                <InputItem placeholder="Ingresa el código postal" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="claseRiesgoId"
                label="Clase de Riesgo"
                required
                rules={[{ required: true, message: "Por favor selecciona la clase de riesgo" }]}
              >
                <SelectItem
                  placeholder="Selecciona la clase de riesgo"
                  onChange={this.handleClaseRiesgoChange}
                >
                  {this.state.objetoClasesRiesgo}
                </SelectItem>
              </FormItem>
            </Col>
          </Row>

          <FormInitial.List name="factores">
            {(fields, { add, remove }) => (
              <>
                <table className="table">
                  {fields.length > 0 ? (
                    <thead>
                      <tr>
                        <th className="th-border" style={{ width: "50%" }}>
                          Factor de Riesgo
                        </th>
                        <th className="th-border" style={{ width: "50%" }}>
                          Fecha de inicio
                        </th>
                      </tr>
                    </thead>
                  ) : null}
                  {fields.map(({ key, name, fieldKey, ...restField }, arrayKey) => (
                    <tbody key={key}>
                      <tr>
                        <td className="td-border">
                          <FormItem
                            {...restField}
                            name={[name, "factorRiesgo"]}
                            fieldKey={[arrayKey, "factorRiesgo"]}
                            required
                            noStyle
                          >
                            <InputNumberItem placeholder="Factor de riesgo" style={{ width: "100%" }} />
                          </FormItem>
                        </td>
                        <td className="td-border">
                          <FormItem
                            {...restField}
                            name={[name, "fechaInicio"]}
                            fieldKey={[arrayKey, "fechaInicio"]}
                            noStyle
                            required
                          >
                            <DatePickerItem placeholder="Fecha de inicio" style={{ width: "100%" }} />
                          </FormItem>
                        </td>
                        <td>
                          <MinusCircleOutlined
                            onClick={() => remove(name)}
                            style={{ alignSelf: "center", justifySelf: "center" }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
                <ButtonItem
                  style={{ marginTop: 10, marginBottom: 10 }}
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Agregar Campo
                </ButtonItem>
              </>
            )}
          </FormInitial.List>

          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                name="fraccionRiesgoId"
                label="Fracción de Riesgo"
                required
                rules={[{ required: true, message: "Por favor selecciona la fracción de riesgo" }]}
              >
                <SelectItem
                  placeholder="Selecciona la fracción de riesgo"
                  disabled={!this.state.fraccionRiesgoEnabled}
                >
                  {this.state.ObjetoFraccionesRiesgo}
                </SelectItem>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => ({
  headersToken: state.user.headersToken,
  empresaId: state.empresa.id,
});

export default connect(mapStateToProps)(EditarRegistroPatronal);
