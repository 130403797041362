import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Col,
  PageHeader,
  Row,
  Spin,
  Form as FormInitial,
  message,
  Select,
  Tabs,
  Tooltip,
} from "antd";
import {
  BoxItem,
  ButtonItem,
  Form,
  HeaderItem,
  InputNumberItem,
  ModalItem,
  SelectItem,
  TextAreaItem,
  FormItem,
  ErrorItem
} from "../../../../Components/Items";
import axios from "axios";
import { CalculatorOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TabPane } = Tabs;

class GenerarOrdenDeCompraConRequisicion extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    objetoProveedores: [],
    objetoInsumosPInsumos: [],
    todasInsumosPInsumos: [],
    objetoProveedoresPInsumos: [],
    todasProveedores: null,
    objetoProyectos: null,
    todasProyectos: null,
    objetoInsumos: null,
    todasInsumos: null,
    impuestos: [],
    numeroRequisicion: 0,
    modalElegirTipoDeProveedor: false,
    modalElegirTipoDeProveedorId: null,
    modalElegirTipoDeProveedorNombre: null,
    valuesForm: null,
    modalConfirmacion: false,
    numOrdenesAGenerar: 0,
    loadingPedidoInteligente: false,
    tipo: 1
  };
  componentDidMount() {
    this.consultarTodo();
  }
  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  async consultarTodo() {
    this.setState({ loading: true });
    const responseProveedores = axios.post(
      "proveedores/selectProveedoresConTipoDeProveedor",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    );

    const responseProyectos = axios.post(
      "proyectos/selectProyectos",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    );

    const responseInsumos = axios.post(
      "insumos/selectInsumosCompra",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    );

    const responses = await Promise.all([
      responseProveedores,
      responseProyectos,
      responseInsumos,
    ]);

    let newState = {};

    if (responses[0].data.status === 404) {
    } else {
      newState.todasProveedores = responses[0].data;
      newState.objetoProveedores = responses[0].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.stringBuscar}
          </Option>
        );
      });
    }

    if (responses[1].data.status === 404) {
    } else {
      newState.todasProyectos = responses[1].data;
      newState.objetoProyectos = responses[1].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.nombre}
          </Option>
        );
      });
    }

    if (responses[2].data.status === 404) {
    } else {
      newState.todasInsumos = responses[2].data;
      newState.objetoInsumos = responses[2].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.stringBuscar}
          </Option>
        );
      });
    }

    await this.promisedSetState(newState);

    let secondState = { loading: false }

    await axios
      .post(
        "requisiciones/datosRequisicion",
        {
          empresaId: this.props.empresaId,
          requisicionId: this.props.location.state.id,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro informacion de la requisición");
        } else {
          this.formRef.current.setFieldsValue({
            ...res.data,
          });
          if (res.data.insumos && res.data?.insumos?.length > 0) {

            let objetoProveedoresPInsumos = res.data.insumos.map(valueInsumo => {

              const insumo = responses[2].data.find(
                (valueInsumos) => valueInsumos._id === valueInsumo.insumoId
              );

              if (!insumo) return (
                <Option value={0} key={0} disabled>
                  Seleccione un insumo para ver sus proveedores
                </Option>
              );

              let objetoProveedores = []

              if (insumo.relaciones && insumo.relaciones?.length > 0) {

                let keysProveedores = []

                for (const relacion of insumo.relaciones) {
                  if (relacion.estado !== 0) {

                    const indexProv = keysProveedores.findIndex(valueKey => valueKey.toString() === relacion.proveedorId.toString())

                    if (indexProv === -1) {
                      keysProveedores.push(relacion.proveedorId.toString())
                    }

                  }
                }

                for (const proveedorId of keysProveedores) {

                  const proveedor = this.state.todasProveedores.find(
                    (valueProveedores) =>
                      valueProveedores._id.toString() === proveedorId.toString()
                  );

                  objetoProveedores.push(
                    <Option value={proveedor._id} key={proveedor._id}>
                      {proveedor.stringBuscar}
                    </Option>
                  )

                }
              }


              return objetoProveedores;

            })

            let objetoInsumosPInsumos = res.data.insumos.map(valueInsumo => {

              const insumo = responses[2].data.find(
                (valueInsumos) => valueInsumos._id === valueInsumo.insumoId
              );


              if (!valueInsumo.proveedorId) return (
                <Option value={0} key={0} disabled>
                  Seleccione un proveedor para ver sus insumos
                </Option>
              )

              let objetoInsumos = []

              if (insumo.relaciones && insumo.relaciones?.length > 0) {


                for (const relacion of insumo.relaciones) {

                  if (relacion.estado !== 0 && relacion.proveedorId.toString() === valueInsumo.proveedorId.toString()) {

                    objetoInsumos.push(
                      <Option value={relacion._id} key={relacion._id}>
                        {relacion.descripcion}
                      </Option>
                    )

                  }
                }
              }


              return objetoInsumos;

            })

            let todasInsumosPInsumos = res.data.insumos.map(valueInsumo => {

              const insumo = responses[2].data.find(
                (valueInsumos) => valueInsumos._id === valueInsumo.insumoId
              );


              if (!valueInsumo.proveedorId) return {}

              let objetoInsumos = []

              if (insumo.relaciones && insumo.relaciones?.length > 0) {


                for (const relacion of insumo.relaciones) {

                  if (relacion.estado !== 0 && relacion.proveedorId.toString() === valueInsumo.proveedorId.toString()) {

                    objetoInsumos.push(relacion)

                  }
                }
              }


              return objetoInsumos;

            })

            secondState.todasInsumosPInsumos = todasInsumosPInsumos
            secondState.objetoInsumosPInsumos = objetoInsumosPInsumos
            secondState.objetoProveedoresPInsumos = objetoProveedoresPInsumos

          }
          secondState.numeroRequisicion = res.data.numero
        }
      });

    await this.promisedSetState(secondState);
  }
  atras() {
    this.props.history.goBack();
  }

  onFinishFailed(e) {
    ErrorItem(e);
  }

  confimacionGenerar(tipo) {

    this.formRef.current.validateFields().then((values) => {

      let proveedores = []

      let cantidades = false

      if(tipo === 1){

        for (const valueInsumo of values.insumos) {
          const proveedor = proveedores.find((valueTodos) => valueTodos.toString() === valueInsumo.proveedorId.toString())
  
          if (!proveedor && valueInsumo.pedir && valueInsumo?.pedir > 0) {
            cantidades = true
            proveedores.push(valueInsumo.proveedorId.toString())
          }
        }

      }else if(tipo === 2){

        for (const valueInsumo of values.insumosInteligente) {
          const proveedor = proveedores.find((valueTodos) => valueTodos.toString() === valueInsumo.proveedorId.toString())
  
          if (!proveedor && valueInsumo.pedir && valueInsumo?.pedir > 0) {
            cantidades = true
            proveedores.push(valueInsumo.proveedorId.toString())
          }
        }

      }else if(tipo === 3){

        for (const valueInsumo of values.insumosProveedorDefault) {
          const proveedor = proveedores.find((valueTodos) => valueTodos.toString() === valueInsumo.proveedorId.toString())
  
          if (!proveedor && valueInsumo.pedir && valueInsumo?.pedir > 0) {
            cantidades = true
            proveedores.push(valueInsumo.proveedorId.toString())
          }
        }

      }

      if (!cantidades) {
        return message.info('Agregue cantidades para generar las ordenes de compra')
      }

      this.setState({ modalConfirmacion: true, valuesForm: values, numOrdenesAGenerar: proveedores.length, tipo })

    }).catch((e) => this.onFinishFailed(e))
  }

  onChangeProveedor(value, key) {


    const insumos = this.formRef.current.getFieldValue('insumos')
    const insumoArray = insumos[key]

    if (!insumoArray) return

    const insumo = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumoArray.insumoId.toString())

    if (!insumo) return

    let relacionesObject = []
    let todasRelaciones = []
    const objetoInsumosPInsumos = [...this.state.objetoInsumosPInsumos]
    const todasInsumosPInsumos = [...this.state.todasInsumosPInsumos]

    if (insumo?.relaciones && insumo?.relaciones?.length > 0) {

      for (const relacion of insumo.relaciones) {

        if (relacion.estado !== 0 && relacion.proveedorId.toString() === value.toString()) {

          relacionesObject.push(
            <Option value={relacion._id} key={relacion._id}>
              {relacion.descripcion}
            </Option>
          )

          todasRelaciones.push(relacion)

        }

      }

    }

    objetoInsumosPInsumos[key] = relacionesObject
    todasInsumosPInsumos[key] = todasRelaciones


    this.setState({ objetoInsumosPInsumos, todasInsumosPInsumos })

  }

  onChangeInsumoP(value, key) {

    let insumos = this.formRef.current.getFieldValue('insumos')
    const insumoArray = insumos[key]

    if (!insumoArray) return

    const relacion = this.state.todasInsumosPInsumos[key].find(valueTR => valueTR._id.toString() === value.toString())

    if (!relacion) return

    insumos[key].conversion = relacion.conversion

    this.formRef.current.setFieldsValue({ insumos })

  }

  pedidoInteligente() {
    this.setState({ loadingPedidoInteligente: true })
    axios
      .post(
        "requisiciones/pedidoInteligente",
        {
          requisicionId: this.props.location.state.id,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          this.formRef.current.setFieldsValue({ insumosInteligente: res.data.insumosInteligente })
          this.setState({
            loadingPedidoInteligente: false, objetoInsumosPInsumos: res.data.insumosInteligente.map(valueII => {

              const insumo = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === valueII.insumoId.toString())

              if (!insumo) return null

              let relacionesObject = []


              if (insumo?.relaciones && insumo?.relaciones?.length > 0) {

                for (const relacion of insumo.relaciones) {

                  if (relacion.estado !== 0 && relacion.proveedorId.toString() === valueII.proveedorId.toString()) {

                    relacionesObject.push(
                      <Option value={relacion._id} key={relacion._id}>
                        {relacion.descripcion}
                      </Option>
                    )

                  }

                }

              }

              return relacionesObject

            })
          });
          message.success("Se ha cargado el pedido inteligente");
        } else if (res.data.success === 1) {
          this.setState({ loadingPedidoInteligente: false });
          message.error("Hubo un error y no se cargo el pedido inteligente");
        } else {
          this.setState({ loadingPedidoInteligente: false });
          message.error("Hubo un error y no se cargo el pedido inteligente");
        }
      });
  }

  pedirTotalesInteligente() {
    const insumosInteligente = this.formRef.current.getFieldValue('insumosInteligente')

    if (!insumosInteligente) return


    const nuevosInsumosI = insumosInteligente.map(valueII => {
      return {
        ...valueII,
        pedir: valueII.cantidad
      }
    })

    this.formRef.current.setFieldsValue({ insumosInteligente: nuevosInsumosI })

  }

  pedidoProveedorDefault() {
    this.setState({ loadingPedidoProveedorDefault: true })
    axios
      .post(
        "requisiciones/pedidoProveedorDefault",
        {
          requisicionId: this.props.location.state.id,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          this.formRef.current.setFieldsValue({ insumosProveedorDefault: res.data.insumosProveedorDefault })
          this.setState({
            loadingPedidoProveedorDefault: false, objetoInsumosPInsumos: res.data.insumosProveedorDefault.map(valueII => {

              const insumo = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === valueII.insumoId.toString())

              if (!insumo) return null

              let relacionesObject = []


              if (insumo?.relaciones && insumo?.relaciones?.length > 0) {

                for (const relacion of insumo.relaciones) {

                  if (relacion.estado !== 0 && relacion.proveedorId.toString() === valueII.proveedorId.toString()) {

                    relacionesObject.push(
                      <Option value={relacion._id} key={relacion._id}>
                        {relacion.descripcion}
                      </Option>
                    )

                  }

                }

              }

              return relacionesObject

            })
          });
          message.success("Se ha cargado el pedido con proveedor default");
        } else if (res.data.success === 1) {
          this.setState({ loadingPedidoProveedorDefault: false });
          message.error("Hubo un error y no se cargo el pedido con proveedor default");
        } else {
          this.setState({ loadingPedidoProveedorDefault: false });
          message.error("Hubo un error y no se cargo el pedido con proveedor default");
        }
      });
  }

  pedirTotalesDefault() {
    const insumosProveedorDefault = this.formRef.current.getFieldValue('insumosProveedorDefault')

    if (!insumosProveedorDefault) return


    const nuevosInsumosPD = insumosProveedorDefault.map(valueII => {
      return {
        ...valueII,
        pedir: valueII.cantidad
      }
    })

    this.formRef.current.setFieldsValue({ insumosProveedorDefault: nuevosInsumosPD })

  }


  generarOrdenManual(values) {
    for (const valueInsumo of values.insumos) {
      if (valueInsumo.cantidad && !valueInsumo.proveedorId) {
        return message.info('Seleccione los proveedores de los insumos que requiere')
      }
    }

    this.setState({ loading: true });

    axios
      .post(
        "requisiciones/generarOrdenesDeCompra",
        {
          ...values,
          requisicionId: this.props.location.state.id,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se han generado las ordenes de compra");
          this.formRef.current.resetFields();
          this.atras();
        } else if (res.data.success === 1) {
          message.error("Hubo un error y no se generaron las ordenes de compra");
        } else {
          message.error("Hubo un error y no se generaron las ordenes de compra");
        }
      });
  }

  generarOrdenInteligente(values) {
    for (const valueInsumo of values.insumosInteligente) {
      if (valueInsumo.cantidad && !valueInsumo.proveedorId) {
        return message.info('Seleccione los proveedores de los insumos que requiere')
      }
    }

    this.setState({ loading: true });

    axios
      .post(
        "requisiciones/generarOrdenesDeCompraInteligente",
        {
          ...values,
          insumos: values.insumosInteligente,
          requisicionId: this.props.location.state.id,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se han generado las ordenes de compra");
          this.formRef.current.resetFields();
          this.atras();
        } else if (res.data.success === 1) {
          message.error("Hubo un error y no se generaron las ordenes de compra");
        } else {
          message.error("Hubo un error y no se generaron las ordenes de compra");
        }
      });
  }

  generarOrdenDefault(values) {
    for (const valueInsumo of values.insumosProveedorDefault) {
      if (valueInsumo.cantidad && !valueInsumo.proveedorId) {
        return message.info('Seleccione los proveedores de los insumos que requiere')
      }
    }

    this.setState({ loading: true });

    axios
      .post(
        "requisiciones/generarOrdenesDeCompraProveedorDefault",
        {
          ...values,
          insumos: values.insumosProveedorDefault,
          requisicionId: this.props.location.state.id,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se han generado las ordenes de compra");
          this.formRef.current.resetFields();
          this.atras();
        } else if (res.data.success === 1) {
          message.error("Hubo un error y no se generaron las ordenes de compra");
        } else {
          message.error("Hubo un error y no se generaron las ordenes de compra");
        }
      });

  }

  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title={"Generar Orden De Compra - Requisicion #" + this.state.numeroRequisicion}
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="generarOrdenDeCompraConRequisicion"
              layout
              onFinishFailed={this.onFinishFailed}
            >
              <Row>
                <Col span={18}>
                  <HeaderItem>DATOS GENERALES</HeaderItem>
                  <BoxItem>
                    <Row>
                      <Col span={12}>
                        <FormItem
                          label="Proyecto"
                          name="proyectoId"
                          margin
                          required
                        >
                          <SelectItem disabled placeholder="Proyecto">
                            {this.state.objetoProyectos}
                          </SelectItem>
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem shouldUpdate noStyle>
                          {({ getFieldValue }) => {
                            let proyectoId = getFieldValue("proyectoId");
                            if (!proyectoId)
                              return <p>Seleccione un proyecto primero</p>;
                            let proyecto = this.state.todasProyectos.find(
                              (value) => value._id === proyectoId
                            );
                            return (
                              <>
                                <Row>
                                  <Col span={12}>
                                    <h1 style={{ margin: 0 }}>
                                      Nombre: {proyecto.nombre}
                                    </h1>
                                    <p>Descripcion: {proyecto.descripcion}</p>
                                  </Col>
                                  <Col span={12}>
                                    <h1 style={{ margin: 0 }}>
                                      Dirección de envio
                                    </h1>
                                    {proyecto.calle ? (
                                      <>
                                        <p style={{ margin: 0 }}>
                                          Calle: {proyecto.calle}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                          Numero: {proyecto.numeroCalle}
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        <p style={{ margin: 0 }}>
                                          Marca: {proyecto.marca}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                          Modelo: {proyecto.modelo}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                          Placas: {proyecto.placas}
                                        </p>
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              </>
                            );
                          }}
                        </FormItem>
                      </Col>
                    </Row>
                    <Row></Row>
                  </BoxItem>
                </Col>
                <Col span={6}>
                  <HeaderItem>EXTRAS</HeaderItem>
                  <BoxItem>
                    <FormItem
                      name="observaciones"
                      margin
                      labelCol={{ span: 3 }}
                      wrapperCol={{ span: 21 }}
                      pattern
                    >
                      <TextAreaItem disabled placeholder="Observaciones" />
                    </FormItem>
                  </BoxItem>
                </Col>
              </Row>
              <HeaderItem>INSUMOS</HeaderItem>

              <BoxItem>


                <Tabs onTabClick={(key) => {
                  if (key === '2') {
                    this.pedidoInteligente()
                  }

                  if (key === '3') {
                    this.pedidoProveedorDefault()
                  }
                }}>

                  <TabPane tab='Manual' key='1' forceRender>

                    <Spin spinning={false}>

                      <FormInitial.List name="insumos">
                        {(fields) => (
                          <>
                            <table className="table">
                              {fields.length > 0 ? (
                                <thead>
                                  <tr>
                                    <th
                                      className="th-border"
                                      style={{ width: "20%" }}
                                    >
                                      Insumo
                                    </th>
                                    <th
                                      className="th-border"
                                      style={{ width: "10%" }}
                                    >
                                      Cantidad
                                    </th>
                                    <th
                                      className="th-border"
                                      style={{ width: "20%" }}
                                    >
                                      Proveedor
                                    </th>
                                    <th
                                      className="th-border"
                                      style={{ width: "20%" }}
                                    >
                                      Insumo Proveedor
                                    </th>
                                    <th
                                      className="th-border"
                                      style={{ width: "20%" }}
                                    >
                                      Conversion
                                    </th>
                                    <th
                                      className="th-border"
                                      style={{ width: "10%" }}
                                    >
                                      Cantidad A Pedir
                                    </th>
                                  </tr>
                                </thead>
                              ) : null}
                              {fields.map(
                                ({ key, name, fieldKey, ...restField }, arrayKey) => (
                                  <tbody key={arrayKey}>
                                    <FormItem noStyle shouldUpdate>
                                      {({ getFieldValue }) => {
                                        const insumos = getFieldValue("insumos");
                                        const insumo = insumos[arrayKey];
                                        const conversion = insumo?.conversion ? insumo.conversion : 1

                                        let maximoConversion

                                        if (insumo.cantidad) {

                                          const cantidadOrdenada = insumo.cantidadOrdenada || 0

                                          if(cantidadOrdenada > insumo.cantidad){
                                            maximoConversion = 0
                                          }else{

                                            const residuoConversion = (insumo.cantidad - insumo.cantidadOrdenada) % conversion
  
                                            if (residuoConversion !== 0) {
  
                                              maximoConversion = (Math.floor((insumo.cantidad - cantidadOrdenada) / conversion) + 1)
  
                                            } else {
  
                                              maximoConversion = ((insumo.cantidad - cantidadOrdenada) / conversion)
  
                                            }
                                          }

                                        }

                                        return (
                                          <tr>
                                            <td className="td-border">
                                              <FormItem
                                                {...restField}
                                                name={[name, "insumoId"]}
                                                noStyle
                                              >
                                                <SelectItem
                                                  sinBorde
                                                  autoFocus
                                                  style={{ width: "100%" }}
                                                  placeholder="Insumo"
                                                  disabled
                                                >
                                                  {this.state.objetoInsumos}
                                                </SelectItem>
                                              </FormItem>
                                            </td>
                                            <td className="td-border">
                                              <FormItem
                                                {...restField}
                                                name={[name, "cantidad"]}
                                                number
                                                noStyle
                                              >
                                                <InputNumberItem
                                                  sinBorde
                                                  placeholder="Cantidad"
                                                  disabled
                                                />
                                              </FormItem>
                                            </td>
                                            <td className="td-border">
                                              <FormItem
                                                {...restField}
                                                name={[name, "proveedorId"]}
                                                margin
                                                noStyle
                                              >
                                                <SelectItem
                                                  sinBorde
                                                  style={{ width: "100%" }}
                                                  placeholder="Proveedor"
                                                  onChange={(value) => this.onChangeProveedor(value, arrayKey)}
                                                >
                                                  {this.state.objetoProveedoresPInsumos[arrayKey]}
                                                </SelectItem>
                                              </FormItem>
                                            </td>
                                            <td className="td-border">
                                              <FormItem
                                                {...restField}
                                                name={[name, "insumoProveedorId"]}
                                                noStyle
                                              >
                                                <SelectItem
                                                  sinBorde
                                                  autoFocus
                                                  style={{ width: "100%" }}
                                                  placeholder="Insumo Proveedor"
                                                  onChange={(value) => this.onChangeInsumoP(value, arrayKey)}
                                                >
                                                  {this.state.objetoInsumosPInsumos[arrayKey]}
                                                </SelectItem>
                                              </FormItem>
                                            </td>
                                            <td className="td-border">
                                              <FormItem
                                                {...restField}
                                                name={[name, "conversion"]}
                                                numberCero
                                                type="number"
                                                noStyle
                                              >
                                                <InputNumberItem
                                                  sinBorde
                                                  disabled
                                                  placeholder="Conversion"
                                                />
                                              </FormItem>
                                            </td>
                                            <td className="td-border">
                                              <FormItem
                                                {...restField}
                                                name={[name, "pedir"]}
                                                max={maximoConversion}
                                                numberCero
                                                type="number"
                                                noStyle
                                              >
                                                <InputNumberItem
                                                  sinBorde
                                                  placeholder={"Max: " + (maximoConversion)}
                                                />
                                              </FormItem>
                                            </td>
                                          </tr>
                                        );
                                      }}
                                    </FormItem>
                                  </tbody>
                                )
                              )}
                            </table>
                          </>
                        )}
                      </FormInitial.List>

                      <ButtonItem
                        type="primary"
                        onClick={() => this.confimacionGenerar(1)}
                        style={{ display: "block", margin: "10px 0 0 auto" }}
                      >
                        Generar
                      </ButtonItem>

                    </Spin>

                  </TabPane>

                  <TabPane tab='Pedido Inteligente' key='2' forceRender>

                    <Spin spinning={this.state.loadingPedidoInteligente}>
                      <FormInitial.List name="insumosInteligente">
                        {(fields) => (
                          <>
                            <table className="table">
                              {fields.length > 0 ? (
                                <thead>
                                  <tr>
                                    <th
                                      className="th-border"
                                      style={{ width: "20%" }}
                                    >
                                      Insumo
                                    </th>
                                    <th
                                      className="th-border"
                                      style={{ width: "10%" }}
                                    >
                                      Cantidad
                                    </th>
                                    <th
                                      className="th-border"
                                      style={{ width: "15%" }}
                                    >
                                      Proveedor
                                    </th>
                                    <th
                                      className="th-border"
                                      style={{ width: "20%" }}
                                    >
                                      Insumo Proveedor
                                    </th>
                                    <th
                                      className="th-border"
                                      style={{ width: "10%" }}
                                    >
                                      Conversion
                                    </th>
                                    <th
                                      className="th-border"
                                      style={{ width: "10%" }}
                                    >
                                      Cantidad Pedir
                                    </th>
                                    <th
                                      className="th-border"
                                      style={{ width: "15%" }}
                                    >
                                      Cantidad A Pedir
                                      <Tooltip title='Totales'>
                                        <CalculatorOutlined onClick={() => this.pedirTotalesInteligente()} style={{ marginLeft: '5px', color: '#00b5e2' }} />
                                      </Tooltip>
                                    </th>
                                  </tr>
                                </thead>
                              ) : null}
                              {fields.map(
                                ({ key, name, fieldKey, ...restField }, arrayKey) => (
                                  <tbody key={arrayKey}>
                                    <FormItem noStyle shouldUpdate>
                                      {({ getFieldValue }) => {
                                        const insumos = getFieldValue("insumosInteligente");
                                        const insumo = insumos[arrayKey];

                                        let maximoConversion = insumo.cantidad

                                        return (
                                          <tr>
                                            <td className="td-border">
                                              <FormItem
                                                {...restField}
                                                name={[name, "insumoId"]}
                                                noStyle
                                              >
                                                <SelectItem
                                                  sinBorde
                                                  autoFocus
                                                  style={{ width: "100%" }}
                                                  placeholder="Insumo"
                                                  disabled
                                                >
                                                  {this.state.objetoInsumos}
                                                </SelectItem>
                                              </FormItem>
                                            </td>
                                            <td className="td-border">
                                              <FormItem
                                                {...restField}
                                                name={[name, "cantidadInsumo"]}
                                                number
                                                noStyle
                                              >
                                                <InputNumberItem
                                                  sinBorde
                                                  placeholder="Cantidad"
                                                  disabled
                                                />
                                              </FormItem>
                                            </td>
                                            <td className="td-border">
                                              <FormItem
                                                {...restField}
                                                name={[name, "proveedorId"]}
                                                margin
                                                noStyle
                                              >
                                                <SelectItem
                                                  sinBorde
                                                  disabled
                                                  style={{ width: "100%" }}
                                                  placeholder="Proveedor"
                                                >
                                                  {this.state.objetoProveedores}
                                                </SelectItem>
                                              </FormItem>
                                            </td>
                                            <td className="td-border">
                                              <FormItem
                                                {...restField}
                                                name={[name, "insumoProveedorId"]}
                                                noStyle
                                              >
                                                <SelectItem
                                                  sinBorde
                                                  autoFocus
                                                  style={{ width: "100%" }}
                                                  placeholder="Insumo Proveedor"
                                                  disabled
                                                >
                                                  {this.state.objetoInsumosPInsumos[arrayKey]}
                                                </SelectItem>
                                              </FormItem>
                                            </td>
                                            <td className="td-border">
                                              <FormItem
                                                {...restField}
                                                name={[name, "conversion"]}
                                                numberCero
                                                type="number"
                                                noStyle
                                              >
                                                <InputNumberItem
                                                  sinBorde
                                                  disabled
                                                  placeholder="Conversion"
                                                />
                                              </FormItem>
                                            </td>
                                            <td className="td-border">
                                              <FormItem
                                                {...restField}
                                                name={[name, "cantidad"]}
                                                required
                                                number
                                                noStyle
                                              >
                                                <InputNumberItem
                                                  sinBorde
                                                  placeholder="Cantidad Pedir"
                                                  disabled
                                                />
                                              </FormItem>
                                            </td>
                                            <td className="td-border">
                                              <FormItem
                                                {...restField}
                                                name={[name, "pedir"]}
                                                max={maximoConversion}
                                                numberCero
                                                type="number"
                                                noStyle
                                              >
                                                <InputNumberItem
                                                  sinBorde
                                                  placeholder={"Max: " + (maximoConversion)}
                                                />
                                              </FormItem>
                                            </td>
                                          </tr>
                                        );
                                      }}
                                    </FormItem>
                                  </tbody>
                                )
                              )}
                            </table>
                          </>
                        )}
                      </FormInitial.List>

                      <ButtonItem
                        type="primary"
                        onClick={() => this.confimacionGenerar(2)}
                        style={{ display: "block", margin: "10px 0 0 auto" }}
                      >
                        Generar
                      </ButtonItem>

                    </Spin>

                  </TabPane>

                  <TabPane tab='Proveedor Default' key='3' forceRender>

                    <Spin spinning={this.state.loadingPedidoProveedorDefault}>
                      <FormInitial.List name="insumosProveedorDefault">
                      {(fields) => (
                          <>
                            <table className="table">
                              {fields.length > 0 ? (
                                <thead>
                                  <tr>
                                    <th
                                      className="th-border"
                                      style={{ width: "20%" }}
                                    >
                                      Insumo
                                    </th>
                                    <th
                                      className="th-border"
                                      style={{ width: "10%" }}
                                    >
                                      Cantidad
                                    </th>
                                    <th
                                      className="th-border"
                                      style={{ width: "15%" }}
                                    >
                                      Proveedor
                                    </th>
                                    <th
                                      className="th-border"
                                      style={{ width: "20%" }}
                                    >
                                      Insumo Proveedor
                                    </th>
                                    <th
                                      className="th-border"
                                      style={{ width: "10%" }}
                                    >
                                      Conversion
                                    </th>
                                    <th
                                      className="th-border"
                                      style={{ width: "10%" }}
                                    >
                                      Cantidad Pedir
                                    </th>
                                    <th
                                      className="th-border"
                                      style={{ width: "15%" }}
                                    >
                                      Cantidad A Pedir
                                      <Tooltip title='Totales'>
                                        <CalculatorOutlined onClick={() => this.pedirTotalesDefault()} style={{ marginLeft: '5px', color: '#00b5e2' }} />
                                      </Tooltip>
                                    </th>
                                  </tr>
                                </thead>
                              ) : null}
                              {fields.map(
                                ({ key, name, fieldKey, ...restField }, arrayKey) => (
                                  <tbody key={arrayKey}>
                                    <FormItem noStyle shouldUpdate>
                                      {({ getFieldValue }) => {
                                        const insumos = getFieldValue("insumosProveedorDefault");
                                        const insumo = insumos[arrayKey];

                                        let maximoConversion = insumo.cantidad

                                        return (
                                          <tr>
                                            <td className="td-border">
                                              <FormItem
                                                {...restField}
                                                name={[name, "insumoId"]}
                                                noStyle
                                              >
                                                <SelectItem
                                                  sinBorde
                                                  autoFocus
                                                  style={{ width: "100%" }}
                                                  placeholder="Insumo"
                                                  disabled
                                                >
                                                  {this.state.objetoInsumos}
                                                </SelectItem>
                                              </FormItem>
                                            </td>
                                            <td className="td-border">
                                              <FormItem
                                                {...restField}
                                                name={[name, "cantidadInsumo"]}
                                                number
                                                noStyle
                                              >
                                                <InputNumberItem
                                                  sinBorde
                                                  placeholder="Cantidad"
                                                  disabled
                                                />
                                              </FormItem>
                                            </td>
                                            <td className="td-border">
                                              <FormItem
                                                {...restField}
                                                name={[name, "proveedorId"]}
                                                margin
                                                noStyle
                                              >
                                                <SelectItem
                                                  sinBorde
                                                  disabled
                                                  style={{ width: "100%" }}
                                                  placeholder="Proveedor"
                                                >
                                                  {this.state.objetoProveedores}
                                                </SelectItem>
                                              </FormItem>
                                            </td>
                                            <td className="td-border">
                                              <FormItem
                                                {...restField}
                                                name={[name, "insumoProveedorId"]}
                                                noStyle
                                              >
                                                <SelectItem
                                                  sinBorde
                                                  autoFocus
                                                  style={{ width: "100%" }}
                                                  placeholder="Insumo Proveedor"
                                                  disabled
                                                >
                                                  {this.state.objetoInsumosPInsumos[arrayKey]}
                                                </SelectItem>
                                              </FormItem>
                                            </td>
                                            <td className="td-border">
                                              <FormItem
                                                {...restField}
                                                name={[name, "conversion"]}
                                                numberCero
                                                type="number"
                                                noStyle
                                              >
                                                <InputNumberItem
                                                  sinBorde
                                                  disabled
                                                  placeholder="Conversion"
                                                />
                                              </FormItem>
                                            </td>
                                            <td className="td-border">
                                              <FormItem
                                                {...restField}
                                                name={[name, "cantidad"]}
                                                required
                                                number
                                                noStyle
                                              >
                                                <InputNumberItem
                                                  sinBorde
                                                  placeholder="Cantidad Pedir"
                                                  disabled
                                                />
                                              </FormItem>
                                            </td>
                                            <td className="td-border">
                                              <FormItem
                                                {...restField}
                                                name={[name, "pedir"]}
                                                max={maximoConversion}
                                                numberCero
                                                type="number"
                                                noStyle
                                              >
                                                <InputNumberItem
                                                  sinBorde
                                                  placeholder={"Max: " + (maximoConversion)}
                                                />
                                              </FormItem>
                                            </td>
                                          </tr>
                                        );
                                      }}
                                    </FormItem>
                                  </tbody>
                                )
                              )}
                            </table>
                          </>
                        )}
                      </FormInitial.List>


                      <ButtonItem
                        type="primary"
                        onClick={() => this.confimacionGenerar(3)}
                        style={{ display: "block", margin: "10px 0 0 auto" }}
                      >
                        Generar
                      </ButtonItem>

                    </Spin>


                  </TabPane>

                </Tabs>
              </BoxItem>
            </Form>
          </Spin>
        </div>
        <ModalItem
          title="Confirmacion"
          visible={this.state.modalConfirmacion}
          onCancel={() => this.setState({ modalConfirmacion: false })}
          onOk={() => {
            this.setState({ modalConfirmacion: false });
            if (this.state.tipo === 1) {
              this.generarOrdenManual(this.state.valuesForm);
            } else if (this.state.tipo === 2) {
              this.generarOrdenInteligente(this.state.valuesForm);
            } else if (this.state.tipo === 3) {
              this.generarOrdenDefault(this.state.valuesForm);
            }
          }}
          spinning={false}
          footer
        >
          <h1 style={{ textAlign: "center" }}>Desea continuar</h1>
          <p style={{ textAlign: "center" }}>Se generaran {this.state.numOrdenesAGenerar} ordenes de compra</p>
        </ModalItem>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(GenerarOrdenDeCompraConRequisicion);
