import { PlusOutlined } from "@ant-design/icons";
import { message, Input, Select, Checkbox } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  InputNumberItem,
  SelectItem,
  cuentaMask,
  TextAreaItem,
  InputItem,
  ButtonItem,
  ErrorItem
} from "../../../../Components/Items";
import AgregarCuentaContable from "../../CuentasContables/AgregarCuentaContable/AgregarCuentaContable";

const { Option } = Select;

class EditarBanco extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    objetoBancosSat: null,
    todasBancosSat: null,
    objetoCuentaContable: null,
    todasCuentaContable: null,
    objetoMonedas: null,
    todasMonedas: null,
    utilizado: false
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "cuentascontables/selectCuentasContablesFinales",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasCuentaContable: res.data });
          this.setState({
            objetoCuentaContable: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {cuentaMask(
                    value.nivel1 +
                      value.nivel2 +
                      value.nivel3 +
                      value.nivel4 +
                      value.nivel5
                  ) +
                    " - " +
                    value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "bancosSat/selectBancosSat",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasBancosSat: res.data });
          this.setState({
            objetoBancosSat: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.clave + " - " + value.nombreCorto}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post("monedas/selectMonedas", null, {
        headers: this.props.headersToken,
      })
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasMonedas: res.data });
          this.setState({
            objetoMonedas: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.abreviacion}
                </Option>
              );
            }),
          });
        }
      });
      await axios
      .post(
        "bancos/datosBanco",
        { empresaId: this.props.empresaId, bancoId: this.props.id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro informacion del banco");
        } else {
          if(res.data.utilizado){
            message.info('No se puede modificar toda la informacion ya quel banco ya fue utilizado')
          }
          this.formRef.current.setFieldsValue({
            ...res.data,
          });
          this.setState({
            utilizado: res.data.utilizado,
          })
        }
      });
    this.setState({ loading: false });
  }
  cerrarEditarBanco(codigo) {
    this.props.cerrarEditarBanco(codigo);
  }
  onFinishFailed(e) {
   ErrorItem(e);
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "bancos/editarBanco",
        { ...values, empresaId: this.props.empresaId, bancoId: this.props.id },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se edito un banco");
          this.formRef.current.resetFields();
          this.cerrarEditarBanco();
        } else {
          message.error("Sucedio un error y no se edito");
        }
      });
  }
  abrirAgregarCuentaContable() {
    this.setState({ modalAgregarCuentaContable: true });
  }
  cerrarAgregarCuentaContable(codigo) {
    this.setState({ modalAgregarCuentaContable: false });
    this.consultarTodo();
  }
  render() {
    return (
      <>
      <ModalItem
        title="Editar Banco"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarEditarBanco.bind(this)}
        paddingTop
      >
        <Form
          ref={this.formRef}
          name="editarBanco"
          layout
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
            >
              Editar
            </ButtonItem>
          }
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          <FormItem name="nombre" label="Nombre" required margin pattern>
            <InputItem placeholder="Nombre" />
          </FormItem>
          <FormItem name="banco" label="Banco" margin pattern required>
            <InputItem placeholder="Banco" />
          </FormItem>
          <FormItem label="Moneda" name="monedaId" required margin>
            <SelectItem placeholder="Moneda">
              {this.state.objetoMonedas}
            </SelectItem>
          </FormItem>
          <FormItem label="Banco SAT" name="bancoSatId" required margin>
            <SelectItem placeholder="Banco SAT">
              {this.state.objetoBancosSat}
            </SelectItem>
          </FormItem>
          <FormItem
            label="Cuenta Clabe"
            name="cuentaClabe"
            number
            max={18}
            min={18}
            margin
          >
            <InputItem placeholder="Cuenta Clabe" />
          </FormItem>
          <FormItem label="Cuenta Contable" margin>
            <Input.Group compact>
              <FormItem name="cuentaContableId" required noStyle>
                <SelectItem placeholder="Cuenta Contable" width="80%" disabled={this.state.utilizado}>
                  {this.state.objetoCuentaContable}
                </SelectItem>
              </FormItem>
              <ButtonItem
                disabled={this.state.utilizado}
                icon={<PlusOutlined />}
                style={{ width: "20%" }}
                onClick={this.abrirAgregarCuentaContable.bind(this)}
              />
            </Input.Group>
          </FormItem>
          <FormItem
            number
            margin
            label="Consecutivo Cheque"
            name="consecutivoCheque"
          >
            <InputNumberItem placeholder="Consecutivo Cheque" />
          </FormItem>
          <FormItem
            label="Es inversion"
            name="inversion"
            margin
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox></Checkbox>
          </FormItem>
          <FormItem
            name="observaciones"
            label="Observaciones"
            margin
            pattern
          >
            <TextAreaItem placeholder="Observaciones" />
          </FormItem>
        </Form>
      </ModalItem>
      <AgregarCuentaContable
          visible={this.state.modalAgregarCuentaContable}
          cerrarAgregarCuentaContable={this.cerrarAgregarCuentaContable.bind(
            this
          )}
        />
        </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(EditarBanco);
