import { message, DatePicker, Select, Row, Col } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  ButtonItem,
  InputItem,
  ErrorItem,
  SelectItem,
} from "../../../../Components/Items"; // Componentes personalizados

const { Option } = Select;

class AgregarTipoPeriodo extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
    visible: this.props.visible,
    periocidadesSat: null, // Estado para almacenar los tipos de periocidades
    objetoPeriocidades: null,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      this.consultarPeriocidadesSat();
      this.setState({ visible: this.props.visible });
    }
  }

  onFinishFailed = (e) => {
    ErrorItem(e);
  };

  consultarPeriocidadesSat = () => {
    this.setState({ loading: true });
    axios
      .post(
        "tiposPeriodos/selectDatosParaTipoPeriodo",
        {},
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          if (res.data.periocidadesSat && res.data.periocidadesSat.length > 0) {
            this.setState({
              periocidadesSat: res.data.periocidadesSat,
              objetoPeriocidades: res.data.periocidadesSat.map((value) => (
                <Option value={value._id} key={value._id}>
                  {value.Descripcion}
                </Option>
              )),
            });
            message.success("Periocidades obtenidas exitosamente");
          } else {
            message.error("No se encontraron periocidades");
          }
        } else if (res.data.success === 1) {
          message.error("Error al obtener las periocidades");
        } else {
          message.error("Error desconocido en la solicitud");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.error("Error al consultar las periocidades");
      });
  };

  onFinish = (values) => {
    if (values?.nombre) {
      this.setState({ loading: true });

      axios
        .post(
          "tiposPeriodos/agregarTipoPeriodo",
          {
            ...values,
            empresaId: this.props.empresaId,
            fechaInicioEjercicio: values.fechaInicioEjercicio,
            periodoTrabajo: values.periodoTrabajo,
            posisionSeptimoDia: values.posisionSeptimoDia,
          },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.success === 2) {
            message.success("Tipo de periodo agregado exitosamente");
            this.cerrarModal();
          } else {
            message.error("Error al agregar el tipo de periodo");
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          message.error("Error al comunicarse con el servidor");
        });
    } else {
      message.info("Proporcione al menos el nombre del tipo de periodo");
    }
  };

  cerrarModal = () => {
    this.setState({ visible: false }); // Reiniciar los campos al cerrar el modal
    this.props.cerrarModal();
    if (this.formRef.current) {
      this.formRef.current.resetFields();
    }
  };

  render() {
    return (
      <ModalItem
        title="Agregar Tipo de Periodo"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarModal}
        width={1200}
      >
        <Form
          ref={this.formRef}
          name="modalAgregarTipoPeriodo"
          layout="vertical"
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
              loading={this.state.loading}
            >
              Guardar
            </ButtonItem>
          }
        >
          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                name="fechaInicioEjercicio"
                label="Fecha de Inicio del Ejercicio"
                required
                margin
                rules={[{ required: true, message: "Por favor selecciona la fecha de inicio del ejercicio" }]}
              >
                <DatePicker placeholder="Selecciona la fecha de inicio" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="nombre"
                label="Nombre del Periodo"
                required
                margin
                rules={[{ required: true, message: "Por favor ingresa el nombre del tipo de periodo" }]}
              >
                <InputItem placeholder="Ingresa el nombre del tipo de periodo" />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                name="diasPeriodo"
                label="Días del Periodo"
                required
                margin
                rules={[{ required: true, message: "Por favor ingresa los días del periodo" }]}
              >
                <InputItem type="number" placeholder="Ingresa los días del periodo" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="diasDePago"
                label="Días de Pago"
                required
                margin
                rules={[{ required: true, message: "Por favor ingresa los días de pago" }]}
              >
                <InputItem type="number" placeholder="Ingresa los días de pago" />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                name="diaDePago"
                label="Día de Pago"
                required
                margin
                rules={[{ required: true, message: "Por favor selecciona el día de pago" }]}
              >
                <InputItem type="number" placeholder="Ingresa el día de pago" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="ajustarMes"
                label="Ajustar Mes"
                required
                margin
                rules={[{ required: true, message: "Por favor selecciona el ajuste de mes" }]}
              >
                <SelectItem placeholder="Selecciona el ajuste de mes">
                  <Option value={0}>Días laborados</Option>
                  <Option value={1}>Días no laborados</Option>
                </SelectItem>
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                name="periodicidadPagoSatId"
                label="Periocidad del Pago SAT"
                required
                margin
                rules={[{ required: true, message: "Por favor selecciona la periocidad del pago SAT" }]}
              >
                <SelectItem placeholder="Selecciona la periocidad">
                  {this.state.objetoPeriocidades}
                </SelectItem>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="periodoTrabajo"
                label="Periodo de Trabajo"
                required
                margin
                rules={[{ required: true, message: "Por favor ingresa el periodo de trabajo" }]}
              >
                <InputItem type="number" placeholder="Ingresa el periodo de trabajo" />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                name="posisionSeptimoDia"
                label="Posición del Séptimo Día"
                required
                margin
                rules={[{ required: true, message: "Por favor selecciona la posición del séptimo día" }]}
              >
                <SelectItem placeholder="Selecciona el séptimo día">
                  <Option value={0}>Lunes</Option>
                  <Option value={1}>Martes</Option>
                  <Option value={2}>Miércoles</Option>
                  <Option value={3}>Jueves</Option>
                  <Option value={4}>Viernes</Option>
                  <Option value={5}>Sábado</Option>
                  <Option value={6}>Domingo</Option>
                </SelectItem>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => ({
  headersToken: state.user.headersToken,
  empresaId: state.empresa.id,
});

export default connect(mapStateToProps)(AgregarTipoPeriodo);
