import {
  message,
  Spin,
  Row,
  Col,
  Select,
  Form as FormInitial,
  notification,
  Space,
  Checkbox,
} from "antd";
import React, { Component } from "react";
import {
  Form,
  FormItem,
  InputNumberItem,
  SelectItem,
  round2Decimals,
  round6Decimals,
  dineroDisabledMask,
  ModalItem,
  DatePickerItem,
  BoxItem,
  HeaderItem,
  InputItem,
  ButtonItem,
  NotificacionPolizas,
  TextAreaItem,
  SwitchItem,
  ErrorItem
} from "../../../../Components/Items";
import moment from "moment";
import axios from "axios";
import { connect } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import facturaPdf from "../../../../Pdf/facturaPdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ElegirTipoDeCliente from "../../Clientes/ElegirTipoDeCliente/ElegirTipoDeCliente";
import MandarCorreo from "../MandarCorreo/MandarCorreo";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Option } = Select;

class PuntoDeVenta extends Component {
  formRef = React.createRef();
  state = {
    remisionFacturaPredefinido: null,
    fechaPredefinido: null,
    contadoPredefinido: null,
    clientePredefinido: null,
    bancoPredefinido: null,
    cuentasXCPredefinido: null,
    proyectoPredefinido: null,
    precioPredefinido: null,
    vendedorPredefinido: null,
    formaDePagoPredefinido: null,
    usoDeCfdiPredefinido: null,
    diasCreditoPredefinido: null,
    monedaPredefinido: null,
    tipoDeCambioPredefinido: null,
    loading: false,
    objetoClientes: null,
    todasClientes: null,
    objetoProyectos: null,
    todasProyectos: null,
    objetoPrecios: null,
    todasPrecios: null,
    objetoFormasDePago: null,
    todasFormasDePago: null,
    objetoInsumos: null,
    todasInsumos: null,
    objetoUsosDeCFDI: null,
    todasUsosDeCFDI: null,
    objetoBancos: null,
    todasBancos: null,
    objetoUsuarios: null,
    todasUsuarios: null,
    objetoMonedas: null,
    todasMonedas: null,
    impuestos: [],
    modalConfirmacion: false,
    modalCorreo: false,
    modalCorreoId: null,
    modalElegirTipoDeCliente: false,
    modalElegirTipoDeClienteNombre: null,
    modalElegirTipoDeClienteId: null,
    valuesForm: null,
    entregarInsumos: false,
    numero: "",
    totalPagar: 0,
    venderSinInventario: false
  };
  leerCodigo() {
    if (!this.state.clientePredefinido) {
      this.setState({ numero: "" });
      return message.warning("Primero llene los datos predefinidos");
    }
    const insumo = this.state.todasInsumos.find(
      (todosInsumos) => todosInsumos.codigoBarras === this.state.numero
    );
    if (!insumo) {
      this.setState({ numero: "" });
      return message.warning("No encontramos el producto");
    }
    const insumosEnVenta = this.formRef.current.getFieldValue("insumos");
    const indexInsumoEnVenta =
      insumosEnVenta?.length > 0
        ? insumosEnVenta.findIndex((todosInsumosVenta) => {
          if (todosInsumosVenta.insumoId) {
            return (
              todosInsumosVenta?.insumoId?.toString() ===
              insumo._id.toString()
            );
          } else {
            return false;
          }
        })
        : -1;
    if (indexInsumoEnVenta === -1) {
      this.formRef.current.setFieldsValue({
        insumos:
          insumosEnVenta?.length > 0
            ? [...insumosEnVenta, { insumoId: insumo._id, cantidad: 1 }]
            : [{ insumoId: insumo._id, cantidad: 1 }],
      });
      this.onChangeInsumoProyecto(null, insumosEnVenta?.length ? insumosEnVenta.length : 0);
    } else {
      const insumosConNuevaCantidad = [...insumosEnVenta];
      insumosConNuevaCantidad[indexInsumoEnVenta].cantidad =
        insumosConNuevaCantidad[indexInsumoEnVenta].cantidad + 1;
      this.formRef.current.setFieldsValue({
        insumos: insumosConNuevaCantidad,
      });
      this.onChangeInsumoProyecto(null, indexInsumoEnVenta);
    }
    this.setState({ numero: "" });
  }
  eventListenerKeyboard(event) {
    if (
      event.key === "Enter" &&
      this.state.numero !== "" &&
      !this.state.loading
    ) {
      this.leerCodigo();
    } else if (event.key.length === 1) {
      this.setState({ numero: this.state.numero + event.key });
    }
  }
  eventListenerKeyboards = this.eventListenerKeyboard.bind(this)
  componentDidMount() {
    this.consultarTodo();
    document.addEventListener("keydown", this.eventListenerKeyboards);
  }
  componentWillUnmount() {
    document.removeEventListener(
      "keydown",
      this.eventListenerKeyboards
    );
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "ventas/selectDatosParaVenta",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({
            todasClientes: res.data.clientes,
            todasProyectos: res.data.proyectos,
            todasPrecios: res.data.precios,
            todasFormasDePago: res.data.formasDePago,
            todasUsosDeCFDI: res.data.usosDeCFDI,
            todasBancos: res.data.bancosYCajas,
            todasInsumos: res.data.insumosVenta,
            todasUsuarios: res.data.usuariosEmpresa,
            todasMonedas: res.data.monedas,
            venderSinInventario: res.data.venderSinInventario
          });
          this.setState({
            objetoClientes: res.data.clientes.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
            objetoProyectos: res.data.proyectos.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
            objetoPrecios: res.data.precios.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
            objetoFormasDePago: res.data.formasDePago.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.descripcion}
                </Option>
              );
            }),
            objetoUsosDeCFDI: res.data.usosDeCFDI.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.descripcion}
                </Option>
              );
            }),
            objetoBancos: res.data.bancosYCajas.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
            objetoInsumos: res.data.insumosVenta.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
            objetoUsuarios: res.data.usuariosEmpresa.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre +
                    " " +
                    value.apellidoPaterno +
                    " " +
                    value.apellidoMaterno}
                </Option>
              );
            }),
            objetoMonedas: res.data.monedas.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.abreviacion}
                </Option>
              );
            }),
          });
        }
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }
  onFinish(values) {
    if (!values.insumos || values.insumos?.length === 0)
      return message.warning("Agregue insumos para agregar la venta");

    for (const valueInsumo of values.insumos) {
      if (this.state.entregarInsumos && valueInsumo.cantidadProyecto !== "No inventariable" && valueInsumo.cantidad > valueInsumo.cantidadProyecto) {
        return message.warning("No se pueden entregar los insumos ya que supera la cantidad disponible");
      } else if (!this.state.entregarInsumos && !this.state.venderSinInventario && valueInsumo.cantidadProyecto !== "No inventariable" && valueInsumo.cantidad > valueInsumo.cantidadProyecto) {
        return message.warning("No se puede agregar la venta ya que no hay suficientes insumos");
      }
    }
    this.setState({ loading: true });
    let insumos = values.insumos;
    let total = 0;
    let subtotal = 0;
    let arrayImpuestos = [];
    let arrayRetenciones = [];
    let cuentasCliente = 0;
    insumos.map((value) => {
      total += value.subtotal;
      subtotal += value.subtotal;
      value.arrayImpuestos.map((valueImpuestos) => {
        let index = arrayImpuestos.findIndex(
          (valueImpuestosAnterios) =>
            valueImpuestosAnterios.descripcionFactura ===
            valueImpuestos.descripcionFactura
            &&
            valueImpuestosAnterios.tasaCuota ===
            valueImpuestos.tasaCuota
            &&
            valueImpuestosAnterios.impuesto ===
            valueImpuestos.impuesto
        );
        if (index !== -1) {
          let newItem = {
            totalImpuestos:
              arrayImpuestos[index].totalImpuestos +
              valueImpuestos.totalImpuestos,
            baseImpuesto:
              arrayImpuestos[index].baseImpuesto +
              valueImpuestos.baseImpuesto,
            descripcionFactura:
              valueImpuestos.descripcionFactura,
            tasaCuota:
              valueImpuestos.tasaCuota,
            impuesto:
              valueImpuestos.impuesto,
            montoImpuesto:
              valueImpuestos.montoImpuesto,
            local: valueImpuestos.local,
          };
          arrayImpuestos.splice(index, 1, newItem);
        } else {
          arrayImpuestos.push({
            tasaCuota: valueImpuestos.tasaCuota,
            local: valueImpuestos.local,
            impuesto: valueImpuestos.impuesto,
            montoImpuesto: valueImpuestos.montoImpuesto,
            baseImpuesto: valueImpuestos.baseImpuesto,
            descripcionFactura: valueImpuestos.descripcionFactura,
            totalImpuestos: valueImpuestos.totalImpuestos,
          });
        }
        return null;
      });
      if (value.arrayRetenciones) {
        value.arrayRetenciones.map((valueRetenciones) => {
          let index = arrayRetenciones.findIndex(
            (valueRetencionesAnterios) =>
              valueRetencionesAnterios.descripcionFactura ===
              valueRetenciones.descripcionFactura
              &&
              valueRetencionesAnterios.tasaCuota ===
              valueRetenciones.tasaCuota
              &&
              valueRetencionesAnterios.impuesto ===
              valueRetenciones.impuesto
          );
          cuentasCliente += round2Decimals(valueRetenciones.totalRetenciones);
          if (index !== -1) {
            let newItem = {
              totalRetenciones:
                arrayRetenciones[index].totalRetenciones +
                valueRetenciones.totalRetenciones,
              baseRetencion:
                arrayRetenciones[index].baseRetencion +
                valueRetenciones.baseRetencion,
              descripcionFactura:
                valueRetenciones.descripcionFactura,
              tasaCuota:
                valueRetenciones.tasaCuota,
              impuesto:
                valueRetenciones.impuesto,
              montoRetencion:
                valueRetenciones.montoRetencion,
              local: valueRetenciones.local,
            };
            arrayRetenciones.splice(index, 1, newItem);
          } else {
            arrayRetenciones.push({
              tasaCuota: valueRetenciones.tasaCuota,
              impuesto: valueRetenciones.impuesto,
              montoRetencion: valueRetenciones.montoRetencion,
              baseRetencion: valueRetenciones.baseRetencion,
              local: valueRetenciones.local,
              descripcionFactura: valueRetenciones.descripcionFactura,
              totalRetenciones: valueRetenciones.totalRetenciones,
            });
          }
          return null;
        });
      }
      arrayImpuestos = arrayImpuestos.map(valueImpuesto => {
        total += round2Decimals(valueImpuesto.totalImpuestos)
        return {
          ...valueImpuesto,
          totalImpuestos: round2Decimals(valueImpuesto.totalImpuestos),
        }
      })
      return null;
    });
    axios
      .post(
        "ventas/agregarVenta",
        {
          ...values,
          empresaId: this.props.empresaId,
          total,
          subtotal,
          cuentasCliente: round2Decimals(cuentasCliente),
          totalPagar: round2Decimals(total - cuentasCliente),
          arrayImpuestos,
          arrayRetenciones,
          entregarInsumos: this.state.entregarInsumos,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se ha registrado una venta");
          if (res.data.respuestaFactura.status === "success") {
            notification.success({
              description: "Haga click para descargar",
              message: "Factura Emitida",
              duration: 0,
              style: { cursor: "pointer" },
              onClick: () => {
                var xmltext = res.data.respuestaFactura.data.cfdi;

                var filename = res.data.respuestaFactura.data.uuid + ".xml";
                var pom = document.createElement("a");
                var bb = new Blob([xmltext], { type: "text/plain" });

                pom.setAttribute("href", window.URL.createObjectURL(bb));
                pom.setAttribute("download", filename);

                pom.dataset.downloadurl = [
                  "text/plain",
                  pom.download,
                  pom.href,
                ].join(":");
                pom.draggable = true;
                pom.classList.add("dragout");

                pom.click();

                const pdf = facturaPdf(res.data);

                pdfMake.createPdf(pdf).download(res.data.respuestaFactura.data.uuid + ".pdf");
              },
            });
            NotificacionPolizas(res.data.polizasId);
            this.setState({
              modalCorreo: true,
              modalCorreoId: res.data.ventaId,
            });
          } else {
            NotificacionPolizas(res.data.polizasId);
            this.formRef.current.resetFields();
            this.ponerPredefinidos()
          }
        } else if (res.data.success === 1) {
          message.error("No se pudo registrar la venta");
          notification.warning({
            description: res.data.message,
            message: "Error en el Timbrado",
            duration: 0,
          });
        } else {
          message.error("Hubo un error y la venta no se registro");
        }
      });
  }
  atras() {
    this.props.history.goBack();
  }
  async onChangeInsumoProyecto(value, key) {
    let arrayValue = await this.formRef.current.getFieldValue("insumos");
    let precioId = await this.formRef.current.getFieldValue("precioId");
    let tipoDeCambio = await this.formRef.current.getFieldValue("tipoDeCambio");
    let clienteId = await this.formRef.current.getFieldValue("clienteId");
    let proyectoId = arrayValue[key].proyectoId;
    const insumoId = arrayValue[key].insumoId;
    const insumo = this.state.todasInsumos.find(
      (valueInsumos) => valueInsumos._id === insumoId
    );
    if (!insumo) return;
    if (insumo.inventariable || insumo.insumoCompuesto) {
      await axios
        .post(
          "inventarios/inventarioInsumoProyecto",
          {
            proyectoId: proyectoId,
            insumoId: insumoId,
            noPrincipal: insumo.noPrincipal,
            insumoCompuesto: insumo.insumoCompuesto,
            empresaId: this.props.empresaId,
          },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          this.setState({ loading: false });
          arrayValue[key].cantidadProyecto = res.data.cantidad;
        });
    } else {
      arrayValue[key].cantidadProyecto = "No inventariable";
    }
    const precio = insumo.precios.find(
      (valuePrecios) => valuePrecios.precioId?.toString() === precioId
    );
    if (precio) {
      arrayValue[key].precioUnitario = precio.precio;
    } else {
      message.info("Este insumo no tiene ese precio");
      arrayValue[key].precioUnitario = 0;
    }

    const precioUnitario = arrayValue[key].precioUnitario;

    let totalImpuestosPrecioSinImpuestos = 0;
    insumo.impuestos.map((valueImpuesto) => {
      let totalImpuesto;
      if (valueImpuesto.tasaCuota === 1) {
        totalImpuesto = precioUnitario * (valueImpuesto.monto / 100);
      } else if (valueImpuesto.tasaCuota === 2) {
        totalImpuesto =
          (precioUnitario + totalImpuestosPrecioSinImpuestos) *
          (valueImpuesto.monto / 100);
      } else if (valueImpuesto.tasaCuota === 3) {
        totalImpuesto = (valueImpuesto.monto / tipoDeCambio);
      } else if (valueImpuesto.tasaCuota === 5) {
        totalImpuesto = 0
      }
      totalImpuestosPrecioSinImpuestos += totalImpuesto;
      return null;
    });
    arrayValue[key].precioUnitarioConImpuestos = round2Decimals(
      precioUnitario + totalImpuestosPrecioSinImpuestos
    );

    const cantidad = arrayValue[key].cantidad;
    const descuento = arrayValue[key].descuento || 0;

    if (precioUnitario && cantidad && insumo) {
      //1
      const subtotal = round2Decimals(cantidad * precioUnitario);
      //2
      const totalDescuento = round2Decimals(subtotal * (descuento / 100));
      //3
      const totalEntrada = round2Decimals(subtotal - totalDescuento);
      arrayValue[key].subtotal = totalEntrada;

      let totalImpuestos = 0;
      let arrayImpuestos = insumo.impuestos.map(
        //4
        (valueImpuesto) => {
          let totalImpuesto;
          let baseImpuesto;
          let montoImpuesto;
          if (valueImpuesto.tasaCuota === 1) {
            totalImpuesto = round6Decimals(
              totalEntrada * (valueImpuesto.monto / 100)
            );
            baseImpuesto = totalEntrada
            montoImpuesto = round6Decimals(valueImpuesto.monto / 100)
          } else if (valueImpuesto.tasaCuota === 2) {
            totalImpuesto = round6Decimals(
              (totalEntrada + totalImpuestos) * (valueImpuesto.monto / 100)
            );
            baseImpuesto = (totalEntrada + totalImpuestos)
            montoImpuesto = round6Decimals(valueImpuesto.monto / 100)
          } else if (valueImpuesto.tasaCuota === 3) {
            totalImpuesto = round6Decimals((cantidad * valueImpuesto.monto) / tipoDeCambio);
            baseImpuesto = cantidad
            montoImpuesto = round6Decimals(valueImpuesto.monto / tipoDeCambio)
          } else if (valueImpuesto.tasaCuota === 5) {
            totalImpuesto = 0
            baseImpuesto = totalEntrada
            montoImpuesto = 0
          }
          totalImpuestos += totalImpuesto;
          return {
            tasaCuota: valueImpuesto.tasaCuota,
            impuesto: valueImpuesto.impuesto,
            montoImpuesto: montoImpuesto,
            local: valueImpuesto.local,
            baseImpuesto: baseImpuesto,
            descripcionFactura: valueImpuesto.descripcionFactura,
            totalImpuestos: totalImpuesto,
          };
        }
      );
      arrayValue[key].impuestos = totalImpuestos;
      arrayValue[key].arrayImpuestos = arrayImpuestos;
      //5
      arrayValue[key].total = round2Decimals(
        arrayValue[key].subtotal + totalImpuestos
      );

      if (clienteId) {
        //Consultar cliente

        const cliente = this.state.todasClientes.find(
          (valueP) => valueP._id === clienteId
        );
        //6
        let totalRetenciones = 0;
        let arrayRetenciones = []
        cliente.retenciones.map((valueRetencion) => {
          //7
          let totalRetencion;
          let baseRetencion;
          if (valueRetencion.tasaCuota === 1) {
            totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
            baseRetencion = totalEntrada
          } else if (valueRetencion.tasaCuota === 2) {
            totalRetencion =
              round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
            baseRetencion = (totalEntrada + totalImpuestos)
          }
          totalRetenciones += totalRetencion;
          arrayRetenciones.push({
            tasaCuota: valueRetencion.tasaCuota,
            impuesto: valueRetencion.impuesto,
            montoRetencion: valueRetencion.monto,
            local: valueRetencion.local,
            baseRetencion: baseRetencion,
            descripcionFactura: valueRetencion.descripcionFactura,
            totalRetenciones: totalRetencion,
          });
          return null
        })
        insumo.retenciones.map((valueRetencion) => {
          if ((valueRetencion.aplicaVenta === 1 && cliente.persona === false) || (valueRetencion.aplicaVenta === 2 && cliente.persona === true) || valueRetencion.aplicaVenta === 3) {
            //7
            let totalRetencion;
            let baseRetencion;
            if (valueRetencion.tasaCuota === 1) {
              totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
              baseRetencion = totalEntrada
            } else if (valueRetencion.tasaCuota === 2) {
              totalRetencion =
                round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
              baseRetencion = (totalEntrada + totalImpuestos)
            }
            totalRetenciones += totalRetencion;
            arrayRetenciones.push({
              tasaCuota: valueRetencion.tasaCuota,
              impuesto: valueRetencion.impuesto,
              montoRetencion: valueRetencion.monto,
              local: valueRetencion.local,
              baseRetencion: baseRetencion,
              descripcionFactura: valueRetencion.descripcionFactura,
              totalRetenciones: totalRetencion,
            });
          }
          return null
        })
        arrayValue[key].retenciones = totalRetenciones;
        arrayValue[key].arrayRetenciones = arrayRetenciones;
      }

    }
    this.formRef.current.setFieldsValue({
      insumos: arrayValue,
    });
  }
  async onChangePrecioCantidadDescuento(value, key) {
    let arrayValue = await this.formRef.current.getFieldValue("insumos");
    let clienteId = await this.formRef.current.getFieldValue("clienteId");
    let tipoDeCambio = await this.formRef.current.getFieldValue("tipoDeCambio");
    const insumo = this.state.todasInsumos.find(
      (valueInsumos) => valueInsumos._id === arrayValue[key].insumoId
    );
    if (!insumo) return;
    const precioUnitario = arrayValue[key].precioUnitario;

    let totalImpuestosPrecioSinImpuestos = 0;
    insumo.impuestos.map((valueImpuesto) => {
      let totalImpuesto;
      if (valueImpuesto.tasaCuota === 1) {
        totalImpuesto = precioUnitario * (valueImpuesto.monto / 100);
      } else if (valueImpuesto.tasaCuota === 2) {
        totalImpuesto =
          (precioUnitario + totalImpuestosPrecioSinImpuestos) *
          (valueImpuesto.monto / 100);
      } else if (valueImpuesto.tasaCuota === 3) {
        totalImpuesto = (valueImpuesto.monto / tipoDeCambio);
      } else if (valueImpuesto.tasaCuota === 5) {
        totalImpuesto = 0
      }
      totalImpuestosPrecioSinImpuestos += totalImpuesto;
      return null;
    });
    arrayValue[key].precioUnitarioConImpuestos = round2Decimals(
      precioUnitario + totalImpuestosPrecioSinImpuestos
    );
    const cantidad = arrayValue[key].cantidad;
    const descuento = arrayValue[key].descuento || 0;

    if (precioUnitario && cantidad && insumo) {
      //1
      const subtotal = round2Decimals(cantidad * precioUnitario);
      //2
      const totalDescuento = round2Decimals(subtotal * (descuento / 100));
      //3
      const totalEntrada = round2Decimals(subtotal - totalDescuento);
      arrayValue[key].subtotal = totalEntrada;

      let totalImpuestos = 0;
      let arrayImpuestos = insumo.impuestos.map(
        //4
        (valueImpuesto) => {
          let totalImpuesto;
          let baseImpuesto;
          let montoImpuesto;
          if (valueImpuesto.tasaCuota === 1) {
            totalImpuesto = round6Decimals(
              totalEntrada * (valueImpuesto.monto / 100)
            );
            baseImpuesto = totalEntrada
            montoImpuesto = round6Decimals(valueImpuesto.monto / 100)
          } else if (valueImpuesto.tasaCuota === 2) {
            totalImpuesto = round6Decimals(
              (totalEntrada + totalImpuestos) * (valueImpuesto.monto / 100)
            );
            baseImpuesto = (totalEntrada + totalImpuestos)
            montoImpuesto = round6Decimals(valueImpuesto.monto / 100)
          } else if (valueImpuesto.tasaCuota === 3) {
            totalImpuesto = round6Decimals((cantidad * valueImpuesto.monto) / tipoDeCambio);
            baseImpuesto = cantidad
            montoImpuesto = round6Decimals(valueImpuesto.monto / tipoDeCambio)
          } else if (valueImpuesto.tasaCuota === 5) {
            totalImpuesto = 0
            baseImpuesto = totalEntrada
            montoImpuesto = 0
          }
          totalImpuestos += totalImpuesto;
          return {
            tasaCuota: valueImpuesto.tasaCuota,
            impuesto: valueImpuesto.impuesto,
            montoImpuesto: montoImpuesto,
            local: valueImpuesto.local,
            baseImpuesto: baseImpuesto,
            descripcionFactura: valueImpuesto.descripcionFactura,
            totalImpuestos: totalImpuesto,
          };
        }
      );
      arrayValue[key].impuestos = totalImpuestos;
      arrayValue[key].arrayImpuestos = arrayImpuestos;
      //5
      arrayValue[key].total = round2Decimals(
        arrayValue[key].subtotal + totalImpuestos
      );

      if (clienteId) {
        //Consultar cliente

        const cliente = this.state.todasClientes.find(
          (valueP) => valueP._id === clienteId
        );
        //6
        let totalRetenciones = 0;
        let arrayRetenciones = []
        cliente.retenciones.map((valueRetencion) => {
          //7
          let totalRetencion;
          let baseRetencion;
          if (valueRetencion.tasaCuota === 1) {
            totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
            baseRetencion = totalEntrada
          } else if (valueRetencion.tasaCuota === 2) {
            totalRetencion =
              round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
            baseRetencion = (totalEntrada + totalImpuestos)
          }
          totalRetenciones += totalRetencion;
          arrayRetenciones.push({
            tasaCuota: valueRetencion.tasaCuota,
            impuesto: valueRetencion.impuesto,
            montoRetencion: valueRetencion.monto,
            local: valueRetencion.local,
            baseRetencion: baseRetencion,
            descripcionFactura: valueRetencion.descripcionFactura,
            totalRetenciones: totalRetencion,
          });
          return null
        })
        insumo.retenciones.map((valueRetencion) => {
          if ((valueRetencion.aplicaVenta === 1 && cliente.persona === false) || (valueRetencion.aplicaVenta === 2 && cliente.persona === true) || valueRetencion.aplicaVenta === 3) {
            //7
            let totalRetencion;
            let baseRetencion;
            if (valueRetencion.tasaCuota === 1) {
              totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
              baseRetencion = totalEntrada
            } else if (valueRetencion.tasaCuota === 2) {
              totalRetencion =
                round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
              baseRetencion = (totalEntrada + totalImpuestos)
            }
            totalRetenciones += totalRetencion;
            arrayRetenciones.push({
              tasaCuota: valueRetencion.tasaCuota,
              impuesto: valueRetencion.impuesto,
              montoRetencion: valueRetencion.monto,
              local: valueRetencion.local,
              baseRetencion: baseRetencion,
              descripcionFactura: valueRetencion.descripcionFactura,
              totalRetenciones: totalRetencion,
            });
          }
          return null
        })
        arrayValue[key].retenciones = totalRetenciones;
        arrayValue[key].arrayRetenciones = arrayRetenciones;
      }

    }
    this.formRef.current.setFieldsValue({
      insumos: arrayValue,
    });
  }
  onChangeCliente(valueId) {
    const contado = this.formRef.current.getFieldValue("contado");
    const cliente = this.state.todasClientes.find(
      (valueProv) => valueProv._id === valueId
    );
    if (!cliente.tipoClienteId) {
      const nombre = cliente.persona
        ? cliente.razonSocial
        : `${cliente.nombre} ${cliente.apellidoPaterno} ${cliente.apellidoMaterno}`;
      this.abrirElegirTipoDeCliente(cliente._id, nombre);
    }
    this.formRef.current.setFieldsValue({
      diasCredito: cliente.diasCredito || 0,
      precioId:
        contado === 1 ? cliente.precioContadoId : cliente.precioCreditoId,
      usoDeCfdiId: cliente.usoDeCFDIID,
      proyectoId: cliente.proyectoId,
    });
  }
  onChangeContado(value) {
    const clienteId = this.formRef.current.getFieldValue("clienteId");
    if (!clienteId) return;
    const cliente = this.state.todasClientes.find(
      (valueProv) => valueProv._id === clienteId
    );
    this.formRef.current.setFieldsValue({
      precioId: value === 1 ? cliente.precioContadoId : cliente.precioCreditoId,
    });
  }
  abrirElegirTipoDeCliente(id, nombre) {
    this.setState({
      modalElegirTipoDeCliente: true,
      modalElegirTipoDeClienteId: id,
      modalElegirTipoDeClienteNombre: nombre,
    });
  }
  cerrarElegirTipoDeCliente(elegido) {
    if (!elegido) {
      this.formRef.current.setFieldsValue({ clienteId: undefined });
    }
    this.consultarTodo();
    this.setState({
      modalElegirTipoDeCliente: false,
      modalElegirTipoDeClienteId: null,
      modalElegirTipoDeClienteNombre: null,
    });
  }
  cerrarMandarCorreo() {
    this.setState({
      modalCorreo: false,
      modalCorreoId: null,
    });
    this.formRef.current.resetFields();
    this.ponerPredefinidos()
  }
  elegirPredefinidos(values) {
    this.setState({
      remisionFacturaPredefinido: values.factura,
      fechaPredefinido: values.fechaVenta,
      contadoPredefinido: values.contado,
      clientePredefinido: values.clienteId,
      bancoPredefinido: values.bancoId,
      cuentasXCPredefinido: values.cuentasXC,
      proyectoPredefinido: values.proyectoId,
      precioPredefinido: values.precioId,
      vendedorPredefinido: values.vendedorId,
      formaDePagoPredefinido: values.formasDePagoId,
      usoDeCfdiPredefinido: values.usoDeCfdiId,
      diasCreditoPredefinido: values.diasCredito,
      monedaPredefinido: values.monedaId,
      tipoDeCambioPredefinido: values.tipoDeCambio,
    })
  }
  ponerPredefinidos() {
    this.formRef.current.setFieldsValue({
      factura: this.state.remisionFacturaPredefinido,
      fechaVenta: this.state.fechaPredefinido,
      contado: this.state.contadoPredefinido,
      clienteId: this.state.clientePredefinido,
      bancoId: this.state.bancoPredefinido,
      cuentasXC: this.state.cuentasXCPredefinido,
      proyectoId: this.state.proyectoPredefinido,
      precioId: this.state.precioPredefinido,
      vendedorId: this.state.vendedorPredefinido,
      formasDePagoId: this.state.formaDePagoPredefinido,
      usoDeCfdiId: this.state.usoDeCfdiPredefinido,
      diasCredito: this.state.diasCreditoPredefinido,
      monedaId: this.state.monedaPredefinido,
      tipoDeCambio: this.state.tipoDeCambioPredefinido,
      insumos: []
    })
    message.success('Los datos seran predefinidos para las siguientes ventas')
  }
  render() {
    const predefinido =
      !!this.state.remisionFacturaPredefinido &&
      !!this.state.fechaPredefinido &&
      !!this.state.contadoPredefinido &&
      !!this.state.clientePredefinido &&
      !!this.state.proyectoPredefinido &&
      !!this.state.vendedorPredefinido &&
      !!this.state.monedaPredefinido &&
      !!this.state.tipoDeCambioPredefinido &&
      !!this.state.precioPredefinido;
    return (
      <>
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin
            spinning={this.state.loading}
            style={{ display: "block", margin: "0 0 0 auto" }}
          >
            {!predefinido ? (
              <Form
                name="elegirPredefinidos"
                layout
                ref={this.formRef}
                bottom={
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Space>
                      <ButtonItem
                        type="primary"
                        danger
                        onClick={this.atras.bind(this)}
                      >
                        Regresar
                      </ButtonItem>
                      <ButtonItem
                        type="primary"
                        htmlType="submit"
                      >
                        Siguiente
                      </ButtonItem>
                    </Space>
                  </div>
                }
                onFinish={this.elegirPredefinidos.bind(this)}
                onFinishFailed={this.onFinishFailed}
              >
                <FormItem
                  noStyle
                  shouldUpdate={(prev, curr) =>
                    prev.insumos?.length !== curr.insumos?.length ||
                    prev.factura !== curr.factura
                  }
                >
                  {({ getFieldValue }) => {
                    const insumos = getFieldValue("insumos");
                    const factura = getFieldValue("factura");
                    const disabled =
                      insumos && insumos.length > 0 ? true : false;
                    return (
                      <>
                        <Row>
                          <Col span={16}>
                            <HeaderItem>DATOS GENERALES</HeaderItem>
                            <BoxItem>
                              <Row>
                                <Col span={8}>
                                  <FormItem
                                    required
                                    noStyle
                                    initialValue={2}
                                    name="factura"
                                    labelCol={{ span: 0 }}
                                    wrapperCol={{ span: 24 }}
                                  >
                                    <SelectItem
                                      width="80%"
                                      disabled={disabled}
                                      onChange={() =>
                                        this.formRef.current.setFieldsValue({
                                          fechaVenta: moment(),
                                        })
                                      }
                                    >
                                      <Option value={2}>Remisión</Option>
                                      {this.props.permisoFacturarVentas &&
                                        this.props.certificada && (
                                          <Option value={1}>Factura</Option>
                                        )}
                                    </SelectItem>
                                  </FormItem>
                                </Col>
                                <Col span={8}>
                                  <FormItem
                                    label="Fecha"
                                    name="fechaVenta"
                                    required
                                    margin
                                    initialValue={moment()}
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 20 }}
                                  >
                                    <DatePickerItem
                                      disabled={factura === 1}
                                      placeholder="Fecha de Venta"
                                      allowClear={false}
                                    />
                                  </FormItem>
                                </Col>
                                <Col span={8}>
                                  <FormItem
                                    required
                                    noStyle
                                    initialValue={1}
                                    name="contado"
                                    labelCol={{ span: 0 }}
                                    wrapperCol={{ span: 24 }}
                                  >
                                    <SelectItem
                                      width="100%"
                                      onChange={this.onChangeContado.bind(this)}
                                      disabled={disabled}
                                    >
                                      <Option value={1}>
                                        Contado - PUE : Pago en una sola
                                        exhibición
                                      </Option>
                                      <Option value={2}>
                                        Crédito sin enganche - PPD : Pago en
                                        parcialidades o diferido
                                      </Option>
                                      <Option value={3} disabled>
                                        Crédito con enganche - PPD : Pago en
                                        parcialidades o diferido
                                      </Option>
                                    </SelectItem>
                                  </FormItem>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <FormItem
                                    noStyle
                                    shouldUpdate={(prev, curr) =>
                                      prev.insumos?.length !==
                                      curr.insumos?.length
                                    }
                                  >
                                    {({ getFieldValue }) => {
                                      const insumos = getFieldValue("insumos");
                                      const disabled =
                                        insumos && insumos.length > 0
                                          ? true
                                          : false;
                                      return (
                                        <FormItem
                                          label="Cliente"
                                          name="clienteId"
                                          margin
                                          required
                                          labelCol={{ span: 2 }}
                                          wrapperCol={{ span: 22 }}
                                        >
                                          <SelectItem
                                            placeholder="Cliente"
                                            disabled={disabled}
                                            onChange={(valueId) =>
                                              this.onChangeCliente(valueId)
                                            }
                                          >
                                            {this.state.objetoClientes}
                                          </SelectItem>
                                        </FormItem>
                                      );
                                    }}
                                  </FormItem>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <FormItem
                                    label="Vendedor"
                                    name="vendedorId"
                                    margin
                                    required
                                    initialValue={this.props.usuarioId}
                                    labelCol={{ span: 2 }}
                                    wrapperCol={{ span: 22 }}
                                  >
                                    <SelectItem
                                      placeholder="Vendedor"
                                    >
                                      {this.state.objetoUsuarios}
                                    </SelectItem>
                                  </FormItem>
                                </Col>
                              </Row>
                            </BoxItem>
                          </Col>

                          {/* <Col span={8}>
                            <HeaderItem>DATOS DE CONTACTO</HeaderItem>
                            <BoxItem>
                              <Row>
                                <FormItem
                                  shouldUpdate={(prev, curr) =>
                                    prev.clienteId !== curr.clienteId
                                  }
                                  noStyle
                                >
                                  {({ getFieldValue }) => {
                                    let clienteId = getFieldValue("clienteId");
                                    let cliente;
                                    if (clienteId) {
                                      cliente = this.state.todasClientes.find(
                                        (value) => value._id === clienteId
                                      );
                                    }
                                    return (
                                      <>
                                        <Col span={10}>
                                          <p
                                            style={{
                                              margin: 0,
                                              fontSize: 12.5,
                                            }}
                                          >
                                            RFC:{" "}
                                            {cliente?.rfc ? cliente.rfc : ""}
                                          </p>
                                          <p
                                            style={{
                                              margin: 0,
                                              fontSize: 12.5,
                                            }}
                                          >
                                            Correo:{" "}
                                            {cliente?.correo
                                              ? cliente.correo
                                              : ""}
                                          </p>
                                          <p
                                            style={{
                                              margin: 0,
                                              fontSize: 12.5,
                                            }}
                                          >
                                            Teléfono:{" "}
                                            {cliente?.telefono
                                              ? cliente.telefono
                                              : ""}
                                          </p>
                                          <p
                                            style={{
                                              margin: 0,
                                              fontSize: 12.5,
                                            }}
                                          >
                                            Celular:{" "}
                                            {cliente?.celular
                                              ? cliente.celular
                                              : ""}
                                          </p>
                                        </Col>
                                        <Col span={14}>
                                          <p
                                            style={{
                                              margin: 0,
                                              fontSize: 12.5,
                                            }}
                                          >
                                            Contacto:{" "}
                                            {cliente?.nombreContacto
                                              ? cliente.nombreContacto
                                              : ""}
                                          </p>
                                          <p
                                            style={{
                                              margin: 0,
                                              fontSize: 12.5,
                                            }}
                                          >
                                            Tel. Contacto:{" "}
                                            {cliente?.telefonoContacto
                                              ? cliente.telefonoContacto
                                              : ""}
                                          </p>
                                          <p
                                            style={{
                                              margin: 0,
                                              fontSize: 12.5,
                                            }}
                                          >
                                            Correo Contacto:{" "}
                                            {cliente?.correoContacto
                                              ? cliente.correoContacto
                                              : ""}
                                          </p>
                                          <p
                                            style={{
                                              margin: 0,
                                              fontSize: 12.5,
                                            }}
                                          >
                                            Días de crédito:{" "}
                                            {cliente?.diasCredito
                                              ? cliente.diasCredito
                                              : ""}
                                          </p>
                                        </Col>
                                      </>
                                    );
                                  }}
                                </FormItem>
                              </Row>
                            </BoxItem>
                          </Col> */}

                          <Col span={8}>
                            <HeaderItem>MONEDA</HeaderItem>
                            <BoxItem>
                              <FormItem
                                noStyle
                                shouldUpdate={(prev, curr) =>
                                  prev.insumos?.length !==
                                  curr.insumos?.length
                                }
                              >
                                {({ getFieldValue }) => {
                                  const insumos = getFieldValue("insumos");
                                  const disabled =
                                    insumos && insumos.length > 0
                                      ? true
                                      : false;
                                  return (
                                    <FormItem
                                      required
                                      margin
                                      name="monedaId"
                                      label="Moneda"
                                      labelCol={{ span: 6 }}
                                      wrapperCol={{ span: 18 }}
                                      initialValue='60c7e4396465ad440c41c15a'
                                    >
                                      <SelectItem disabled={disabled} onChange={(value) => {
                                        if (value === '60c7e4396465ad440c41c15a') {
                                          this.formRef.current.setFieldsValue({ tipoDeCambio: 1 })
                                        }
                                      }} placeholder="Moneda">
                                        {this.state.objetoMonedas}
                                      </SelectItem>
                                    </FormItem>
                                  )
                                }}
                              </FormItem>
                              <FormItem noStyle shouldUpdate={(prev, curr) => prev.monedaId !== curr.monedaId || prev.insumos?.length !== curr.insumos?.length}>
                                {({ getFieldValue }) => {

                                  const monedaId = getFieldValue('monedaId')
                                  const insumos = getFieldValue("insumos");
                                  const disabled =
                                    insumos && insumos.length > 0
                                      ? true
                                      : false;

                                  return (
                                    <FormItem
                                      required
                                      number
                                      margin
                                      label="T. Cambio"
                                      name="tipoDeCambio"
                                      type="number"
                                      initialValue={1}
                                      labelCol={{ span: 6 }}
                                      wrapperCol={{ span: 18 }}
                                    >
                                      <InputNumberItem
                                        disabled={monedaId === '60c7e4396465ad440c41c15a' || disabled}
                                        dinero
                                        placeholder="T. Cambio"
                                      />
                                    </FormItem>
                                  )
                                }}
                              </FormItem>
                            </BoxItem>
                          </Col>

                        </Row>
                        <Row>
                          <Col span={6}>
                            <HeaderItem>PAGO</HeaderItem>
                            <BoxItem>
                              <FormItem shouldUpdate noStyle>
                                {({ getFieldValue }) => {
                                  const contado = getFieldValue("contado");
                                  const cuentasXC = getFieldValue("cuentasXC");
                                  if (contado === 1 || contado === 3) {
                                    return (
                                      <>
                                        <FormItem
                                          label="Cuentas XC"
                                          name="cuentasXC"
                                          margin
                                          initialValue={false}
                                          valuePropName="checked"
                                        >
                                          <SwitchItem />
                                        </FormItem>
                                        <FormItem
                                          required={!cuentasXC}
                                          margin
                                          name="bancoId"
                                          label="Banco"
                                          labelCol={{ span: 6 }}
                                          wrapperCol={{ span: 18 }}
                                        >
                                          <SelectItem placeholder="Banco" disabled={cuentasXC}>
                                            {this.state.objetoBancos}
                                          </SelectItem>
                                        </FormItem>
                                        {contado === 3 && (
                                          <FormItem
                                            required
                                            number
                                            margin
                                            label="Enganche"
                                            name="monto"
                                            type="number"
                                            max={this.state.total}
                                            labelCol={{ span: 6 }}
                                            wrapperCol={{ span: 18 }}
                                          >
                                            <InputNumberItem
                                              dinero
                                              placeholder="Enganche"
                                              disabled={!this.state.total}
                                            />
                                          </FormItem>
                                        )}
                                      </>
                                    );
                                  }
                                }}
                              </FormItem>
                            </BoxItem>
                          </Col>
                          <Col span={18}>
                            <HeaderItem>FORMA DE PAGO</HeaderItem>
                            <BoxItem>
                              <FormItem
                                noStyle
                                shouldUpdate={(prev, curr) =>
                                  prev.contado !== curr.contado ||
                                  prev.factura !== curr.factura
                                }
                              >
                                {({ getFieldValue }) => {
                                  const contado = getFieldValue("contado");
                                  const factura = getFieldValue("factura");
                                  const diasCredito =
                                    contado !== 1 ? (
                                      <FormItem
                                        label="Días de crédito"
                                        name="diasCredito"
                                        numberCero
                                        margin
                                        required
                                        initialValue={0}
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                      >
                                        <InputNumberItem placeholder="Días de Crédito" />
                                      </FormItem>
                                    ) : null;
                                  const usoCFDI =
                                    factura === 1 ? (
                                      <FormItem
                                        name="usoDeCfdiId"
                                        label="Uso de CFDI"
                                        initialValue="6123bd944518d23048736747"
                                        margin
                                        required
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                      >
                                        <SelectItem placeholder="Uso de CFDI">
                                          {this.state.objetoUsosDeCFDI}
                                        </SelectItem>
                                      </FormItem>
                                    ) : null;
                                  return (
                                    <>
                                      <Row>
                                        <FormItem
                                          noStyle
                                          shouldUpdate={(prev, curr) =>
                                            prev.insumos?.length !==
                                            curr.insumos?.length
                                          }
                                        >
                                          {({ getFieldValue }) => {
                                            const insumos =
                                              getFieldValue("insumos");
                                            const disabled =
                                              insumos && insumos.length > 0
                                                ? true
                                                : false;
                                            return (
                                              <>
                                                <Col span={8}>
                                                  <FormItem
                                                    label="Proyecto"
                                                    name="proyectoId"
                                                    margin
                                                    required
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                  >
                                                    <SelectItem
                                                      placeholder="Proyecto"
                                                      disabled={disabled}
                                                    >
                                                      {
                                                        this.state
                                                          .objetoProyectos
                                                      }
                                                    </SelectItem>
                                                  </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                  <FormItem
                                                    name="precioId"
                                                    label="Precio"
                                                    margin
                                                    required
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                  >
                                                    <SelectItem
                                                      placeholder="Precio"
                                                      disabled={disabled}
                                                    >
                                                      {this.state.objetoPrecios}
                                                    </SelectItem>
                                                  </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                  <FormItem
                                                    name="ordenDeCompra"
                                                    label="O Compra"
                                                    margin
                                                    pattern
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                  >
                                                    <InputItem placeholder="Orden de Compra" />
                                                  </FormItem>
                                                </Col>
                                              </>
                                            );
                                          }}
                                        </FormItem>
                                      </Row>
                                      <Row>
                                        <Col span={8}>
                                          <FormItem
                                            name="formaDePagoId"
                                            label="F Pago"
                                            initialValue="6125149afe07662acc100d58"
                                            margin
                                            required
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 16 }}
                                          >
                                            <SelectItem placeholder="Forma de Pago">
                                              {this.state.objetoFormasDePago}
                                            </SelectItem>
                                          </FormItem>
                                        </Col>
                                        <Col span={8}>
                                          {usoCFDI || diasCredito}
                                        </Col>
                                        <Col span={8}>
                                          {usoCFDI ? diasCredito : null}
                                        </Col>
                                      </Row>
                                    </>
                                  );
                                }}
                              </FormItem>
                            </BoxItem>
                          </Col>
                        </Row>
                      </>
                    );
                  }}
                </FormItem>
              </Form>
            ) : (
              <Form
                ref={this.formRef}
                name="agregarVenta"
                layout
                bottom={
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Space>
                      <ButtonItem
                        type="primary"
                        danger
                        onClick={this.atras.bind(this)}
                      >
                        Cancelar
                      </ButtonItem>
                      <ButtonItem
                        type="primary"
                        htmlType="submit"
                        onClick={() => this.setState({ entregarInsumos: true })}
                      >
                        Agregar y entregar insumos
                      </ButtonItem>
                      <ButtonItem
                        type="primary"
                        htmlType="submit"
                        onClick={() =>
                          this.setState({ entregarInsumos: false })
                        }
                      >
                        Agregar
                      </ButtonItem>
                    </Space>
                  </div>
                }
                onFinish={(values) =>
                  this.setState({ modalConfirmacion: true, valuesForm: values })
                }
                onFinishFailed={this.onFinishFailed}
              >
                <FormItem
                  noStyle
                  shouldUpdate={(prev, curr) =>
                    prev.insumos?.length !== curr.insumos?.length ||
                    prev.factura !== curr.factura
                  }
                >
                  {({ getFieldValue }) => {
                    const insumos = getFieldValue("insumos");
                    const factura = getFieldValue("factura");
                    const disabled =
                      insumos && insumos.length > 0 ? true : false;
                    return (
                      <>
                        <Row>
                          <Col span={16}>
                            <HeaderItem>DATOS GENERALES</HeaderItem>
                            <BoxItem>
                              <Row>
                                <Col span={8}>
                                  <FormItem
                                    required
                                    noStyle
                                    initialValue={this.state.remisionFacturaPredefinido}
                                    name="factura"
                                    labelCol={{ span: 0 }}
                                    wrapperCol={{ span: 24 }}
                                  >
                                    <SelectItem
                                      width="80%"
                                      disabled={disabled}
                                      onChange={() =>
                                        this.formRef.current.setFieldsValue({
                                          fechaVenta: moment(),
                                        })
                                      }
                                    >
                                      <Option value={2}>Remisión</Option>
                                      {this.props.permisoFacturarVentas &&
                                        this.props.certificada && (
                                          <Option value={1}>Factura</Option>
                                        )}
                                    </SelectItem>
                                  </FormItem>
                                </Col>
                                <Col span={8}>
                                  <FormItem
                                    label="Fecha"
                                    name="fechaVenta"
                                    required
                                    margin
                                    initialValue={this.state.fechaPredefinido}
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 20 }}
                                  >
                                    <DatePickerItem
                                      disabled={factura === 1}
                                      placeholder="Fecha de Venta"
                                      allowClear={false}
                                    />
                                  </FormItem>
                                </Col>
                                <Col span={8}>
                                  <FormItem
                                    required
                                    noStyle
                                    initialValue={this.state.contadoPredefinido}
                                    name="contado"
                                    labelCol={{ span: 0 }}
                                    wrapperCol={{ span: 24 }}
                                  >
                                    <SelectItem
                                      width="100%"
                                      onChange={this.onChangeContado.bind(this)}
                                      disabled={disabled}
                                    >
                                      <Option value={1}>
                                        Contado - PUE : Pago en una sola
                                        exhibición
                                      </Option>
                                      <Option value={2}>
                                        Crédito sin enganche - PPD : Pago en
                                        parcialidades o diferido
                                      </Option>
                                      <Option value={3} disabled>
                                        Crédito con enganche - PPD : Pago en
                                        parcialidades o diferido
                                      </Option>
                                    </SelectItem>
                                  </FormItem>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <FormItem
                                    noStyle
                                    shouldUpdate={(prev, curr) =>
                                      prev.insumos?.length !==
                                      curr.insumos?.length
                                    }
                                  >
                                    {({ getFieldValue }) => {
                                      const insumos = getFieldValue("insumos");
                                      const disabled =
                                        insumos && insumos.length > 0
                                          ? true
                                          : false;
                                      return (
                                        <FormItem
                                          label="Cliente"
                                          name="clienteId"
                                          margin
                                          required
                                          initialValue={this.state.clientePredefinido}
                                          labelCol={{ span: 2 }}
                                          wrapperCol={{ span: 22 }}
                                        >
                                          <SelectItem
                                            placeholder="Cliente"
                                            disabled={disabled}
                                            onChange={(valueId) =>
                                              this.onChangeCliente(valueId)
                                            }
                                          >
                                            {this.state.objetoClientes}
                                          </SelectItem>
                                        </FormItem>
                                      );
                                    }}
                                  </FormItem>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <FormItem
                                    label="Vendedor"
                                    name="vendedorId"
                                    margin
                                    required
                                    initialValue={this.state.vendedorPredefinido}
                                    labelCol={{ span: 2 }}
                                    wrapperCol={{ span: 22 }}
                                  >
                                    <SelectItem
                                      placeholder="Vendedor"
                                    >
                                      {this.state.objetoUsuarios}
                                    </SelectItem>
                                  </FormItem>
                                </Col>
                              </Row>
                            </BoxItem>
                          </Col>

                          {/* <Col span={8}>
                            <HeaderItem>DATOS DE CONTACTO</HeaderItem>
                            <BoxItem>
                              <Row>
                                <FormItem
                                  shouldUpdate={(prev, curr) =>
                                    prev.clienteId !== curr.clienteId
                                  }
                                  noStyle
                                >
                                  {({ getFieldValue }) => {
                                    let clienteId = getFieldValue("clienteId");
                                    let cliente;
                                    if (clienteId) {
                                      cliente = this.state.todasClientes.find(
                                        (value) => value._id === clienteId
                                      );
                                    }
                                    return (
                                      <>
                                        <Col span={10}>
                                          <p
                                            style={{
                                              margin: 0,
                                              fontSize: 12.5,
                                            }}
                                          >
                                            RFC:{" "}
                                            {cliente?.rfc ? cliente.rfc : ""}
                                          </p>
                                          <p
                                            style={{
                                              margin: 0,
                                              fontSize: 12.5,
                                            }}
                                          >
                                            Correo:{" "}
                                            {cliente?.correo
                                              ? cliente.correo
                                              : ""}
                                          </p>
                                          <p
                                            style={{
                                              margin: 0,
                                              fontSize: 12.5,
                                            }}
                                          >
                                            Teléfono:{" "}
                                            {cliente?.telefono
                                              ? cliente.telefono
                                              : ""}
                                          </p>
                                          <p
                                            style={{
                                              margin: 0,
                                              fontSize: 12.5,
                                            }}
                                          >
                                            Celular:{" "}
                                            {cliente?.celular
                                              ? cliente.celular
                                              : ""}
                                          </p>
                                        </Col>
                                        <Col span={14}>
                                          <p
                                            style={{
                                              margin: 0,
                                              fontSize: 12.5,
                                            }}
                                          >
                                            Contacto:{" "}
                                            {cliente?.nombreContacto
                                              ? cliente.nombreContacto
                                              : ""}
                                          </p>
                                          <p
                                            style={{
                                              margin: 0,
                                              fontSize: 12.5,
                                            }}
                                          >
                                            Tel. Contacto:{" "}
                                            {cliente?.telefonoContacto
                                              ? cliente.telefonoContacto
                                              : ""}
                                          </p>
                                          <p
                                            style={{
                                              margin: 0,
                                              fontSize: 12.5,
                                            }}
                                          >
                                            Correo Contacto:{" "}
                                            {cliente?.correoContacto
                                              ? cliente.correoContacto
                                              : ""}
                                          </p>
                                          <p
                                            style={{
                                              margin: 0,
                                              fontSize: 12.5,
                                            }}
                                          >
                                            Días de crédito:{" "}
                                            {cliente?.diasCredito
                                              ? cliente.diasCredito
                                              : ""}
                                          </p>
                                        </Col>
                                      </>
                                    );
                                  }}
                                </FormItem>
                              </Row>
                            </BoxItem>
                          </Col> */}

                          <Col span={8}>
                            <HeaderItem>MONEDA</HeaderItem>
                            <BoxItem>
                              <FormItem
                                noStyle
                                shouldUpdate={(prev, curr) =>
                                  prev.insumos?.length !==
                                  curr.insumos?.length
                                }
                              >
                                {({ getFieldValue }) => {
                                  const insumos = getFieldValue("insumos");
                                  const disabled =
                                    insumos && insumos.length > 0
                                      ? true
                                      : false;
                                  return (
                                    <FormItem
                                      required
                                      margin
                                      name="monedaId"
                                      label="Moneda"
                                      labelCol={{ span: 6 }}
                                      wrapperCol={{ span: 18 }}
                                      initialValue='60c7e4396465ad440c41c15a'
                                    >
                                      <SelectItem disabled={disabled} onChange={(value) => {
                                        if (value === '60c7e4396465ad440c41c15a') {
                                          this.formRef.current.setFieldsValue({ tipoDeCambio: 1 })
                                        }
                                      }} placeholder="Moneda">
                                        {this.state.objetoMonedas}
                                      </SelectItem>
                                    </FormItem>
                                  )
                                }}
                              </FormItem>
                              <FormItem noStyle shouldUpdate={(prev, curr) => prev.monedaId !== curr.monedaId || prev.insumos?.length !== curr.insumos?.length}>
                                {({ getFieldValue }) => {

                                  const monedaId = getFieldValue('monedaId')
                                  const insumos = getFieldValue("insumos");
                                  const disabled =
                                    insumos && insumos.length > 0
                                      ? true
                                      : false;

                                  return (
                                    <FormItem
                                      required
                                      number
                                      margin
                                      label="T. Cambio"
                                      name="tipoDeCambio"
                                      type="number"
                                      initialValue={1}
                                      labelCol={{ span: 6 }}
                                      wrapperCol={{ span: 18 }}
                                    >
                                      <InputNumberItem
                                        disabled={monedaId === '60c7e4396465ad440c41c15a' || disabled}
                                        dinero
                                        placeholder="T. Cambio"
                                      />
                                    </FormItem>
                                  )
                                }}
                              </FormItem>
                            </BoxItem>
                          </Col>

                        </Row>
                        <Row>
                          <Col span={6}>
                            <HeaderItem>PAGO</HeaderItem>
                            <BoxItem>
                              <FormItem shouldUpdate noStyle>
                                {({ getFieldValue }) => {
                                  const contado = getFieldValue("contado");
                                  const cuentasXC = getFieldValue("cuentasXC");
                                  if (contado === 1 || contado === 3) {
                                    return (
                                      <>
                                        <FormItem
                                          label="Cuentas XC"
                                          name="cuentasXC"
                                          margin
                                          initialValue={this.state.cuentasXCPredefinido}
                                          valuePropName="checked"
                                        >
                                          <SwitchItem />
                                        </FormItem>
                                        <FormItem
                                          required={!cuentasXC}
                                          margin
                                          name="bancoId"
                                          label="Banco"
                                          initialValue={this.state.bancoPredefinido}
                                          labelCol={{ span: 6 }}
                                          wrapperCol={{ span: 18 }}
                                        >
                                          <SelectItem placeholder="Banco" disabled={cuentasXC}>
                                            {this.state.objetoBancos}
                                          </SelectItem>
                                        </FormItem>
                                        {contado === 3 && (
                                          <FormItem
                                            required
                                            number
                                            margin
                                            label="Enganche"
                                            name="monto"
                                            type="number"
                                            max={this.state.totalPagar}
                                            labelCol={{ span: 6 }}
                                            wrapperCol={{ span: 18 }}
                                          >
                                            <InputNumberItem
                                              dinero
                                              placeholder="Enganche"
                                              disabled={!this.state.totalPagar}
                                            />
                                          </FormItem>
                                        )}
                                      </>
                                    );
                                  }
                                }}
                              </FormItem>
                            </BoxItem>
                          </Col>
                          <Col span={18}>
                            <HeaderItem>FORMA DE PAGO</HeaderItem>
                            <BoxItem>
                              <FormItem
                                noStyle
                                shouldUpdate={(prev, curr) =>
                                  prev.contado !== curr.contado ||
                                  prev.factura !== curr.factura
                                }
                              >
                                {({ getFieldValue }) => {
                                  const contado = getFieldValue("contado");
                                  const factura = getFieldValue("factura");
                                  const diasCredito =
                                    contado !== 1 ? (
                                      <FormItem
                                        label="Días de crédito"
                                        name="diasCredito"
                                        initialValue={this.state.diasCreditoPredefinido}
                                        numberCero
                                        margin
                                        required
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                      >
                                        <InputNumberItem placeholder="Días de Crédito" />
                                      </FormItem>
                                    ) : null;
                                  const usoCFDI =
                                    factura === 1 ? (
                                      <FormItem
                                        name="usoDeCfdiId"
                                        label="Uso de CFDI"
                                        initialValue={this.state.usoDeCfdiPredefinido}
                                        margin
                                        required
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                      >
                                        <SelectItem placeholder="Uso de CFDI">
                                          {this.state.objetoUsosDeCFDI}
                                        </SelectItem>
                                      </FormItem>
                                    ) : null;
                                  return (
                                    <>
                                      <Row>
                                        <FormItem
                                          noStyle
                                          shouldUpdate={(prev, curr) =>
                                            prev.insumos?.length !==
                                            curr.insumos?.length
                                          }
                                        >
                                          {({ getFieldValue }) => {
                                            const insumos =
                                              getFieldValue("insumos");
                                            const disabled =
                                              insumos && insumos.length > 0
                                                ? true
                                                : false;
                                            return (
                                              <>
                                                <Col span={8}>
                                                  <FormItem
                                                    label="Proyecto"
                                                    name="proyectoId"
                                                    margin
                                                    required
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                  >
                                                    <SelectItem
                                                      placeholder="Proyecto"
                                                      disabled={disabled}
                                                    >
                                                      {
                                                        this.state
                                                          .objetoProyectos
                                                      }
                                                    </SelectItem>
                                                  </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                  <FormItem
                                                    name="precioId"
                                                    label="Precio"
                                                    margin
                                                    required
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                  >
                                                    <SelectItem
                                                      placeholder="Precio"
                                                      disabled={disabled}
                                                    >
                                                      {this.state.objetoPrecios}
                                                    </SelectItem>
                                                  </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                  <FormItem
                                                    name="ordenDeCompra"
                                                    label="O Compra"
                                                    margin
                                                    pattern
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                  >
                                                    <InputItem placeholder="Orden de Compra" />
                                                  </FormItem>
                                                </Col>
                                              </>
                                            );
                                          }}
                                        </FormItem>
                                      </Row>
                                      <Row>
                                        <Col span={8}>
                                          <FormItem
                                            name="formaDePagoId"
                                            label="F Pago"
                                            initialValue="6125149afe07662acc100d58"
                                            margin
                                            required
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 16 }}
                                          >
                                            <SelectItem placeholder="Forma de Pago">
                                              {this.state.objetoFormasDePago}
                                            </SelectItem>
                                          </FormItem>
                                        </Col>
                                        <Col span={8}>
                                          {usoCFDI || diasCredito}
                                        </Col>
                                        <Col span={8}>
                                          {usoCFDI ? diasCredito : null}
                                        </Col>
                                      </Row>
                                    </>
                                  );
                                }}
                              </FormItem>
                            </BoxItem>
                          </Col>
                        </Row>
                      </>
                    );
                  }}
                </FormItem>
                <FormInitial.List name="insumos">
                  {(fields, { add, remove }) => (
                    <>
                      <table className="table">
                        {fields.length > 0 ? (
                          <thead>
                            <tr>
                              <th
                                className="th-border"
                                style={{ width: "20%" }}
                              >
                                Insumo
                              </th>
                              <th
                                className="th-border"
                                style={{ width: "10%" }}
                              >
                                Proyecto
                              </th>
                              <th
                                className="th-border"
                                style={{ width: "10%" }}
                              >
                                PU
                              </th>
                              <th
                                className="th-border"
                                style={{ width: "10%" }}
                              >
                                Precio Neto
                              </th>
                              <th className="th-border" style={{ width: "8%" }}>
                                Inventario
                              </th>
                              <th className="th-border" style={{ width: "8%" }}>
                                Cant.
                              </th>
                              <th className="th-border" style={{ width: "8%" }}>
                                Desc.
                              </th>
                              <th
                                className="th-border"
                                style={{ width: "10%" }}
                              >
                                Subtotal
                              </th>
                              <th
                                className="th-border"
                                style={{ width: "10%" }}
                              >
                                Impuestos
                              </th>
                              <th
                                className="th-border"
                                style={{ width: "10%" }}
                              >
                                Total
                              </th>
                              <th
                                className="th-border-sin-right"
                                style={{ width: "6%" }}
                              ></th>
                            </tr>
                          </thead>
                        ) : null}
                        {fields.map(
                          ({ key, name, fieldKey, ...restField }, arrayKey) => (
                            <tbody key={arrayKey}>
                              <tr>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "insumoId"]}
                                    required
                                    noStyle
                                  >
                                    <SelectItem
                                      sinBorde
                                      autoFocus
                                      dropdownMatchSelectWidth={600}
                                      width="100%"
                                      placeholder="Insumo"
                                      onChange={(value) =>
                                        this.onChangeInsumoProyecto(
                                          value,
                                          arrayKey
                                        )
                                      }
                                    >
                                      {this.state.objetoInsumos}
                                    </SelectItem>
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    shouldUpdate={(prev, curr) =>
                                      prev.proyectoId !== curr.proyectoId
                                    }
                                    noStyle
                                  >
                                    {({ getFieldValue }) => {
                                      const proyectoId =
                                        getFieldValue("proyectoId");
                                      return (
                                        <FormItem
                                          {...restField}
                                          name={[name, "proyectoId"]}
                                          required
                                          noStyle
                                          initialValue={proyectoId}
                                        >
                                          <SelectItem
                                            sinBorde
                                            width="100%"
                                            placeholder="Proyecto"
                                            onChange={(value) =>
                                              this.onChangeInsumoProyecto(
                                                value,
                                                arrayKey
                                              )
                                            }
                                          >
                                            {this.state.objetoProyectos}
                                          </SelectItem>
                                        </FormItem>
                                      );
                                    }}
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "precioUnitario"]}
                                    noStyle
                                    required
                                  >
                                    <InputNumberItem
                                      sinBorde
                                      onChange={(value) =>
                                        this.onChangePrecioCantidadDescuento(
                                          value,
                                          arrayKey
                                        )
                                      }
                                      placeholder="Precio Unitario"
                                      dinero
                                    />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "precioUnitarioConImpuestos"]}
                                    noStyle
                                    required
                                  >
                                    <InputNumberItem
                                      sinBorde
                                      disabled
                                      placeholder="Precio Unitario con Impuestos"
                                      dineroDisabled
                                    />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "cantidadProyecto"]}
                                    required
                                    noStyle
                                  >
                                    <InputNumberItem
                                      sinBorde
                                      disabled
                                      placeholder="Inventario"
                                    />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    noStyle
                                    shouldUpdate={(prev, curr) =>
                                      prev.insumos[arrayKey]
                                        ?.cantidadProyecto !==
                                      curr.insumos[arrayKey]?.cantidadProyecto
                                    }
                                  >
                                    {({ getFieldValue }) => {
                                      const insumos = getFieldValue("insumos");
                                      const cantidadProyecto =
                                        insumos[arrayKey].cantidadProyecto;
                                      let max = 0;
                                      if (
                                        cantidadProyecto === "No inventariable"
                                      ) {
                                        max = Infinity;
                                      } else {
                                        max = cantidadProyecto;
                                      }
                                      return (
                                        <FormItem
                                          {...restField}
                                          name={[name, "cantidad"]}
                                          number
                                          noStyle
                                          type="number"
                                        >
                                          <InputNumberItem
                                            sinBorde
                                            placeholder={
                                              max && max !== Infinity
                                                ? "Max: " + max
                                                : "Cantidad"
                                            }
                                            onChange={(value) =>
                                              this.onChangePrecioCantidadDescuento(
                                                value,
                                                arrayKey
                                              )
                                            }
                                          />
                                        </FormItem>
                                      );
                                    }}
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "descuento"]}
                                    required
                                    numberCero
                                    noStyle
                                    initialValue={0}
                                  >
                                    <InputNumberItem
                                      sinBorde
                                      placeholder="Descuento"
                                      porcentaje
                                      max={100}
                                      onChange={(value) =>
                                        this.onChangePrecioCantidadDescuento(
                                          value,
                                          arrayKey
                                        )
                                      }
                                    />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "subtotal"]}
                                    required
                                    numberCero
                                    noStyle
                                  >
                                    <InputNumberItem
                                      sinBorde
                                      disabled
                                      placeholder="Subtotal"
                                      dineroDisabled
                                    />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "impuestos"]}
                                    required
                                    numberCero
                                    noStyle
                                  >
                                    <InputNumberItem
                                      sinBorde
                                      disabled
                                      dineroDisabled
                                      placeholder="Impuestos"
                                    />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "total"]}
                                    required
                                    numberCero
                                    noStyle
                                  >
                                    <InputNumberItem
                                      sinBorde
                                      dineroDisabled
                                      disabled
                                      placeholder="Total"
                                    />
                                  </FormItem>
                                </td>
                                <td className="td-border-sin-right">
                                  <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                    style={{
                                      alignSelf: "center",
                                      justifySelf: "center",
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          )
                        )}
                      </table>
                      <FormItem
                        noStyle
                        shouldUpdate={(prev, curr) =>
                          prev.proyectoId !== curr.proyectoId ||
                          prev.precioId !== curr.precioId ||
                          prev.clienteId !== curr.clienteId
                        }
                      >
                        {({ getFieldValue }) => {
                          const proyecto = getFieldValue("proyectoId");
                          const precio = getFieldValue("precioId");
                          const cliente = getFieldValue("clienteId");
                          const moneda = getFieldValue("monedaId");
                          const tipoDeCambio = getFieldValue("tipoDeCambio");
                          return (
                            <FormItem noStyle>
                              <ButtonItem
                                style={{
                                  marginTop: 10,
                                  marginBottom: 10,
                                  justifySelf:
                                    fields.length > 0 ? "left" : null,
                                  width: fields.length > 0 ? "auto" : null,
                                }}
                                type="primary"
                                onClick={
                                  proyecto && precio && cliente && moneda && tipoDeCambio
                                    ? () => add()
                                    : () =>
                                      message.info(
                                        "Agregue los datos de venta primero"
                                      )
                                }
                                block
                                icon={<PlusOutlined />}
                              >
                                Agregar Insumo
                              </ButtonItem>
                            </FormItem>
                          );
                        }}
                      </FormItem>
                    </>
                  )}
                </FormInitial.List>
                <Row>
                  <Col span={12}>
                    <FormItem
                      pattern
                      name='observaciones'
                      label='Observaciones'
                    >
                      <TextAreaItem />
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem
                      label="Imprimir"
                      name="imprimirObservaciones"
                      valuePropName="checked"
                    >
                      <Checkbox></Checkbox>
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        let arrayInsumos = getFieldValue("insumos") || [];
                        let arrayRetenciones = [];
                        let cuentasCliente = 0;
                        let subtotal = 0;
                        let arrayImpuestos = [];
                        let total = 0;
                        arrayInsumos.map((value) => {
                          if (value) {
                            if (
                              value.subtotal &&
                              value.total &&
                              value.arrayImpuestos
                            ) {
                              subtotal += value.subtotal;
                              total += value.subtotal;
                              value.arrayImpuestos.map((valueImpuestos) => {
                                let index = arrayImpuestos.findIndex(
                                  (valueImpuestosAnterios) =>
                                    valueImpuestosAnterios.descripcionFactura ===
                                    valueImpuestos.descripcionFactura
                                    &&
                                    valueImpuestosAnterios.tasaCuota ===
                                    valueImpuestos.tasaCuota
                                    &&
                                    valueImpuestosAnterios.impuesto ===
                                    valueImpuestos.impuesto
                                );
                                if (index !== -1) {
                                  let newItem = {
                                    totalImpuestos:
                                      arrayImpuestos[index].totalImpuestos +
                                      valueImpuestos.totalImpuestos,
                                    baseImpuesto:
                                      arrayImpuestos[index].baseImpuesto +
                                      valueImpuestos.baseImpuesto,
                                    descripcionFactura:
                                      valueImpuestos.descripcionFactura,
                                    tasaCuota:
                                      valueImpuestos.tasaCuota,
                                    impuesto:
                                      valueImpuestos.impuesto,
                                    montoImpuesto:
                                      valueImpuestos.montoImpuesto,
                                    local: valueImpuestos.local,
                                  };
                                  arrayImpuestos.splice(index, 1, newItem);
                                } else {
                                  arrayImpuestos.push({
                                    tasaCuota: valueImpuestos.tasaCuota,
                                    impuesto: valueImpuestos.impuesto,
                                    local: valueImpuestos.local,
                                    montoImpuesto: valueImpuestos.montoImpuesto,
                                    baseImpuesto: valueImpuestos.baseImpuesto,
                                    descripcionFactura: valueImpuestos.descripcionFactura,
                                    totalImpuestos: valueImpuestos.totalImpuestos,
                                  });
                                }
                                return null;
                              });

                              if (value.arrayRetenciones) {
                                value.arrayRetenciones.map((valueRetenciones) => {
                                  let index = arrayRetenciones.findIndex(
                                    (valueRetencionesAnterios) =>
                                      valueRetencionesAnterios.descripcionFactura ===
                                      valueRetenciones.descripcionFactura
                                      &&
                                      valueRetencionesAnterios.tasaCuota ===
                                      valueRetenciones.tasaCuota
                                      &&
                                      valueRetencionesAnterios.impuesto ===
                                      valueRetenciones.impuesto
                                  );
                                  cuentasCliente += round2Decimals(valueRetenciones.totalRetenciones);
                                  if (index !== -1) {
                                    let newItem = {
                                      totalRetenciones:
                                        arrayRetenciones[index].totalRetenciones +
                                        valueRetenciones.totalRetenciones,
                                      baseRetencion:
                                        arrayRetenciones[index].baseRetencion +
                                        valueRetenciones.baseRetencion,
                                      descripcionFactura:
                                        valueRetenciones.descripcionFactura,
                                      tasaCuota:
                                        valueRetenciones.tasaCuota,
                                      impuesto:
                                        valueRetenciones.impuesto,
                                      montoRetencion:
                                        valueRetenciones.montoRetencion,
                                      local: valueRetenciones.local,
                                    };
                                    arrayRetenciones.splice(index, 1, newItem);
                                  } else {
                                    arrayRetenciones.push({
                                      tasaCuota: valueRetenciones.tasaCuota,
                                      impuesto: valueRetenciones.impuesto,
                                      montoRetencion: valueRetenciones.montoRetencion,
                                      local: valueRetenciones.local,
                                      baseRetencion: valueRetenciones.baseRetencion,
                                      descripcionFactura: valueRetenciones.descripcionFactura,
                                      totalRetenciones: valueRetenciones.totalRetenciones,
                                    });
                                  }
                                  return null;
                                });
                              }

                            }
                          }
                          return null;
                        });
                        arrayImpuestos = arrayImpuestos.map(valueImpuesto => {
                          total += round2Decimals(valueImpuesto.totalImpuestos)
                          return {
                            ...valueImpuesto,
                            totalImpuestos: round2Decimals(valueImpuesto.totalImpuestos),
                          }
                        })
                        if (this.state.totalPagar !== (total - cuentasCliente)) {
                          this.setState({ totalPagar: (total - cuentasCliente) });
                        }
                        return (
                          <table className="table" style={{ marginTop: 0 }}>
                            <tbody>
                              <tr>
                                <th
                                  className="th-totales"
                                  style={{ width: "50%" }}
                                >
                                  Subtotal
                                </th>
                                <td
                                  className="td-totales-right"
                                  style={{ width: "50%" }}
                                >
                                  {dineroDisabledMask(subtotal)}
                                </td>
                              </tr>
                            </tbody>
                            {arrayImpuestos.map((valueArray) => (
                              <tbody>
                                <tr key={valueArray.descripcionFactura}>
                                  <th className="th-totales">
                                    {valueArray.descripcionFactura}
                                  </th>
                                  <td className="td-totales-right">
                                    {dineroDisabledMask(
                                      valueArray.totalImpuestos
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                            <tbody>
                              <tr>
                                <th className="th-totales">Total</th>
                                <td className="td-totales-right">
                                  {dineroDisabledMask(total)}
                                </td>
                              </tr>
                            </tbody>
                            {arrayRetenciones.map((value) => (
                              <tbody>
                                <tr>
                                  <th className="th-totales">
                                    {value.descripcionFactura}
                                  </th>
                                  <td className="td-totales-right">
                                    {dineroDisabledMask(value.totalRetenciones)}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                            <tbody>
                              <tr>
                                <th className="th-totales">Total a Pagar</th>
                                <td className="td-totales-right">
                                  {dineroDisabledMask(total - cuentasCliente)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        );
                      }}
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            )}
          </Spin>
        </div>
        <ModalItem
          title="Confirmacion"
          visible={this.state.modalConfirmacion}
          onCancel={() => this.setState({ modalConfirmacion: false })}
          onOk={() => {
            this.setState({ modalConfirmacion: false });
            this.onFinish(this.state.valuesForm);
          }}
          spinning={false}
          footer
        >
          <h1 style={{ textAlign: "center" }}>Desea continuar</h1>
        </ModalItem>
        <ElegirTipoDeCliente
          visible={this.state.modalElegirTipoDeCliente}
          id={this.state.modalElegirTipoDeClienteId}
          nombre={this.state.modalElegirTipoDeClienteNombre}
          cerrarElegirTipoDeCliente={this.cerrarElegirTipoDeCliente.bind(this)}
        />
        <MandarCorreo
          visible={this.state.modalCorreo}
          id={this.state.modalCorreoId}
          cerrarMandarCorreo={this.cerrarMandarCorreo.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    usuarioId: state.user.usuarioId,
    certificada: state.empresa.certificada,
    permisoFacturarVentas:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615ce36bf30e81b54183cfaf"
      )?.activado,
  };
};

export default connect(mapStateToProps)(PuntoDeVenta);
