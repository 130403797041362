import { message, Select } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  ButtonItem,
  InputItem,
  ErrorItem,
  SelectItem,
} from "../../../../Components/Items";

const { Option } = Select;

class EditarDepartamentos extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
    visible: this.props.visible,
    todosProyectos: null,
    objetoProyectos: null,
    departamento: this.props.departamento || {},  // Cargar datos iniciales del departamento
  };

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      this.consultarProyecto();
      this.formRef.current.resetFields();
      this.consultarDatosDepartamento(this.props.departamento._id);
    }
  }

  onFinishFailed(e) {
    ErrorItem(e);
  }

  consultarProyecto() {
    this.setState({ loading: true });
    axios
      .post(
        "departamentos/selectDatosParaDepartamento",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
          message.error("No se encontraron proyectos");
        } else {
          this.setState({
            todosProyectos: res.data.proyectos,
            objetoProyectos: res.data.proyectos.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
  }

  consultarDatosDepartamento(id) {
    this.setState({ loading: true });
    axios
      .post(
        "departamentos/datosDepartamento",
        { departamentoId: id },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
          message.error("No se encontraron los datos del departamento");
        } else {
          const { codigo, nombre, proyectoId } = res.data.departamento;
          this.formRef.current.setFieldsValue({
            codigo,
            nombre,
            proyectoId,
          });
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Error al comunicarse con el servidor");
      });
  }

  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "departamentos/editarDepartamento",
        {
          ...values,
          departamentoId: this.props.departamento._id,
        },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Departamento actualizado exitosamente");
          this.cerrarModal();
        } else {
          message.error("No se pudo actualizar el departamento");
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Error al comunicarse con el servidor");
      });
  }

  cerrarModal = () => {
    this.props.cerrarModal();
    if (this.formRef.current) {
      this.formRef.current.resetFields();
    }
  };

  render() {
    return (
      <ModalItem
        title="Editar Departamento"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarModal}
        width={800}
      >
        <Form
          ref={this.formRef}
          name="modalEditarDepartamento"
          layout
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
              loading={this.state.loading}
            >
              Guardar
            </ButtonItem>
          }
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          <FormItem
            name="codigo"
            label="Código del Departamento"
            required
            margin
            rules={[{ required: true, message: "Por favor ingresa el código del departamento" }]}
          >
            <InputItem placeholder="Ingresa el código del departamento" />
          </FormItem>

          <FormItem
            name="nombre"
            label="Nombre del Departamento"
            required
            margin
            rules={[{ required: true, message: "Por favor ingresa el nombre del departamento" }]}
          >
            <InputItem placeholder="Ingresa el nombre del departamento" />
          </FormItem>

          <FormItem
            name="proyectoId"
            label="Nombre del Proyecto (Opcional)"
            margin
          >
            <SelectItem placeholder="Selecciona el proyecto asociado (opcional)">
              {this.state.objetoProyectos}
            </SelectItem>
          </FormItem>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => ({
  headersToken: state.user.headersToken,
  empresaId: state.empresa.id,
});

export default connect(mapStateToProps)(EditarDepartamentos);
