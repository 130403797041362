import {
    message,
    PageHeader,
    Spin,
    Select,
    Form as FormInitial,
    Divider,
    Row,
    Col,
    Input,
  } from "antd";
  import axios from "axios";
  import React, { Component } from "react";
  import { connect } from "react-redux";
  import {
    Form,
    FormItem,
    SelectItem,
    InputNumberItem,
    cuentaMask,
    DatePickerItem,
    dineroDisabledMask,
    round2Decimals,
    NotificacionPolizas,
    InputItem,
    ButtonItem,
    ErrorItem
  } from "../../../../Components/Items";
  import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
  import moment from 'moment'
import AgregarTipoDePoliza from "../../TiposDePolizas/AgregarTipoDePoliza/AgregarTipoDePoliza";
  
  const { Option } = Select;
  
  class EditarPoliza extends Component {
    formRef = React.createRef();
    state = {
      loading: false,
      objetoProveedores: null,
      todasProveedores: null,
      objetoProyectos: null,
      todasProyectos: null,
      objetoInsumos: null,
      todasInsumos: null,
      todasTiposDePolizas: null,
      objetoTiposDePolizas: null,
      todasCuentaContable: null,
      objetoCuentaContable: null,
      modalAgregarTipoDePoliza: false,
      impuestos: [],
    };
    componentDidMount() {
      this.consultarTodo();
    }
    async consultarTodo() {
      this.setState({ loading: true });
      await axios
        .post(
          "proyectos/selectProyectos",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasProyectos: res.data });
            this.setState({
              objetoProyectos: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre}
                  </Option>
                );
              }),
            });
          }
        });
        await axios
        .post(
          "cuentascontables/selectCuentasContablesFinales",
          { empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasCuentaContable: res.data });
            this.setState({
              objetoCuentaContable: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {cuentaMask(
                      value.nivel1 +
                        value.nivel2 +
                        value.nivel3 +
                        value.nivel4 +
                        value.nivel5
                    ) +
                      " - " +
                      value.descripcion}
                  </Option>
                );
              }),
            });
          }
        });
        await axios
        .post(
          "tiposdepolizas/selectTiposDePolizas",
          { empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasTiposDePolizas: res.data });
            this.setState({
              objetoTiposDePolizas: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre + " - " + value.descripcion}
                  </Option>
                );
              }),
            });
          }
        });
        await axios
        .post(
          "polizas/datosPolizaManual",
          { empresaId: this.props.empresaId, polizaId: this.props.location.state.id },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.status === 404) {
            message.error("Error no se encontro informacion de la poliza");
          } else {
            this.formRef.current.setFieldsValue({
              ...res.data,
              fechaPoliza: moment(res.data.fechaRegistro),
            });
          }
        });
      this.setState({ loading: false });
    }
    atras() {
      this.props.history.goBack();
    }
    onFinishFailed(e) {
      ErrorItem(e);
    }
    onFinish(values) {
        let total = 0
        values.cargosAbonos.map(value => {
            if(!value) return null
            if(value.cargo){
              total = round2Decimals(total + value.cargo)
            }
            if(value.abono){
              total = round2Decimals(total - value.abono)
          }
          return null
        })
        if(Math.abs(round2Decimals(total)) !== 0) return message.info('Los cargos y abonos deben coincidir')
      this.setState({ loading: true });
      axios
        .post(
          "polizas/editarPolizaManual",
          {
            ...values,
            empresaId: this.props.empresaId,
            polizaId: this.props.location.state.id,
          },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.success === 2) {
            message.success(
              "Se ha editado una poliza"
            );
            NotificacionPolizas(res.data.polizasId)
            this.formRef.current.resetFields();
            this.atras(res.data.codigo);
          } else if (res.data.success === 1) {
            message.error("No se pudo editar la poliza");
          } else {
            message.error("Hubo un error y la poliza no se edito");
          }
        });
    }
    abrirAgregarTipoDePoliza() {
        this.setState({ modalAgregarTipoDePoliza: true });
      }
      cerrarAgregarTipoDePoliza(codigo) {
        this.setState({ modalAgregarTipoDePoliza: false });
        this.consultarTodo();
      }
    render() {
      return (
        <>
          <PageHeader
            onBack={this.atras.bind(this)}
            title="Editar Poliza"
            style={{
              border: "1px solid rgb(235, 237, 240)",
              backgroundColor: "white",
              marginBottom: 10,
            }}
          />
          <div style={{ backgroundColor: "white", padding: 20 }}>
            <Spin spinning={this.state.loading}>
              <Form
                ref={this.formRef}
                name="editarPoliza"
                layout
                bottom={
                  <ButtonItem
                    type="primary"
                    htmlType="submit"
                    style={{ display: "block", margin: "0 0 0 auto" }}
                  >
                    Editar
                  </ButtonItem>
                }
                onFinish={this.onFinish.bind(this)}
                onFinishFailed={this.onFinishFailed}
              >
                <Row>
                <Col span={12}>
                    <FormItem label="Proyecto" name="proyectoId" margin required>
                        <SelectItem placeholder="Proyecto">
                        {this.state.objetoProyectos}
                        </SelectItem>
                    </FormItem>
                    </Col>
                    <Col span={12}>
                <FormItem
                    label="Fecha de Poliza"
                    name="fechaPoliza"
                    required
                    margin
                    initialValue={moment()}
                  >
                    <DatePickerItem
                      placeholder="Fecha de Poliza"
                      allowClear={false}
                    />
                  </FormItem>
                </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <FormItem
                        label="Tipo de Poliza"
                        margin
                    >
                        <Input.Group compact>
                        <FormItem name="tipoDePolizaId" required noStyle>
                            <SelectItem
                            placeholder="Tipo de Poliza"
                            width="80%"
                            >
                            {this.state.objetoTiposDePolizas}
                            </SelectItem>
                        </FormItem>
                        <ButtonItem
                            blanco
                            icon={<PlusOutlined />}
                            style={{ width: "20%" }}
                            onClick={this.abrirAgregarTipoDePoliza.bind(this)}
                        />
                        </Input.Group>
                    </FormItem>
                    </Col>
                    <Col span={12}>
                <FormItem
                    name="concepto"
                    label="Concepto"
                    margin
                    pattern
                    required
                  >
                    <InputItem placeholder="Concepto" />
                  </FormItem>
                </Col>
                </Row>
                <Divider
                  style={{ marginBottom: 10, marginTop: 5 }}
                  children="Cargos y Abonos"
                />
                   <FormInitial.List name="cargosAbonos">
                    {(fields, { add, remove }) => (
                      <>
                        <table className="table">
                          {fields.length > 0 ? (
                            <thead>
                              <tr>
                                <th className="th-border" style={{width: '25%'}}>Cuenta Contable</th>
                                <th className="th-border" style={{width: '25%'}}>Cargo</th>
                                <th className="th-border" style={{width: '25%'}}>Abono</th>
                                <th className="th-border" style={{width: '25%'}}>
                                  Concepto
                                </th>
                              </tr>
                            </thead>
                          ) : null}
                          {fields.map(
                            ({ key, name, fieldKey, ...restField }, arrayKey) => (
                              <tbody>
                                <tr>
                                  <td className="td-border">
                                    <FormItem
                                      {...restField}
                                      name={[name, "cuentaContableId"]}
                                      fieldKey={[arrayKey, "cuentaContableId"]}
                                      required
                                      noStyle
                                    >
                                      <SelectItem
                                        sinBorde
                                       autoFocus
                                        width="100%"
                                        placeholder="Cuenta"
                                      >
                                        {
                                          this.state
                                            .objetoCuentaContable
                                        }
                                      </SelectItem>
                                    </FormItem>
                                  </td>
                                  <td className="td-border">
                                  <FormItem noStyle shouldUpdate>
                                          {({getFieldValue}) => {
                                              const arrayCargosAbonos = getFieldValue('cargosAbonos')
                                              const disabled =  arrayCargosAbonos[arrayKey] ? arrayCargosAbonos[arrayKey].abono : false
                                              return (
                                                <FormItem
                                                {...restField}
                                                name={[name, "cargo"]}
                                                fieldKey={[arrayKey, "cargo"]}
                                                required={!disabled}
                                                number
                                                noStyle
                                                initialValue={undefined}
                                                >
                                                <InputNumberItem sinBorde disabled={disabled} dinero/>
                                                </FormItem>
                                              )
                                          }}
                                      </FormItem>
                                  </td>
                                  <td className="td-border">
                                      <FormItem noStyle shouldUpdate>
                                          {({getFieldValue}) => {
                                              const arrayCargosAbonos = getFieldValue('cargosAbonos')
                                              const disabled = arrayCargosAbonos[arrayKey] ? arrayCargosAbonos[arrayKey].cargo : false
                                              return (
                                                <FormItem
                                                {...restField}
                                                name={[name, "abono"]}
                                                fieldKey={[arrayKey, "abono"]}
                                                required={!disabled}
                                                number
                                                noStyle
                                                initialValue={undefined}
                                                >
                                                <InputNumberItem sinBorde disabled={disabled} dinero/>
                                                </FormItem>
                                              )
                                          }}
                                      </FormItem>
                                  </td>
                                  <td className="td-border">
                                    <FormItem
                                      {...restField}
                                      name={[name, "concepto"]}
                                      fieldKey={[
                                        arrayKey,
                                        "concepto",
                                      ]}
                                      required
                                      noStyle
                                    >
                                        <InputItem sinBorde />
                                    </FormItem>
                                  </td>
                                  <td>
                                    <MinusCircleOutlined
                                      onClick={() => remove(name)}
                                      style={{
                                        alignSelf: "center",
                                        justifySelf: "center",
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            )
                          )}
                        </table>
                        <FormItem noStyle>
                          <ButtonItem
                            blanco
                            style={{ marginTop: 10, marginBottom: 10 }}
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Agregar Campo
                          </ButtonItem>
                        </FormItem>
                      </>
                    )}
                  </FormInitial.List>
                  <Row>
                <Col span={8}></Col>
                <Col span={8}></Col>
                <Col span={8}>
                  <FormItem noStyle shouldUpdate>
                    {({getFieldValue}) => {
                      let cargosAbonos = getFieldValue('cargosAbonos')
                      if(!cargosAbonos) return null
                      let totalCargos = 0
                      let totalAbonos = 0
                      let total = 0
                      cargosAbonos.map(value => {
                          if(!value) return null
                        if(value.cargo){
                            totalCargos = round2Decimals(totalCargos + value.cargo)
                            total = round2Decimals(total + value.cargo)
                        }
                        if(value.abono){
                            totalAbonos = round2Decimals(totalAbonos + value.abono)
                            total = round2Decimals(total - value.abono)
                        }
                        return null
                      })
                      return (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th
                                    className="th-totales"
                                    style={{ width: "33%" }}
                                    >
                                    Total Cargos
                                    </th>
                                    <th
                                    className="th-totales"
                                    style={{ width: "33%" }}
                                    >
                                    Total Abonos
                                    </th>
                                    <th
                                    className="th-totales"
                                    style={{ width: "33%" }}
                                    >
                                    Saldo
                                    </th>
                                </tr>
                            </thead>
                        <tbody>
                          <tr>
                            <td
                              className="td-totales-right"
                              style={{ width: "50%" }}
                            >
                              {dineroDisabledMask(totalCargos)}
                            </td>
                            <td
                              className="td-totales-right"
                              style={{ width: "50%" }}
                            >
                              {dineroDisabledMask(totalAbonos)}
                            </td>
                            <td
                              className="td-totales-right"
                              style={{ width: "50%" }}
                            >
                              {dineroDisabledMask(Math.abs(total))}
                            </td>
                          </tr>
                        </tbody>
                        </table>
                      )
                    }}
                  </FormItem>
                </Col>
              </Row>
              </Form>
            </Spin>
          </div>
          <AgregarTipoDePoliza
          visible={this.state.modalAgregarTipoDePoliza}
          cerrarAgregarTipoDePoliza={this.cerrarAgregarTipoDePoliza.bind(this)}
        />
        </>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      headersToken: state.user.headersToken,
      empresaId: state.empresa.id,
    };
  };
  
  export default connect(mapStateToProps)(EditarPoliza);
  