import { Checkbox, Tabs, notification, Button, Select, Radio, Row, Col, Card } from "antd";
import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Form, FormItem, ModalItem, InputNumberItem, SelectItem, InputItem, ErrorItem } from '../../../../Components/Items'

const {Option} = Select
const { TabPane } = Tabs;

class AgregarEmpresaBasica extends Component {
  formRef = React.createRef();
  state = {
    persona: true,
    loading: false,
    todasPaises: null,
    objetoPaises: null,
    todasEstados: null,
    objetoEstados: null,
    todasMunicipios: null,
    objetoMunicipios: null,
    todasColonias: null,
    objetoColonias: null
  };

  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
    .post("paises/selectPaises", null, {
      headers: this.props.headersToken,
    })
    .then((res) => {
      if (res.data.status === 404) {
      } else {
        this.setState({ todasPaises: res.data });
        this.setState({
          objetoPaises: res.data.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.nombre + " - " + value.abreviatura}
              </Option>
            );
          }),
        });
      }
    });
  await axios
    .post("estados/selectEstados", null, {
      headers: this.props.headersToken,
    })
    .then((res) => {
      if (res.data.status === 404) {
      } else {
        this.setState({ todasEstados: res.data });
        this.setState({
          objetoEstados: res.data.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.nombre + " - " + value.abreviatura}
              </Option>
            );
          }),
        });
      }
    });
    this.setState({ loading: false });
  }

  cerrarAgregarEmpresaBasica() {
    this.props.cerrarAgregarEmpresaBasica();
  }

  onChangeSwich(e) {
    this.setState({ persona: e.target.checked });
  }

  onFinishFailed(e) {
    ErrorItem(e);
  }

  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "empresas/agregarEmpresaBasica",
        {
          ...values,
          persona: this.state.persona,
          usuarioId: this.props.usuarioId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.id) {
          notification.success({
            message: "Empresa",
            description: "Se agrego una empresa",
          });
          this.cerrarAgregarEmpresaBasica();
        } else if (res.data === 0) {
          notification.error({
            message: "Error",
            description: "Ha sucedido un error",
          });
          this.cerrarAgregarEmpresaBasica();
        } else {
          notification.error({
            message: "Error",
            description: "Ha sucedido un error",
          });
          this.cerrarAgregarEmpresaBasica();
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        notification.error({
          message: "Error inesperado",
          description: err.toString(),
        });
      });
  }

  onChangeEstado(value) {
    this.formRef.current.setFieldsValue({
      municipioId: undefined,
      coloniaId: undefined,
    });
    this.setState({ objetoMunicipios: null, objetoColonias: null });
    axios
      .post(
        "municipios/selectMunicipios",
        { estadoId: value },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasMunicipios: res.data });
          this.setState({
            objetoMunicipios: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
  }
  onChangeMunicipio(value) {
    this.formRef.current.setFieldsValue({ coloniaId: undefined });
    this.setState({ objetoColonias: null });
    axios
      .post(
        "colonias/selectColonias",
        { municipioId: value },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasColonias: res.data });
          this.setState({
            objetoColonias: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.cp + " - " + value.nombre}
                </Option>
              );
            }),
          });
        }
      });
  }

  render() {
    return (
      <ModalItem
        title="Agregar Empresa Basica"
        visible={this.props.visible}
        onCancel={this.cerrarAgregarEmpresaBasica.bind(this)}
        spinning={this.state.loading}
        width={800}
      >
        <Form
          name="agregarEmpresaBasica"
          layout
          bottom={
            <Button
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
            >
              Agregar
            </Button>
          }
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
          ref={this.formRef}
        >
          <Tabs defaultActiveKey={1}>
          <TabPane tab='Paquete' tabKey={1} key={1} forceRender>
              <FormItem
                noStyle
                name='paquete'
                initialValue={1}
              >
                <Radio.Group style={{width: '100%'}}>
                  <Row style={{width: '100%'}} gutter={5}>
                    <Col span={8}>
                      <Card hoverable title="Basico" extra={<Radio value={1}/>} style={{width: '100%'}}>
                        <p>15 días de prueba</p>
                        <p>$500 + IVA</p>
                        <p>Acceso a toda la plataforma</p>
                        <p>2 Usuario + Contador</p>
                        <p>Facturacion Electronica</p>
                        <p>*Por el momento regalaremos los timbres fiscales</p>
                        <p>*Los timbres se pagan individualmente</p>
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card hoverable title="Intermedio" extra={<Radio value={2}/>} style={{width: '100%'}}>
                        <p>15 días de prueba</p>
                        <p>$800 + IVA</p>
                        <p>Acceso a toda la plataforma</p>
                        <p>5 Usuarios + Contador</p>
                        <p>Facturacion Electronica</p>
                        <p>*Por el momento regalaremos los timbres fiscales</p>
                        <p>*Los timbres se pagan individualmente</p>
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card hoverable title="Estandar" extra={<Radio value={3}/>} style={{width: '100%'}}>
                        <p>15 días de prueba</p>
                        <p>$1,100 + IVA</p>
                        <p>Acceso a toda la plataforma</p>
                        <p>8 Usuarios + Contador</p>
                        <p>Atención con asesores financieros</p>
                        <p>Facturacion Electronica</p>
                        <p>*Por el momento regalaremos los timbres fiscales</p>
                        <p>*Los timbres se pagan individualmente</p>
                      </Card>
                    </Col>
                  </Row>
                </Radio.Group>
              </FormItem>
            </TabPane>
            <TabPane tab="General" tabKey={2} key={2} forceRender>
              <FormItem
                label="Rfc"
                name="rfc"
                rfc
                required
                max={200}
                min={5}
                margin
              >
                <InputItem placeholder="Registro Federal de Contribuyentes" />
              </FormItem>
              <FormItem
                label="Nombre"
                name="nombre"
                pattern
                required
                max={200}
                min={5}
                margin
              >
                <InputItem placeholder="Nombre" />
              </FormItem>
              <FormItem
                name="persona"
                label="Persona Moral"
                valuePropName="checked"
                margin
                initialValue={true}
              >
                <Checkbox
                  checked={this.state.persona}
                  onChange={this.onChangeSwich.bind(this)}
                />
              </FormItem>
              {this.state.persona ? null : (
                <>
                  <FormItem
                    label="Apellido P."
                    name="apellidoPaterno"
                    pattern
                    max={200}
                    min={5}
                    margin
                  >
                    <InputItem placeholder="Apellido Paterno" />
                  </FormItem>
                  <FormItem
                    label="Apellido M."
                    name="apellidoMaterno"
                    pattern
                    max={200}
                    min={5}
                    margin
                  >
                    <InputItem placeholder="Apellido Materno" />
                  </FormItem>
                </>
              )}
            </TabPane>
            <TabPane tab="Dirección" tabKey={3} key={3} forceRender>
                  <FormItem label="Pais" name="paisId" required margin>
                    <SelectItem placeholder="Pais">
                      {this.state.objetoPaises}
                    </SelectItem>
                  </FormItem>
                  <FormItem label="Estado" name="estadoId" required margin>
                    <SelectItem
                      placeholder="Estado"
                      onChange={this.onChangeEstado.bind(this)}
                    >
                      {this.state.objetoEstados}
                    </SelectItem>
                  </FormItem>
                  <FormItem
                    label="Municipio"
                    name="municipioId"
                    required
                    margin
                  >
                    <SelectItem
                      placeholder="Municipio"
                      onChange={this.onChangeMunicipio.bind(this)}
                    >
                      {this.state.objetoMunicipios === null ? (
                        <Option disabled>Eliga primero un estado</Option>
                      ) : (
                        this.state.objetoMunicipios
                      )}
                    </SelectItem>
                  </FormItem>
                  <FormItem
                    label="Colonia"
                    name="coloniaId"
                    required
                    margin
                  >
                    <SelectItem placeholder="Colonia">
                      {this.state.objetoColonias === null ? (
                        <Option disabled>Eliga primero un estado</Option>
                      ) : (
                        this.state.objetoColonias
                      )}
                    </SelectItem>
                  </FormItem>
                  <FormItem
                    label="Calle"
                    name="calle"
                    max={200}
                    margin
                    pattern
                  >
                    <InputItem placeholder="Calle" />
                  </FormItem>
                  <FormItem
                    label="Numero"
                    name="numeroCalle"
                    margin
                    number
                  >
                    <InputNumberItem placeholder="Numero" numeroCalle />
                  </FormItem>
                  <FormItem
                    label="Numero Interior"
                    name="numeroInterior"
                    margin
                  >
                    <InputNumberItem placeholder="Numero Interior" numeroCalle />
                  </FormItem>
            </TabPane>
            <TabPane tab="Avanzado" tabKey={4} key={4} forceRender>
              {this.state.persona ? null : (
                <FormItem
                  label="Curp"
                  name="curp"
                  curp
                  max={200}
                  min={5}
                  margin
                >
                  <InputItem placeholder="Clave Unica de Registro de Poblacion" />
                </FormItem>
              )}
              <FormItem label="Telefono" name="telefono" number margin>
                <InputNumberItem placeholder="Telefono" celular />
              </FormItem>
              <FormItem
                label="Correo"
                name="correo"
                pattern
                max={200}
                email
                margin
              >
                <InputItem placeholder="Correo" />
              </FormItem>
              <FormItem
                label="Pagina Web"
                name="paginaWeb"
                pattern
                max={200}
                margin
              >
                <InputItem placeholder="Pagina Web" />
              </FormItem>
              <FormItem
                label="Nombre Contacto"
                name="nombreContacto"
                pattern
                max={200}
                margin
              >
                <InputItem placeholder="Nombre de Contacto" />
              </FormItem>
              <FormItem
                label="Telefono Contacto"
                name="telefonoContacto"
                number
                margin
              >
                <InputNumberItem placeholder="Telefono de Contacto" celular />
              </FormItem>
              <FormItem
                label="Correo Contacto"
                name="correoContacto"
                pattern
                max={200}
                email
                margin
              >
                <InputItem placeholder="Correo de Contacto" />
              </FormItem>
              <FormItem name="banco" label="Banco" margin pattern>
              <InputItem placeholder="Banco" />
            </FormItem>
              <FormItem
                label="Cuenta Banco"
                name="cuentaBanco"
                number
                max={20}
                margin
              >
                <InputItem placeholder="Cuenta de Banco" />
              </FormItem>
              <FormItem
                label="Cuenta Clabe"
                name="cuentaClabe"
                number
                max={20}
                min={18}
                margin
              >
                <InputItem placeholder="Cuenta Clabe" />
              </FormItem>
            </TabPane>
          </Tabs>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
  };
};

export default connect(mapStateToProps)(AgregarEmpresaBasica);
