import React, { Component } from "react";
import { message, Row, Col, Spin } from "antd";
import { ButtonItem, ErrorItem, Form, FormItem, InputItem, InputNumberItem } from "../../../../Components/Items";
import axios from "axios";
import { connect } from "react-redux";


class DireccionConfiguracion extends Component {
  formRef = React.createRef();
  state = {
    archivo: null,
    base64: null,
    archivosLogo: [],
    persona: false,
    loading: false
  };
  componentDidMount() {
    this.consultarConfiguracion();
  }
  onFinish(values) {
    axios
      .post(
        "empresas/editarConfiguracionAvanzado",
        {...values, empresaId: this.props.empresaId},
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Se ha modificado la configuracion de la empresa");
          this.consultarConfiguracion();
        } else if (res.data.success === 1) {
          message.error("No se pudo modificar la configuracion");
        } else {
          message.error("Hubo un error y no se modifico la configuracion");
        }
      });
  }
  async consultarConfiguracion() {
    this.setState({loading: true})
    await axios
      .post(
        "empresas/selectConfiguracionAvanzado",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro configuracion de esta empresa");
        } else {
            this.setState({persona: res.data.persona})
          this.formRef.current.setFieldsValue({
            ...res.data
          });
        }
      });
      this.setState({loading: false})
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }
  render() {
    return (
      <>
      <Spin spinning={this.state.loading}>
        <Form
          name="avanzadoConfiguracion"
          layout
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
          ref={this.formRef}
          loading={this.state.loading}
        >
          <Row>
            <Col span={12}>
            {this.state.persona ? null : (
                <FormItem
                  label="Curp"
                  name="curp"
                  curp
                  max={200}
                  min={5}
                  margin
                >
                  <InputItem placeholder="Clave Unica de Registro de Poblacion" />
                </FormItem>
              )}
              <FormItem label="Telefono" name="telefono" number margin>
                <InputNumberItem placeholder="Telefono" celular />
              </FormItem>
              <FormItem
                label="Correo"
                name="correo"
                pattern
                max={200}
                email
                margin
              >
                <InputItem placeholder="Correo" />
              </FormItem>
              <FormItem
                label="Pagina Web"
                name="paginaWeb"
                pattern
                max={200}
                margin
              >
                <InputItem placeholder="Pagina Web" />
              </FormItem>
              <FormItem name="banco" label="Banco" margin pattern>
                <InputItem placeholder="Banco" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Nombre Contacto"
                name="nombreContacto"
                pattern
                max={200}
                margin
              >
                <InputItem placeholder="Nombre de Contacto" />
              </FormItem>
              <FormItem
                label="Telefono Contacto"
                name="telefonoContacto"
                number
                margin
              >
                <InputNumberItem placeholder="Telefono de Contacto" celular />
              </FormItem>
              <FormItem
                label="Correo Contacto"
                name="correoContacto"
                pattern
                max={200}
                email
                margin
              >
                <InputItem placeholder="Correo de Contacto" />
              </FormItem>
              <FormItem
                label="Cuenta Banco"
                name="cuentaBanco"
                number
                max={20}
                margin
              >
                <InputItem placeholder="Cuenta de Banco" />
              </FormItem>
              <FormItem
                label="Cuenta Clabe"
                name="cuentaClabe"
                number
                max={20}
                min={18}
                margin
              >
                <InputItem placeholder="Cuenta Clabe" />
              </FormItem>
            </Col>
          </Row>
          <ButtonItem
            type="primary"
            htmlType="submit"
            style={{ display: "block", margin: "0 0 0 auto" }}
          >
            Guardar Configuracion
          </ButtonItem>
        </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(DireccionConfiguracion);
