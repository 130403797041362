import {  Checkbox, message, Select } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  SelectItem,
  ButtonItem,
  ErrorItem,
} from "../../../../Components/Items";

const { Option } = Select;

class ElegirTipoDeProveedor extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    objetoTipoDeProveedor: null,
    todasTipoDeProveedor: null,
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  cerrarElegirTipoDeProveedor(elegido) {
    this.props.cerrarElegirTipoDeProveedor(elegido);
  }
  async consultarTodo() {
    await axios
      .post(
        "tiposdeproveedores/selectTiposDeProveedores",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasTipoDeProveedor: res.data });
          this.setState({
            objetoTipoDeProveedor: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "proveedores/elegirTipoDeProveedor",
        { ...values, empresaId: this.props.empresaId, proveedorId: this.props.id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se edito el proveedor");
          this.formRef.current.resetFields();
          this.cerrarElegirTipoDeProveedor(true);
        } else if (res.data.success === 1) {
          message.error("No se puede editar el proveedor");
        } else {
          message.error("Hubo un error y el proveedor no se edito");
        }
      });
  }
  onChangeTipoDeProveedor(value) {
    const tipoDeProveedor = this.state.todasTipoDeProveedor.find(
      (valueTodos) => valueTodos._id.toString() === value.toString()
    );
    if (tipoDeProveedor.generarCuentasAutomatico) {
      this.formRef.current.setFieldsValue({
        generarCuentaAnticipoId: false,
        generarCuentaFonGarId: false,
        generarCuentaDeudorId: false,
      });
    } else {
      this.formRef.current.setFieldsValue({
        generarCuentaAnticipoId: false,
        generarCuentaFonGarId: false,
        generarCuentaDeudorId: false,
      });
    }
  }
  render() {
    return (
      <>
        <ModalItem
          title={"Elegir Tipo de Proveedor: " + this.props.nombre}
          visible={this.props.visible}
          spinning={this.state.loading}
          paddingTop
          onCancel={() => this.cerrarElegirTipoDeProveedor(false)}
        >
          <Form
            ref={this.formRef}
            name="elegirTipoDeProveedor"
            layout
            bottom={
              <ButtonItem
                type="primary"
                htmlType="submit"
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Ok
              </ButtonItem>
            }
            onFinish={this.onFinish.bind(this)}
            onFinishFailed={this.onFinishFailed}
          >
                      <FormItem
                        label="Tipo de Proveedor"
                        margin
                        name="tipoProveedorId"
                        required
                      >
                        <SelectItem placeholder="Tipo de Proveedor" onChange={this.onChangeTipoDeProveedor.bind(this)}>
                          {this.state.objetoTipoDeProveedor}
                        </SelectItem>
                      </FormItem>
            <FormItem shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const tipoDeProveedorId = getFieldValue("tipoProveedorId");
                const tipoDeProveedor = tipoDeProveedorId
                  ? this.state.todasTipoDeProveedor.find(
                      (valueTodos) =>
                        valueTodos._id.toString() === tipoDeProveedorId.toString()
                    )
                  : null;
                const disabled = tipoDeProveedor?.generarCuentasAutomatico
                  ? false
                  : true;
                return (
                  <>
                    <FormItem
                      label="Generar Cuenta Anticipo"
                      specialLayout
                      margin
                      name="generarCuentaAnticipoId"
                      valuePropName="checked"
                      initialValue={false}
                    >
                      <Checkbox disabled={disabled}></Checkbox>
                    </FormItem>
                    <FormItem
                      label="Generar Cuenta Fon. Gar."
                      specialLayout
                      margin
                      name="generarCuentaFonGarId"
                      valuePropName="checked"
                      initialValue={false}
                    >
                      <Checkbox disabled={disabled}></Checkbox>
                    </FormItem>
                    <FormItem
                      label="Generar Cuenta Deudor"
                      specialLayout
                      margin
                      name="generarCuentaDeudorId"
                      valuePropName="checked"
                      initialValue={false}
                    >
                      <Checkbox disabled={disabled}></Checkbox>
                    </FormItem>
                  </>
                );
              }}
            </FormItem>
          </Form>
        </ModalItem>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(ElegirTipoDeProveedor);
