import { Button, message, Row, Col, Checkbox, Tabs } from "antd";
import axios from "axios";
import { Divider } from "rc-menu";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  TableItem,
  InputItem,
  ButtonItem,
  ErrorItem,
} from "../../../../Components/Items";

const { TabPane } = Tabs;

class EditarPerfil extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    todasPermisos: null,
    permisosObject: null,
    permisosValues: [],
    activeKey: undefined
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  cerrarEditarPerfil(codigo) {
    this.props.cerrarEditarPerfil(codigo);
  }


  
  async seleccionarTodos() {

    await this.setState({
      permisosValues: this.state.permisosValues.map((valuePermiso) => {
        const id = valuePermiso.permisoId.toString();
        return { permisoId: id, activado: true }
      }),
      permisosObject: null
    })

    //Tabla
    let columns = [
      {
        title: "Subgrupo",
        dataIndex: "subgrupo",
        key: "subgrupo",
        render: (value) => value.nombre,
      },
      {
        title: "Permiso",
        dataIndex: "nombre",
        key: "nombre",
      },
      {
        title: "Activar",
        dataIndex: "_id",
        key: "_id",
        render: (text) => {
          const id = text.toString();
          const index = this.state.permisosValues.findIndex(valueA => valueA.permisoId === id)
          const onChange = (e) => {
            let nuevoArray = [...this.state.permisosValues]
            nuevoArray[index].activado = e.target.checked
            this.setState({
              permisosValues: nuevoArray,
            });
          };
          return (
            <Checkbox
              value={this.state.permisosValues[index].activado}
              defaultChecked={this.state.permisosValues[index].activado}
              onChange={onChange}
            />
          );
        },
      },
    ];
    this.setState({
      permisosObject: this.state.todasPermisos.map((value) => {
        return (
          <TabPane tab={value.nombre} key={value.grupoId} forceRender>
            {value.permisos.length > 0 ? (
              <>
                <ButtonItem size="small" style={{ margin: "0 0 0 auto" }} blanco onClick={() => this.seleccionarTodosGrupo(value.grupoId)} >Seleccionar</ButtonItem>
                <TableItem
                  pagination={false}
                  size="small"
                  columns={columns}
                  dataSource={value.permisos}
                />
              </>
            ) : (
              <p>No se encontraron permisos para este grupo</p>
            )}
          </TabPane>
        );
      }),
    });


  }

  async seleccionarTodosGrupo(grupoId) {

    let permisosValues = []

    for (const valueGrupo of this.state.todasPermisos) {
      for (const valuePermiso of valueGrupo.permisos) {
        const id = valuePermiso._id.toString();
        if (valueGrupo._id.toString() === grupoId.toString()) {
          permisosValues.push({ permisoId: id, activado: true });
        } else {
          const index = this.state.permisosValues.findIndex(valueA => valueA.permisoId === id)
          if (index === -1) {
            permisosValues.push({ permisoId: id, activado: false });
          } else {
            permisosValues.push({ permisoId: id, activado: this.state.permisosValues[index].activado });
          }

        }
      }
    }

    await this.setState({
      permisosObject: null,
      permisosValues: permisosValues,
    })

    let columns = [
      {
        title: "Subgrupo",
        dataIndex: "subgrupo",
        key: "subgrupo",
        render: (value) => value.nombre,
      },
      {
        title: "Permiso",
        dataIndex: "nombre",
        key: "nombre",
      },
      {
        title: "Activar",
        dataIndex: "_id",
        key: "_id",
        render: (text) => {
          const id = text.toString();
          const index = this.state.permisosValues.findIndex(valueA => valueA.permisoId === id)
          const onChange = (e) => {
            let nuevoArray = [...this.state.permisosValues]
            nuevoArray[index].activado = e.target.checked
            this.setState({
              permisosValues: nuevoArray,
            });
          };
          return (
            <Checkbox
              value={this.state.permisosValues[index].activado}
              defaultChecked={this.state.permisosValues[index].activado}
              onChange={onChange}
            />
          );
        },
      },
    ];
    this.setState({
      permisosObject: this.state.todasPermisos.map((value) => {
        return (
          <TabPane tab={value.nombre} key={value.grupoId} forceRender>
            {value.permisos.length > 0 ? (
              <>
                <ButtonItem size="small" style={{ margin: "0 0 0 auto" }} blanco onClick={() => this.seleccionarTodosGrupo(value.grupoId)} >Seleccionar</ButtonItem>
                <TableItem
                  pagination={false}
                  size="small"
                  columns={columns}
                  dataSource={value.permisos}
                />
              </>
            ) : (
              <p>No se encontraron permisos para este grupo</p>
            )}
          </TabPane>
        );
      }),
    });

  }

  async consultarTodo(cargar) {
    this.setState({ loading: true });
    if(!cargar){
      await axios
      .post(
        "perfiles/datosPerfil",
        { perfilId: this.props.id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro informacion del perfil");
        } else {
          this.formRef.current.setFieldsValue({
            ...res.data,
          });
          this.setState({
            permisosValues: res.data.permisos.map((valuePermiso) => {
                  const id = valuePermiso.permisoId.toString();
                return { permisoId: id, activado: valuePermiso.activado }
            }),
          })
        }
      });
    }
    await axios
      .post("permisos/selectPermisosPGrupos", null,{
        headers: this.props.headersToken,
      })
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          let permisosValues = [];
          res.data.map((valueGrupo) => {
            valueGrupo.permisos.map((valuePermiso) => {
              const id = valuePermiso._id.toString();
              const index = this.state.permisosValues.findIndex(valueA => valueA.permisoId === id)
              if(index === -1){
                  permisosValues.push({ permisoId: id, activado: false });
              }
              return null;
            });
            return null;
          });
          this.setState({ permisosValues: [...this.state.permisosValues, ...permisosValues], todasPermisos: res.data }, () => {
              //Tabla
              let columns = [
                {
                  title: "Subgrupo",
                  dataIndex: "subgrupo",
                  key: "subgrupo",
                  render: (value) => value.nombre,
                },
                {
                  title: "Permiso",
                  dataIndex: "nombre",
                  key: "nombre",
                },
                {
                  title: "Activar",
                  dataIndex: "_id",
                  key: "_id",
                  render: (text) => {
                    const id = text.toString();
                    const index = this.state.permisosValues.findIndex(valueA => valueA.permisoId === id)
                    const onChange = (e) => {
                        let nuevoArray = [...this.state.permisosValues]
                        nuevoArray[index].activado = e.target.checked
                      this.setState({
                        permisosValues: nuevoArray,
                      });
                    };
                    return (
                      <Checkbox
                        value={this.state.permisosValues[index].activado}
                        defaultChecked={this.state.permisosValues[index].activado}
                        onChange={onChange}
                      />
                    );
                  },
                },
              ];
              this.setState({
                permisosObject: res.data.map((value) => {
                  return (
                    <TabPane tab={value.nombre} key={value.grupoId} forceRender>
                      {value.permisos.length > 0 ? (
                        <>
                        <ButtonItem size="small" style={{margin: "0 0 0 auto"}} blanco onClick={() => this.seleccionarTodosGrupo(value.grupoId)} >Seleccionar</ButtonItem>
                          <TableItem
                            pagination={false}
                            size="small"
                            columns={columns}
                            dataSource={value.permisos}
                          />
                        </>
                      ) : (
                        <p>No se encontraron permisos para este grupo</p>
                      )}
                    </TabPane>
                  );
                }),
              });
          });

        }
      });
      this.setState({ loading: false });
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "perfiles/editarPerfil",
        { ...values, permisos: this.state.permisosValues, perfilId: this.props.id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se ha editado un perfil");
          this.formRef.current.resetFields();
          this.setState({
            todasPermisos: null,
            permisosObject: null
          }, () => {
            this.setState({
              loading: false,
              permisosValues: [],
            })
          })
          this.cerrarEditarPerfil(res.data.codigo);
        } else if (res.data.success === 1) {
          message.error("No se puede editar el perfil");
        } else {
          message.error("Hubo un error y el perfil no se edito");
        }
      });
  }
  abrirAgregarCuentaContable() {
    this.setState({ modalAgregarCuentaContable: true });
  }
  cerrarAgregarCuentaContable(codigo) {
    this.setState({ modalAgregarCuentaContable: false });
    this.consultarTodo();
  }


  async cerrarModalEditarPerfil(){
    this.formRef.current.resetFields();
    await this.setState({
      todasPermisos: null,
      permisosObject: null
    }, () => {
      this.setState({
        loading: false,
        permisosValues: [],
      })
    })
    this.cerrarEditarPerfil()
  }
  render() {
    return (
      <>
        <ModalItem
          title="Editar Perfil"
          visible={this.props.visible}
          spinning={this.state.loading}
          onCancel={this.cerrarModalEditarPerfil.bind(this)}
          paddingTop
          width="70%"
        >
          <Form
            ref={this.formRef}
            name="editarPerfil"
            layout
            bottom={
              <Button
                type="primary"
                htmlType="submit"
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Guardar
              </Button>
            }
            onFinish={this.onFinish.bind(this)}
            onFinishFailed={this.onFinishFailed}
          >
            <Row>
              <Col span={12}>
                <FormItem
                  name="nombre"
                  label="Nombre"
                  required
                  max={100}
                  margin
                  pattern
                >
                  <InputItem placeholder="Nombre" />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  name="descripcion"
                  label="Descripcion"
                  required
                  max={100}
                  margin
                  pattern
                >
                  <InputItem placeholder="Descripcion" />
                </FormItem>
              </Col>
            </Row>
            <Divider
              style={{ marginBottom: 10, marginTop: 5 }}
              children="Permisos"
            />
            <Row>
              <Col span={24}>
              {this.state.permisosObject ? (
                  <Tabs activeKey={this.state.activeKey} onTabClick={(activeKey) => this.setState({activeKey})}  tabBarExtraContent={(
                    <ButtonItem size="small" blanco onClick={this.seleccionarTodos.bind(this)} >Todos</ButtonItem>
                  )}>{this.state.permisosObject}</Tabs>
                ) : null}
              </Col>
            </Row>
          </Form>
        </ModalItem>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    usuarioPrincipal: state.user.usuarioPrincipal,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(EditarPerfil);
