import {
    message,
    Tabs,
    Select,
    PageHeader,
    Spin,
    Row,
    Col,
    Space,
    TreeSelect,
    Popconfirm,
    Tooltip,
} from "antd";
import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
    Form,
    FormItem,
    ButtonItem,
    InputItem,
    DatePickerItem,
    SelectItem,
    InputNumberItem,
    round10Decimals,
    round2Decimals,
    dineroDisabledMask,
    TableItem,
} from "../../../../Components/Items";
import moment from "moment";
import { CalculatorOutlined, MinusCircleOutlined, PlusCircleOutlined, } from "@ant-design/icons";
import { Gantt,/*Task, EventOption, StylingOption, ViewMode, DisplayOption*/ } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import "moment/locale/es";
// import { GanttComponent } from "@syncfusion/ej2-react-gantt";

moment.locale('es');

const { TabPane } = Tabs;
const { Option } = Select;

class PresupuestoPU extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        buscarInfoCode: false,
        archivosPrincipal: [],
        archivosTecnica: [],
        archivosFotos: [],
        todasMatrices: null,
        objetoMatrices: null,
        loadingExplosion: false,
        loadingTabla: false,
        todasInsumos: null,
        objetoInsumos: null,
        todasProyectos: null,
        objetoProyectos: null,
        insumosExplosionados: [],
        costoTotalIE: 0,
        partidasArbol: [],
        partidas: [],
        keyEditing: '',
        expandedRowKeys: [],
        confirmado: false,
        id: this.props?.location?.state?.id,
        valueExplosion: [],
        tasksDiagrama: [
            {
                id: '1',
                name: 'Cargando',
                start: moment().toDate(),
                end: moment().add(1, 'd').toDate(),
                progress: 50,
                type: 'project',
                hideChildren: false,
            },
        ],
    };
    atras() {
        this.props.history.goBack();
    }
    componentDidMount() {
        this.consultarTodo();
    }
    async consultarTodo() {
        this.setState({ loading: true });
        await axios
            .post(
                "presupuestosPU/selectDatosParaPresupuestoPU",
                { empresaId: this.props.empresaId },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                if (res.data.status === 404) {
                } else {
                    this.setState({
                        todasMatrices: res.data.matrices,
                        todasInsumos: res.data.insumos,
                        todasProyectos: res.data.proyectos,
                        objetoMatrices: res.data.matrices.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.stringBuscar + ' ' + dineroDisabledMask(value.costo)}
                                </Option>
                            );
                        }),
                        objetoProyectos: res.data.proyectos.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                    })
                }
            });
        if (this.state.id) {
            await axios
                .post(
                    "presupuestosPU/datosPresupuesto",
                    { empresaId: this.props.empresaId, presupuestoId: this.state.id },
                    { headers: this.props.headersToken }
                )
                .then((res) => {
                    if (res.data.success === 2) {
                        this.formRef.current.setFieldsValue({
                            ...res.data.presupuesto,
                            fechaInicio: moment(res.data.presupuesto.fechaInicio),
                            fechaFinal: moment(res.data.presupuesto.fechaFinal)
                        });
                        const nuevasPartidas = res.data.presupuesto.confirmado ? res.data.presupuesto.partidas : this.calcularPresupuesto(res.data.presupuesto.partidas)
                        this.setState({
                            partidas: nuevasPartidas,
                            confirmado: res.data.presupuesto.confirmado
                        })
                    } else {
                        message.error("Error no se encontro informacion del presupuesto");
                    }
                });
        }
        this.setState({ loading: false });
    }

    onFinishFailed(e) {
        message.error("Porfavor ingrese bien los datos");
    }

    onFinish(values) {
        const partidas = [...this.state.partidas]
        if (partidas) {
            for (const [indexP1, valueP1] of partidas.entries()) {
                if (valueP1.children) {
                    for (const [indexP2, valueP2] of valueP1.children.entries()) {
                        if (valueP2.children) {
                            for (const [indexP3, valueP3] of valueP2.children.entries()) {
                                if (valueP3.children) {
                                    for (const [indexP4, valueP4] of valueP3.children.entries()) {
                                        if (valueP4.children) {
                                            for (const [indexP5, valueP5] of valueP4.children.entries()) {
                                                if (valueP5.children) {
                                                    for (const [indexP6, valueP6] of valueP5.children.entries()) {
                                                        if (!valueP6.nombre || !valueP6.cantidad || valueP6.cantidad === 0 || (valueP6.tipo !== 0 && valueP6.tipo !== 1 && !valueP6.insumoMatriz)) {
                                                            return message.warning('Falta un dato en la partida: ' + (indexP1 + 1) + '-' + (indexP2 + 1) + '-' + (indexP3 + 1) + '-' + (indexP4 + 1) + '-' + (indexP5 + 1) + '-' + (indexP6 + 1))
                                                        }
                                                    }
                                                }
                                                if (!valueP5.nombre || !valueP5.cantidad || valueP5.cantidad === 0 || (valueP5.tipo !== 0 && valueP5.tipo !== 1 && !valueP5.insumoMatriz)) {
                                                    return message.warning('Falta un dato en la partida: ' + (indexP1 + 1) + '-' + (indexP2 + 1) + '-' + (indexP3 + 1) + '-' + (indexP4 + 1) + '-' + (indexP5 + 1))
                                                }
                                            }
                                        }
                                        if (!valueP4.nombre || !valueP4.cantidad || valueP4.cantidad === 0 || (valueP4.tipo !== 0 && valueP4.tipo !== 1 && !valueP4.insumoMatriz)) {
                                            return message.warning('Falta un dato en la partida: ' + (indexP1 + 1) + '-' + (indexP2 + 1) + '-' + (indexP3 + 1) + '-' + (indexP4 + 1))
                                        }
                                    }
                                }
                                if (!valueP3.nombre || !valueP3.cantidad || valueP3.cantidad === 0 || (valueP3.tipo !== 0 && valueP3.tipo !== 1 && !valueP3.insumoMatriz)) {
                                    return message.warning('Falta un dato en la partida: ' + (indexP1 + 1) + '-' + (indexP2 + 1) + '-' + (indexP3 + 1))
                                }
                            }
                        }
                        if (!valueP2.nombre || !valueP2.cantidad || valueP2.cantidad === 0 || (valueP2.tipo !== 0 && valueP2.tipo !== 1 && !valueP2.insumoMatriz)) {
                            return message.warning('Falta un dato en la partida: ' + (indexP1 + 1) + '-' + (indexP2 + 1))
                        }
                    }
                }
                if (!valueP1.nombre || !valueP1.cantidad || valueP1.cantidad === 0 || (valueP1.tipo !== 0 && valueP1.tipo !== 1 && !valueP1.insumoMatriz)) {
                    return message.warning('Falta un dato en la partida: ' + (indexP1 + 1))
                }
            }
        }
        this.setState({ loading: true });
        if (this.state.id) {
            axios
                .post("presupuestosPU/editarPresupuesto", { ...values, partidas: this.state.partidas, presupuestoId: this.state.id, empresaId: this.props.empresaId }, {
                    headers: {
                        ...this.props.headersToken,
                    },
                })
                .then((res) => {
                    this.setState({ loading: false, id: res.data.presupuestoId });
                    if (res.data.success === 2) {
                        message.success("Se ha editado un presupuesto");
                        this.consultarTodo()
                    } else if (res.data.success === 1) {
                        message.error("No se puede editar el presupuesto");
                    } else {
                        message.error("Hubo un error y el presupuesto no se edito");
                    }
                });
        } else {
            axios
                .post("presupuestosPU/agregarPresupuesto", { ...values, partidas: this.state.partidas, presupuestoId: this.state.id, empresaId: this.props.empresaId }, {
                    headers: {
                        ...this.props.headersToken,
                    },
                })
                .then((res) => {
                    this.setState({ loading: false, id: res.data.presupuestoId });
                    if (res.data.success === 2) {
                        message.success("Se ha agregado un presupuesto");
                        this.consultarTodo()
                    } else if (res.data.success === 1) {
                        message.error("No se puede agregar el presupuesto");
                    } else {
                        message.error("Hubo un error y el presupuesto no se agrego");
                    }
                });
        }
    }

    async explosionInsumos() {
        await this.setState({ loadingExplosion: true })
        const partidas = [...this.state.partidas]

        let insumosExplosionados = []

        let costoTotalIE = 0

        if (partidas) {

            for (const valueP1 of partidas) {
                if (valueP1.children) {

                    for (const valueP2 of valueP1.children) {

                        if (valueP2.children) {
                            for (const valueP3 of valueP2.children) {
                                if (valueP3.children) {
                                    for (const valueP4 of valueP3.children) {
                                        if (valueP4.children) {
                                            for (const valueP5 of valueP4.children) {
                                                if (valueP5.tipo === 1) {
                                                    if (valueP5.nombre) {
                                                        if (valueP5.children) {

                                                            for (const insumo of valueP5.children) {
                                                                let cantidadTotal = 1
                                                                if (valueP5.cantidad && valueP4.cantidad && valueP3.cantidad && valueP2.cantidad && valueP1.cantidad) {
                                                                    cantidadTotal = round10Decimals(insumo.cantidad * valueP5.cantidad * valueP4.cantidad * valueP3.cantidad * valueP2.cantidad * valueP1.cantidad)
                                                                }
                                                                const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString())
                                                                if (indexIE === -1) {
                                                                    const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString())
                                                                    insumosExplosionados.push({
                                                                        insumoId: insumo.insumoId,
                                                                        nombre: insumoBuscar.stringBuscar,
                                                                        unidad: insumoBuscar.unidad.nombre,
                                                                        catidadMinCompra: insumoBuscar?.catidadMinCompra,
                                                                        costo: insumoBuscar.costo,
                                                                        costoTotal: round2Decimals(insumoBuscar.costo * cantidadTotal),
                                                                        cantidadPedida: round10Decimals(insumo.cantidadPedida || 0),
                                                                        cantidad: cantidadTotal
                                                                    })
                                                                    costoTotalIE += round2Decimals(insumoBuscar.costo * cantidadTotal)
                                                                } else {
                                                                    insumosExplosionados[indexIE].cantidad = round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal)
                                                                    insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal))
                                                                    insumosExplosionados[indexIE].cantidadPedida = round10Decimals(insumosExplosionados[indexIE].cantidadPedida + (insumo.cantidadPedida || 0))
                                                                    costoTotalIE += round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal)
                                                                }
                                                            }

                                                        }

                                                    }
                                                }
                                            }
                                        }
                                        if (valueP4.tipo === 1) {
                                            if (valueP4.nombre) {
                                                if (valueP4.children) {

                                                    for (const insumo of valueP4.children) {
                                                        let cantidadTotal = 1
                                                        if (valueP4.cantidad && valueP3.cantidad && valueP2.cantidad && valueP1.cantidad) {
                                                            cantidadTotal = round10Decimals(insumo.cantidad * valueP4.cantidad * valueP3.cantidad * valueP2.cantidad * valueP1.cantidad)
                                                        }
                                                        const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString())
                                                        if (indexIE === -1) {
                                                            const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString())
                                                            insumosExplosionados.push({
                                                                insumoId: insumo.insumoId,
                                                                nombre: insumoBuscar.stringBuscar,
                                                                unidad: insumoBuscar.unidad.nombre,
                                                                costo: insumoBuscar.costo,
                                                                catidadMinCompra: insumoBuscar?.catidadMinCompra,
                                                                costoTotal: round2Decimals(insumoBuscar.costo * cantidadTotal),
                                                                cantidadPedida: round10Decimals(insumo.cantidadPedida || 0),
                                                                cantidad: cantidadTotal
                                                            })
                                                            costoTotalIE += round2Decimals(insumoBuscar.costo * cantidadTotal)
                                                        } else {
                                                            insumosExplosionados[indexIE].cantidad = round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal)
                                                            insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal))
                                                            insumosExplosionados[indexIE].cantidadPedida = round10Decimals(insumosExplosionados[indexIE].cantidadPedida + (insumo.cantidadPedida || 0))
                                                            costoTotalIE += round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal)
                                                        }
                                                    }

                                                }

                                            }
                                        }
                                    }
                                }

                                if (valueP3.tipo === 1) {
                                    if (valueP3.nombre) {
                                        if (valueP3.children) {

                                            for (const insumo of valueP3.children) {
                                                let cantidadTotal = 1
                                                if (valueP3.cantidad && valueP2.cantidad && valueP1.cantidad) {
                                                    cantidadTotal = round10Decimals(insumo.cantidad * valueP3.cantidad * valueP2.cantidad * valueP1.cantidad)
                                                }
                                                const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString())
                                                if (indexIE === -1) {
                                                    const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString())
                                                    insumosExplosionados.push({
                                                        insumoId: insumo.insumoId,
                                                        nombre: insumoBuscar.stringBuscar,
                                                        unidad: insumoBuscar.unidad.nombre,
                                                        costo: insumoBuscar.costo,
                                                        catidadMinCompra: insumoBuscar?.catidadMinCompra,
                                                        costoTotal: round2Decimals(insumoBuscar.costo * cantidadTotal),
                                                        cantidadPedida: round10Decimals(insumo.cantidadPedida || 0),
                                                        cantidad: cantidadTotal
                                                    })
                                                    costoTotalIE += round2Decimals(insumoBuscar.costo * cantidadTotal)
                                                } else {
                                                    insumosExplosionados[indexIE].cantidad = round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal)
                                                    insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal))
                                                    insumosExplosionados[indexIE].cantidadPedida = round10Decimals(insumosExplosionados[indexIE].cantidadPedida + (insumo.cantidadPedida || 0))
                                                    costoTotalIE += round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal)
                                                }
                                            }

                                        }

                                    }
                                }
                            }

                        }

                        if (valueP2.tipo === 1) {
                            if (valueP2.nombre) {
                                if (valueP2.children) {

                                    for (const insumo of valueP2.children) {
                                        let cantidadTotal = 1
                                        if (valueP2.cantidad && valueP1.cantidad) {
                                            cantidadTotal = round10Decimals(insumo.cantidad * valueP2.cantidad * valueP1.cantidad)
                                        }
                                        const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString())
                                        if (indexIE === -1) {
                                            const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString())
                                            insumosExplosionados.push({
                                                insumoId: insumo.insumoId,
                                                nombre: insumoBuscar.stringBuscar,
                                                unidad: insumoBuscar.unidad.nombre,
                                                costo: insumoBuscar.costo,
                                                catidadMinCompra: insumoBuscar?.catidadMinCompra,
                                                costoTotal: round2Decimals(insumoBuscar.costo * cantidadTotal),
                                                cantidadPedida: round10Decimals(insumo.cantidadPedida || 0),
                                                cantidad: cantidadTotal
                                            })
                                            costoTotalIE += round2Decimals(insumoBuscar.costo * cantidadTotal)
                                        } else {
                                            insumosExplosionados[indexIE].cantidad = round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal)
                                            insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal))
                                            insumosExplosionados[indexIE].cantidadPedida = round10Decimals(insumosExplosionados[indexIE].cantidadPedida + (insumo.cantidadPedida || 0))
                                            costoTotalIE += round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal)
                                        }
                                    }

                                }

                            }
                        }
                    }

                }

                if (valueP1.tipo === 1) {
                    if (valueP1.nombre) {
                        if (valueP1.children) {

                            for (const insumo of valueP1.children) {
                                let cantidadTotal = 1
                                if (valueP1.cantidad) {
                                    cantidadTotal = round10Decimals(insumo.cantidad * valueP1.cantidad)
                                }
                                const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString())
                                if (indexIE === -1) {
                                    const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString())
                                    insumosExplosionados.push({
                                        insumoId: insumo.insumoId,
                                        nombre: insumoBuscar.stringBuscar,
                                        unidad: insumoBuscar.unidad.nombre,
                                        costo: insumoBuscar.costo,
                                        catidadMinCompra: insumoBuscar?.catidadMinCompra,
                                        costoTotal: round2Decimals(insumoBuscar.costo * cantidadTotal),
                                        cantidadPedida: round10Decimals(insumo.cantidadPedida || 0),
                                        cantidad: cantidadTotal
                                    })
                                    costoTotalIE += round2Decimals(insumoBuscar.costo * cantidadTotal)
                                } else {
                                    insumosExplosionados[indexIE].cantidad = round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal)
                                    insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal))
                                    insumosExplosionados[indexIE].cantidadPedida = round10Decimals(insumosExplosionados[indexIE].cantidadPedida + (insumo.cantidadPedida || 0))
                                    costoTotalIE += round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal)
                                }
                            }

                        }

                    }
                }
            }
        }

        const partidasArbol = partidas ? partidas.map((valueP1, indexP1) => {
            const nuevasPartidas2 = valueP1.children ? valueP1.children.map((valueP2, indexP2) => {
                const nuevasPartidas3 = valueP2.children ? valueP2.children.map((valueP3, indexP3) => {
                    const nuevasPartidas4 = valueP3.children ? valueP3.children.map((valueP4, indexP4) => {
                        const nuevasPartidas5 = valueP4.children ? valueP4.children.map((valueP5, indexP5) => {

                            const nuevasPartidas6 = valueP5.children ? valueP5.children.map((valueP6, indexP6) => {

                                let title6 = ''

                                if (valueP6.tipo === 0) {
                                    title6 = 'P : ' + valueP6.nombre + ' ' + dineroDisabledMask(valueP6.costoTotal)
                                } else if (valueP6.tipo === 1 && valueP6.nombre) {
                                    const matriz6 = this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP6?.nombre.toString())
                                    title6 = 'M : ' + matriz6.descripcion + ' ' + dineroDisabledMask(valueP6.costoTotal)
                                } else {
                                    title6 = valueP6.nombre + ' ' + dineroDisabledMask(valueP6.costoTotal)
                                }


                                return {
                                    ...valueP6,
                                    title: title6
                                }

                            }) : []

                            let title5 = ''

                            if (valueP5.tipo === 0) {
                                title5 = 'P : ' + valueP5.nombre + ' ' + dineroDisabledMask(valueP5.costoTotal)
                            } else if (valueP5.tipo === 1 && valueP5.nombre) {
                                const matriz5 = this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP5?.nombre.toString())
                                title5 = 'M : ' + matriz5.descripcion + ' ' + dineroDisabledMask(valueP5.costoTotal)
                            } else {
                                title5 = valueP5.nombre + ' ' + dineroDisabledMask(valueP5.costoTotal)
                            }


                            return {
                                ...valueP5,
                                children: nuevasPartidas6,
                                title: title5
                            }
                        }) : []

                        let title4 = ''

                        if (valueP4.tipo === 0) {
                            title4 = 'P : ' + valueP4.nombre + ' ' + dineroDisabledMask(valueP4.costoTotal)
                        } else if (valueP4.tipo === 1 && valueP4.nombre) {
                            const matriz4 = this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP4?.nombre.toString())
                            title4 = 'M : ' + matriz4.descripcion + ' ' + dineroDisabledMask(valueP4.costoTotal)
                        } else {
                            title4 = valueP4.nombre + ' ' + dineroDisabledMask(valueP4.costoTotal)
                        }


                        return {
                            ...valueP4,
                            children: nuevasPartidas5,
                            title: title4
                        }

                    }) : []


                    let title3 = ''

                    if (valueP3.tipo === 0) {
                        title3 = 'P : ' + valueP3.nombre + ' ' + dineroDisabledMask(valueP3.costoTotal)
                    } else if (valueP3.tipo === 1 && valueP3.nombre) {
                        const matriz3 = this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP3?.nombre.toString())
                        title3 = 'M : ' + matriz3.descripcion + ' ' + dineroDisabledMask(valueP3.costoTotal)
                    } else {
                        title3 = valueP3.nombre + ' ' + dineroDisabledMask(valueP3.costoTotal)
                    }


                    return {
                        ...valueP3,
                        children: nuevasPartidas4,
                        title: title3
                    }

                }) : []

                let title2 = ''

                if (valueP2.tipo === 0) {
                    title2 = 'P : ' + valueP2.nombre + ' ' + dineroDisabledMask(valueP2.costoTotal)
                } else if (valueP2.tipo === 1 && valueP2.nombre) {
                    const matriz2 = this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP2?.nombre.toString())
                    title2 = 'M : ' + matriz2.descripcion + ' ' + dineroDisabledMask(valueP2.costoTotal)
                } else {
                    title2 = valueP2.nombre + ' ' + dineroDisabledMask(valueP2.costoTotal)
                }


                return {
                    ...valueP2,
                    children: nuevasPartidas3,
                    title: title2
                }

            }) : []

            let title1 = ''

            if (valueP1.tipo === 0) {
                title1 = 'P : ' + valueP1.nombre + ' ' + dineroDisabledMask(valueP1.costoTotal)
            } else if (valueP1.tipo === 1 && valueP1.nombre) {
                const matriz1 = this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP1?.nombre.toString())
                title1 = 'M : ' + matriz1.descripcion + ' ' + dineroDisabledMask(valueP1.costoTotal)
            } else {
                title1 = valueP1.nombre + ' ' + dineroDisabledMask(valueP1.costoTotal)
            }


            return {
                ...valueP1,
                children: nuevasPartidas2,
                title: title1
            }

        }) : []

        let nuevosInsumosExplosionados = []

        await axios
            .post(
                "inventarios/inventarioInsumosProyecto",
                {
                    proyectoId: this.formRef.current.getFieldValue('proyectoId'),
                    insumos: insumosExplosionados,
                    empresaId: this.props.empresaId,
                },
                { headers: this.props.headersToken }
            ).then((res) => {
                if (res.data.insumos) {
                    nuevosInsumosExplosionados = res.data.insumos
                } else {
                    nuevosInsumosExplosionados = res.data.insumos
                }
            })


        await this.setState({ loadingExplosion: false, insumosExplosionados: nuevosInsumosExplosionados, costoTotalIE, partidasArbol })

        this.cambioPedir()

    }

    async onChangeExplosion(valuesKeys) {

        let newFielsValue = {}
        for (const insumoExplosion of this.state.insumosExplosionados) {
            newFielsValue['pedir' + insumoExplosion.insumoId] = undefined
        }

        this.formRef.current.setFieldsValue(newFielsValue)

        if (valuesKeys.length === 0) {
            this.explosionInsumos()
            await this.setState({ valueExplosion: valuesKeys })
        } else {

            await this.setState({ loadingExplosion: true })
            const partidas = [...this.state.partidas]

            let insumosExplosionados = []

            let costoTotalIE = 0

            if (partidas) {

                for (const [indexP1, valueP1] of partidas.entries()) {
                    const key1 = indexP1
                    const key1Explosion = valuesKeys.findIndex(valueTK => valueTK === key1 || valueTK.toString() === key1.toString()) !== -1

                    if (valueP1.children) {

                        for (const [indexP2, valueP2] of valueP1.children.entries()) {
                            const key2 = indexP1 + '-' + indexP2
                            const key2Explosion = valuesKeys.findIndex(valueTK => valueTK === key2) !== -1

                            if (valueP2.children) {
                                for (const [indexP3, valueP3] of valueP2.children.entries()) {
                                    const key3 = indexP1 + '-' + indexP2 + '-' + indexP3
                                    const key3Explosion = valuesKeys.findIndex(valueTK => valueTK === key3) !== -1

                                    if (valueP3.children) {
                                        for (const [indexP4, valueP4] of valueP3.children.entries()) {
                                            const key4 = indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4
                                            const key4Explosion = valuesKeys.findIndex(valueTK => valueTK === key4) !== -1

                                            if (valueP4.children) {
                                                for (const [indexP5, valueP5] of valueP4.children.entries()) {
                                                    const key5 = indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4 + '-' + indexP5
                                                    const key5Explosion = valuesKeys.findIndex(valueTK => valueTK === key5) !== -1

                                                    if (valueP5.tipo === 1) {
                                                        if (valueP5.nombre) {
                                                            if (valueP5.children) {

                                                                for (const [indexMatriz, insumo] of valueP5.children.entries()) {
                                                                    const keyMatriz = key5 + '-' + indexMatriz
                                                                    const keyMatrizExplosion = valuesKeys.findIndex(valueTK => valueTK === keyMatriz) !== -1
                                                                    if (keyMatrizExplosion || key5Explosion || key4Explosion || key3Explosion || key2Explosion || key1Explosion) {
                                                                        let cantidadTotal = 1
                                                                        if (valueP5.cantidad && valueP4.cantidad && valueP3.cantidad && valueP2.cantidad && valueP1.cantidad) {
                                                                            cantidadTotal = round10Decimals(insumo.cantidad * valueP5.cantidad * valueP4.cantidad * valueP3.cantidad * valueP2.cantidad * valueP1.cantidad)
                                                                        }
                                                                        const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString())
                                                                        if (indexIE === -1) {
                                                                            const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString())
                                                                            insumosExplosionados.push({
                                                                                insumoId: insumo.insumoId,
                                                                                nombre: insumoBuscar.stringBuscar,
                                                                                unidad: insumoBuscar.unidad.nombre,
                                                                                costo: insumoBuscar.costo,
                                                                                catidadMinCompra: insumoBuscar?.catidadMinCompra,
                                                                                costoTotal: round2Decimals(insumoBuscar.costo * cantidadTotal),
                                                                                cantidadPedida: round10Decimals(insumo.cantidadPedida || 0),
                                                                                cantidad: cantidadTotal
                                                                            })
                                                                            costoTotalIE += round2Decimals(insumoBuscar.costo * cantidadTotal)
                                                                        } else {
                                                                            insumosExplosionados[indexIE].cantidad = round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal)
                                                                            insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal))
                                                                            insumosExplosionados[indexIE].cantidadPedida = round10Decimals(insumosExplosionados[indexIE].cantidadPedida + (insumo.cantidadPedida || 0))
                                                                            costoTotalIE += round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal)
                                                                        }
                                                                    }
                                                                }

                                                            }

                                                        }
                                                    }
                                                }
                                            }
                                            if (valueP4.tipo === 1) {
                                                if (valueP4.nombre) {
                                                    if (valueP4.children) {

                                                        for (const [indexMatriz, insumo] of valueP4.children.entries()) {

                                                            const keyMatriz = key4 + '-' + indexMatriz
                                                            const keyMatrizExplosion = valuesKeys.findIndex(valueTK => valueTK === keyMatriz) !== -1
                                                            if (keyMatrizExplosion || key4Explosion || key3Explosion || key2Explosion || key1Explosion) {

                                                                let cantidadTotal = 1
                                                                if (valueP4.cantidad && valueP3.cantidad && valueP2.cantidad && valueP1.cantidad) {
                                                                    cantidadTotal = round10Decimals(insumo.cantidad * valueP4.cantidad * valueP3.cantidad * valueP2.cantidad * valueP1.cantidad)
                                                                }
                                                                const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString())
                                                                if (indexIE === -1) {
                                                                    const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString())
                                                                    insumosExplosionados.push({
                                                                        insumoId: insumo.insumoId,
                                                                        nombre: insumoBuscar.stringBuscar,
                                                                        unidad: insumoBuscar.unidad.nombre,
                                                                        catidadMinCompra: insumoBuscar?.catidadMinCompra,
                                                                        costo: insumoBuscar.costo,
                                                                        costoTotal: round2Decimals(insumoBuscar.costo * cantidadTotal),
                                                                        cantidadPedida: round10Decimals(insumo.cantidadPedida || 0),
                                                                        cantidad: cantidadTotal
                                                                    })
                                                                    costoTotalIE += round2Decimals(insumoBuscar.costo * cantidadTotal)
                                                                } else {
                                                                    insumosExplosionados[indexIE].cantidad = round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal)
                                                                    insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal))
                                                                    insumosExplosionados[indexIE].cantidadPedida = round10Decimals(insumosExplosionados[indexIE].cantidadPedida + (insumo.cantidadPedida || 0))
                                                                    costoTotalIE += round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal)
                                                                }
                                                            }
                                                        }

                                                    }

                                                }
                                            }
                                        }
                                    }

                                    if (valueP3.tipo === 1) {
                                        if (valueP3.nombre) {
                                            if (valueP3.children) {

                                                for (const [indexMatriz, insumo] of valueP3.children.entries()) {

                                                    const keyMatriz = key3 + '-' + indexMatriz
                                                    const keyMatrizExplosion = valuesKeys.findIndex(valueTK => valueTK === keyMatriz) !== -1
                                                    if (keyMatrizExplosion || key3Explosion || key2Explosion || key1Explosion) {

                                                        let cantidadTotal = 1
                                                        if (valueP3.cantidad && valueP2.cantidad && valueP1.cantidad) {
                                                            cantidadTotal = round10Decimals(insumo.cantidad * valueP3.cantidad * valueP2.cantidad * valueP1.cantidad)
                                                        }
                                                        const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString())
                                                        if (indexIE === -1) {
                                                            const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString())
                                                            insumosExplosionados.push({
                                                                insumoId: insumo.insumoId,
                                                                nombre: insumoBuscar.stringBuscar,
                                                                unidad: insumoBuscar.unidad.nombre,
                                                                costo: insumoBuscar.costo,
                                                                catidadMinCompra: insumoBuscar?.catidadMinCompra,
                                                                costoTotal: round2Decimals(insumoBuscar.costo * cantidadTotal),
                                                                cantidadPedida: round10Decimals(insumo.cantidadPedida || 0),
                                                                cantidad: cantidadTotal
                                                            })
                                                            costoTotalIE += round2Decimals(insumoBuscar.costo * cantidadTotal)
                                                        } else {
                                                            insumosExplosionados[indexIE].cantidad = round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal)
                                                            insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal))
                                                            insumosExplosionados[indexIE].cantidadPedida = round10Decimals(insumosExplosionados[indexIE].cantidadPedida + (insumo.cantidadPedida || 0))
                                                            costoTotalIE += round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal)
                                                        }
                                                    }
                                                }

                                            }

                                        }
                                    }
                                }

                            }

                            if (valueP2.tipo === 1) {
                                if (valueP2.nombre) {
                                    if (valueP2.children) {

                                        for (const [indexMatriz, insumo] of valueP2.children.entries()) {
                                            const keyMatriz = key2 + '-' + indexMatriz
                                            const keyMatrizExplosion = valuesKeys.findIndex(valueTK => valueTK === keyMatriz) !== -1
                                            if (keyMatrizExplosion || key2Explosion || key1Explosion) {

                                                let cantidadTotal = 1
                                                if (valueP2.cantidad && valueP1.cantidad) {
                                                    cantidadTotal = round10Decimals(insumo.cantidad * valueP2.cantidad * valueP1.cantidad)
                                                }
                                                const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString())
                                                if (indexIE === -1) {
                                                    const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString())
                                                    insumosExplosionados.push({
                                                        insumoId: insumo.insumoId,
                                                        nombre: insumoBuscar.stringBuscar,
                                                        unidad: insumoBuscar.unidad.nombre,
                                                        costo: insumoBuscar.costo,
                                                        catidadMinCompra: insumoBuscar?.catidadMinCompra,
                                                        costoTotal: round2Decimals(insumoBuscar.costo * cantidadTotal),
                                                        cantidadPedida: round10Decimals(insumo.cantidadPedida || 0),
                                                        cantidad: cantidadTotal
                                                    })
                                                    costoTotalIE += round2Decimals(insumoBuscar.costo * cantidadTotal)
                                                } else {
                                                    insumosExplosionados[indexIE].cantidad = round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal)
                                                    insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal))
                                                    insumosExplosionados[indexIE].cantidadPedida = round10Decimals(insumosExplosionados[indexIE].cantidadPedida + (insumo.cantidadPedida || 0))
                                                    costoTotalIE += round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal)
                                                }
                                            }
                                        }

                                    }

                                }
                            }
                        }

                    }

                    if (valueP1.tipo === 1) {
                        if (valueP1.nombre) {
                            if (valueP1.children) {

                                for (const [indexMatriz, insumo] of valueP1.children.entries()) {

                                    const keyMatriz = key1 + '-' + indexMatriz
                                    const keyMatrizExplosion = valuesKeys.findIndex(valueTK => valueTK === keyMatriz) !== -1
                                    if (keyMatrizExplosion || key1Explosion) {

                                        let cantidadTotal = 1
                                        if (valueP1.cantidad) {
                                            cantidadTotal = round10Decimals(insumo.cantidad * valueP1.cantidad)
                                        }
                                        const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString())
                                        if (indexIE === -1) {
                                            const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString())
                                            insumosExplosionados.push({
                                                insumoId: insumo.insumoId,
                                                nombre: insumoBuscar.stringBuscar,
                                                unidad: insumoBuscar.unidad.nombre,
                                                catidadMinCompra: insumoBuscar?.catidadMinCompra,
                                                costo: insumoBuscar.costo,
                                                costoTotal: round2Decimals(insumoBuscar.costo * cantidadTotal),
                                                cantidadPedida: round10Decimals(insumo.cantidadPedida || 0),
                                                cantidad: cantidadTotal
                                            })
                                            costoTotalIE += round2Decimals(insumoBuscar.costo * cantidadTotal)
                                        } else {
                                            insumosExplosionados[indexIE].cantidad = round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal)
                                            insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal))
                                            insumosExplosionados[indexIE].cantidadPedida = round10Decimals(insumosExplosionados[indexIE].cantidadPedida + (insumo.cantidadPedida || 0))
                                            costoTotalIE += round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal)
                                        }
                                    }
                                }

                            }

                        }
                    }
                }
            }

            let nuevosInsumosExplosionados = []

            await axios
                .post(
                    "inventarios/inventarioInsumosProyecto",
                    {
                        proyectoId: this.formRef.current.getFieldValue('proyectoId'),
                        insumos: insumosExplosionados,
                        empresaId: this.props.empresaId,
                    },
                    { headers: this.props.headersToken }
                ).then((res) => {
                    if (res.data.insumos) {
                        nuevosInsumosExplosionados = res.data.insumos
                    } else {
                        nuevosInsumosExplosionados = res.data.insumos
                    }
                })



            await this.setState({ loadingExplosion: false, insumosExplosionados: nuevosInsumosExplosionados, costoTotalIE, valueExplosion: valuesKeys })
            this.cambioPedir()
        }
    }

    async confirmarPresupuesto() {
        this.formRef.current.validateFields().then((values) => {
            this.setState({ loading: true })
            axios
                .post("presupuestosPU/confirmarPresupuesto", { ...values, partidas: this.state.partidas, presupuestoId: this.state.id, empresaId: this.props.empresaId }, {
                    headers: {
                        ...this.props.headersToken,
                    },
                })
                .then((res) => {
                    this.setState({ loading: false, id: res.data.presupuestoId });
                    if (res.data.success === 2) {
                        message.success("Se ha confirmado el presupuesto");
                        this.consultarTodo()
                    } else if (res.data.success === 1) {
                        message.error("No se puede confirmar el presupuesto");
                    } else {
                        message.error("Hubo un error y el presupuesto no se confirmo");
                    }
                });
        }).catch(() => this.onFinishFailed())
    }

    agregarPartida(key) {
        let partidas = [...this.state.partidas]
        let expandedRowKeys = [...this.state.expandedRowKeys]
        if (key) {
            const arrayKey = key.split("-").map(value => Number(value))
            if (arrayKey.length === 1) {
                if (partidas[arrayKey[0]].children) {
                    partidas[arrayKey[0]].children.push({
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        key: key + '-' + partidas[arrayKey[0]].children.length
                    })
                } else {
                    partidas[arrayKey[0]].children = [{
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        key: key + '-0'
                    }]
                }
            } else if (arrayKey.length === 2) {
                if (partidas[arrayKey[0]].children[arrayKey[1]].children) {
                    partidas[arrayKey[0]].children[arrayKey[1]].children.push({
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        key: key + '-' + partidas[arrayKey[0]].children[arrayKey[1]].children.length
                    })
                } else {
                    partidas[arrayKey[0]].children[arrayKey[1]].children = [{
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        key: key + '-0'
                    }]
                }
            } else if (arrayKey.length === 3) {
                if (partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children) {
                    partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children.push({
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        key: key + '-' + partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children.length
                    })
                } else {
                    partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children = [{
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        key: key + '-0'
                    }]
                }
            } else if (arrayKey.length === 4) {
                if (partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children[arrayKey[3]].children) {
                    partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children[arrayKey[3]].children.push({
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        key: key + '-' + partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children[arrayKey[3]].children.length
                    })
                } else {
                    partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children[arrayKey[3]].children = [{
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        key: key + '-0'
                    }]
                }
            }
        } else {
            partidas.push({
                tipo: 0,
                nombre: "",
                cantidad: 1,
                costo: 0,
                costoTotal: 0,
                key: partidas.length.toString()
            })
        }

        const keyExpand = expandedRowKeys.findIndex(value => value === key)

        if (keyExpand === -1 && key) {
            expandedRowKeys.push(key)
        }

        this.setState({ partidas: partidas, expandedRowKeys })
    }

    onChangeTipo(value, key) {
        const partidas = [...this.state.partidas]

        const arrayKeys = key.split('-').map(valueKey => Number(valueKey))

        if (arrayKeys.length === 1) {

            partidas[arrayKeys[0]].tipo = value
            partidas[arrayKeys[0]].costo = 0
            partidas[arrayKeys[0]].costoTotal = 0

            partidas[arrayKeys[0]].children = undefined
            partidas[arrayKeys[0]].nombre = undefined

        } else if (arrayKeys.length === 2) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].tipo = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].costo = 0
            partidas[arrayKeys[0]].children[arrayKeys[1]].costoTotal = 0

            partidas[arrayKeys[0]].children[arrayKeys[1]].children = undefined
            partidas[arrayKeys[0]].children[arrayKeys[1]].nombre = undefined

        } else if (arrayKeys.length === 3) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].tipo = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].costo = 0
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].costoTotal = 0

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children = undefined
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].nombre = undefined

        } else if (arrayKeys.length === 4) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].tipo = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].costo = 0
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].costoTotal = 0

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children = undefined
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].nombre = undefined

        } else if (arrayKeys.length === 5) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].tipo = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].costo = 0
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].costoTotal = 0

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].children = undefined
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].nombre = undefined

        }

        const nuevasPartidas = this.calcularPresupuesto(partidas)

        this.setState({ partidas: nuevasPartidas })
    }

    onChangeNombre(value, key) {
        const partidas = [...this.state.partidas]

        const arrayKeys = key.split('-').map(valueKey => Number(valueKey))

        if (arrayKeys.length === 1) {

            partidas[arrayKeys[0]].nombre = value.target.value

        } else if (arrayKeys.length === 2) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].nombre = value.target.value

        } else if (arrayKeys.length === 3) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].nombre = value.target.value

        } else if (arrayKeys.length === 4) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].nombre = value.target.value

        } else if (arrayKeys.length === 5) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].nombre = value.target.value

        }

        this.setState({ partidas: partidas })
    }

    calcularPresupuesto(partidas) {

        const nuevasPartidas = partidas ? partidas.map((valueP1, indexP1) => {
            let costo1 = valueP1.insumoMatriz ? valueP1.costo : 0

            const newChildren = valueP1.children ? valueP1.children.map((valueP2, indexP2) => {
                let costo2 = valueP2.insumoMatriz ? valueP2.costo : 0

                const newChildren = valueP2.children ? valueP2.children.map((valueP3, indexP3) => {

                    let costo3 = valueP3.insumoMatriz ? valueP3.costo : 0
                    const newChildren = valueP3.children ? valueP3.children.map((valueP4, indexP4) => {
                        let costo4 = valueP4.insumoMatriz ? valueP4.costo : 0
                        const newChildren = valueP4.children ? valueP4.children.map((valueP5, indexP5) => {
                            let costo5 = valueP5.insumoMatriz ? valueP5.costo : 0

                            const newChildren = valueP5.children ? valueP5.children.map((valueP6, indexP6) => {
                                costo5 += round2Decimals(valueP6.costo * valueP6.cantidad)

                                let cantidadTotal6 = valueP6.cantidad

                                if (valueP1.cantidad && valueP2.cantidad && valueP3.cantidad && valueP4.cantidad && valueP5.cantidad && valueP6.cantidad) {
                                    cantidadTotal6 = round10Decimals(valueP1.cantidad * valueP2.cantidad * valueP3.cantidad * valueP4.cantidad * valueP5.cantidad * valueP6.cantidad)
                                }

                                return {
                                    ...valueP6,
                                    key: indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4 + '-' + indexP5 + '-' + indexP6,
                                    costoTotal: round2Decimals(valueP6.costo * valueP6.cantidad),
                                    cantidadTotal: cantidadTotal6
                                }

                            }) : undefined

                            costo4 += round2Decimals(costo5 * valueP5.cantidad)

                            let cantidadTotal5 = valueP5.cantidad

                            if (valueP1.cantidad && valueP2.cantidad && valueP3.cantidad && valueP4.cantidad && valueP5.cantidad) {
                                cantidadTotal5 = round10Decimals(valueP1.cantidad * valueP2.cantidad * valueP3.cantidad * valueP4.cantidad * valueP5.cantidad)
                            }

                            return {
                                ...valueP5,
                                key: indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4 + '-' + indexP5,
                                costo: costo5,
                                costoTotal: round2Decimals(costo5 * valueP5.cantidad),
                                cantidadTotal: cantidadTotal5,
                                children: newChildren,
                            }
                        }) : undefined

                        costo3 += round2Decimals(costo4 * valueP4.cantidad)

                        let cantidadTotal4 = valueP4.cantidad

                        if (valueP1.cantidad && valueP2.cantidad && valueP3.cantidad && valueP4.cantidad) {
                            cantidadTotal4 = round10Decimals(valueP1.cantidad * valueP2.cantidad * valueP3.cantidad * valueP4.cantidad)
                        }

                        return {
                            ...valueP4,
                            key: indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4,
                            costo: costo4,
                            costoTotal: round2Decimals(costo4 * valueP4.cantidad),
                            children: newChildren,
                            cantidadTotal: cantidadTotal4
                        }
                    }) : undefined

                    costo2 += round2Decimals(costo3 * valueP3.cantidad)

                    let cantidadTotal3 = valueP3.cantidad

                    if (valueP1.cantidad && valueP2.cantidad && valueP3.cantidad) {
                        cantidadTotal3 = round10Decimals(valueP1.cantidad * valueP2.cantidad * valueP3.cantidad)
                    }

                    return {
                        ...valueP3,
                        key: indexP1 + '-' + indexP2 + '-' + indexP3,
                        costo: costo3,
                        costoTotal: round2Decimals(costo3 * valueP3.cantidad),
                        children: newChildren,
                        cantidadTotal: cantidadTotal3
                    }
                }) : undefined

                costo1 += round2Decimals(costo2 * valueP2.cantidad)

                let cantidadTotal2 = valueP2.cantidad

                if (valueP1.cantidad && valueP2.cantidad) {
                    cantidadTotal2 = round10Decimals(valueP1.cantidad * valueP2.cantidad)
                }

                return {
                    ...valueP2,
                    key: indexP1 + '-' + indexP2,
                    costo: costo2,
                    costoTotal: round2Decimals(costo2 * valueP2.cantidad),
                    children: newChildren,
                    cantidadTotal: cantidadTotal2
                }
            }) : undefined

            let cantidadTotal1 = valueP1.cantidad

            return {
                ...valueP1,
                key: indexP1.toString(),
                costo: costo1,
                costoTotal: round2Decimals(costo1 * valueP1.cantidad),
                children: newChildren,
                cantidadTotal: cantidadTotal1
            }
        }) : []

        return nuevasPartidas
    }

    onChangeMatrizId(value, key) {
        const partidas = [...this.state.partidas]

        const arrayKeys = key.split('-').map(valueKey => Number(valueKey))

        let childrenMatriz = []
        let costoMatriz = 0
        let unidad = ''

        if (value) {
            const matriz = this.state.todasMatrices.find(valueM => valueM._id.toString() === value.toString())
            unidad = matriz.unidad.nombre
            childrenMatriz = matriz.insumosUtilizar.map((valueMatriz, indexMatriz) => {
                const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === valueMatriz.insumoId.toString())
                const costoTotal = round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)
                costoMatriz += round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)

                return {
                    insumoMatriz: true,
                    nombre: insumoBuscar?.descripcion,
                    unidad: insumoBuscar?.unidad?.nombre,
                    costoTotal: costoTotal,
                    costo: insumoBuscar.costo,
                    cantidad: valueMatriz.cantidad,
                    insumoId: valueMatriz.insumoId,
                    key: key + '-' + indexMatriz,
                }
            })
        }

        if (arrayKeys.length === 1) {

            partidas[arrayKeys[0]].nombre = value
            partidas[arrayKeys[0]].children = childrenMatriz
            partidas[arrayKeys[0]].costo = costoMatriz
            partidas[arrayKeys[0]].unidad = unidad
            partidas[arrayKeys[0]].costoTotal = round2Decimals(costoMatriz * (partidas[arrayKeys[0]].cantidad || 0))

        } else if (arrayKeys.length === 2) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].nombre = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children = childrenMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].costo = costoMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].unidad = unidad
            partidas[arrayKeys[0]].children[arrayKeys[1]].costoTotal = round2Decimals(costoMatriz * (partidas[arrayKeys[0]].children[arrayKeys[1]].cantidad || 0))

        } else if (arrayKeys.length === 3) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].nombre = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children = childrenMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].costo = costoMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].unidad = unidad
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].costoTotal = round2Decimals(costoMatriz * (partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].cantidad || 0))

        } else if (arrayKeys.length === 4) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].nombre = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children = childrenMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].costo = costoMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].unidad = unidad
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].costoTotal = round2Decimals(costoMatriz * (partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].cantidad || 0))

        } else if (arrayKeys.length === 5) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].nombre = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].children = childrenMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].costo = costoMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].unidad = unidad
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].costoTotal = round2Decimals(costoMatriz * (partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].cantidad || 0))

        }

        const nuevasPartidas = this.calcularPresupuesto(partidas)

        this.setState({ partidas: nuevasPartidas })
    }

    onChangeCantidad(value, key) {
        const partidas = [...this.state.partidas]

        const arrayKeys = key.split('-').map(valueKey => Number(valueKey))

        if (arrayKeys.length === 1) {

            partidas[arrayKeys[0]].cantidad = value.target.value

        } else if (arrayKeys.length === 2) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].cantidad = value.target.value

        } else if (arrayKeys.length === 3) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].cantidad = value.target.value

        } else if (arrayKeys.length === 4) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].cantidad = value.target.value

        } else if (arrayKeys.length === 5) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].cantidad = value.target.value

        }

        const nuevasPartidas = this.calcularPresupuesto(partidas)

        this.setState({ partidas: nuevasPartidas })
    }

    Cell(props) {
        if (props?.dataIndex === "tipo" && !props.record.insumoMatriz) {
            return (
                <td {...props} style={{ ...props.style, padding: 0 }}>
                    <SelectItem
                        sinBorde
                        style={{
                            backgroundColor: 'transparent'
                        }}
                        placeholder="Tipo"
                        value={props.record.tipo}
                        onChange={(value) => {
                            this.onChangeTipo(value, props.record.key)
                        }}
                    >
                        <Option value={0} key={0}>Partida</Option>
                        <Option value={1} key={1}>Matriz</Option>
                    </SelectItem>
                </td>
            )
        } else if (props?.dataIndex === "nombre" && !props.record.insumoMatriz) {
            if (props.record.tipo === 0) {
                return (
                    <td {...props} style={{ ...props.style, padding: 0 }}>
                        <InputItem
                            defaultValue={props.record.nombre}
                            style={{
                                backgroundColor: 'transparent'
                            }}
                            placeholder="Nombre"
                            sinBorde
                            onBlur={(value) => {
                                this.onChangeNombre(value, props.record.key)
                            }}
                        />
                    </td>
                )
            } else {
                return (
                    <td {...props} style={{ ...props.style, padding: 0 }}>
                        <SelectItem
                            sinBorde
                            style={{
                                backgroundColor: 'transparent'
                            }}
                            placeholder="Matriz"
                            width='100%'
                            value={props.record.nombre}
                            onChange={(value) => {
                                this.onChangeMatrizId(value, props.record.key)
                            }}
                        >
                            {
                                this.state
                                    .objetoMatrices
                            }
                        </SelectItem>
                    </td>
                )
            }
        } else if (props?.dataIndex === "cantidad" && !props.record.insumoMatriz) {
            return (
                <td {...props} style={{ ...props.style, padding: 0 }}>
                    <InputNumberItem
                        sinBorde
                        style={{
                            backgroundColor: 'transparent'
                        }}
                        placeholder="Cantidad"
                        defaultValue={props.record.cantidad}
                        onBlur={(value) => {
                            this.onChangeCantidad(value, props.record.key)
                        }}
                    />
                </td>
            )
        } else {
            return <td {...props} />
        }
    }

    quitarPartida(key) {
        let partidas = [...this.state.partidas]
        if (key) {
            const arrayKey = key.split("-").map(value => Number(value))
            if (arrayKey.length === 1) {
                partidas.splice(arrayKey[0], 1)
            } else if (arrayKey.length === 2) {
                partidas[arrayKey[0]].children.splice(arrayKey[1], 1)
            } else if (arrayKey.length === 3) {
                partidas[arrayKey[0]].children[arrayKey[1]].children.splice(arrayKey[2], 1)
            } else if (arrayKey.length === 4) {
                partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children.splice(arrayKey[3], 1)
            }
        }

        const nuevasPartidas = this.calcularPresupuesto(partidas)

        this.setState({ partidas: nuevasPartidas })
    }

    onExpandedRowsChange(value) {
        this.setState({ expandedRowKeys: value })
    }

    requerirInsumos() {
        this.formRef.current.validateFields().then((values) => {
            this.setState({ loading: true })
            axios
                .post("presupuestosPU/requerirInsumos", { ...values, valueExplosion: this.state.valueExplosion, presupuestoId: this.state.id, empresaId: this.props.empresaId }, {
                    headers: {
                        ...this.props.headersToken,
                    },
                })
                .then(async (res) => {
                    if (res.data.success === 2) {
                        message.success("Se ha generado la requisicion numero: " + res.data.numeroRequisicion);
                        await this.consultarTodo()
                        await this.onChangeExplosion(this.state.valueExplosion)
                        this.setState({ loading: false });
                    } else if (res.data.success === 1) {
                        message.error(res.data.message);
                        this.setState({ loading: false });
                    } else {
                        message.error("Hubo un error y la requisicion no se genero");
                        this.setState({ loading: false });
                    }
                });
        }).catch((err) => {
            message.info('Esta explosion de insumos se guardara para una autorizacion posterior')
            axios
                .post("presupuestosPU/requerirInsumos", { ...err.values, valueExplosion: this.state.valueExplosion, sobresaleCantidades: true, presupuestoId: this.state.id, empresaId: this.props.empresaId }, {
                    headers: {
                        ...this.props.headersToken,
                    },
                })
                .then(async (res) => {
                    if (res.data.success === 2) {
                        message.success("Se ha guardado la explosion de insumos");
                        await this.consultarTodo()
                        await this.onChangeExplosion(this.state.valueExplosion)
                        this.setState({ loading: false });
                    } else if (res.data.success === 1) {
                        message.error(res.data.message);
                        this.setState({ loading: false });
                    } else {
                        message.error("Hubo un error y la requisicion no se genero");
                        this.setState({ loading: false });
                    }
                });
        })
    }

    pedirTotales() {
        let newFielsValue = {}
        for (const insumoExplosion of this.state.insumosExplosionados) {
            newFielsValue['pedir' + insumoExplosion.insumoId] = round10Decimals(insumoExplosion.cantidad - insumoExplosion.cantidadPedida)
        }

        this.cambioPedir()

        this.formRef.current.setFieldsValue(newFielsValue)
    }

    async cambioPedir() {

        await this.setState({ loadingExplosion: true })

        let totalCostoPedir = 0

        const nuevosInsumosExplosionados = await Promise.all(this.state.insumosExplosionados.map(async (valueIE) => {

            const pedir = await this.formRef.current.getFieldValue('pedir' + valueIE.insumoId)

            totalCostoPedir = round2Decimals(totalCostoPedir + (valueIE.costo * (pedir || 0)))

            return {
                ...valueIE,
                costoPedir: round2Decimals(valueIE.costo * (pedir || 0))
            }
        }))

        await this.setState({ loadingExplosion: false, insumosExplosionados: nuevosInsumosExplosionados, totalCostoPedir })
    }

    async pedirMinCompraTotales() {
        let newFielsValue = {}
        for (const insumoExplosion of this.state.insumosExplosionados) {

            let cantidadPedir = round10Decimals(insumoExplosion.cantidad - insumoExplosion.cantidadPedida)

            if (insumoExplosion.catidadMinCompra) {
                const residuo = round10Decimals(cantidadPedir % insumoExplosion.catidadMinCompra)
                if (residuo !== 0) {
                    const divisionEntera = Math.floor(cantidadPedir / insumoExplosion.catidadMinCompra)

                    cantidadPedir = round10Decimals((divisionEntera + 1) * insumoExplosion.catidadMinCompra)
                }
            }

            newFielsValue['pedir' + insumoExplosion.insumoId] = cantidadPedir
        }

        this.cambioPedir()

        this.formRef.current.setFieldsValue(newFielsValue)
    }

    handleTaskChange(task) {
        let updatedTasks = this.state.tasksDiagrama.map((t) => (t.id === task.id ? task : t));

        let updatedPartidas = [...this.state.partidas]

        const taskKeyString = task.id.toString()

        const arrayKeys = task.id.split('-').map(valueKey => Number(valueKey))

        if (arrayKeys.length === 1) {

            updatedPartidas[arrayKeys[0]].start = task.start
            updatedPartidas[arrayKeys[0]].end = task.end

        } else if (arrayKeys.length === 2) {

            updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].start = task.start
            updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].end = task.end

        } else if (arrayKeys.length === 3) {

            updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].start = task.start
            updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].end = task.end

        } else if (arrayKeys.length === 4) {

            updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].start = task.start
            updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].end = task.end

        } else if (arrayKeys.length === 5) {

            updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].start = task.start
            updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].end = task.end

        }

        // Actualizar las fechas de la tarea padre según los hijos


        for (let tIndex = updatedTasks.length - 1; tIndex >= 0; tIndex--) {
            const t = updatedTasks[tIndex]
            if (t.type === 'project') {
    
                const keyString = t.id.toString()
                const lengthKey = keyString.length
                if (keyString === taskKeyString.slice(0, lengthKey)) {
    
                    let start
                    let end
    
                    const children = updatedTasks.filter((tC) => tC.project === t.id);
    
                    for (const valueC of children) {
                        if (moment(valueC.start) < start || !start) {
                            start = moment(valueC.start).clone()
                        }
    
                        if (moment(valueC.end) > end || !end) {
                            end = moment(valueC.end).clone()
                        } 
                    }

                    updatedTasks[tIndex].start = start.toDate()
                    updatedTasks[tIndex].end = end.toDate()

                    const arrayKeys = t.id.split('-').map(valueKey => Number(valueKey))

                    if (arrayKeys.length === 1) {
            
                        updatedPartidas[arrayKeys[0]].start = start.toDate()
                        updatedPartidas[arrayKeys[0]].end = end.toDate()
            
                    } else if (arrayKeys.length === 2) {
            
                        updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].start = start.toDate()
                        updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].end = end.toDate()
            
                    } else if (arrayKeys.length === 3) {
            
                        updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].start = start.toDate()
                        updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].end = end.toDate()
            
                    } else if (arrayKeys.length === 4) {
            
                        updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].start = start.toDate()
                        updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].end = end.toDate()
            
                    } else if (arrayKeys.length === 5) {
            
                        updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].start = task.start
                        updatedPartidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].end = task.end
            
                    }

                    }
            }            
        }

        console.log(updatedPartidas)

        this.setState({ tasksDiagrama: updatedTasks, partidas: updatedPartidas })
    };

    // updatedTasks = updatedTasks.map((t) => {
    //     if (t.type === 'project') {
    //         const children = updatedTasks.filter((child) => child.project === t.id);
    //         const totalProgress = children.reduce((acc, child) => acc + child.progress, 0);
    //         const avgProgress = totalProgress / children.length;
    //         return { ...t, progress: avgProgress };
    //     }
    //     return t;
    // });

    async hacerDiagramaGantt() {

        await this.setState({ loadingDiagrama: true })

        const partidas = [...this.state.partidas]

        let tasksDiagrama = []

        const stylesTask = {
            backgroundColor: "#00b5e2",
            backgroundSelectedColor: "#00b5e2",
            progressColor: "#00b5e2",
            progressSelectedColor: "#00b5e2"
        }

        const stylesProject = {
            backgroundColor: "#fac465",
            backgroundSelectedColor: "#fac465",
            progressColor: "#fac465",
            progressSelectedColor: "#fac465"
        }


        if (partidas) {

            for (const [indexP1, valueP1] of partidas.entries()) {
                const key1 = indexP1.toString()

                if (valueP1.children) {

                    for (const [indexP2, valueP2] of valueP1.children.entries()) {
                        const key2 = indexP1 + '-' + indexP2

                        if (valueP2.children) {
                            for (const [indexP3, valueP3] of valueP2.children.entries()) {
                                const key3 = indexP1 + '-' + indexP2 + '-' + indexP3

                                if (valueP3.children) {
                                    for (const [indexP4, valueP4] of valueP3.children.entries()) {
                                        const key4 = indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4

                                        if (valueP4.children) {
                                            for (const [indexP5, valueP5] of valueP4.children.entries()) {
                                                const key5 = indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4 + '-' + indexP5

                                                if (!valueP5.insumoMatriz) {

                                                    let proyecto = false
                                                    let nombre = valueP5.nombre

                                                    if (valueP5.children && valueP5?.children?.length > 0) {
                                                        const findPartida = valueP5.children.find(valueTC => !valueTC.insumoMatriz)
                                                        if (findPartida) {
                                                            proyecto = true
                                                        }
                                                    }

                                                    if(valueP5.tipo === 1 && valueP5.nombre){
                                                        const matriz = this.state.todasMatrices.find(valueTM => valueTM._id.toString() === valueP5.nombre.toString())
                                                        nombre = matriz.descripcion
                                                    }

                                                    tasksDiagrama.push({
                                                        id: key5,
                                                        name: nombre,
                                                        start: moment(valueP5.start).toDate(),
                                                        end: valueP5.end ? moment(valueP5.end).toDate() : moment().add(1, 'd').toDate(),
                                                        progress: 0,
                                                        project: key4,
                                                        type: proyecto ? 'project' : undefined,
                                                        styles: proyecto ? stylesProject : stylesTask,
                                                        hideChildren: !proyecto,
                                                    })

                                                }
                                            }
                                        }

                                        if (!valueP4.insumoMatriz) {

                                            let proyecto = false
                                            let nombre = valueP4.nombre

                                            if (valueP4.children && valueP4?.children?.length > 0) {
                                                const findPartida = valueP4.children.find(valueTC => !valueTC.insumoMatriz)
                                                if (findPartida) {
                                                    proyecto = true
                                                }
                                            }

                                            if(valueP4.tipo === 1 && valueP4.nombre){
                                                const matriz = this.state.todasMatrices.find(valueTM => valueTM._id.toString() === valueP4.nombre.toString())
                                                nombre = matriz.descripcion
                                            }

                                            tasksDiagrama.push({
                                                id: key4,
                                                name: nombre,
                                                start: moment(valueP4.start).toDate(),
                                                end: valueP4.end ? moment(valueP4.end).toDate() : moment().add(1, 'd').toDate(),
                                                progress: 0,
                                                project: key3,
                                                type: proyecto ? 'project' : undefined,
                                                styles: proyecto ? stylesProject : stylesTask,
                                                hideChildren: !proyecto,
                                            })

                                        }
                                    }
                                }

                                if (!valueP3.insumoMatriz) {

                                    let proyecto = false
                                    let nombre = valueP3.nombre

                                    if (valueP3.children && valueP3?.children?.length > 0) {
                                        const findPartida = valueP3.children.find(valueTC => !valueTC.insumoMatriz)
                                        if (findPartida) {
                                            proyecto = true
                                        }
                                    }

                                    if(valueP3.tipo === 1 && valueP3.nombre){
                                        const matriz = this.state.todasMatrices.find(valueTM => valueTM._id.toString() === valueP3.nombre.toString())
                                        nombre = matriz.descripcion
                                    }

                                    tasksDiagrama.push({
                                        id: key3,
                                        name: nombre,
                                        start: moment(valueP3.start).toDate(),
                                        end: valueP3.end ? moment(valueP3.end).toDate() : moment().add(1, 'd').toDate(),
                                        progress: 0,
                                        project: key2,
                                        type: proyecto ? 'project' : undefined,
                                        styles: proyecto ? stylesProject : stylesTask,
                                        hideChildren: !proyecto,
                                    })

                                }
                            }

                        }

                        if (!valueP2.insumoMatriz) {

                            let proyecto = false
                            let nombre = valueP2.nombre

                            if (valueP2.children && valueP2?.children?.length > 0) {
                                const findPartida = valueP2.children.find(valueTC => !valueTC.insumoMatriz)
                                if (findPartida) {
                                    proyecto = true
                                }
                            }

                            if(valueP2.tipo === 1 && valueP2.nombre){
                                const matriz = this.state.todasMatrices.find(valueTM => valueTM._id.toString() === valueP2.nombre.toString())
                                nombre = matriz.descripcion
                            }

                            tasksDiagrama.push({
                                id: key2,
                                name: nombre,
                                start: moment(valueP2.start).toDate(),
                                end: valueP2.end ? moment(valueP2.end).toDate() : moment().add(1, 'd').toDate(),
                                progress: 0,
                                project: key1,
                                type: proyecto ? 'project' : undefined,
                                styles: proyecto ? stylesProject : stylesTask,
                                hideChildren: !proyecto,
                            })

                        }
                    }

                }

                if (!valueP1.insumoMatriz) {

                    let proyecto = false
                    let nombre = valueP1.nombre

                    if (valueP1.children && valueP1?.children?.length > 0) {
                        const findPartida = valueP1.children.find(valueTC => !valueTC.insumoMatriz)
                        if (findPartida) {
                            proyecto = true
                        }
                    }

                    if(valueP1.tipo === 1 && valueP1.nombre){
                        const matriz = this.state.todasMatrices.find(valueTM => valueTM._id.toString() === valueP1.nombre.toString())
                        nombre = matriz.descripcion
                    }

                    tasksDiagrama.push({
                        id: key1,
                        name: nombre,
                        start: moment(valueP1.start).toDate(),
                        end: valueP1.end ? moment(valueP1.end).toDate() : moment().add(1, 'd').toDate(),
                        progress: 0,
                        type: proyecto ? 'project' : undefined,
                        styles: proyecto ? stylesProject : stylesTask,
                        hideChildren: !proyecto,
                    })

                }
            }
        }

        if (tasksDiagrama.length === 0) {
            tasksDiagrama = [
                {
                    id: '1',
                    name: 'No cargo correctamente',
                    start: moment().toDate(),
                    end: moment().add(1, 'd').toDate(),
                    progress: 50,
                    type: 'project',
                    styles: stylesTask,
                    hideChildren: false,
                },
            ]
        } else {
            // tasksDiagrama = tasksDiagrama.map(valueD => {
            //     const arraykeys = valueD.id.split('-')
            //     let suma = 0

            //     for (const key of arraykeys) {
            //         suma = suma + key
            //     }

            //     return {
            //         ...valueD,
            //         suma
            //     }
            // }).sort((a, b) => a.suma - b.suma);

            tasksDiagrama.sort((a, b) => {
                const aParts = a.id.split('-').map(Number);
                const bParts = b.id.split('-').map(Number);

                for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
                    const aVal = aParts[i] !== undefined ? aParts[i] : -1;
                    const bVal = bParts[i] !== undefined ? bParts[i] : -1;

                    if (aVal !== bVal) {
                        return aVal - bVal;
                    }
                }

                return 0;
            });
        }

        await this.setState({ loadingDiagrama: false, tasksDiagrama })
    }

    onExpanderClick(task) {

        let newTasks = [...this.state.tasksDiagrama]

        const index = newTasks.findIndex(valueTT => valueTT.id === task.id)

        if (index !== -1) {
            newTasks[index].hideChildren = !newTasks[index].hideChildren
        }

        this.setState({ tasksDiagrama: newTasks })
    }


    render() {

        const columns = [
            {
                title: "Clave",
                dataIndex: "key",
                key: "key",
                width: "15%",
                render: (value) => {
                    const arrayKey = value.split('-').map((value) => Number(value) + 1)
                    let texto = ''

                    for (const [index, key] of arrayKey.entries()) {
                        if (index === (arrayKey.length - 1)) {
                            texto += key
                        } else {
                            texto += key + '-'
                        }
                    }

                    return texto
                }
            },
            {
                title: "Tipo",
                dataIndex: "tipo",
                key: "tipo",
                render: (value) => {
                    if (this.state.confirmado) {
                        return value === 0 ? 'Partida' : 'Matriz'
                    } else {
                        return value
                    }
                },
                width: "10%",
            },
            {
                title: "Nombre",
                dataIndex: "nombre",
                key: "nombre",
                render: (value, record) => {
                    if (this.state.confirmado) {
                        if (record.tipo === 1) {
                            const matriz = this.state.todasMatrices.find(valueTM => valueTM._id.toString() === value.toString())
                            if (matriz) {
                                return matriz.descripcion
                            } else {
                                return ''
                            }
                        } else {
                            return value
                        }
                    } else {
                        return value
                    }
                },
                width: "25%",
            },
            {
                title: "Costo",
                dataIndex: "costo",
                key: "costo",
                sorter: (a, b) => {
                    if (a.costo < b.costo) {
                        return -1;
                    }
                    if (a.costo > b.costo) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => dineroDisabledMask(value),
                width: "10%",
                align: 'right'
            },
            {
                title: "Unidad",
                dataIndex: "unidad",
                key: "unidad",
                width: "10%",
                align: 'left'
            },
            {
                title: "Cantidad",
                dataIndex: "cantidad",
                key: "cantidad",
                width: "10%",
                align: 'left'
            },
            {
                title: "Costo",
                dataIndex: "costoTotal",
                key: "costoTotal",
                sorter: (a, b) => {
                    if (a.costoTotal < b.costoTotal) {
                        return -1;
                    }
                    if (a.costoTotal > b.costoTotal) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => dineroDisabledMask(value),
                width: "10%",
                align: 'right'
            },
            {
                title: "Accion",
                dataIndex: "key",
                key: "key",
                width: "10%",
                render: (value, record) => {
                    if (record.tipo === 0 && !record?.insumoMatriz && value.split('-').length < 5 && !this.state.confirmado) {
                        return (
                            <>
                                <PlusCircleOutlined
                                    onClick={() => this.agregarPartida(value)}
                                    style={{
                                        color: "green",
                                        marginRight: 20
                                    }}
                                />
                                <MinusCircleOutlined
                                    onClick={() => this.quitarPartida(value)}
                                    style={{
                                        color: "red"
                                    }}
                                />
                            </>
                        )
                    } else if (!record?.insumoMatriz && !this.state.confirmado) {
                        return (
                            <>
                                <MinusCircleOutlined
                                    onClick={() => this.quitarPartida(value)}
                                    style={{
                                        color: "red"
                                    }}
                                />
                            </>
                        )
                    } else {
                        return null
                    }
                }
            }
        ]


        const mergedColumns = columns.map((col) => {
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    dataIndex: col?.dataIndex,
                    title: col?.title,
                }),
            };
        });

        // console.log(es)

        // const CustomTaskListTable = ({ tasks, ...restProps }) => {
        //     return (
        //         <table style={{ width: '100%' }}>
        //             <tbody>
        //                 {tasks.map(task => 
        //                 (
        //                     <tr key={task.id}>
        //                         <td className="td-border" style={{ height: 35, width: '33%' }}>{task.type === 'project' ? <DownOutlined style={{ color: 'black' }} onClick={() => this.onExpanderClick(task)} /> : null}{task.name}</td>
        //                         <td className="td-border" style={{ height: 35, width: '33%' }}>{moment(task.start).format('L')}</td>
        //                         <td className="td-border" style={{ height: 35, width: '33%' }}>{moment(task.end).format('L')}</td>
        //                     </tr>
        //                 ))}
        //             </tbody>
        //         </table>
        //     );
        // };

        return (
            <>
                <PageHeader
                    onBack={this.atras.bind(this)}
                    title="Presupuesto"
                    style={{
                        border: "1px solid rgb(235, 237, 240)",
                        backgroundColor: "white",
                        marginBottom: 10,
                    }}
                />
                <div style={{ backgroundColor: "white", padding: 20, paddingTop: 5 }}>
                    <Spin spinning={this.state.loading}>
                        <Form
                            ref={this.formRef}
                            name="presupuestoPU"
                            layout
                            bottom={
                                <div style={{ display: "flex", justifyContent: "right" }}>
                                    <Space>
                                        {!this.state.confirmado ?
                                            <Popconfirm
                                                title="Seguro que quieres confirmar este presupuesto, al confirmarlo no se podran editar los datos de las partidas"
                                                onConfirm={() => this.confirmarPresupuesto()}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <ButtonItem
                                                    verde
                                                    style={{ display: "block", margin: "0 0 0 auto" }}
                                                >
                                                    Confirmar
                                                </ButtonItem>
                                            </Popconfirm>
                                            : null}
                                        <ButtonItem
                                            type="primary"
                                            htmlType="submit"
                                            style={{ display: "block", margin: "0 0 0 auto" }}
                                        >
                                            Guardar
                                        </ButtonItem>
                                    </Space>
                                </div>
                            }
                            onFinish={this.onFinish.bind(this)}
                            onFinishFailed={this.onFinishFailed}
                        // onFieldsChange={this.onFieldsChange.bind(this)}
                        >
                            <Tabs defaultActiveKey={1} onTabClick={(key) => {
                                if (key === '3') {
                                    this.arbolPresupuesto()
                                }
                                if (key === '4') {
                                    this.explosionInsumos()
                                }
                                if (key === '6') {
                                    this.hacerDiagramaGantt()
                                }
                                // if (key === '5') {
                                //     this.tablaPresupuesto()
                                // }
                            }}>
                                <TabPane tab="General" tabKey={1} key={1} forceRender>
                                    <Row>
                                        <Col span={12}>

                                            <FormItem
                                                label="Nombre"
                                                name="nombre"
                                                required
                                                margin
                                                pattern
                                            >
                                                <InputItem
                                                    placeholder="Nombre"
                                                />
                                            </FormItem>
                                            <FormItem
                                                label="Fecha Inicio"
                                                name="fechaInicio"
                                                required
                                                margin
                                                initialValue={moment()}
                                            >
                                                <DatePickerItem allowClear={false} placeholder="Fecha Inicio" />
                                            </FormItem>
                                            <FormItem
                                                label="Fecha Final"
                                                name="fechaFinal"
                                                required
                                                margin
                                                initialValue={moment()}
                                            >
                                                <DatePickerItem allowClear={false} placeholder="Fecha Final" />
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem label="Proyecto" name="proyectoId" margin required>
                                                <SelectItem disabled={this.state.confirmado} placeholder="Proyecto">
                                                    {this.state.objetoProyectos}
                                                </SelectItem>
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </TabPane>

                                <TabPane tab="Partidas" tabKey='5' key='5' forceRender>
                                    <Spin spinning={this.state.loadingExplosion}>
                                        {!this.state.confirmado ?
                                            <ButtonItem onClick={() => this.agregarPartida()} style={{marginBottom: 10}}>Agregar partida</ButtonItem>
                                            : undefined}
                                        <TableItem
                                            components={this.state.confirmado ? undefined : {
                                                body: {
                                                    cell: this.Cell.bind(this)
                                                },
                                            }}
                                            rowKey={(value) => value.key}
                                            size="small"
                                            expandable={{
                                                expandedRowKeys: this.state.expandedRowKeys,
                                                onExpandedRowsChange: this.onExpandedRowsChange.bind(this)
                                            }}
                                            dataSource={this.state.partidas}
                                            columns={mergedColumns}
                                        />
                                    </Spin>
                                </TabPane>

                                <TabPane tab='Explosion de insumos' tabKey='4' key='4'>
                                    <Spin spinning={this.state.loadingExplosion}>
                                        <Row>
                                            <Col span={24}>
                                                <TreeSelect
                                                    showSearch
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    // value={value}
                                                    dropdownStyle={{
                                                        maxHeight: 400,
                                                        overflow: 'auto',
                                                    }}
                                                    multiple
                                                    placeholder="Seleccionar"
                                                    allowClear
                                                    treeDefaultExpandAll
                                                    value={this.state.valueExplosion}
                                                    onChange={this.onChangeExplosion.bind(this)}
                                                    treeData={this.state.partidasArbol}
                                                />
                                            </Col>
                                        </Row>
                                        <table className="table">
                                            {this.state.insumosExplosionados.length > 0 ?
                                                <thead>
                                                    <tr>
                                                        <th className="th-border" style={{ width: "30%" }}>
                                                            Insumo
                                                        </th>
                                                        <th className="th-border" style={{ width: "10%" }}>
                                                            Unidad
                                                        </th>
                                                        <th className="th-border" style={{ width: "10%" }}>
                                                            Costo Unitario
                                                        </th>
                                                        <th className="th-border" style={{ width: "10%" }}>
                                                            Inventario
                                                        </th>
                                                        <th className="th-border" style={{ width: "10%" }}>
                                                            Cantidad
                                                        </th>
                                                        <th className="th-border" style={{ width: "10%" }}>
                                                            Min. C
                                                        </th>
                                                        {this.state.confirmado ?
                                                            <>
                                                                <th className="th-border" style={{ width: "10%" }}>
                                                                    Pedido
                                                                </th>
                                                                <th className="th-border" style={{ width: "10%" }}>
                                                                    Pedir
                                                                    <Tooltip title='Calcular lo sobrante'>
                                                                        <CalculatorOutlined onClick={() => this.pedirTotales()} style={{ marginLeft: '5px', color: '#00b5e2' }} />
                                                                    </Tooltip>
                                                                    <Tooltip title='Calcular con minimos de compra'>
                                                                        <CalculatorOutlined onClick={() => this.pedirMinCompraTotales()} style={{ marginLeft: '5px', color: 'red' }} />
                                                                    </Tooltip>
                                                                </th>
                                                                <th className="th-border" style={{ width: "10%" }}>
                                                                    Costo
                                                                </th>
                                                            </>
                                                            : null}
                                                        <th className="th-border" style={{ width: "10%" }}>
                                                            Costo T
                                                        </th>
                                                    </tr>
                                                </thead>
                                                : null}
                                            {this.state.insumosExplosionados.map((valueInsumo, index) => {
                                                return (
                                                    <tbody key={index}>
                                                        <tr>
                                                            <td className="td-border" style={{ textAlign: 'left', wordBreak: 'keep-all', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                                <p style={{ margin: 5 }}>{valueInsumo.nombre}</p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'center' }}>
                                                                <p style={{ margin: 5 }}>{valueInsumo.unidad}</p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}>{dineroDisabledMask(valueInsumo.costo)}</p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}>{valueInsumo.cantidadProyecto}</p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}>{valueInsumo.cantidad}</p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}>{valueInsumo.catidadMinCompra}</p>
                                                            </td>
                                                            {this.state.confirmado ?
                                                                <>
                                                                    <td className="td-border" style={{ textAlign: 'right' }}>
                                                                        <p style={{ margin: 5 }}>{valueInsumo.cantidadPedida}</p>
                                                                    </td>
                                                                    <td className="td-border" style={{ textAlign: 'right' }}>
                                                                        <FormItem
                                                                            name={"pedir" + valueInsumo.insumoId}
                                                                            noStyle
                                                                            numberCero
                                                                            type='number'
                                                                            max={valueInsumo.cantidad - valueInsumo.cantidadPedida}
                                                                        >
                                                                            <InputNumberItem
                                                                                onChange={this.cambioPedir.bind(this)}
                                                                                sinBorde
                                                                                style={{
                                                                                    backgroundColor: 'transparent'
                                                                                }}
                                                                                placeholder={"Max: " + (valueInsumo.cantidad - (valueInsumo.cantidadPedida || 0))}
                                                                            />
                                                                        </FormItem>
                                                                    </td>
                                                                    <td className="td-border" style={{ textAlign: 'right' }}>
                                                                        <p style={{ margin: 5 }}>{dineroDisabledMask(valueInsumo.costoPedir)}</p>
                                                                    </td>
                                                                </>
                                                                : null}
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}>{dineroDisabledMask(valueInsumo.costoTotal)}</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )
                                            })}

                                            <tbody key='T'>
                                                <tr>
                                                    <td className="td-border" style={{ textAlign: 'left' }}>
                                                        <p style={{ margin: 5 }}>Total:</p>
                                                    </td>
                                                    <td className="td-border" style={{ textAlign: 'center' }}>
                                                        <p style={{ margin: 5 }}></p>
                                                    </td>
                                                    <td className="td-border" style={{ textAlign: 'center' }}>
                                                        <p style={{ margin: 5 }}></p>
                                                    </td>
                                                    <td className="td-border" style={{ textAlign: 'center' }}>
                                                        <p style={{ margin: 5 }}></p>
                                                    </td>
                                                    <td className="td-border" style={{ textAlign: 'center' }}>
                                                        <p style={{ margin: 5 }}></p>
                                                    </td>
                                                    {this.state.confirmado ?
                                                        <>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}></p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}></p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}></p>
                                                            </td>
                                                        </>
                                                        : null}
                                                    <td className="td-border" style={{ textAlign: 'right' }}>
                                                        <p style={{ margin: 5 }}>{dineroDisabledMask(this.state.totalCostoPedir)}</p>
                                                    </td>
                                                    <td className="td-border" style={{ textAlign: 'right' }}>
                                                        <p style={{ margin: 5 }}>{dineroDisabledMask(this.state.costoTotalIE)}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {this.state.confirmado && this.state.partidasArbol.length > 0 ?
                                            <Popconfirm
                                                title="Seguro que quieres generar una requisicion de estos insumos, si la cantidad sobresale del presupuesto se pedira una autorizacion extra"
                                                onConfirm={() => this.requerirInsumos()}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <ButtonItem
                                                    blanco
                                                    style={{ display: "block", margin: "10px 0px 0 auto" }}
                                                >
                                                    Requerir insumos
                                                </ButtonItem>
                                            </Popconfirm>
                                            : null}
                                    </Spin>
                                </TabPane>

                                <TabPane tab='Diagrama de gantt' tabKey="6" key="6">
                                    <Gantt
                                        onDateChange={this.handleTaskChange.bind(this)}
                                        onExpanderClick={this.onExpanderClick.bind(this)}
                                        tasks={this.state.tasksDiagrama}
                                        columnWidth={20}
                                        rowHeight={35}
                                        fontSize={12}
                                        viewMode="Day"
                                        todayColor="#cadde1"
                                        TaskListHeader={({ headerHeight }) => (
                                            <div style={{ display: 'flex', fontFamily: 'Arial, Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue"', fontSize: '14px', height: headerHeight, border: "#e6e4e4 1px solid" }}>
                                                <div style={{ flex: 1, padding: 12 }}>Partida</div>
                                                <div style={{ flex: 1, padding: 12 }}>Inicio</div>
                                                <div style={{ flex: 1, padding: 12 }}>Fin</div>
                                            </div>
                                        )}
                                        locale="es"
                                        // TaskListTable={(props) => <CustomTaskListTable {...props} tasks={this.state.tasksDiagrama} />}

                                    />
                                </TabPane>
                            </Tabs>
                        </Form>
                    </Spin>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
    };
};

export default connect(mapStateToProps)(PresupuestoPU);
