import { message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  InputNumberItem,
  InputItem,
  ButtonItem,
  DatePickerItem,
  ErrorItem,
} from "../../../../../Components/Items";

class AgregarConciliacion extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    this.setState({ loading: false });
  }
  cerrarAgregarConciliacion(codigo) {
    this.props.cerrarAgregarConciliacion(codigo);
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "conciliaciones/agregarConciliacion",
        { 
            ...values, 
            empresaId: this.props.empresaId,
            bancoId: this.props.id
        },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se agrego una conciliacion");
          this.formRef.current.resetFields();
          this.cerrarAgregarConciliacion();
        } else {
          message.error("Sucedio un error y no se guardo");
        }
      });
  }
  render() {
    return (
      <>
        <ModalItem
          title="Agregar Conciliacion"
          visible={this.props.visible}
          spinning={this.state.loading}
          onCancel={this.cerrarAgregarConciliacion.bind(this)}
          paddingTop
        >
          <Form
            ref={this.formRef}
            name="agregarConciliacion"
            layout
            bottom={
              <ButtonItem
                type="primary"
                htmlType="submit"
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Cerrar Conciliación
              </ButtonItem>
            }
            onFinish={this.onFinish.bind(this)}
            onFinishFailed={this.onFinishFailed}
          >
            <FormItem
              label="Saldo Final"
              name="saldoFinal"
              required
              margin
              numberCero
            >
              <InputNumberItem
                placeholder="saldoFinal"
                dinero
              />
            </FormItem>
            <FormItem name="referencia" label="Referencia" margin pattern>
              <InputItem placeholder="Referencia" />
            </FormItem>
            <FormItem margin label="Fecha Inicial" name="fechaInicial">
              <DatePickerItem />
            </FormItem>
            <FormItem margin label="Fecha Final" name="fechaFinal">
              <DatePickerItem />
            </FormItem>
          </Form>
        </ModalItem>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(AgregarConciliacion);
