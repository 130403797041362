import React, { Component } from "react";
import {
  message,
  PageHeader,
  Spin,
  Row,
  Col,
  Checkbox,
  Divider,
} from "antd";
import {
  dineroDisabledMask,
  Form,
  FormItem,
  InputNumberItem,
  DatePickerItem,
  InputItem,
  HeaderItem,
  BoxItem,
  ButtonItem,
  round2Decimals,
  ErrorItem,
} from "../../../../../Components/Items";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

moment.locale("es");

class Conciliacion extends Component {
  formRef = React.createRef();
  formRefDatosConciliacion = React.createRef();
  state = {
    loading: false,
    cargosAbonos: [],
    saldoInicial: 0,
    saldoFinal: 0,
    totalCargos: 0,
    totalAbonos: 0,
  };
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await this.consultarDatos(true)
    this.setState({ loading: false });
  }

  async consultarDatos(consultarInfo) {
    await axios
    .post(
      "conciliaciones/datosParaConciliacion",
      {
        empresaId: this.props.empresaId,
        conciliacionId: this.props.location.state.id,
      },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      if (res.data.success !== 2) {
        message.error(
          "Error no se encontro informacion para la conciliacion"
        );
      } else {

        let totalCargos = 0
        let totalAbonos = 0

          const nuevosCargosAbonos = res.data.cargosAbonos.map((valueCargoAbono) => {
              let cargo
              let abono

              if(valueCargoAbono.cargo){
                  cargo = valueCargoAbono.monto
                  if(valueCargoAbono.conciliado){
                    totalCargos += valueCargoAbono.monto
                  }
              }else{
                  abono = valueCargoAbono.monto
                  if(valueCargoAbono.conciliado){
                    totalAbonos += valueCargoAbono.monto
                  }
              }

              return {
                  ...valueCargoAbono,
                  cargo,
                  abono,
              }
          })
        this.setState({ cargosAbonos: nuevosCargosAbonos, totalCargos, totalAbonos, saldoInicial: res.data.conciliacion.saldoInicial, saldoFinal: res.data.conciliacion.saldoFinal });
        if(consultarInfo){
          this.formRefDatosConciliacion.current.setFieldsValue({
            saldoFinal: res.data.conciliacion.saldoFinal,
            referencia: res.data.conciliacion.referencia,
            fechaInicial: moment(res.data.conciliacion.fechaInicial),
            fechaFinal: moment(res.data.conciliacion.fechaFinal,)
          });
        }
      }
    });
  }

  onFinishFailed(e) {
   ErrorItem(e);
  }
  onFinish() {
    const diferencia = round2Decimals((this.state.saldoInicial + this.state.totalCargos - this.state.totalAbonos) - this.state.saldoFinal)
    if (diferencia !== 0)
      return message.info(
        "Para guardar la conciliación la diferencia necesita ser 0"
      );
    this.setState({ loading: true });
    axios
      .post(
        "conciliaciones/guardarConciliacion",
        {
          empresaId: this.props.empresaId,
          conciliacionId: this.props.location.state.id,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Se ha guardado una conciliación");
          this.atras()
        } else if (res.data.success === 1) {
          message.error("No se pudo guardar la conciliacion");
        } else {
          message.error("Hubo un error y no se guardo la conciliacion");
        }
        this.setState({ loading: false });
      });
  }
  atras() {
    this.props.history.goBack();
  }
  datosConciliacionChange(values) {
    if(values.saldoFinal !== null){
      axios.post(
        "conciliaciones/editarConciliacion",
        {
          ...values,
          empresaId: this.props.empresaId,
          conciliacionId: this.props.location.state.id,
        },
        { headers: this.props.headersToken }
      ).then((res) => {
        if (res.data.success !== 2) {
          message.error("No se pudo editar la información de la conciliación");
        }else{
          this.consultarDatos()
        }
      })
    }
  }
  onChangeConciliacion(value, index){
    //Editar valor mientras se actualiza

    const nuevoArrayCargosAbonos = [...this.state.cargosAbonos]

    nuevoArrayCargosAbonos[index].conciliado = value

    this.setState({cargosAbonos: nuevoArrayCargosAbonos})

    const cargoAbono = this.state.cargosAbonos[index]
    if(value){
      axios.post(
        "conciliaciones/agregarCargoAbonoAConciliacion",
        {
          empresaId: this.props.empresaId,
          conciliacionId: this.props.location.state.id,
          cargoAbonoId: cargoAbono._id
        },
        { headers: this.props.headersToken }
      ).then((res) => {
        if (res.data.success !== 2) {
          message.error("No se pudo agregar el movimiento a la conciliación");
        }else{
          this.consultarDatos(false)
        }
      })
    }else{
      axios.post(
        "conciliaciones/eliminarCargoAbonoAConciliacion",
        {
          empresaId: this.props.empresaId,
          conciliacionId: this.props.location.state.id,
          cargoAbonoId: cargoAbono._id
        },
        { headers: this.props.headersToken }
      ).then((res) => {
        if (res.data.success !== 2) {
          message.error("No se pudo eliminar el movimiento a la conciliación");
        }else{
          this.consultarDatos(false)
        }
      })
    }
  }
  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title={"Conciliación: " + this.props.location.state.nombre}
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Row>
              <Col span={8}>
                <Form
                  ref={this.formRefDatosConciliacion}
                  name="datosConciliacion"
                  layout
                  onValuesChange={this.datosConciliacionChange.bind(this)}
                >
                  <HeaderItem>DATOS</HeaderItem>
                  <BoxItem>
                        <FormItem
                          label="Saldo Final"
                          name="saldoFinal"
                          required
                          margin
                          numberCero
                        >
                          <InputNumberItem placeholder="saldoFinal" dinero />
                        </FormItem>
                        <FormItem
                          name="referencia"
                          label="Referencia"
                          margin
                          pattern
                        >
                          <InputItem placeholder="Referencia" />
                        </FormItem>
                        <FormItem margin label="Fecha Inicial" name="fechaInicial">
                          <DatePickerItem />
                        </FormItem>
                        <FormItem margin label="Fecha Final" name="fechaFinal">
                          <DatePickerItem />
                        </FormItem>
                        <Row>
                      <Col span={24} style={{textAlign: 'center'}}>
                        <ButtonItem onClick={this.onFinish.bind(this)}>Guardar</ButtonItem>
                      </Col>
                    </Row>
                  </BoxItem>
                </Form>
              </Col>
              <Col span={16}>
              <HeaderItem>SALDO FINAL</HeaderItem>
                  <BoxItem>
                    <Row>
                      <Col span={5} style={{textAlign: 'center'}}>
                        <h1 style={{fontSize: 20}}>{dineroDisabledMask(this.state.saldoInicial)}</h1>
                        <p>Saldo Inicial</p>
                      </Col>
                      <Col span={1} style={{textAlign: 'center'}}>
                        <h1 style={{fontSize: 20}}>+</h1>
                      </Col>
                      <Col span={5} style={{textAlign: 'center'}}>
                        <h1 style={{fontSize: 20}}>{dineroDisabledMask(this.state.totalCargos)}</h1>
                        <p>Depositos</p>
                      </Col>
                      <Col span={1} style={{textAlign: 'center'}}>
                        <h1 style={{fontSize: 20}}>-</h1>
                      </Col>
                      <Col span={5} style={{textAlign: 'center'}}>
                      <h1 style={{fontSize: 20}}>{dineroDisabledMask(this.state.totalAbonos)}</h1>
                        <p>Pagos</p>
                      </Col>
                      <Col span={1} style={{textAlign: 'center'}}>
                        <h1 style={{fontSize: 20}}>=</h1>
                      </Col>
                      <Col span={5} style={{textAlign: 'center'}}>
                      <h1 style={{fontSize: 20}}>{dineroDisabledMask(this.state.saldoInicial + this.state.totalCargos - this.state.totalAbonos)}</h1>
                        <p>Saldo Final</p>
                      </Col>
                    </Row>
                    <Divider style={{ margin: 0, marginBottom: 10 }} />
                    <Row>
                      <Col span={8} style={{textAlign: 'center'}}>
                        <h1 style={{fontSize: 20}}>{dineroDisabledMask(this.state.saldoInicial + this.state.totalCargos - this.state.totalAbonos)}</h1>
                        <p>Saldo Final Calculado</p>
                      </Col>
                      <Col span={8} style={{textAlign: 'center'}}>
                        <h1 style={{fontSize: 20}}>{dineroDisabledMask(this.state.saldoFinal)}</h1>
                        <p>Saldo Final</p>
                      </Col>
                      <Col span={8} style={{textAlign: 'center'}}>
                        <h1 style={{fontSize: 20}}>{dineroDisabledMask((this.state.saldoInicial + this.state.totalCargos - this.state.totalAbonos) - this.state.saldoFinal)}</h1>
                        <p>Diferencia</p>
                      </Col>
                    </Row>
                    </BoxItem>
              </Col>
            </Row>
            <div style={{overflow: 'scroll', maxHeight: '50vh'}}>
              <table className="table" >
                {this.state.cargosAbonos.length > 0 ? (
                  <thead>
                    <tr>
                      <th className="th-border" style={{ width: "15%" }}>
                        Fecha
                      </th>
                      <th className="th-border" style={{ width: "35%" }}>
                        Concepto
                      </th>
                      <th className="th-border" style={{ width: "15%" }}>
                        Deposito (Cargo)
                      </th>
                      <th className="th-border" style={{ width: "15%" }}>
                        Pago (Abono)
                      </th>
                      <th className="th-border" style={{ width: "20%" }}>
                        Conciliado
                      </th>
                    </tr>
                  </thead>
                ) : null}
                {this.state.cargosAbonos.map((valueCA, index) => (
                  <tbody key={index.toString()}>
                    <tr>
                      <td className="td-border-center">
                        <p style={{ marginBottom: 0 }}>
                          {moment(
                            this.state.cargosAbonos[index]
                              .fechaRegistro
                          )
                            .locale("es")
                            .format("ll")}
                        </p>
                      </td>
                      <td className="td-border">
                        <p style={{ marginBottom: 0 }}>
                          {this.state.cargosAbonos[index].concepto}
                        </p>
                      </td>
                      <td className="td-border-right">
                        <p style={{ marginBottom: 0 }}>
                          {this.state.cargosAbonos[index].cargo
                            ? dineroDisabledMask(
                              this.state.cargosAbonos[index].cargo
                              )
                            : " - "}
                        </p>
                      </td>
                      <td className="td-border-right">
                        <p style={{ marginBottom: 0 }}>
                        {this.state.cargosAbonos[index].abono
                            ? dineroDisabledMask(
                              this.state.cargosAbonos[index].abono
                              )
                            : " - "}
                        </p>
                      </td>
                      <td className="td-border-center">
                          <Checkbox
                            checked={this.state.cargosAbonos[index].conciliado}
                            onChange={(e) => this.onChangeConciliacion(e.target.checked, index)}
                          />
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
              </div>
          </Spin>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(Conciliacion);
