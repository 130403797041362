import { Empty, PageHeader, Popconfirm, Space, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem } from "../../../Components/Items";
import moment from "moment";
import "moment/locale/es";
import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import AgregarTiposIncidencias from "./AgregarTiposIncidencias/AgregarTiposIncidencias";
import EditarTiposIncidencias from "./EditarTiposIncidencias/EditarTiposIncidencias";

moment.locale("es");

class TiposIncidencias extends Component {
  state = {
    modalAgregar: false,
    modalEditar: false,
    todasIncidencias: [],
    loading: false,
    incidenciaSeleccionada: null,
    tiposAcumulados: [],
  };

  abrirAgregarIncidencia = () => {
    this.setState({ modalAgregar: true, incidenciaSeleccionada: null });
  };

  cerrarAgregarIncidencia = () => {
    this.setState({ modalAgregar: false });
    this.consultarTodasIncidencias();
  };

  abrirEditarIncidencia = (incidencia) => {
    this.setState({ modalEditar: true, incidenciaSeleccionada: incidencia });
  };

  cerrarEditarIncidencia = () => {
    this.setState({ modalEditar: false });
    this.consultarTodasIncidencias();
  };

  componentDidMount() {
    this.consultarTodasIncidencias();
  }

  consultarTodasIncidencias() {
    this.setState({ loading: true });
    axios
      .post(
        "tiposIncidencias/selectTodosTiposIncidencias",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          if (res.data.tiposIncidencias && res.data.tiposIncidencias.length > 0) {
            const tiposIncidencias = res.data.tiposIncidencias.map((value) => ({
              ...value,
              key: value._id, 
              tipoDescripcion: value.tipo === 0 ? "Destajos" : value.tipo === 1 ? "Días" : "Horas",
              seConsideraDescripcion:
                value.seConsidera === 0
                  ? "Ninguno"
                  : value.seConsidera === 1
                  ? "Ausencia"
                  : value.seConsidera === 2
                  ? "Incapacidad"
                  : "Vacaciones",
            }));
            this.setState({
              todasIncidencias: tiposIncidencias,
            });
          } else {
            message.error("No se encontraron incidencias");
          }
        } else if (res.data.success === 1) {
          message.warning("Acción parcial completada, revisar detalles");
        } else if (res.data.success === 0) {
          message.error("No se pudieron recuperar las incidencias");
        } else {
          message.error("Error inesperado en el estado");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.error("Error al consultar las incidencias");
      });
  }

  agregarIncidencia = (datosIncidencia) => {
    axios
      .post(
        "tiposIncidencias/agregarTipoIncidencia",
        {
          ...datosIncidencia,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === true) {
          message.success("Tipo de incidencia agregado exitosamente");
          this.consultarTodasIncidencias();
          this.cerrarAgregarIncidencia();
        } else {
          message.error("Error al agregar el tipo de incidencia");
        }
      });
  };

  editarIncidencia = (datosIncidencia) => {
    axios
      .post(
        "tiposIncidencias/editarTipoIncidencia",
        {
          ...datosIncidencia,
          empresaId: this.props.empresaId,
          tipoIncidenciaId: this.state.incidenciaSeleccionada._id,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === true) {
          message.success("Tipo de incidencia actualizado exitosamente");
          this.consultarTodasIncidencias();
          this.cerrarEditarIncidencia();
        } else {
          message.error("Error al actualizar el tipo de incidencia");
        }
      });
  };

  deshabilitarIncidencia(id) {
    axios
      .post(
        "tiposIncidencias/deshabilitarTipoIncidencia",
        { tipoIncidenciaId: id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Tipo de incidencia deshabilitado exitosamente");
          this.consultarTodasIncidencias();
        } else {
          message.error("Error al deshabilitar el tipo de incidencia");
        }
      });
  }

  habilitarIncidencia(id) {
    axios
      .post(
        "tiposIncidencias/habilitarTipoIncidencia",
        { tipoIncidenciaId: id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Tipo de incidencia habilitado exitosamente");
          this.consultarTodasIncidencias();
        } else {
          message.error("Error al habilitar el tipo de incidencia");
        }
      });
  }

  render() {
    const columns = [
      {
        title: "Clave",
        dataIndex: "codigo", // Clave
        key: "codigo",
        sorter: (a, b) => a.codigo.localeCompare(b.codigo),
      },
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      },
      {
        title: "Tipo",
        dataIndex: "tipoDescripcion",
        key: "tipoDescripcion",
        sorter: (a, b) => a.tipo - b.tipo,
      },
      {
        title: "Se Considera",
        dataIndex: "seConsideraDescripcion",
        key: "seConsideraDescripcion",
      },
      {
        title: "Derecho a Sueldo",
        dataIndex: "derechoSueldo",
        key: "derechoSueldo",
        render: (derecho) => (derecho ? "Sí" : "No"),
      },
      {
        title: "Disminuye Séptimo",
        dataIndex: "disminuyeSeptimo",
        key: "disminuyeSeptimo",
        render: (disminuye) => (disminuye ? "Sí" : "No"),
      },
      {
        title: "Acción",
        key: "accion",
        render: (record) => (
          <Space size="middle">
            <EditOutlined
              onClick={() => this.abrirEditarIncidencia(record)}
              style={{ color: "blue" }}
            />
            {record.estado === 1 ? (
              <Popconfirm
                title="¿Seguro que quieres deshabilitar esta incidencia?"
                onConfirm={() => this.deshabilitarIncidencia(record._id)}
                okText="Sí"
                cancelText="No"
              >
                <CheckCircleOutlined style={{ color: "green" }} />
              </Popconfirm>
            ) : (
              <Popconfirm
                title="¿Seguro que quieres habilitar esta incidencia?"
                onConfirm={() => this.habilitarIncidencia(record._id)}
                okText="Sí"
                cancelText="No"
              >
                <StopOutlined style={{ color: "red" }} />
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ];

    

    return (
      <>
        <PageHeader
          title="Tipos de Incidencias"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <ButtonItem
              type="primary"
              key="agregarIncidencia"
              onClick={this.abrirAgregarIncidencia}
            >
              Agregar Tipo de Incidencia
            </ButtonItem>
          }
        />
        <TableItem
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<p>No hay registros de tipos de incidencias.</p>}
              />
            ),
          }}
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todasIncidencias}
          columns={columns}
          pagination={false}
        />
        {/* Modales para agregar y editar incidencias */}
        <AgregarTiposIncidencias
          visible={this.state.modalAgregar}
          cerrarModal={this.cerrarAgregarIncidencia}
          tiposAcumulados={this.state.tiposAcumulados}
          onGuardar={(datosIncidencia) =>
            this.state.incidenciaSeleccionada
              ? this.editarIncidencia(datosIncidencia)
              : this.agregarIncidencia(datosIncidencia)
          }
        />
        <EditarTiposIncidencias
          visible={this.state.modalEditar}
          cerrarModal={this.cerrarEditarIncidencia}
          incidencia={this.state.incidenciaSeleccionada}
          tiposAcumulados={this.state.tiposAcumulados}
          onGuardar={(datosIncidencia) => this.editarIncidencia(datosIncidencia)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(TiposIncidencias);
