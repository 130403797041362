import { Empty, PageHeader, Popconfirm, Space, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem } from "../../../Components/Items";
import { CheckCircleOutlined, StopOutlined, EditOutlined } from "@ant-design/icons";
import AgregarTiposAcumulados from "./AgregarTiposAcumulados/AgregarTiposAcumulados";
import EditarTiposAcumulados from "./EditarTiposAcumulados/EditarTiposAcumulados";

class TiposAcumulados extends Component {
  state = {
    todosTiposAcumulados: [],
    loading: false,
    modalAgregar: false,
    modalEditar: false,
    tipoAcumuladoSeleccionado: null,
  };

   // Abrir modal de agregar tipo de acumulado
   abrirAgregarTipoAcumulado = () => {
    this.setState({ modalAgregar: true });
  };

  // Cerrar modal de agregar tipo de acumulado
  cerrarAgregarTipoAcumulado = () => {
    this.setState({ modalAgregar: false });
    this.consultarTodosTiposAcumulados();
  };

  // Abrir modal de editar tipo de acumulado
  abrirEditarTipoAcumulado = (tipoAcumulado) => {
    this.setState({ modalEditar: true, tipoAcumuladoSeleccionado: tipoAcumulado });
  };

  // Cerrar modal de editar tipo de acumulado
  cerrarEditarTipoAcumulado = () => {
    this.setState({ modalEditar: false});
    this.consultarTodosTiposAcumulados();
  };

  componentDidMount() {
    this.consultarTodosTiposAcumulados();
  }

  // Consulta todos los tipos de acumulados activados
  consultarTodosTiposAcumulados() {
    this.setState({ loading: true });
    axios
      .post(
        "tiposAcumulados/selectTodosTiposAcumulados",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
          message.error("No se encontraron tipos de acumulados");
        } else {
          this.setState({
            todosTiposAcumulados: res.data.tiposAcumulados,
          });
        }
      });
  }

 

  // Deshabilitar tipo de acumulado
  deshabilitarTipoAcumulado = (id) => {
    axios
      .post(
        "tiposAcumulados/deshabilitarTipoAcumulado",
        { tipoAcumuladoId: id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Tipo de acumulado deshabilitado exitosamente");
          this.consultarTodosTiposAcumulados();
        } else {
          message.error("Error al deshabilitar el tipo de acumulado");
        }
      });
  };

  // Habilitar tipo de acumulado
  habilitarTipoAcumulado = (id) => {
    axios
      .post(
        "tiposAcumulados/habilitarTipoAcumulado",
        { tipoAcumuladoId: id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Tipo de acumulado habilitado exitosamente");
          this.consultarTodosTiposAcumulados();
        } else {
          message.error("Error al habilitar el tipo de acumulado");
        }
      });
  };

  render() {
    const columns = [
      {
        title: "Código",
        dataIndex: "codigo",
        key: "codigo",
        sorter: (a, b) => a.codigo.localeCompare(b.codigo),
      },
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      },
      {
        title: "Tipo de Acumulado",
        dataIndex: "tipoAcumulado",
        key: "tipoAcumulado",
        render: (tipoAcumulado) => {
          const tipo = Number(tipoAcumulado); // Convertir a número
          return (
            <span>
              {tipo === 0 ? "Días y horas" : tipo === 1 ? "Percepción, deducción y obligación" : "No definido"}
            </span>
          );
        },
      },
      
      
      {
        title: "Acción",
        key: "accion",
        render: (record) => (
          <Space size="middle">
            <EditOutlined
              onClick={() => this.abrirEditarTipoAcumulado(record)}
              style={{ color: "blue" }}
            />
            {record.estado === 1 ? (
              <Popconfirm
                title="¿Seguro que quieres deshabilitar este tipo de acumulado?"
                onConfirm={() => this.deshabilitarTipoAcumulado(record._id)}
                okText="Sí"
                cancelText="No"
              >
                <CheckCircleOutlined style={{ color: "green" }} />
              </Popconfirm>
            ) : (
              <Popconfirm
                title="¿Seguro que quieres habilitar este tipo de acumulado?"
                onConfirm={() => this.habilitarTipoAcumulado(record._id)}
                okText="Sí"
                cancelText="No"
              >
                <StopOutlined style={{ color: "red" }} />
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ];

    return (
      <>
        <PageHeader
          title="Tipos de Acumulados"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <ButtonItem
              type="primary"
              key="agregarTipoAcumulado"
              onClick={this.abrirAgregarTipoAcumulado}
            >
              Agregar Tipo de Acumulado
            </ButtonItem>
          }
        />
        <TableItem
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No hay registros de tipos de acumulados."
              />
            ),
          }}
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todosTiposAcumulados}
          columns={columns}
          pagination={false}
        />

        
        <AgregarTiposAcumulados
          visible={this.state.modalAgregar}
          cerrarModal={this.cerrarAgregarTipoAcumulado}
        />
          <EditarTiposAcumulados
            visible={this.state.modalEditar}
            cerrarModal={this.cerrarEditarTipoAcumulado}
            tipoAcumulado={this.state.tipoAcumuladoSeleccionado}
          />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(TiposAcumulados);
