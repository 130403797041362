import { Empty, PageHeader, Popconfirm, Space, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem } from "../../../Components/Items";
import moment from "moment";
import "moment/locale/es";
import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import AgregarTurno from "./AgregarTurno/AgregarTurno";
import EditarTurno from "./EditarTurno/EditarTurno";

moment.locale("es");

class Turnos extends Component {
  state = {
    modalAgregar: false,
    modalEditar: false,
    todosTurnos: [],
    loading: false,
    turnoSeleccionado: null,
    tiposJornada: [],
  };

  // Abrir modal para agregar
  abrirAgregarTurno = () => {
    this.setState({ modalAgregar: true, turnoSeleccionado: null });
  };

  // Cerrar modal de agregar
  cerrarAgregarTurno = () => {
    this.setState({ modalAgregar: false });
    this.consultarTodosTurnos();
  };

  // Abrir modal para editar
  abrirEditarTurno = (turno) => {
    this.setState({ modalEditar: true, turnoSeleccionado: turno });
  };

  // Cerrar modal de editar
  cerrarEditarTurno = () => {
    this.setState({ modalEditar: false });
    this.consultarTodosTurnos();
  };

  componentDidMount() {
    this.consultarTodosTurnos();
  }

  
  

  consultarTodosTurnos() {
    this.setState({ loading: true });
    axios
      .post(
        "turnos/selectTodosTurnos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
          message.error("No se encontraron turnos");
        } else {
          this.setState({
            todosTurnos: res.data.turnos.map((value) => {
              return { ...value, tipoJornadaDescripcion: value.tipoJornadaSat.c_TipoJornada + " - " + value.tipoJornadaSat.Descripcion, accion: { _id: value._id, estado: value.estado } };
            }),
          });
        }
      });
  }

  // Agrega un nuevo turno
  agregarTurno = (datosTurno) => {
    axios
      .post(
        "turnos/agregarTurno",
        {
          ...datosTurno,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === true) {
          message.success("Turno agregado exitosamente");
          this.consultarTodosTurnos();
          this.cerrarAgregarTurno();
        } else {
          message.error("Error al agregar el turno");
        }
      });
  };

  // Edita un turno existente
  editarTurno = (datosTurno) => {
    axios
      .post(
        "turnos/editarTurno",
        {
          ...datosTurno,
          empresaId: this.props.empresaId,
          turnoId: this.state.turnoSeleccionado._id,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === true) {
          message.success("Turno actualizado exitosamente");
          this.consultarTodosTurnos();
          this.cerrarEditarTurno();
        } else {
          message.error("Error al actualizar el turno");
        }
      });
  };

  // Deshabilita un turno
  deshabilitarTurno(id) {
    axios
      .post(
        "turnos/deshabilitarTurno",
        { turnoId: id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Turno deshabilitado exitosamente");
          this.consultarTodosTurnos();
        } else {
          message.error("Error al deshabilitar el turno");
        }
      });
  }

  // Habilita un turno
  habilitarTurno(id) {
    axios
      .post(
        "turnos/habilitarTurno",
        { turnoId: id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Turno habilitado exitosamente");
          this.consultarTodosTurnos();
        } else {
          message.error("Error al habilitar el turno");
        }
      });
  }

  render() {
    const columns = [
      {
        title: "Código",
        dataIndex: "codigo",
        key: "codigo",
        sorter: (a, b) => a.codigo.localeCompare(b.codigo),
      },
      {
        title: "Nombre del Turno",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      },
      {
      title: 'Tipo de Jornada',
      dataIndex: 'tipoJornadaDescripcion',
      key: 'tipoJornadaDescripcion',
      render: (text) => <span>{text}</span>,
    },
      {
        title: "Acción",
        key: "accion",
        render: (record) => (
          <Space size="middle">
            <EditOutlined
              onClick={() => this.abrirEditarTurno(record)}
              style={{ color: "blue" }}
            />
            <>
              {record.estado === 1 ? (
                <Popconfirm
                  title="¿Seguro que quieres deshabilitar este turno?"
                  onConfirm={() => this.deshabilitarTurno(record._id)}
                  okText="Sí"
                  cancelText="No"
                >
                  <CheckCircleOutlined style={{ color: "green" }} />
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="¿Seguro que quieres habilitar este turno?"
                  onConfirm={() => this.habilitarTurno(record._id)}
                  okText="Sí"
                  cancelText="No"
                >
                  <StopOutlined style={{ color: "red" }} />
                </Popconfirm>
              )}
            </>
          </Space>
        ),
      },
    ];

    return (
      <>
        <PageHeader
          title="Turnos"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <ButtonItem
              type="primary"
              key="agregarTurno"
              onClick={this.abrirAgregarTurno}
            >
              Agregar Turno
            </ButtonItem>
          }
        />
        <TableItem
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <p>No hay registros de turnos. Aquí puedes gestionar y llevar un control de los turnos.</p>
                  </>
                }
              />
            ),
          }}
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todosTurnos}
          columns={columns}
          pagination={false}
        />
        <AgregarTurno
          visible={this.state.modalAgregar}
          cerrarModal={this.cerrarAgregarTurno}
          tiposJornada={this.state.tiposJornada}
          onGuardar={(datosTurno) =>
            this.state.turnoSeleccionado
              ? this.editarTurno(datosTurno)
              : this.agregarTurno(datosTurno)
          }
        />
        <EditarTurno
          visible={this.state.modalEditar}
          cerrarModal={this.cerrarEditarTurno}
          turno={this.state.turnoSeleccionado}
          tiposJornada={this.state.tiposJornada}
          onGuardar={(datosTurno) =>
            this.editarTurno(datosTurno)
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(Turnos);
